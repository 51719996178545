import { FC, useEffect, useState } from 'react';

import { TDevice } from 'store/slices/devicesSlice/type';
import { devicesPlaceholderImg } from 'assets/images';
import { getSignedMediaUrl } from 'api/media';
export const DeviceHistoryCard: FC<{
  device: TDevice;
  setShowEditDevice?: any;
  totalCount?: number;
}> = ({ device, totalCount }) => {
  const [mediaUrl, setMediaUrl] = useState<string>();

  useEffect(() => {
    if (device?.image_url_id) {
      getSignedMediaUrl({ folder: 'analytics', media_url_id: device?.image_url_id })
        .then((response) => {
          setMediaUrl(response.data.url);
        })
        .catch((e) => {});
    }
  }, []);

  return (
    <div className='p-3 flex flex-col gap-3 bg-white dark:bg-darkBg rounded-[5px] w-fill  md:max-w-[250px]'>
      {/* image section */}
      <div className='flex gap-2 flex-col items-center'>
        <img
          src={mediaUrl || devicesPlaceholderImg}
          style={{ objectFit: 'cover' }}
          className='w-full h-40'
        />
      </div>
      {/* image section */}

      {/* bottom section */}
      <div className='flex flex-col gap-1'>
        <div className='flex items-center gap-1'>
          <p className='capitalize font-semibold text-[15px] opacity-50 '>Head Count:</p>
          <p className='capitalize font-semibold text-[15px]'>{device?.headcount}</p>
        </div>

        {totalCount ? (
          <div className='flex items-center gap-1'>
            <p className='capitalize font-semibold text-[15px] opacity-50 '>Total Count:</p>
            <p className='capitalize font-semibold text-[15px]'>{totalCount}</p>
          </div>
        ) : null}
      </div>
      {/* bottom section */}
    </div>
  );
};
