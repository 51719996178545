import { Link, useLocation } from 'react-router-dom';

import ConstructionIcon from 'assets/icons/ConstructionIcon';
import EventsIcon from 'assets/icons/EventsIcon';
import HospitalityIcon from 'assets/icons/HospitalityIcon';
import IndustryRetailIcon from 'assets/icons/IndustryRetailIcon';
import MinistryIcon from 'assets/icons/MinistryIcon';
import ProductionIcon from 'assets/icons/ProductionIcon';
import TransportIcon from 'assets/icons/TransportIcon';
import paths from 'constants/paths';

const navigationItems = [
  {
    itemName: 'Retail',
    itemIcon: <IndustryRetailIcon />,
    route: paths.INDUSTRIES_PAGE,
  },
  {
    itemName: 'Congregation',
    itemIcon: <MinistryIcon />,
    route: paths.MINISTRY_PAGE,
  },
  {
    itemName: 'Hospitality',
    itemIcon: <HospitalityIcon />,
    route: paths.HOSPITALITY_PAGE,
  },
  {
    itemName: 'Transport',
    itemIcon: <TransportIcon />,
    route: paths.TRANSPORT_PAGE,
  },
  {
    itemName: 'Construction',
    itemIcon: <ConstructionIcon />,
    route: paths.CONSTRUCTION_PAGE,
  },
  {
    itemName: 'Production',
    itemIcon: <ProductionIcon />,
    route: paths.PRODUCTION_PAGE,
  },
  {
    itemName: 'Events',
    itemIcon: <EventsIcon />,
    route: paths.EVENTS_PAGE,
  },
] as const;

const IndustryNav = () => {
  const { pathname } = useLocation();

  const isActiveNav = (value: string) => value === pathname;

  return (
    <nav className=' pt-10  pb-10 '>
      <ul className='flex flex-col gap-4'>
        {navigationItems.map(({ itemIcon, itemName, route }, index) => (
          <li key={index}>
            <Link
              to={route}
              className={`flex cursor-pointer py-6 px-4 font-Avenir-Next-Pro font-medium text-sm sm:text-base gap-x-2 rounded-2xl transition-all duration-300 items-center ${
                isActiveNav(route)
                  ? 'bg-accent text-white dark:bg-white dark:text-accent'
                  : 'text-black hover:bg-accent/80 dark:bg-darkBgOld dark:text-white dark:hover:bg-white dark:hover:text-accent hover:text-white bg-white hover:brightness-110 '
              }`}
            >
              <span>{itemIcon}</span>
              <h2 className='font-Avenir-Next-Pro font-semibold'>{itemName}</h2>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default IndustryNav;
