import React, { useState } from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import PrimaryButton from 'components/buttons/PrimaryButton';

const PremTab = () => {
  const [deploymentType, setDeploymentType] = useState<'saas' | 'onPrem'>('saas');
  const [licenseKey, setLicenseKey] = useState('');
  const [licenseInfo, setLicenseInfo] = useState<{
    startDate: string;
    expiryDate: string;
    issuedTo: string;
  } | null>(null);

  const handleDeploymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeploymentType(event.target.value as 'saas' | 'onPrem');
    setLicenseKey('');
    setLicenseInfo(null);
  };

  const handleSave = () => {
    if (licenseKey && deploymentType === 'onPrem') {
      setLicenseInfo({
        startDate: '2024-01-01',
        expiryDate: '2025-01-01',
        issuedTo: 'Churcha Corporation',
      });
    }
  };

  return (
    <div className='max-w-lg mx-9 mt-12 min-h-96'>
      <p className='mb-4 text-[18px] md:text-[24px] font-semibold'>Deployment Settings</p>
      <hr />

      <div className='flex gap-4 mb-6'>
        <RadioGroup
          value={deploymentType}
          onChange={handleDeploymentChange}
          className='w-full gap-6'
        >
          <div className='flex justify-between w-full'>
            <FormControlLabel
              value='saas'
              control={<Radio color='primary' />}
              label='Software as a Service'
              className='font-bold'
            />
            <FormControlLabel
              value='onPrem'
              control={<Radio color='primary' />}
              label='On-Prem'
              className='font-bold'
            />
          </div>
        </RadioGroup>
      </div>

      {deploymentType === 'onPrem' && (
        <div className='w-full mt-4 py-1 bg-gray-50 dark:bg-transparent'>
          <input
            type='text'
            className='w-full p-3 border border-gray-300 rounded-md mb-6 focus:outline-none focus:ring-2 focus:ring-blue-500'
            value={licenseKey}
            onChange={(e) => setLicenseKey(e.target.value)}
            placeholder='Enter your license key'
          />

          {licenseInfo && (
            <div className='w-full mt-4 py-1 bg-gray-50 dark:bg-transparent'>
              <p className='text-sm'>
                <strong>License Key Info:</strong>
              </p>
              <p className='text-sm'>Issued to: {licenseInfo.issuedTo}</p>
              <p className='text-sm'>Start Date: {licenseInfo.startDate}</p>
              <p className='text-sm'>Expiry Date: {licenseInfo.expiryDate}</p>
            </div>
          )}
        </div>
      )}

      <PrimaryButton variant='neutral' onClick={handleSave} className='my-8'>
        Save Settings
      </PrimaryButton>
    </div>
  );
};

export default PremTab;
