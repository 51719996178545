import { AxiosResponse } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TCreatePaymentIntent,
  TCreateSubscription,
  TGetInvoiceHistoryParams,
  TPaymentMethodSetupIntentResponse,
  TSetDefaultPaymentMethod,
  TUpdateBillingDetails,
  TUpdateSubscriptionParams,
  TUserSubscriptionParams,
} from 'store/slices/subscriptionSlice/types';
import { TInvoiceHistory } from 'types/global/invoice';
import { TSubscriptionDetails } from 'types';
import { TPaymentMethods } from 'types/global/paymentMethods';

export const createSubscriptionRequest = ({
  org_id,
  subscription,
  subscription_type,
  free = false,
}: TCreateSubscription) => {
  const sendedValue = free
    ? { org_id, subscription, subscription_type, trial_period_days: 30 }
    : { org_id, subscription, subscription_type };

  return client.post(
    endpoints.SubscriptionsService.createSubscription(org_id, subscription, subscription_type),
    null,
    {
      params: sendedValue,
    },
  );
};

export const createPaymentIntentRequest = (options: TCreatePaymentIntent) =>
  client.post(endpoints.SubscriptionsService.createPaymentIntent(), null, {
    params: { ...options },
  });

export const setupPaymentIntentRequest = (
  org_id: number,
): Promise<AxiosResponse<TPaymentMethodSetupIntentResponse>> =>
  client.post(endpoints.SubscriptionsService.setupPaymentIntent(), null, {
    params: { org_id },
  });

export const getUserSubscriptionsRequest = (
  params: TUserSubscriptionParams,
): Promise<AxiosResponse<TSubscriptionDetails>> =>
  client.get(endpoints.SubscriptionsService.getUserSubscriptions(), { params });

export const getPaymentMethodsRequest = (org_id: number): Promise<AxiosResponse<TPaymentMethods>> =>
  client.get(endpoints.SubscriptionsService.getPaymentMethods(), { params: { org_id } });

export const getDefaultPaymentMethodsRequest = (org_id: number) =>
  client.get(endpoints.SubscriptionsService.getDefaultPaymentMethod(), { params: { org_id } });

export const setDefaultPaymentMethodRequest = (options: TSetDefaultPaymentMethod) =>
  client.put(endpoints.SubscriptionsService.setDefaultPaymentMethod(), null, {
    params: { ...options },
  });

export const removePaymentMethodRequest = (options: TSetDefaultPaymentMethod) =>
  client.delete(endpoints.SubscriptionsService.removePaymentMethod(), { params: { ...options } });

export const updateBillingDetailsRequest = (options: TUpdateBillingDetails) =>
  client.put(endpoints.SubscriptionsService.updateBillingDetails(), options);

export const getInvoiceHistoryRequest = (
  params: TGetInvoiceHistoryParams,
): Promise<AxiosResponse<TInvoiceHistory[]>> =>
  client.get(endpoints.SubscriptionsService.getInvoiceHistory(), { params });

export const updateSubscriptionRequest = (params: TUpdateSubscriptionParams) =>
  client.put(endpoints.SubscriptionsService.updateSubscription(), null, { params: { ...params } });
