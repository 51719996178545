export { default as dataStructure } from './options';
export { default as breakPoints } from './BreakPoints';

export { default as membersStructure } from './membersStructure';
export { default as subscriptionPlans } from './SubscriptionPlans';
export { default as processFormValues } from './ProcessFormValues';
export { default as permissions } from './Permissions';
export const xLink = 'https://www.x.com/HawkeyeVisionTK';
export const instagramLink = 'https://www.instagram.com/hawkeyevisiontek/';
export const BOT_NAME = 'hawkeyevision ai agent';
