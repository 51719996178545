import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

import { authApi } from 'api';
import { TokenProvider } from 'store/slices/authSlice/types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { Routes } from 'types';
import { SignInToastMessages } from 'constants/ToastMessages';
import { organizationsSelector } from 'store/slices/organizationsSlice/selectors';
import useAppSelector from 'hooks/useAppSelector';
import { isOrganisationCreated } from 'helpers/utils';
import useAppDispatch from 'hooks/useAppDispatch';
import { getAllOrganisations as getOrganizations } from 'store/thunks';

const useUserOrganizationsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organizationsResponse = useAppSelector(organizationsSelector);

  const createdOrganizations = useMemo(
    () => organizationsResponse?.data?.filter(isOrganisationCreated) ?? [],
    [organizationsResponse?.data],
  );

  const organizationFromStorage = BrowserStorageService.get(
    BrowserStorageKeys.CurrentOrganizationId,
  );

  const getAllOrganizations = async () => {
    try {
      return await dispatch(getOrganizations()).unwrap();
    } catch (error) {
      const err = error as AxiosError;
      throw err;
      // eslint-disable-next-line no-console
    }
  };

  const currentOrganization = useMemo(
    () =>
      createdOrganizations.find(
        (organization) => organization?.id === Number(organizationFromStorage),
      ),
    [createdOrganizations, organizationFromStorage],
  );

  const loginDependentInOrganization = async (
    org_id: number,
    tokenProvider: TokenProvider,
    socialToken: string,
  ) => {
    const start = toast.loading(SignInToastMessages.SIGNIN_START);
    try {
      const sendedData = new FormData();

      sendedData.append('oauth_token', String(socialToken));
      sendedData.append('oauth_provider', tokenProvider);
      sendedData.append('org_id', String(org_id));

      const response = await authApi.signInOauthRequest(sendedData);
      const accessToken = response?.data?.token?.access_token;

      if (accessToken) {
        BrowserStorageService.set(BrowserStorageKeys.AccessToken, JSON.stringify(accessToken));

        BrowserStorageService.set(
          BrowserStorageKeys.RefreshToken,
          JSON.stringify(response?.data?.token?.refresh_token),
        );
      }

      if (response) {
        navigate(Routes.Dashboard);

        BrowserStorageService.set(BrowserStorageKeys.CurrentOrganizationId, JSON.stringify(org_id));

        toast.success(SignInToastMessages.SIGNIN_SUCCESS, {
          id: start
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(SignInToastMessages.SIGNIN_FAILURE, {
        id: start
      });

      throw err;
    }
  };

  return {
    getAllOrganizations,
    createdOrganizations,
    loginDependentInOrganization,
    currentOrganization,
  };
};

export default useUserOrganizationsList;
