import { AxiosResponse } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';

import {
  TAllEventsParams,
  TCreateEventRequestBody,
  TCurrentEventsParams,
  TEditEventRequestBody,
  TEvent,
  TEventCountsResponse,
  TEventsDataParams,
  TEventsDataResponse,
  TEventsResponse,
  TUpdateEventsBody,
} from './types';

export const getEventCountsRequest = (): Promise<AxiosResponse<TEventCountsResponse>> =>
  client.get(endpoints.EventsService.getEventCounts());

export function getAllEventsRequest(
  params: TAllEventsParams,
): Promise<AxiosResponse<TEventsResponse>> {
  const options = { params };
  return client.get(endpoints.EventsService.getAllEvents(), options);
}

export const getCurrentEventsRequest = (
  params: TCurrentEventsParams,
): Promise<AxiosResponse<TEventsResponse>> =>
  client.get(endpoints.EventsService.getCurrentEvents(), { params });

export const getEventsDataRequest = (
  params?: TEventsDataParams,
): Promise<AxiosResponse<TEventsDataResponse>> =>
  client.get(endpoints.EventsService.getEventsData(), { ...(params ? { params } : {}) });

export function createEventRequest(body: TCreateEventRequestBody): Promise<AxiosResponse<TEvent>> {
  return client.post(endpoints.EventsService.postEvent(), body);
}

export function getEventByIdRequest(id: number): Promise<AxiosResponse<TEvent>> {
  return client.get(endpoints.EventsService.getEventById(id));
}

export function editEventRequest(body: TEditEventRequestBody): Promise<AxiosResponse<TEvent>> {
  return client.put(endpoints.EventsService.postEvent(), body);
}
export function updateEventRequest(body: TUpdateEventsBody): Promise<AxiosResponse<TEvent>> {
  return client.put(endpoints.EventsService.updateEvent(), body);
}
