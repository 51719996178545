import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TWeatherResponse } from 'api/weather/types';

import { TInitialWeatherState } from './types';
import { getWeather } from './thunks';

const initialState: TInitialWeatherState = {
  temperature: null,
  iconId: '',
  latitude: '',
  longitude: '',
};

const weatherSlice = createSlice({
  name: 'weather',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getWeather.fulfilled, (state: any, action: PayloadAction<TWeatherResponse>) => {
      state.temperature = action.payload.current.temp;
      state.iconId = action.payload.current.weather[0].icon;
    });
  },
});

export default weatherSlice.reducer;
