import { FormControl, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';

import { useDarkMode } from 'hooks';
import { Colors } from 'types';

export type TDropdownItem = {
  value: string;
  label: string;
};

type TDropDownProps = {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  items: TDropdownItem[];
  width?: number;
  className?: string;
} & Pick<SelectProps, 'displayEmpty' | 'renderValue'>;

export default function Dropdown({
  value,
  onChange,
  items,
  width = 140,
  className,
  ...props
}: TDropDownProps) {
  const { darkMode } = useDarkMode();

  return items.length ? (
    <FormControl sx={{ m: 1, width }} className={className}>
      <Select
        value={value}
        onChange={onChange}
        sx={{
          color: darkMode ? Colors.LIGHT_GRAY : Colors.ENIGMATIC_MIDNIGHT,
          background: 'transparent',
          border: darkMode ? '1px solid #ffffff' : '',
          '.MuiSvgIcon-root': {
            color: darkMode ? 'gray-200' : '',
          },
        }}
        {...props}
      >
        {items.map((item) => (
          <MenuItem key={item.label} value={item.label}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null;
}
