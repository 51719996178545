import type { TPlanDurationPrices } from 'types/global/durationTypes';

export type ProcessSubType = 'api' | 'device';

export enum ProcessFormNames {
  PROCESSFORM = 'Process form',
  SUBSCRIPTIONPLAN = 'Subscription plan',
  PAYMENT = 'Payment',
  INVITEUSERS = 'Invite users',
}

export type TSendDuration = {
  title: string;
  duration: string | TPlanDurationPrices;
  subscription_type?: ProcessSubType;
} & Partial<{
  amount: number;
}>;

export type TOrganizationCreateData = {
  email: string;
  location: string;
  phoneNumber: string;
  organization: string;
  typeOfBusiness: string;
  industryVertical: string;
};

export type TOrganizationData = {
  zip: string;
  city: string;
  size: string;
  email: string;
  state: string;
  address: string;
  location: string;
  phoneNumber: string;
  organization: string;
  ownerLastName: string;
  typeOfBusiness: string;
  ownerFirstName: string;
  industryVertical: string;
};

export type TOrganizationForm = {
  zip: string;
  email: string;
  city: string;
  state: string;
  location: string;
  phoneNumber: string;
  organization: string;
  ownerLastName: string;
  ownerFirstName: string;
  industryVertical: string;
  typeOfBusiness: string | null;
};
