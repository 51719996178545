import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import classNames from 'classnames';

import PrimaryButton from 'components/buttons/PrimaryButton';
import EventCard from 'components/Cards/EventCard';
import SidebarModal from 'components/shared/SideBarModal';
import AddEvents from 'components/events/AddEvents';
import { useAppDispatch, useAppSelector, useDarkMode } from 'hooks';
import { eventsSelector } from 'store/slices/eventsSlice/selectors';
import { TEvent, TTimeFrame } from 'api/events/types';
import { getAllEvents, getCurrentEvents, getEventByID, getEventsData } from 'store/thunks';
import EditEvent from 'components/events/EditEvent';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { TUserRole } from 'store/slices/authSlice/types';
import { userRoleEnums } from 'components/shared/EmployeeStatus/types';
import { EventsEmptyState, EventsEmptyStateDark } from 'assets/icons';

const formatEvents = (event: TEvent) => ({
  title: event.event_title,
  dateTime: event.start_date + 'T' + event.start_time + 'Z',
  totalHeadCount: event.total_head_count,
  img: undefined,
  event_id: event.id,
});

const Events = () => {
  const [showAddEvents, setShowAddEvents] = useState(false);
  const [showEditEvent, setEditEvent] = useState(false);
  const events = useAppSelector(eventsSelector);
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userDataSelector);
  const { user_roles } = userData ?? {};

  const currentEvents = useMemo(
    () => events.currentEvents.data.map(formatEvents),
    [events.currentEvents.data],
  );

  const futureEvents = useMemo(
    () => events.futureEvents.data.map(formatEvents),
    [events.futureEvents.data],
  );

  const pastEvents = useMemo(
    () => events.pastEvents.data.map(formatEvents),
    [events.pastEvents.data],
  );

  const getEvents = useCallback(async (timeframe: TTimeFrame) => {
    await dispatch(
      getAllEvents({
        timeframe: timeframe,
        sort_by: 'start_date',
      }),
    );
    if (timeframe === 'All') await dispatch(getEventsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOngoingEvents = useCallback(async () => {
    await dispatch(
      getCurrentEvents({
        datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sort_by: 'start_time',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData?.member_info) {
      getOngoingEvents()
        .then(() => getEvents('Future'))
        .then(() => getEvents('Past'))
        .then(() => getEvents('All'))
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.member_info]);

  const getEventByIdEdit = async (id: number) => {
    setEditEvent(true);
    await dispatch(getEventByID(id));
  };

  const userWritePermission = user_roles?.some((user: TUserRole) => {
    return user?.role?.toLowerCase() === userRoleEnums.OWNER?.toLowerCase();
  });
  const { darkMode } = useDarkMode();
  return (
    <>
      <SidebarModal
        component={<AddEvents onClose={() => setShowAddEvents(false)} />}
        show={showAddEvents}
        setShow={() => setShowAddEvents(false)}
      />
      <SidebarModal
        component={<EditEvent onClose={() => setEditEvent(false)} />}
        show={showEditEvent}
        setShow={() => setEditEvent(false)}
      />
      <div className='flex items-center justify-between'>
        <p className='font-semibold text-[24px]'>Events</p>
        {userWritePermission && (
          <PrimaryButton
            icon={<PlusIcon className='w-[16px]' />}
            onClick={() => setShowAddEvents(true)}
            id='addEvent'
            variant='secondary'
          >
            New Event
          </PrimaryButton>
        )}
      </div>
      {currentEvents.length ? (
        <div>
          <p className='my-6 font-semibold text-[24px] '>Ongoing Events</p>
          <div className='flex gap-4 flex-wrap  my-4'>
            {currentEvents.map(({ title, dateTime, img, event_id }) => (
              <EventCard
                key={title}
                isPast
                title={title}
                dateTime={dateTime}
                img={img}
                getEventByIdEdit={getEventByIdEdit}
                event_id={event_id}
                className='dark:bg-dark-card-bg'
              />
            ))}
          </div>
        </div>
      ) : null}
      {futureEvents.length ? (
        <div className={classNames('', { 'mt-10': !!currentEvents.length })}>
          <p className='my-6 font-semibold text-[24px] '>Upcoming Events</p>
          <div className='flex gap-4 flex-wrap  my-4'>
            {futureEvents.map(({ title, dateTime, img, event_id }) => (
              <EventCard
                key={title}
                title={title}
                dateTime={dateTime}
                img={img}
                getEventByIdEdit={getEventByIdEdit}
                event_id={event_id}
                userWritePermission={userWritePermission}
                className='dark:bg-dark-card-bg'
              />
            ))}
          </div>
        </div>
      ) : null}

      {pastEvents.length ? (
        <div className={classNames('', { 'mt-10': currentEvents.length || futureEvents.length })}>
          <p className='my-6 font-semibold text-[24px]'>Past Events</p>
          {/* <div className='grid lg:grid-cols-4 grid-cols-1 gap-y-4 my-4'> */}
          <div className='flex gap-4 flex-wrap  my-4'>
            {pastEvents.map(({ title, dateTime, img, totalHeadCount }) => (
              <EventCard
                isPast
                key={title}
                title={title}
                dateTime={dateTime}
                img={img}
                totalHeadCount={totalHeadCount}
                className='dark:bg-dark-card-bg'
              />
            ))}
          </div>
        </div>
      ) : null}

      {!futureEvents.length && !pastEvents.length && !currentEvents.length && (
        <div
          className='bg-white rounded-[14px] my-10 p-5 lg:p-12 lg:h-[70vh] overflow-hidden dark:bg-dark-card-bg'
          id='eventListSection'
        >
          <div className='flex flex-col items-center justify-center grow mt-10 pt-10'>
            {darkMode ? (
              <EventsEmptyStateDark style={{ height: '12em', width: '17em' }} />
            ) : (
              <EventsEmptyState style={{ height: '12em', width: '17em' }} />
            )}
            <p className='font-semibold mt-5'>No events at this time</p>
          </div>
        </div>
      )}
    </>
  );
};
export default Events;
