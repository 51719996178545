import { Pagination, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import ArrowRightAltOutlined from '@mui/icons-material/ArrowForwardIos';
import './styles/dashboard.scss';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { DeviceCard } from 'components/Cards/DeviceCard';
import { Tabs } from 'components/Tabs/Tab';
import { DisplayType } from 'types';
import PrimaryButton from 'components/buttons/PrimaryButton';
import AddDevice from 'components/devices/AddDevice';
import SidebarModal from 'components/shared/SideBarModal';
import { getDevices, getDevicesCounts, getDevicesHistoryByID } from 'store/thunks';
import { useAppDispatch, useAppSelector, useDarkMode, useDeviceControlModal } from 'hooks';
import { capitalizeFirstLetter } from 'utils';
import EditDevice from 'components/devices/EditDevices';
import { TDevice, TDeviceStatus } from 'store/slices/devicesSlice/type';
import { DeviceHistoryCard } from 'components/Cards/DeviviceHistoryCard';
import { EmptyStateDevices, EmptyStateDevicesDark } from 'assets/icons';
import { deviceHistorySelector, devicesCountsSelector } from 'store/slices/devicesSlice/selectors';
import { clearHistory } from 'store/slices/devicesSlice';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { userRoleEnums } from 'components/shared/EmployeeStatus/types';
import { TUserRole } from 'store/slices/authSlice/types';
import DeviceControlModal from 'components/Modal/DeviceControlModal';
import { getDeviceByIdRequest } from 'api/devices';
import { getSignedMediaUrl } from 'api/media';
import { DeviceCardSkeleton } from 'components/skeleton';

import { onlineStatus } from '.';

const limit = 10;

const Devices = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userDataSelector);
  const { user_roles, member_info } = userData ?? {};
  const deviceHistory = useAppSelector(deviceHistorySelector);
  const { total_count, online_count, offline_count } = useAppSelector(devicesCountsSelector);
  const [display, setDisplay] = useState<DisplayType>('cards');
  const [showAddAddDevice, setShowAddDevice] = useState(false);
  const [devices, setDevices] = useState<TDevice[]>([]);
  const [offlineDevices, setOfflineDevices] = useState<TDevice[]>([]);
  const [onlineDevices, setOnlineDevices] = useState<TDevice[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [showEditDevice, setShowEditDevice] = useState(false);
  const [getEditDeviceInfoData, setEditDeviceInfoData] = useState(null);
  const [devicesPageNumber, setDevicesPageNumber] = useState(1);
  const [historyPageNumber, setHistoryPageNumber] = useState(1);
  const [totalHistoryCount, setTotalHistoryCount] = useState(0);
  const [showAllDevices, setShowAllDevices] = useState(true);
  const [getDeviceHistoryInfoId, setDeviceHistoryInfoId] = useState<TDevice>();
  const [isEditing, setIsEditing] = useState(false);
  const { deviceToShow, isDeviceModalOpen, openDeviceModal, closeDeviceModal } =
    useDeviceControlModal();
  const { deviceId } = useParams();
  const [loading, setLoading] = useState(true);

  const getAllDevices = async (offset = 0, status?: TDeviceStatus) => {
    const options = {
      limit,
      offset,
      ...(status ? { status } : {}),
    };
    setLoading(true);
    try {
      const response = await dispatch(getDevices(options)).unwrap();
      switch (status) {
        case onlineStatus.ONLINE:
          setOnlineDevices(response?.data ?? []);
          break;
        case onlineStatus.OFFLINE:
          setOfflineDevices(response?.data ?? []);
          break;
        default:
          setDevices(response?.data ?? []);
          break;
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      toast.error(err.message || 'An Unknown error occurred');
    }
  };

  const getAllDevicesHistory = async (offset = 0) => {
    const options = {
      limit,
      offset,
      id: getDeviceHistoryInfoId?.id,
    };
    try {
      const response = await dispatch(getDevicesHistoryByID(options)).unwrap();
      setTotalHistoryCount(response.total_count);
    } catch (err: any) {
      // toast.error(err.message || "Unknowned error occured");
    }
  };

  const filteredDevices = useMemo(() => {
    switch (activeTabIndex) {
      case 1:
        return onlineDevices;
      case 2:
        return offlineDevices;

      default:
        return devices;
    }
  }, [activeTabIndex, devices, offlineDevices, onlineDevices]);

  const maxDevicesPages = useMemo(() => {
    switch (activeTabIndex) {
      case 1:
        return Math.ceil(online_count / limit);
      case 2:
        return Math.ceil(offline_count / limit);
      default:
        return Math.ceil(total_count / limit);
    }
  }, [activeTabIndex, offline_count, online_count, total_count]);

  const maxHistoryPages = useMemo(() => Math.ceil(deviceHistory / limit), [deviceHistory]);

  const tabs: { text: string }[] = useMemo(
    () => [
      { text: `All devices (${online_count + offline_count})` },
      { text: `Online (${online_count})` },
      { text: `Offline (${offline_count})` },
    ],
    [online_count, offline_count],
  );

  const getEditDeviceInfo = (value: any) => {
    setEditDeviceInfoData(value);
    setIsEditing(true);
  };

  const getDevicesByStatus = useCallback((index = 0, offset = 0) => {
    switch (index) {
      case 1:
        getAllDevices(offset, onlineStatus.ONLINE); // Get online devices
        break;
      case 2:
        getAllDevices(offset, onlineStatus.OFFLINE); // Get offline devices
        break;
      default:
        getAllDevices(offset); // Get all devices
        break;
    }
  }, []);

  const onChangeDevicesPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setDevicesPageNumber(value);
    const offsetBy = value * limit - limit;
    getAllDevices(offsetBy);
    getDevicesByStatus(activeTabIndex, offsetBy);
  };

  const onChangeHistoryPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setHistoryPageNumber(value);
    const offsetBy = value * limit - limit;
    getAllDevicesHistory(offsetBy);
  };

  const navigateToDevices = () => {
    setShowAllDevices(true);
    dispatch(clearHistory(deviceHistory));
  };

  const userWritePermission = user_roles?.some((user: TUserRole) => {
    return user?.role?.toLowerCase() === userRoleEnums.OWNER?.toLowerCase();
  });

  const openDeviceControlForSingleDevice = useCallback(
    async (deviceId: string) => {
      try {
        const deviceResponse = await getDeviceByIdRequest({ device_id: deviceId });
        const { id, device_name, latest_image_url_id } = deviceResponse.data.data[0] ?? {};

        if (!latest_image_url_id) throw Error('No device image to control');

        const mediaResponse = await getSignedMediaUrl({
          folder: 'analytics',
          media_url_id: latest_image_url_id as string,
        });

        openDeviceModal({
          deviceId: id,
          mediaUrl: mediaResponse.data.url,
          deviceName: device_name,
          modalType: 'view',
        });
      } catch (e) {
        // Do something with the error
      }
    },
    [openDeviceModal],
  );

  useEffect(() => {
    if (deviceId) {
      openDeviceControlForSingleDevice(deviceId);
    }
  }, [deviceId, openDeviceControlForSingleDevice]);

  useEffect(() => {
    if (member_info) dispatch(getDevicesCounts());
  }, [dispatch, member_info]);

  useEffect(() => {
    // If we have user data, fetch data for the dashboard
    if (member_info) {
      getAllDevices();
    }
    if (!showAllDevices && member_info) {
      getAllDevicesHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member_info, showAllDevices]);
  const { darkMode } = useDarkMode();
  return (
    <>
      <SidebarModal
        component={
          <AddDevice onClose={() => setShowAddDevice(false)} getAllDevices={getAllDevices} />
        }
        show={showAddAddDevice}
        setShow={() => {
          setShowAddDevice(false);
        }}
      />

      <SidebarModal
        component={
          <EditDevice
            onClose={() => setShowEditDevice(false)}
            getAllDevices={getAllDevices}
            getEditDeviceInfoData={getEditDeviceInfoData}
          />
        }
        show={showEditDevice}
        setShow={() => {
          setShowEditDevice(false);
        }}
      />

      <div>
        {!showAllDevices && (
          <div className='flex items-center gap-1 mb-5 cursor-pointer'>
            <p
              className='text-[15px] font-semibold text-accent dark:text-dark-text'
              onClick={() => navigateToDevices()}
            >
              Devices
            </p>
            <ArrowRightAltOutlined fontSize='small' sx={{ fontSize: '15px' }} />
            <p className='text-[15px] font-semibold text-accent dark:text-dark-text'>History</p>
          </div>
        )}
        <div className='mb-10 flex items-center justify-between'>
          <p className='text-[24px] font-semibold'>
            {showAllDevices ? 'Devices' : `Device History`}
          </p>
          {showAllDevices && userWritePermission && (
            <PrimaryButton
              icon={<PlusIcon className='w-[16px]' />}
              onClick={() => setShowAddDevice(true)}
              variant='secondary'
              id='addDevice'
            >
              Add Device
            </PrimaryButton>
          )}
        </div>
        {showAllDevices && (
          <span id='devicesList'>
            <div
              className='bg-white rounded-[14px] my-10 p-5 lg:p-12 overflow-scroll dark:bg-dark-card-bg'
              id='clearScrollbar'
            >
              <div className='my-3'>
                <Tabs
                  tabs={tabs}
                  activeTabIndex={activeTabIndex}
                  setCurrentTab={(_, index) => {
                    setActiveTabIndex(index);
                    setDevicesPageNumber(1);
                    getDevicesByStatus(index);
                  }}
                />
              </div>

              <div className='my-5  h-[80%] flex flex-col justify-between'>
                <div className='my-5'>
                  {/* clever did some adjustments to the grid-cols- property */}
                  {display == 'cards' && (
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-x-6 gap-y-8'>
                      {loading
                        ? Array.from({ length: 6 }, (_, i) => <DeviceCardSkeleton key={i} />)
                        : filteredDevices?.map((device: TDevice) => (
                            <DeviceCard
                              key={device.id}
                              device={device}
                              totalCount={device?.headcount ?? 0}
                              setShowEditDevice={setShowEditDevice}
                              getAllDevicesHistory={getAllDevicesHistory}
                              setShowAllDevices={setShowAllDevices}
                              setDeviceHistoryInfoId={setDeviceHistoryInfoId}
                              getEditDeviceInfo={getEditDeviceInfo}
                              onOpenDeviceModal={openDeviceModal}
                            />
                          ))}
                    </div>
                  )}

                  {display == 'cards' && !filteredDevices?.length && (
                    <div className='mt-5 pt-5 flex justify-center'>
                      <div className='mt-5 pt-5 flex flex-col justify-center'>
                        {darkMode ? (
                          <EmptyStateDevicesDark style={{ height: '12em', width: '17em' }} />
                        ) : (
                          <EmptyStateDevices style={{ height: '12em', width: '17em' }} />
                        )}
                        <p className='font-semibold pt-1'>
                          Oops, No devices available to show here.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </span>
        )}

        {!showAllDevices && (
          <div
            className='bg-white rounded-[14px] my-10 p-5 lg:p-12 lg:h-[70vh] overflow-scroll dark:bg-dark-card-bg'
            id='clearScrollbar'
          >
            <p className='text-[24px] font-semibold'>
              {capitalizeFirstLetter(getDeviceHistoryInfoId?.device_name || '')}
            </p>
            <div className='my-5  h-[80%] flex flex-col justify-between'>
              <div className='my-5'>
                {display == 'cards' && (
                  <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3'>
                    {deviceHistory?.map((device: any) => (
                      <DeviceHistoryCard
                        key={device.id}
                        device={device}
                        totalCount={totalHistoryCount}
                        setShowEditDevice={setShowEditDevice}
                      />
                    ))}
                  </div>
                )}
                {!deviceHistory?.length && (
                  <div className='mt-5 pt-5 flex justify-center'>
                    <div className='mt-5 pt-5 flex flex-col justify-center'>
                      {darkMode ? (
                        <EmptyStateDevicesDark style={{ height: '12em', width: '17em' }} />
                      ) : (
                        <EmptyStateDevices style={{ height: '12em', width: '17em' }} />
                      )}
                      <p className='font-semibold pt-1'>Oops, no device history at the moment.</p>
                    </div>
                  </div>
                )}
              </div>
              {maxHistoryPages > 1 ? (
                <div className='my-4'>
                  <Stack spacing={2} direction={'row'} justifyContent={'end'}>
                    <Pagination
                      count={maxHistoryPages}
                      page={historyPageNumber}
                      onChange={onChangeHistoryPage}
                      variant='outlined'
                      shape='rounded'
                    />
                  </Stack>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <DeviceControlModal
        isOpen={isDeviceModalOpen}
        onClose={closeDeviceModal}
        deviceId={deviceToShow?.deviceId}
        deviceName={deviceToShow?.deviceName}
        mediaUrl={deviceToShow?.mediaUrl}
        modalType={deviceToShow?.modalType}
      />
    </>
  );
};

export default Devices;
