import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import MoreHorizIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FC, useState } from 'react';

import { useDarkMode } from 'hooks';
import { TCreateTeamParams } from 'store/slices/teamsSlice/type';
import UserAvatar from 'components/shared/UserAvatar';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';

function Cell(props: TableCellProps) {
  const { darkMode } = useDarkMode();

  return <TableCell sx={{ color: darkMode ? 'white' : 'black' }}>{props.children}</TableCell>;
}

export const TeamMemberTable: FC<{
  teammembers: TCreateTeamParams[];
  editTeamMember: any;
  onClickEdit: any;
  deleteTeamMemberbyId: any;
  userWritePermission?: boolean;
}> = ({ teammembers, deleteTeamMemberbyId, onClickEdit, userWritePermission }) => {
  const tableHeaders = userWritePermission
    ? ['Photo', 'Name', 'Position', 'Contact Info', 'Availability', 'Actions']
    : ['Photo', 'Name', 'Position', 'Contact Info', 'Availability'];

  const onUpdate = (member: any) => {
    onClickEdit(member);
  };
  const [openModal, setOpenModal] = useState(false);
  const [memberDetails, setMemberDetails] = useState<TCreateTeamParams>();

  const confirmDeleteFunction = (member: TCreateTeamParams) => {
    setOpenModal(true);
    setMemberDetails(member);
  };

  const deleteTeamMember = () => {
    deleteTeamMemberbyId(memberDetails?.id);
    setOpenModal(false);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((text, index) => (
                <Cell key={index} variant='head'>
                  {text}
                </Cell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {teammembers?.length > 0 &&
              teammembers?.map((member, index) => (
                <TableRow key={index}>
                  <Cell>
                    <UserAvatar
                      fullName={member?.name}
                      image={member?.profile_pic_url}
                      alt='profile image'
                    />
                  </Cell>
                  <Cell>{member.name}</Cell>
                  <Cell>{member.position}</Cell>
                  <Cell>{member.contact_info}</Cell>
                  <Cell>{member.avaliability}</Cell>
                  {userWritePermission && (
                    <TableCell>
                      <div>
                        <IconButton onClick={() => onUpdate(member)}>
                          <BorderColorOutlinedIcon className='text-[#464646] dark:text-white mr-2' />
                        </IconButton>
                        <IconButton onClick={() => confirmDeleteFunction(member)}>
                          <MoreHorizIcon className='text-[#ff0000]' />
                        </IconButton>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDeleteModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title='Remove team member?'
        description='Confirming will permanently remove team member.'
        onDelete={deleteTeamMember}
      />
    </>
  );
};
