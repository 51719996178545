import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Routes } from 'types';
import { TUserProfile } from 'store/slices/authSlice/types';
import { RootObject, generatePlan } from 'utils/generatePlan';
import { userAllRolesSelector, userDataSelector } from 'store/slices/authSlice/selectors';
import { allRolesSelector } from 'store/slices/rolesSlice/selectors';
import { TInviteUser } from 'store/slices/invitationsSlice/types';
import { TCreateSubscription } from 'store/slices/subscriptionSlice/types';
import { createOrganizationSelector } from 'store/slices/organizationsSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService, LocalForageService } from 'services';
import { TInvitedUser } from 'components/InviteUsersDrawer/types';
import { LocalForageKeys } from 'services/localForage/types';
import { startFreeSubscription } from 'store/slices/subscriptionSlice';
import InviteUsers from 'components/views/InviteUsers';
import Container from 'components/shared/Container';
import HawkEyeLogo from 'components/shared/HawkEyeLogo';
import PrimaryStepper from 'components/stepper/PrimaryStepper';
import { capitalizeText } from 'utils/capitalizeText';
import { inviteUser } from 'store/thunks';

import { breakPoints, processFormValues } from '../../constants';
import useOrganizeProcess from '../../hooks/useOrganizeProcess';
import { CreateAccountForm, Payment, SubscriptionPlanBoxes } from '../../components';
import {
  useWindowSize,
  useAppDispatch,
  useAppSelector,
  useStripePayment,
  useUserOrganizationsList,
  useClearStorage,
  useDarkMode,
} from '../../hooks';
import { inviteUserSchema, paymentSchema } from '../../constants/Schemas';
import { TInvitedUserElement } from '../../components/views/InviteUsers/types';
import { createOrganizationFormValuesGenerator } from '../../utils';
import { Button } from '../../components/index';

import styles from './ProcessForm.module.scss';
import { ProcessFormNames, TOrganizationForm, TSendDuration } from './types';

import type { TOrganization } from 'store/slices/organizationsSlice/types';

const ProcessForm = () => {
  const dispatch = useAppDispatch();
  const { clearStorage } = useClearStorage(false);

  const navigate = useNavigate();

  const { width } = useWindowSize();

  const haveActiveOrganization = BrowserStorageService.get(
    BrowserStorageKeys.HaveCreatedOrganization,
  );

  const email = BrowserStorageService.get(BrowserStorageKeys.CurrentUserEmail, { session: true });

  useEffect(() => {
    if (!haveActiveOrganization) {
      BrowserStorageService.set(BrowserStorageKeys.InProcessForm, 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getAllOrganizations } = useUserOrganizationsList();

  useEffect(() => {
    getAllOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userInfo = useAppSelector(userDataSelector);
  const currentOrganization = useAppSelector(createOrganizationSelector);

  const [companyLogo, setCompanyLogo] = useState<File | null>(null);

  const [activeCardForSend, setActiveCardForSend] = useState<TSendDuration>({
    title: 'basic',
    duration: 'Monthly',
    amount: 0,
    subscription_type: 'api',
  });

  const [typeOfBusinessValue, setTypeOfBusinessValue] = useState<string>(
    currentOrganization?.business_type || '',
  );

  const allRoles = useAppSelector(allRolesSelector);

  const userRoles: any = useAppSelector(userAllRolesSelector);

  const organizationIdFromStorage = BrowserStorageService.get(
    BrowserStorageKeys.CurrentOrganizationId,
  );

  const amountFromStorage = BrowserStorageService.get(BrowserStorageKeys.CurrentAmount, {
    session: true,
  });

  const [isCardValid, setIsCardValid] = useState<boolean>(false);

  const activeExistedvalue = BrowserStorageService.get(BrowserStorageKeys.ProcessFormValue, {
    session: true,
  });

  const token = BrowserStorageService.get(BrowserStorageKeys.AccessToken);

  const isFirstTimeUser =
    BrowserStorageService.get(BrowserStorageKeys.IsFirstTimeUser, {
      session: true,
    }) === 'true';

  const [activeStep, setActiveStep] = useState<number>(
    Number(activeExistedvalue) || processFormValues[0]?.id,
  );

  const [activeStepName, setActiveStepName] = useState<ProcessFormNames>(
    ProcessFormNames.PROCESSFORM,
  );

  const [skipped, setSkipped] = useState(new Set<number>());

  const activeStatName = useMemo(
    () => processFormValues.find((name) => name?.id === activeStep),
    [activeStep],
  );

  const buttonClasses = classNames(styles.container__footer__next);

  const getCompanyLogoImg = (file: File | null) => setCompanyLogo(file);
  const getTypeOfBusinessValue = (type: string) => setTypeOfBusinessValue(type);

  useEffect(() => {
    if (activeStatName) {
      setActiveStepName(activeStatName.title);
    }
  }, [activeExistedvalue, activeStatName, activeStep]);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  useEffect(() => {
    if (activeStep === processFormValues.length) {
      navigate(Routes.Success);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  // useEffect(() => {
  //   token && isFirstTimeUser === 'false' && navigate(Routes.Landing);  //look at later
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });

    navigate(Routes.Success);
  };

  const handleBack = (e: SyntheticEvent<HTMLButtonElement, Event>) => {
    e.preventDefault();

    if (activeStep === 0) {
      clearStorage();
      navigate(Routes.Landing);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBackLogo = (e: SyntheticEvent<HTMLDivElement, Event>) => {
    e.preventDefault(); // Prevents issue where user couldn't go back to step 1

    clearStorage();
    navigate(Routes.Landing);

    setActiveStep(0); // Set active step to 0
  };

  const handleCardChange = (event: StripeCardElementChangeEvent) =>
    setIsCardValid(!!event.complete);

  const {
    handleSubmit: organizationSubmit,
    control: organizationControl,
    reset,
    getValues,
    formState: { isDirty, errors: organizationError, isSubmitting: organizationSubmitting },
  } = useForm<TOrganizationForm>({
    // resolver: yupResolver(organizationCreate),
    values: createOrganizationFormValuesGenerator({
      currentOrganization: currentOrganization as TOrganization,
      userInfo: userInfo as TUserProfile,
    }),
    mode: 'all',
  });

  const resetTypeOfBusiness = () => {
    reset({ ...getValues(), typeOfBusiness: '' });
  };

  const organizationStepNext = isDirty && !!typeOfBusinessValue?.trim()?.length;

  const {
    control: inviteUsersForm,
    handleSubmit: inviteUsersSubmit,
    formState: { errors, isSubmitting: inviteUsersSubmitting },
    watch: inviteUsersWatch,
  } = useForm({
    resolver: yupResolver(inviteUserSchema),
  });

  const state = inviteUsersWatch();

  const isValid = state?.invitedUsers?.some(
    (item: TInvitedUser) => !item?.email?.length || !item?.fullName?.length,
  );

  const {
    handleSubmit: paymentSubmit,
    control: paymentControl,
    watch,
    formState: { errors: paymentError, isValid: paymentValid, isSubmitting: paymentSubmitting },
  } = useForm({
    resolver: activeStepName === ProcessFormNames.PAYMENT ? yupResolver(paymentSchema) : undefined,
    mode: 'all',
  });

  const sendedData = watch();

  const { organizeAction, stripeSecret } = useOrganizeProcess();

  const { handleSubmit: paySubmit } = useStripePayment({
    clientSecret: stripeSecret as string,

    name: sendedData?.name,
    street: sendedData?.address,
    state: sendedData?.state,
    postal_code: sendedData?.zip,
    orgId: Number(organizationIdFromStorage),
    amount: activeCardForSend.amount || Number(amountFromStorage),
  });

  const disableButtonCondition =
    activeStepName === ProcessFormNames.PROCESSFORM
      ? !organizationStepNext || organizationSubmitting
      : activeStepName === ProcessFormNames.SUBSCRIPTIONPLAN
      ? activeCardForSend?.title === 'basic'
      : activeStepName === ProcessFormNames.PAYMENT
      ? (!isCardValid && activeStepName === ProcessFormNames.PAYMENT) ||
        paymentValid === false ||
        paymentSubmitting
      : activeStepName === ProcessFormNames.INVITEUSERS
      ? isValid || inviteUsersSubmitting
      : false;

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    BrowserStorageService.set(BrowserStorageKeys.ProcessFormValue, String(activeStep + 1), {
      session: true,
    });

    setSkipped(newSkipped);
  };

  const foundedRole = (name: string) =>
    allRoles.find((role: any) => {
      if (role.value === name) {
        return role;
      }
    });

  const callSubscriptionWithFree = async () => {
    const newSubscription: TCreateSubscription = {
      org_id: Number(currentOrganization?.id) || Number(organizationIdFromStorage),
      subscription: generatePlan(activeCardForSend as RootObject),
      subscription_type: activeCardForSend.subscription_type,
    };

    const subscriptionData = await LocalForageService.get(
      LocalForageKeys.ProcessFormSecondStepData,
    );

    if (!subscriptionData) {
      await LocalForageService.set(LocalForageKeys.ProcessFormSecondStepData, newSubscription);
    } else {
      await LocalForageService.update(LocalForageKeys.ProcessFormSecondStepData, newSubscription);
    }

    dispatch(startFreeSubscription());

    handleNext();
  };

  const handleFormChange: SubmitHandler<FieldValues> = async (data) => {
    if (activeStepName === ProcessFormNames.PROCESSFORM) {
      const object = {
        data,
        ['userInfo.email']: JSON.parse(email as string),
        companyLogo,
      };

      const organizationData = await LocalForageService.get(
        LocalForageKeys.ProcessFormFirstStepData,
      );

      if (!organizationData) {
        await LocalForageService.set(LocalForageKeys.ProcessFormFirstStepData, object);
        BrowserStorageService.set(BrowserStorageKeys.CurrentIndustry, data?.industryVertical, {
          session: true,
        });
      } else {
        await LocalForageService.update(LocalForageKeys.ProcessFormFirstStepData, object);
        BrowserStorageService.update(BrowserStorageKeys.CurrentIndustry, data?.industryVertical, {
          session: true,
        });
      }

      handleNext();
    } else if (activeStepName === ProcessFormNames.SUBSCRIPTIONPLAN) {
      const newSubscription: TCreateSubscription = {
        org_id: Number(currentOrganization?.id) || Number(organizationIdFromStorage),
        subscription: generatePlan(activeCardForSend as RootObject),
        subscription_type: activeCardForSend.subscription_type,
      };

      const subscriptionData = await LocalForageService.get(
        LocalForageKeys.ProcessFormSecondStepData,
      );

      if (!subscriptionData) {
        await LocalForageService.set(LocalForageKeys.ProcessFormSecondStepData, newSubscription);
      } else {
        await LocalForageService.update(LocalForageKeys.ProcessFormSecondStepData, newSubscription);
      }

      handleNext();
    } else if (activeStepName === ProcessFormNames.PAYMENT) {
      const organizationDetails = await LocalForageService.get(
        LocalForageKeys.ProcessFormFirstStepData,
      );

      const organizationData = organizationDetails?.data ?? {};

      // Get user's phone number from payment form
      const phoneNumber = data?.number;

      if (!organizationDetails) {
        await LocalForageService.set(LocalForageKeys.ProcessFormFirstStepData, {
          data: phoneNumber,
        });
      } else {
        await LocalForageService.update(LocalForageKeys.ProcessFormFirstStepData, {
          ...organizationDetails,
          data: {
            ...organizationData,
            phoneNumber,
          },
        });
      }

      const { secret, orgId, subscriptionSecret } = await organizeAction();

      if (secret) {
        await paySubmit(secret as string, orgId, handleNext, subscriptionSecret);
      }

      reset({
        organization: '',
        industryVertical: '',
      });
    } else if (activeStepName === ProcessFormNames.INVITEUSERS) {
      const sendedData: TInviteUser = data?.invitedUsers?.map((item: TInvitedUserElement) => ({
        email: item.email,
        full_name: item.fullName,
        org_id: currentOrganization?.id || Number(organizationIdFromStorage),
        role_id: item.role || 2,
      }));
      const response = await dispatch(inviteUser(sendedData));

      if (response.meta.requestStatus === 'fulfilled') {
        navigate(Routes.Success);
      }
    } else {
      handleNext();
    }
  };

  const submitTypes =
    activeStepName === ProcessFormNames.PROCESSFORM
      ? organizationSubmit(handleFormChange)
      : activeStepName === ProcessFormNames.INVITEUSERS
      ? inviteUsersSubmit(handleFormChange)
      : paymentSubmit(handleFormChange);

  // if (tokenInLocalStorage) {
  //   return <Navigate replace={true} to={Routes.ActivityFeed} />;
  // }

  // if (!tokenInLocalStorage && haveActiveOrganization === 'true') {
  //   return <Navigate replace={true} to={Routes.OrganizationsList} />;
  // }

  const latestProcess =
    activeStep !== processFormValues.length && activeStepName === ProcessFormNames.INVITEUSERS;

  const prevText = activeStepName === ProcessFormNames.PROCESSFORM ? 'Cancel' : 'Previous';

  const { darkMode } = useDarkMode();

  useEffect(() => {
    if (token && !isFirstTimeUser && haveActiveOrganization === 'true') navigate(Routes.Dashboard);
  }, [haveActiveOrganization, isFirstTimeUser, navigate, token]);

  return (
    <Container className='gap-x-10 py-2'>
      <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={handleBackLogo}>
        <HawkEyeLogo isDarkMode={darkMode} />
      </div>
      <div className=' m-auto  h-full mt-10 w-full lg:w-[80%] flex flex-col justify-between'>
        <div>
          <div className='m-auto my-3'>
            <PrimaryStepper
              activeStep={activeStep}
              steps={Object.values(ProcessFormNames).map((v) => capitalizeText(v))}
            />
          </div>
          <form autoComplete='off' className={styles.container} onSubmit={submitTypes}>
            {activeStep !== processFormValues.length &&
              activeStepName === ProcessFormNames.INVITEUSERS &&
              Number(width) <= breakPoints.TABLET_L && (
                <Button
                  disabled={activeStep <= 0}
                  onClick={handleBack}
                  className={styles.container__preview}
                  textClassName={styles.container__text}
                >
                  Prev
                </Button>
              )}
            {activeStepName === ProcessFormNames.PROCESSFORM && (
              <CreateAccountForm
                resetField={resetTypeOfBusiness}
                errors={organizationError}
                getFile={getCompanyLogoImg}
                control={organizationControl}
                getTypeOfBusinessValue={getTypeOfBusinessValue}
              />
            )}
            {activeStepName === ProcessFormNames.SUBSCRIPTIONPLAN && (
              <SubscriptionPlanBoxes
                withTitle
                className={styles.container__cards}
                activeCardForSend={activeCardForSend}
                setActiveCardForSend={setActiveCardForSend}
                callSubscriptionWithFree={callSubscriptionWithFree}
              />
            )}
            {activeStepName === ProcessFormNames.PAYMENT && (
              <Payment
                control={paymentControl}
                errors={paymentError}
                handleCardValid={handleCardChange}
                isDisabled={paymentSubmitting}
              />
            )}
            {activeStepName === ProcessFormNames.INVITEUSERS && (
              <InviteUsers control={inviteUsersForm} errors={errors} />
            )}
            <div className='flex items-center justify-between w-full mt-8'>
              {!latestProcess && (
                <button
                  className='py-3 px-7 bg-[#DFEDFF] rounded-[4px] text-accent text-sm md:text-base cursor-pointer'
                  onClick={handleBack}
                >
                  {prevText}
                </button>
              )}

              {latestProcess && (
                <button
                  className='py-3 px-7 bg-[#DFEDFF] rounded-[4px] text-accent text-sm md:text-base cursor-pointer'
                  onClick={handleSkip}
                  disabled={activeStep === processFormValues.length}
                >
                  Skip
                </button>
              )}
              <button
                type='submit'
                className='py-3 px-7 bg-accent dark:bg-dark-card-bg-old rounded-[4px] text-white text-sm md:text-base  cursor-pointer'
                disabled={disableButtonCondition}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default ProcessForm;
