/* eslint-disable react/jsx-props-no-spreading */
import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import { Icon } from '@iconify/react';
import { ArrowLongUpIcon } from '@heroicons/react/24/solid';

interface PrimaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'button';
  id?: string;
  className?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  isHover?: boolean;
  isArrow?: boolean;
  variant?: 'primary' | 'secondary' | 'neutral';
}

const PrimaryButton: React.FC<PropsWithChildren<PrimaryButtonProps>> = ({
  children,
  type,
  id,
  className,
  icon,
  iconPosition,
  onClick,
  isLoading,
  disabled,
  isHover,
  isArrow,
  variant = 'primary',
  ...buttonProps
}) => {
  function handleOnClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    if (onClick) onClick();
  }
  return (
    <button
      id={id}
      type={type === 'button' ? 'button' : 'submit'}
      className={cn(`btn-primary   p-4 md:p-7   group ${className}`, {
        'gap-2': icon,
        'flex-row-reverse': iconPosition === 'right',
        'opacity-70 cursor-not-allowed hover:brightness-100': disabled || isLoading,
        'hover:bg-accent transition duration-1000 ease-in-out': isHover,
        'bg-accent dark:bg-white text-white dark:text-accent ': variant === 'primary',
        'bg-accent dark:bg-btn-dark text-white dark:text-white ': variant === 'neutral',
        'bg-accent !border-accent border dark:!border-white dark:bg-darkBg dark:text-white text-white':
          variant === 'secondary',
      })}
      onClick={handleOnClick}
      disabled={disabled || isLoading}
      {...buttonProps}
    >
      {icon}
      {children}
      {isLoading && <Icon icon='eos-icons:loading' className='text-sm ml-2' />}
      {isArrow && (
        <ArrowLongUpIcon className='h-6 w-6 text-white dark:text-accent !rotate-[45deg] group-hover:!rotate-[55deg]  ml-4' />
      )}
    </button>
  );
};

PrimaryButton.defaultProps = {
  id: '',
  type: 'button',
  className: '',
  disabled: false,
  isLoading: false,
  icon: undefined,
  iconPosition: 'left',
  onClick: undefined,
  isHover: false,
  isArrow: false,
};
export default PrimaryButton;
