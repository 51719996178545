const HospitalityIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='none' viewBox='0 0 24 25'>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M1 22.5h22M19.78 22.51v-4.46M19.8 11.39c-1.22 0-2.2.98-2.2 2.2v2.27c0 1.22.98 2.2 2.2 2.2 1.22 0 2.2-.98 2.2-2.2v-2.27c0-1.22-.98-2.2-2.2-2.2zM2.1 22.5V6.53c0-2.01 1-3.02 2.99-3.02h6.23c1.99 0 2.98 1.01 2.98 3.02V22.5M5.8 8.75h4.95M5.8 12.5h4.95M8.25 22.5v-3.75'
      ></path>
    </svg>
  );
};

export default HospitalityIcon;
