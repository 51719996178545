import { Switch } from '@mui/material';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { TUpdateUser } from 'store/slices/authSlice/types';
import { updateUser } from 'store/thunks';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import useTopLoader from 'hooks/useTopLoader';
import LoadingBar from 'react-top-loading-bar';
import { loaderOptions } from 'constants/sizeOptions';

const NotificationSettingsTabs = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userDataSelector);
  const { member_info, general_user_info } = userData ?? {};
  const [in_app_notification, set_in_app_notification] = useState(false);
  const [email_notification, set_email_notification] = useState(false);
  const [sms_notification, set_sms_notification] = useState(false);
  const {progress, loaderHandler, resetLoader} = useTopLoader();

  const handleInAppChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    set_in_app_notification(event.target.checked);
    const options: TUpdateUser = {
      name: member_info?.name || general_user_info?.name || '',
      in_app_notification: event.target.checked,
    };
    loaderHandler(dispatch(updateUser(options)))
  };

  const handleEmailNotificationChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    set_email_notification(event.target.checked);
    const options: TUpdateUser = {
      name: member_info?.name || general_user_info?.name || '',
      email_notification: event.target.checked,
    };
    loaderHandler(dispatch(updateUser(options)))
  };

  const handleSMSNotificationChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    set_email_notification(event.target.checked);
    const options: TUpdateUser = {
      name: member_info?.name || general_user_info?.name || '',
      sms_notification: event.target.checked,
    };
    loaderHandler(dispatch(updateUser(options)))
  };

  useEffect(() => {
    set_in_app_notification(member_info?.in_app_notification || false);
    set_email_notification(member_info?.email_notification || false);
    set_sms_notification(member_info?.sms_notification || false);
  }, [in_app_notification, email_notification, sms_notification, member_info]);

  return (
    <>
    {progress > 0 && (
        <LoadingBar
          color={loaderOptions.color}
          progress={progress}
          height={loaderOptions.height}
          loaderSpeed={1600}
          onLoaderFinished={resetLoader}
        />
      )}
      <div className='p-5 md:p-10 shadow-sm '>
        <p className='p-3 text-[18px] md:text-[24px] font-semibold'>Notifications</p>
      </div>
      <div className='p-10 max-w-5xl m-7' id="notificationTabSteps">
        <div className='grid grid-cols-6 gap-10'>
          <div className='flex flex-col gap-3 col-span-4'>
            <p className='font-semibold'>General</p>
            <p className='text-[#222222] opacity-70 dark:text-white'>
              These are notifications for comments on your posts and replies to our comments, tags,
              post or story, updates you might have missed and more.
            </p>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='flex items-center gap-1'>
              <Switch
                checked={in_app_notification}
                onChange={(e) => handleInAppChange(e)}
                name='in_app_notification'
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <p className='font-semibold'>Push</p>
            </div>
            <div className='flex items-center gap-1'>
              <Switch
                checked={email_notification}
                onChange={(e) => handleEmailNotificationChange(e)}
                name='email_notification'
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <p className='font-semibold'>Email</p>
            </div>
            <div className='flex items-center gap-1'>
              <Switch
                checked={sms_notification}
                onChange={(e) => handleSMSNotificationChange(e)}
                name='sms_notification'
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <p className='font-semibold'>SMS</p>
            </div>
          </div>
        </div>
        {/* <div className='bg-[#F2F2F2] h-[2px] my-5' />
      <div className='grid grid-cols-6 gap-10'>
        <div className='flex flex-col gap-3 col-span-4'>
          <p className='font-semibold'>Tags</p>
          <p className='text-[#222222] opacity-70'>
            These are notifications are for when someone tags you in a comment, post or story.
          </p>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex items-center gap-1'>
            <Switch />
            <p className='font-semibold'>Push</p>
          </div>
          <div className='flex items-center gap-1'>
            <Switch />
            <p className='font-semibold'>Email</p>
          </div>
          <div className='flex items-center gap-1'>
            <Switch />
            <p className='font-semibold'>SMS</p>
          </div>
        </div>
      </div>
      <div className='bg-[#F2F2F2] h-[2px] my-5' />
      <div className='grid grid-cols-6 gap-10'>
        <div className='flex flex-col gap-3 col-span-4'>
          <p className='font-semibold'>Reminder</p>
          <p className='text-[#222222] opacity-70'>
            These are notifications to remind you of updates you might have missed.
          </p>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex items-center gap-1'>
            <Switch />
            <p className='font-semibold'>Push</p>
          </div>
          <div className='flex items-center gap-1'>
            <Switch />
            <p className='font-semibold'>Email</p>
          </div>
          <div className='flex items-center gap-1'>
            <Switch />
            <p className='font-semibold'>SMS</p>
          </div>
        </div>
      </div>
      <div className='bg-[#F2F2F2] h-[2px] my-5' />
      <div className='grid grid-cols-6 gap-10'>
        <div className='flex flex-col gap-3 col-span-4'>
          <p className='font-semibold'>More activity about you</p>
          <p className='text-[#222222] opacity-70'>
            These are notifications for post on your profile, likes and other reactions to your
            posts and more.
          </p>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex items-center gap-1'>
            <Switch />
            <p className='font-semibold'>Push</p>
          </div>
          <div className='flex items-center gap-1'>
            <Switch />
            <p className='font-semibold'>Email</p>
          </div>
          <div className='flex items-center gap-1'>
            <Switch />
            <p className='font-semibold'>SMS</p>
          </div>
        </div>
      </div> */}
      </div>
    </>
  );
};

export default NotificationSettingsTabs;
