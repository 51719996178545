import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import { SvgIconProps } from '@mui/material';

import { TPopOverOptionProps, TPopOverOptionType } from './types';

const OptionIcon = ({ type, ...props }: { type: TPopOverOptionType } & SvgIconProps) => {
  switch (type) {
    case 'edit':
      return <EditOutlinedIcon {...props} />;
    case 'delete':
      return <DeleteOutlineOutlinedIcon {...props} />;
    default:
      return <AdjustOutlinedIcon {...props} />;
  }
};

export const PopOverOption = ({ type, text, onClick, ...iconProps }: TPopOverOptionProps) => {
  return (
    <button className='flex flex-row gap-4 p-2' onClick={onClick}>
      <OptionIcon type={type} {...iconProps} />
      <p>{text}</p>
    </button>
  );
};
