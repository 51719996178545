import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ChangeCircleOutlined from '@mui/icons-material/ChangeCircleOutlined';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { Switch } from '@mui/material';
import ControlledInput from 'components/shared/ControlledInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import Canvas from 'components/polygonAnnotations/Canvas';
import { TDeviceHistoryResponse, TDevicesBoundaryEdit } from 'store/slices/devicesSlice/type';
import { editBoundarySchema } from 'constants/Schemas';
import { useAppDispatch, useAppSelector } from 'hooks';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import {
  controlsSelector,
  deviceHistorySelector,
  devicesLoadingSelector,
  endStreamSelector,
  startStreamSelector,
  streamKeySelector,
} from 'store/slices/devicesSlice/selectors';
import devicesPlaceholderImg from 'assets/images/devicesPlaceholderImg.png';
import {
  devicesPanTilt,
  endStream,
  getBatchUrls,
  getDevicesHistoryByID,
  postDevicesVertices,
  startStream,
} from 'store/thunks';
import offline from 'assets/images/offline.png';
import { TDevice } from 'store/slices/devicesSlice/type';
import { getSignedMediaUrl } from 'api/media';
import { TMediaFolder, TMediaParams } from 'api/media/types';
import { eventByIDSelector, eventsSelector } from 'store/slices/eventsSlice/selectors';

import CustomModal, { TModalProps } from '.';

const MIN = 0;
const MAX = 100;

export type TDeviceModalProps = {
  device?: TDevice;
  deviceId?: number;
  deviceName?: string;
  mediaUrl?: string;
  modalType?: 'edit' | 'view';
  totalCount?: number;
};

export default function DeviceModal({
  deviceId,
  deviceName,
  mediaUrl,
  isOpen,
  onClose,
  modalType,
}: TDeviceModalProps & Omit<TModalProps, 'children'>) {
  //hooks
  const devicesLoading = useAppSelector(devicesLoadingSelector);
  const dispatch = useAppDispatch();
  const startStreaming = useAppSelector(startStreamSelector);
  const endStreaming = useAppSelector(endStreamSelector);
  const streamKey = useAppSelector(streamKeySelector);
  const panTiltControls = useAppSelector(controlsSelector);
  const [drawShape, setDrawShape] = useState(true);
  const [points, setPoints] = useState([]);
  const [getOriginalWH, setOriginalWH] = useState<any>();
  const [getWHOnScreen, setWHOnScreen] = useState<any>();
  const [isPolyComplete, setPolyComplete] = useState<boolean>(false);
  const [isLive, setIsLive] = useState(false);
  const [panVal, setPanVal] = useState<number | number[]>(MIN);
  const [tiltVal, setTiltVal] = useState<number | number[]>(MIN);
  const [images, setImages] = useState<string[]>([]);
  const [peopleCount, setPeopleCount] = useState<number>(0);
  const [totalHistoryCount, setTotalHistoryCount] = useState<number>(0);
  const [currentMediaUrl, setCurrentMediaUrl] = useState(mediaUrl);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [deviceDetails, setDeviceDetails] = useState<any>(null);

  const handleImageSelect = (imgUrl: string) => {
    setCurrentMediaUrl(imgUrl);
  };

  useEffect(() => {
    setCurrentMediaUrl(mediaUrl);
  }, [mediaUrl]);

  const {
    control: deviceEditControl,
    handleSubmit: deviceControlSubmit,
    formState: { errors },
    reset,
  } = useForm<TDevicesBoundaryEdit>({
    resolver: yupResolver(editBoundarySchema),
  });

  const getAllDevicesHistory = async (offset = 0) => {
    const options = {
      limit: 10,
      offset,
      id: deviceId,
    };

    try {
      const response = await dispatch(getDevicesHistoryByID(options)).unwrap();
      const { data, total_count }: TDeviceHistoryResponse = response;

      const mediaFolder: TMediaFolder = 'devices';

      const mediaIds = data.map((item) => item?.image_url_id).join(',');

      const params: TMediaParams = {
        folder: mediaFolder,
        media_url_ids: mediaIds,
      };

      const batchUrlResponse = await dispatch(getBatchUrls(params)).unwrap();
      const imageUrls = batchUrlResponse?.split(',');

      setImages(imageUrls);
      setPeopleCount(data[0]?.headcount || 0);
      setTotalHistoryCount(total_count);
    } catch (err: any) {
      // toast.error(err.message || 'Error fetching device history.');
    }
  };

  useEffect(() => {
    if (isOpen && modalType === 'view') {
      getAllDevicesHistory();
    }
  }, [isOpen, deviceId]);

  //functions
  const originalImageWidth = getOriginalWH?.width;
  const originalImageHeight = getOriginalWH?.height;

  const relativeCoordinates = useMemo(
    () =>
      getWHOnScreen?.width && getWHOnScreen?.height
        ? points?.map((coord: any) => {
            return [
              (coord[0] * originalImageWidth) / getWHOnScreen.width,
              (coord[1] * originalImageHeight) / getWHOnScreen.height,
            ];
          })
        : [],
    [getWHOnScreen?.height, getWHOnScreen?.width, originalImageHeight, originalImageWidth, points],
  );

  const roundedCoordinates = useMemo(
    () =>
      relativeCoordinates?.map((coord: any) => {
        return [parseFloat(coord[0]?.toFixed(2)), parseFloat(coord[1]?.toFixed(2))];
      }),
    [relativeCoordinates],
  );

  const handlePanChange = (_: Event, newValue: number | number[]) => {
    // setPanVal(newValue as number);
  };

  const handleTiltChange = (_: Event, newValue: number | number[]) => {
    // setTiltVal(newValue as number);
  };

  const onSendBoundary = async (values: TDevicesBoundaryEdit) => {
    if (points?.length >= 3) {
      const obj = {
        device_id: deviceId,
        vertices: roundedCoordinates,
        name: values?.name,
        description: values?.description,
        entity_type: values?.entity_type?.toLowerCase(),
      };

      try {
        await dispatch(postDevicesVertices(obj));
        reset({
          name: '',
          description: '',
        });
        setPoints([]);
        setPolyComplete(false);
      } catch (err: any) {
        // console.log(err);
      }
    } else {
      toast.error('No polygon captured. please draw polygon or close shape.');
    }
  };

  const undo = () => {
    setPoints(points.slice(0, -1));
    setPolyComplete(false);
    reset({
      name: '',
      description: '',
    });
  };

  const preventHorizontalKeyboardNavigation = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const obj: any = {
      id: deviceId,
      pan: panVal,
      tilt: tiltVal,
    };
    if ((panVal || tiltVal) && deviceId) {
      dispatch(devicesPanTilt(obj));
    }
  }, [deviceId, dispatch, panVal, tiltVal]);

  useEffect(() => {
    let str = '';
    const flatRoundedCoords: number[] = roundedCoordinates?.flat();
    flatRoundedCoords?.forEach(
      (value: number, index: number) =>
        (str += value + `${flatRoundedCoords?.length - 1 === index ? '' : '-'}`),
    );

    const values: any = {
      name: `${deviceName || 'Shape'}-${uuidv4()}`,
      description: `${deviceName || 'Shape'}-${uuidv4()}`,
    };
    if (isPolyComplete) {
      reset(values);
    }
  }, [deviceName, isPolyComplete, reset, roundedCoordinates]);

  useEffect(() => {
    if (deviceId) {
      if (isLive && !startStreaming) {
        dispatch(startStream(deviceId));
      } else if (!isLive && !endStreaming) {
        dispatch(endStream(deviceId));
      }
    }
  }, [isLive, dispatch, deviceId, startStreaming, endStreaming]);

  const onlineImages = [
    'https://via.placeholder.com/600x400?text=Device1',
    'https://via.placeholder.com/600x400?text=Device2',
    'https://via.placeholder.com/600x400?text=Device3',
    'https://via.placeholder.com/600x400?text=Device4',
    'https://via.placeholder.com/600x400?text=Device5',
    'https://via.placeholder.com/600x400?text=Device6',
    'https://via.placeholder.com/600x400?text=Device7',
    'https://via.placeholder.com/600x400?text=Device8',
    'https://via.placeholder.com/600x400?text=Device9',
    'https://via.placeholder.com/600x400?text=Device10',
  ];

  return (
    <div>
      {modalType === 'edit' ? (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          className='max-w-max lg:min-w-[900px] dark:bg-darkBg'
        >
          <div id='clearScrollbar' className='overflow-y-auto'>
            <div className='flex justify-between items-center mb-1'>
              <p className='text-[24px] font-semibold'>Device Control</p>
              <CancelPresentationIcon className='opacity-50 cursor-pointer' onClick={onClose} />
            </div>
            <div className='flex items-center justify-between mt-5 p-1 rounded-md bg-gray-50 dark:bg-black dark:bg-opacity-10  pl-2'>
              <p className='text-[15px] font-semibold'>{isLive ? 'Go Offline' : 'Go Live'}</p>
              <Switch
                checked={isLive}
                onChange={(e) => {
                  const newLiveStatus = e.target.checked;
                  setIsLive(newLiveStatus);

                  if (deviceId) {
                    if (newLiveStatus) {
                      dispatch(startStream(deviceId));
                    } else {
                      dispatch(endStream(deviceId));
                    }
                  }
                }}
                color='primary'
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            {isLive ? (
              <>
                <div className='flex gap-3 lg:min-w-[900px]'>
                  {/* left side - Canvas or image when live */}
                  <div
                    className='col-span-4 mt-2  w-3/4 bg-white shadow-md rounded-lg mx-auto '
                    id=''
                  >
                    {!devicesLoading && drawShape ? (
                      <Canvas
                        // videoSource={streamKey || devicesPlaceholderImg}
                        meetingId={streamKey}
                        videoSource={
                          isLive && streamKey
                            ? `https://your-streaming-server.com/live/${streamKey}`
                            : devicesPlaceholderImg
                        }
                        isPolyComplete={isPolyComplete}
                        setPolyComplete={setPolyComplete}
                        setPoints={setPoints}
                        points={points}
                        setOriginalWH={setOriginalWH}
                        setWHOnScreen={setWHOnScreen}
                      />
                    ) : (
                      <div
                        style={{
                          height: getWHOnScreen?.height || '719px',
                          width: getWHOnScreen?.width || '560px',
                        }}
                      >
                        <div className='pt-60 flex justify-center items-center'>
                          <CircularProgress size='6em' />
                        </div>
                      </div>
                    )}
                  </div>

                  {/* right side - Device controls */}
                  {panTiltControls === true && (
                    <div className='col-span-2 mt-2 w-1/4 h-full shadow-md rounded-lg '>
                      <p className='text-[15px] p-3 border-[#F2F2F2] border-2 dark:border-border-dark-background'>
                        Device Control:
                      </p>

                      <div className='grid grid-cols-2 gap-1 border-[#F2F2F2] border-2 dark:border-border-dark-background px-2'>
                        {/* Pan */}
                        <div className='flex flex-col items-center py-9'>
                          <p className='font-semibold pb-2'>Pan</p>
                          <div className='flex items-center bg-[#e8e8e8] p-2 cursor-pointer border-2 mb-2 dark:bg-dark-card-bg-old dark:border-none'>
                            <p className='font-semibold opacity-45 dark:text-white'>{MAX}</p>
                          </div>
                          <Slider
                            sx={{
                              '& input[type="range"]': {
                                WebkitAppearance: 'slider-vertical',
                              },
                              height: '22.1em',
                            }}
                            orientation='vertical'
                            defaultValue={30}
                            aria-label='Pan'
                            valueLabelDisplay='auto'
                            onKeyDown={preventHorizontalKeyboardNavigation}
                            min={MIN}
                            max={MAX}
                            onChange={handlePanChange}
                            onChangeCommitted={(_, newValue) => setPanVal(newValue)}
                          />
                          <div className='flex items-center bg-[#e8e8e8] dark:bg-dark-card-bg-old p-2 cursor-pointer border-2 mt-2 dark:border-none'>
                            <p className='font-semibold opacity-45 px-2 dark:text-white'>{MIN}</p>
                          </div>
                        </div>

                        {/* Tilt */}
                        <div className='flex flex-col items-center py-9'>
                          <p className='font-semibold pb-2'>Tilt</p>
                          <div className='flex items-center bg-[#e8e8e8] dark:bg-dark-card-bg-old p-2 cursor-pointer border-2 mb-2 dark:border-none'>
                            <p className='font-semibold opacity-45 dark:text-white'>{MAX}</p>
                          </div>
                          <Slider
                            sx={{
                              '& input[type="range"]': {
                                WebkitAppearance: 'slider-vertical',
                              },
                              height: '22.1em',
                            }}
                            orientation='vertical'
                            defaultValue={30}
                            aria-label='Tilt'
                            valueLabelDisplay='auto'
                            onKeyDown={preventHorizontalKeyboardNavigation}
                            min={MIN}
                            max={MAX}
                            onChange={handleTiltChange}
                            onChangeCommitted={(_, newValue) => setTiltVal(newValue)}
                          />
                          <div className='flex items-center bg-[#e8e8e8] dark:bg-dark-card-bg-old p-2 cursor-pointer dark:border-none border-2 mt-2'>
                            <p className='font-semibold opacity-45 px-2 dark:text-white'>{MIN}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={`flex items-center ${drawShape ? 'mt-2 pt-3' : 'mt-8 pt-10'} gap-2`}
                >
                  <div
                    className={`lg:flex hidden items-center ${
                      drawShape ? 'bg-accent' : 'bg-[#F0F7FF]'
                    } rounded-md p-2 gap-2 cursor-pointer`}
                  >
                    <p
                      className={`${
                        drawShape ? 'text-white' : 'text-accent'
                      } font-semibold whitespace-nowrap text-[11px] tracking-wider`}
                    >
                      Draw
                    </p>
                    <OpenInFullIcon className={`${drawShape ? 'text-white' : 'text-accent'}`} />
                  </div>

                  {drawShape && (
                    <div
                      className='lg:flex hidden items-center bg-[#F0F7FF] rounded-md p-2 gap-2 cursor-pointer dark:border-none hover:border-brand border dark:bg-dark-card-bg-old transition-all'
                      onClick={() => undo()}
                    >
                      <p className='text-accent font-semibold whitespace-nowrap text-[11px] tracking-wider dark:text-white'>
                        Undo
                      </p>
                      <ChangeCircleOutlined className='text-brand dark:text-white' />
                    </div>
                  )}
                </div>

                {/* Bottom section - boundary details */}
                <div>
                  <p className='text-[24px] font-semibold mt-5'>Fill in boundary details:</p>
                  <div className='flex justify-between items-center gap-3 pt-5'>
                    <ControlledInput
                      required
                      control={deviceEditControl}
                      name='name'
                      label='Name'
                      error={!!errors.name}
                      placeholder='Name'
                      helperText={errors?.name?.message ?? ''}
                    />
                    <ControlledInput
                      required
                      control={deviceEditControl}
                      name='description'
                      label='Description'
                      error={!!errors.description}
                      placeholder='Description'
                      helperText={errors?.description?.message ?? ''}
                    />
                    <ControlledInput
                      required
                      control={deviceEditControl}
                      name='entity_type'
                      label='Entity Type'
                      error={!!errors.entity_type}
                      placeholder='Entity Type'
                      helperText={errors?.entity_type?.message ?? ''}
                      defaultValue='Audience Metrics'
                      disabled
                    />
                  </div>
                </div>

                <PrimaryButton
                  className='mt-8'
                  variant='neutral'
                  onClick={deviceControlSubmit(onSendBoundary)}
                >
                  Save
                </PrimaryButton>
              </>
            ) : (
              <div className='flex flex-col justify-center items-center h-[900px] lg:min-w-[900px]'>
                <img src={offline} alt='offline' className='h-[400px] -mt-96' />
                <p className='text-[18px] font-semibold text-gray-600'>
                  Your device is offline, go offline to view live feed
                </p>
              </div>
            )}
          </div>
        </CustomModal>
      ) : null}

      {modalType === 'view' ? (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          className='max-w-lg max-h-screen dark:bg-darkBg p-6 rounded-lg shadow-lg'
        >
          <div id='clearScrollbar' className='space-y-6'>
            {/* Header */}
            <div className='flex justify-between items-center mb-4'>
              <h2 className='text-lg font-semibold text-gray-800 dark:text-gray-200'>
                Device Details
              </h2>
              <CancelPresentationIcon
                className='opacity-70 cursor-pointer hover:opacity-100'
                onClick={onClose}
              />
            </div>

            {/* Device Info Section */}
            <div className='mb-4 flex justify-between'>
              <p className='text-sm font-medium text-gray-700 dark:text-gray-300'>
                <span className='font-bold'>Device:</span> {deviceDetails?.name || 'Game'}
              </p>
              <p className='text-sm font-medium text-gray-700 dark:text-gray-300'>
                <span className='font-bold'>Count:</span> {deviceDetails?.count || 0}
              </p>
            </div>

            {/* Main Image Section */}
            <div className='bg-white dark:bg-gray-800 shadow-md rounded-lg p-4'>
              <img
                className='object-cover w-full h-96 rounded-lg'
                src={currentMediaUrl || devicesPlaceholderImg}
                alt='Main Media'
              />
            </div>

            {/* Scrollable Thumbnail Gallery with Navigation */}
            <div className='relative'>
              {/* Thumbnails and Buttons Wrapper */}
              <div className='flex items-center gap-4'>
                {/* Left Scroll Button */}
                <button
                  onClick={() => {
                    const gallery = document.getElementById('thumbnailGallery');
                    if (gallery) {
                      gallery.scrollBy({
                        left: -100, // Adjust scroll amount for left scroll
                        behavior: 'smooth',
                      });
                    }
                  }}
                  className='p-2 bg-gray-200 dark:bg-gray-700 rounded-full shadow hover:bg-gray-300 dark:hover:bg-gray-600'
                >
                  &lt;
                </button>

                {/* Thumbnails (Fixed Width and Overflow Hidden) */}
                <div
                  id='thumbnailGallery'
                  className='flex gap-2 overflow-hidden'
                  style={{
                    width: 'calc(100% - 6rem)', // Adjusted to fit the reduced modal width
                  }}
                >
                  {loading ? (
                    <p>Loading thumbnails...</p>
                  ) : error ? (
                    <p>{error}</p>
                  ) : (
                    onlineImages.map((imgUrl, index) => (
                      <img
                        key={index}
                        src={imgUrl}
                        alt={`Thumbnail ${index + 1}`}
                        onClick={() => handleImageSelect(imgUrl)}
                        className={`object-cover h-20 w-20 rounded-lg cursor-pointer border-2 ${
                          currentMediaUrl === imgUrl ? 'border-blue-500' : 'border-transparent'
                        }`}
                      />
                    ))
                  )}
                </div>

                {/* Right Scroll Button */}
                <button
                  onClick={() => {
                    const gallery = document.getElementById('thumbnailGallery');
                    if (gallery) {
                      gallery.scrollBy({
                        left: 100, // Adjust scroll amount for right scroll
                        behavior: 'smooth',
                      });
                    }
                  }}
                  className='p-2 bg-gray-200 dark:bg-gray-700 rounded-full shadow hover:bg-gray-300 dark:hover:bg-gray-600'
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : null}
    </div>
  );
}
