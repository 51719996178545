import React from 'react';

interface BadgeProps {
  title: string;
}

const Badge: React.FC<BadgeProps> = ({ title }) => {
  return (
    <div className='bg-pill-bg dark:bg-dark-pill-bg text-[13px] rounded-[8px] px-4 py-2 font-bold uppercase text-center max-w-[250px] mb-6'>
      {title}
    </div>
  );
};

export default Badge;
