import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { BarGraph } from 'components/analytics/BarGraph';
import { TBarGraphData } from 'components/analytics/types';
import { getDateInTimeZone } from 'helpers/utils';
import { getHeadCountAtIntervals } from 'store/thunks';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { Colors } from 'types';
import { useDarkMode } from 'hooks';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const emptyStateIntervalData = {
  labels: [
    '9:00',
    '9:05',
    '10:15',
    '9:20',
    '9:25',
    '9:00',
    '9:05',
    '10:15',
    '9:20',
    '9:25',
    '9:00',
    '9:05',
    '10:15',
    '9:20',
    '9:25',
  ],
  datasets: [
    {
      label: '',
      data: [1, 2, 3, 4, 5, 6, 7, 7, 7, 8, 5, 4, 3, 2, 4],
      borderColor: '#0082dc',
      backgroundColor: Colors.LIGHT_GRAYISH_SILVER,
      fill: false,
      tension: 0.3,
      borderDash: [1],
      showLine: true,
      dash: 3,
    },
  ],
};

export default function PercentageOccupancyGraph() {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userDataSelector);
  const attendanceIntervalRef = useRef<NodeJS.Timeout>();
  const [headCountIntervalData, setHeadCountIntervalData] = useState<TBarGraphData>();
  const { darkMode } = useDarkMode();

  // const sittingOptions = useMemo(
  //   () => ({
  //     responsive: true,
  //     lineWidth: 0,
  //     maintainAspectRatio: false,
  //     plugins: {
  //       legend: { display: false },
  //       borderJoinStyle: 'round',
  //       title: {
  //         display: false,
  //         text: 'Sitting Occupancy',
  //       },
  //     },
  //     scales: {
  //       x: {
  //         grid: {
  //           display: false,
  //         },
  //         ticks: {
  //           font: {
  //             size: 10,
  //             color: darkMode ? '#ffffff' : '#000000',
  //           },
  //         },
  //       },
  //       y: {
  //         beginAtZero: true,
  //         grid: {
  //           display: false,
  //         },

  //         ticks: {
  //           color: darkMode ? '#ffffff' : '#000000',
  //         },

  //         title: {
  //           display: true,
  //           align: 'center',
  //           text: 'Occupancy %',
  //           color: darkMode ? '#ffffff' : '#000000',
  //         },
  //       },
  //     },
  //   }),
  //   [darkMode],
  // );

  const sittingOptions = useMemo(
    () => ({
      responsive: true,
      lineWidth: 0,
      maintainAspectRatio: false,
      layout: {
        padding: { top: 20 },
      },
      plugins: {
        legend: { display: false },
        borderJoinStyle: 'round',

        title: {
          display: false,
          text: 'Sitting Occupancy',
        },
        datalabels: {
          display: true,
          color: 'gray',
          anchor: 'end',
          align: 'end',
          font: {
            size: 10,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 10,
              color: darkMode ? '#ffffff' : '#000000',
            },
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: false,
          },
          ticks: {
            color: darkMode ? '#ffffff' : '#000000',
          },
          title: {
            display: true,
            align: 'center',
            text: 'Occupancy %',
            color: darkMode ? '#ffffff' : '#000000',
          },
        },
      },
    }),
    [darkMode],
  );

  const getHeadCounts = useCallback(async () => {
    dispatch(getHeadCountAtIntervals({}))
      .unwrap()
      .then((response) => {
        const labels = response?.data.map((intervalData) => {
          const now = new Date();
          const times = intervalData?.time?.split?.(':') ?? [];
          const hours = Number(times[0] ?? '0');
          const mins = Number(times[1] ?? '0');
          now.setHours(hours, mins, 0);

          return dayjs(getDateInTimeZone(now)).format('hh:mma');
        }); // x-axis
        const data = response?.data.map((intervalData) => intervalData.count); // y-axis
        const graphData = {
          labels,
          datasets: [
            {
              label: '',
              data,
              borderColor: '#0082dc',
              backgroundColor: '#4472c4',
              fill: true,
              tension: 0.3,
            },
          ],
        };
        setHeadCountIntervalData(graphData);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    getHeadCounts();
  }, []);

  useEffect(() => {
    // Get headcounts every 5 mins
    attendanceIntervalRef.current = setInterval(() => {
      getHeadCounts();
    }, 1000 * 60 * 5); // 5 minutes

    return () => {
      clearInterval(attendanceIntervalRef.current);
    };
  }, [getHeadCounts, userData?.member_info]);

  return (
    <BarGraph
      data={headCountIntervalData}
      emptyStateData={emptyStateIntervalData}
      options={sittingOptions}
    />
  );
}
