import { SubscriptionPlans } from 'store/slices/subscriptionSlice/types';

export type RootObject = {
  title: string;
  duration: TDuration | string;
  amount: number;
};

type TDuration = {
  id: number;
  duration: string;
  price: number;
};

export const generatePlan = (obj: {
  duration: TDuration | string;
  title: string;
}): SubscriptionPlans => {
  let extractedDuration: string;

  if (typeof obj.duration === 'string') {
    extractedDuration = obj.duration;
  } else {
    extractedDuration = String(obj.duration.duration);
  }

  const { title } = obj;
  if (extractedDuration === 'Monthly') {
    switch (title) {
      case 'Basic Plan':
        return SubscriptionPlans.BASIC_MONTHLY;
      case 'Pro Plan':
        return SubscriptionPlans.PRO_MONTHLY;
      case 'Premier Plan':
        return SubscriptionPlans.PREMIER_MONTHLY;
      case 'Custom Plan':
        return SubscriptionPlans.CUSTOM_MONTHLY;
      default:
        return SubscriptionPlans.BASIC_MONTHLY;
    }
  } else if (extractedDuration === 'Yearly') {
    switch (title) {
      case 'Basic Plan':
        return SubscriptionPlans.BASIC_YEARLY;
      case 'Pro Plan':
        return SubscriptionPlans.PRO_YEARLY;
      case 'Premier Plan':
        return SubscriptionPlans.PREMIER_YEARLY;
      case 'Custom Plan':
        return SubscriptionPlans.CUSTOM_YEARLY;
      default:
        return SubscriptionPlans.BASIC_YEARLY;
    }
  }

  return SubscriptionPlans.BASIC_MONTHLY;
};
