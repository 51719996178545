import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import LoadingBar from 'react-top-loading-bar';

import PrimaryButton from 'components/buttons/PrimaryButton';
import Avatar from 'components/shared/Avatar';
import { usePermissions, useTopLoader, useUserOrganizationsList } from 'hooks';
import { ControlledInput, ControlledSelect, NumberControlledInput } from 'components';
import { organizationSettingsSchema } from 'constants/Schemas';
import { Colors, TChangeInputEvent } from 'types';
import { useAppDispatch } from 'app/hooks';
import { updateOrganization } from 'store/thunks';
import { sizeLimits } from 'constants/SizeLimits';
import { UploadMessages } from 'constants/ToastMessages';
import { TOrganization, TOrganizationUpdate } from 'store/slices/organizationsSlice/types';
import { industryOptions, subtitleOptions } from 'constants/BusinessTypes';
import { BusinessType } from 'constants/BusinessTypes/types';
import { loaderOptions } from 'constants/sizeOptions';
import { stateOptions } from 'constants/States';

export type TOrganizationUpdateForm = Partial<
  Omit<TOrganizationUpdate, 'size' | 'is_active' | 'id' | 'business_type'> & {
    business_type: BusinessType;
  }
>;

const OrganizationInfo = ({
  title,
  text,
  capitalize = true,
}: {
  title: string;
  text?: string;
  capitalize?: boolean;
}) => {
  return (
    <div className='flex flex-col gap-2'>
      <p className=' capitalize text-gray-700 dark:text-gray-300 font-semibold'>{title}</p>
      {text && <p className={capitalize ? 'capitalize text-gray-400' : ''}>{text}</p>}
    </div>
  );
};

const OrganizationTab = () => {
  const [editingEnabled, setEditingEnabled] = useState<boolean>(false);
  const organizationImageUploadInput = useRef<HTMLInputElement>(null);
  const { currentOrganization, getAllOrganizations } = useUserOrganizationsList();
  const [selectedBusinessType, setSelectedBusinessType] = useState<BusinessType>();
  const { isAllowedToEditOrganisation } = usePermissions();
  const { progress, loaderHandler, resetLoader } = useTopLoader();

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TOrganizationUpdateForm>({
    resolver: yupResolver(organizationSettingsSchema),
  });

  const onClickEdit = useCallback(() => {
    if (!editingEnabled) setEditingEnabled((prevState) => !prevState);
  }, [editingEnabled]);

  async function onSubmitOrganizationDetails(organizationDetails: TOrganizationUpdateForm) {
    // Form data to be updated on the backend
    const formData = new FormData();
    let counter = 0;

    // Get new items to be updated in organization details
    for (const [key, value] of Object.entries(organizationDetails)) {
      if (value && value !== currentOrganization?.[key as keyof TOrganization]) {
        formData.append(key, value);
        counter += 1;
      }
    }

    // If user updated the form
    if (counter > 0) loaderHandler(dispatch(updateOrganization(formData)));
    setEditingEnabled((prevState) => !prevState);
  }

  const organizationImageUpload = async (event: TChangeInputEvent) => {
    const file = event?.target?.files?.[0];
    if (Number(file?.size) > sizeLimits.logo) {
      toast(UploadMessages.LOGO_SIZE_EXCEEDED);
      return;
    }

    if (file) {
      const formData = new FormData();
      formData.append('media', file);
      loaderHandler(dispatch(updateOrganization(formData)));
    }
  };

  function onClickOrganizationImage() {
    if (isAllowedToEditOrganisation) organizationImageUploadInput?.current?.click();
  }

  const industryVerticals = useMemo(
    () => (selectedBusinessType ? subtitleOptions[selectedBusinessType] : []),
    [selectedBusinessType],
  );

  useEffect(() => {
    getAllOrganizations();
  }, []);

  return (
    <>
      {progress > 0 && (
        <LoadingBar
          color={loaderOptions.color}
          progress={progress}
          height={loaderOptions.height}
          loaderSpeed={1600}
          onLoaderFinished={resetLoader}
        />
      )}
      <div className='p-5 md:p-10 shadow-sm flex flex-row justify-between'>
        <p className='p-3 text-[18px] md:text-[24px] font-semibold'>My Organization</p>
        {editingEnabled && isAllowedToEditOrganisation ? (
          <button
            form='update-organization-form'
            type='submit'
            className='h-[55px] w-[150px] py-3 px-7 bg-accent rounded-[4px] text-white text-sm md:text-base cursor-pointer'
          >
            Save Details
          </button>
        ) : isAllowedToEditOrganisation ? (
          <PrimaryButton type='button' variant='neutral' onClick={onClickEdit} id='editOrgSettings'>
            Edit
          </PrimaryButton>
        ) : null}
      </div>
      <div className='p-10' id='orgArea'>
        <form
          id='update-organization-form'
          autoComplete='off'
          onSubmit={handleSubmit(onSubmitOrganizationDetails)}
        >
          <div className='grid grid-cols-6'>
            <div className='col-span-1 flex justify-center items-start cursor-pointer'>
              <span id='editOrgImageUpload'>
                <Avatar
                  fullName={currentOrganization?.name}
                  mediaId={currentOrganization?.media_url_id as string}
                  mediaFolder='organization'
                  className={classNames('h-[40px] max-w-48 cursor-pointer', {
                    'w-[40px] h-[40px]': !currentOrganization?.media_url_id,
                  })}
                  onClick={onClickOrganizationImage}
                />
              </span>
              <input
                ref={organizationImageUploadInput}
                hidden
                accept='image/*'
                type='file'
                onChange={organizationImageUpload}
              />
            </div>

            <div className='col-span-4 -mt-9'>
              <div className='grid grid-cols-2  gap-7 my-10'>
                {editingEnabled ? (
                  <ControlledInput
                    required
                    defaultValue={currentOrganization?.name}
                    control={control}
                    name='name'
                    label='Organization Name'
                    error={!!errors.name}
                    placeholder='Organization Name'
                    helperText={(errors?.name?.message as string) ?? ''}
                  />
                ) : (
                  <OrganizationInfo title='Organization name' text={currentOrganization?.name} />
                )}
              </div>
              <div className='grid grid-cols-2  gap-7 my-10'>
                {editingEnabled ? (
                  <ControlledSelect
                    defaultValue=''
                    name='business_type'
                    control={control}
                    options={industryOptions}
                    setValue={(newValue) => setSelectedBusinessType(newValue as BusinessType)}
                    label='Business Type'
                    border={`1px solid ${Colors.SOFT_SILVER}`}
                  />
                ) : (
                  <OrganizationInfo
                    title='Business Type'
                    text={currentOrganization?.business_type}
                  />
                )}
                {editingEnabled ? (
                  <ControlledSelect
                    defaultValue=''
                    name='industry_vertical'
                    control={control}
                    options={industryVerticals}
                    label='Industry'
                    border={`1px solid ${Colors.SOFT_SILVER}`}
                  />
                ) : (
                  <OrganizationInfo
                    title='Industry'
                    text={currentOrganization?.industry_vertical}
                  />
                )}
              </div>
              <div className='grid grid-cols-2 gap-7 my-10'>
                {editingEnabled ? (
                  <ControlledInput
                    defaultValue={currentOrganization?.email ?? ''}
                    control={control}
                    name='email'
                    label='Email'
                    error={!!errors.email}
                    placeholder='Email address'
                    helperText={errors.email ? errors.email.message : ''}
                  />
                ) : (
                  <OrganizationInfo
                    title='Email'
                    text={currentOrganization?.email}
                    capitalize={false}
                  />
                )}
                {editingEnabled ? (
                  <NumberControlledInput
                    defaultValue={currentOrganization?.phone ?? ''}
                    control={control}
                    name='phone'
                    label='Phone Number'
                    error={!!errors.phone}
                    placeholder='Phone Number'
                    helperText={errors.phone ? errors.phone.message : ''}
                  />
                ) : (
                  <OrganizationInfo title='Phone' text={currentOrganization?.phone} />
                )}
              </div>
            </div>
          </div>
          <div className='bg-[#F2F2F2] h-[2px] my-10' />
          <div className='grid grid-cols-6'>
            <div className='col-span-1'></div>
            <div className='col-span-4'>
              <p className='text-[18px] font-semibold'>Address</p>
              <div className='grid grid-cols-1 my-10'>
                {editingEnabled ? (
                  <ControlledInput
                    defaultValue={currentOrganization?.address ?? ''}
                    control={control}
                    name='address'
                    label='Street'
                    error={!!errors.address}
                    placeholder='Street'
                    helperText={(errors?.address?.message as string) ?? ''}
                  />
                ) : (
                  <OrganizationInfo title='Street' text={currentOrganization?.address} />
                )}
              </div>
              <div className='grid grid-cols-2 gap-7 my-10'>
                {editingEnabled ? (
                  <ControlledInput
                    defaultValue={currentOrganization?.city ?? ''}
                    control={control}
                    name='city'
                    label='City'
                    error={!!errors.city}
                    placeholder='City'
                    helperText={(errors?.city?.message as string) ?? ''}
                  />
                ) : (
                  <OrganizationInfo title='City' text={currentOrganization?.city} />
                )}
                {editingEnabled ? (
                  <ControlledSelect
                    defaultValue={currentOrganization?.state ?? ''}
                    name='state'
                    control={control}
                    options={stateOptions}
                    label='State'
                    border={`1px solid ${Colors.SOFT_SILVER}`}
                  />
                ) : (
                  <OrganizationInfo title='State' text={currentOrganization?.state} />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default OrganizationTab;
