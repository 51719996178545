// import { sanitize } from 'dompurify';
import DOMPurify from 'dompurify';
import { useCallback, useEffect, useState } from 'react';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';

import { replaceActivityEntitiesWithLinks } from 'helpers/utils';
import { TPostComment, TPostMetadata } from 'store/slices/activitiesSlices/types';
import { getSignedMediaUrl } from 'api/media';
import '../../pages/dashboard/styles/dashboard.scss';
import UserAvatar from 'components/shared/UserAvatar';
import { Comment } from 'containers/activities/Comment';

type TActivityFeedCardProps = {
  name?: string;
  role?: string;
  relativeTime: string;
  profileMediaId?: string;
  mediaIds?: string[];
  text: string;
  metadata: TPostMetadata;
  comments: TPostComment[];
};

export const ActivityFeedCard = ({
  name,
  relativeTime,
  text,
  mediaIds,
  profileMediaId,
  metadata,
  comments,
}: TActivityFeedCardProps) => {
  const [images, setImages] = useState<string[]>([]);
  const cleanHTML = DOMPurify.sanitize(replaceActivityEntitiesWithLinks(text, metadata));

  const getImageURLs = useCallback(async () => {
    const imageURLs = [];

    if (mediaIds) {
      for (const mediaId of mediaIds) {
        const response = await getSignedMediaUrl({
          folder: 'activity-info',
          media_url_id: mediaId,
        });
        imageURLs.push(response.data.url);
      }
    }

    setImages(imageURLs);
  }, [mediaIds]);

  useEffect(() => {
    if (mediaIds?.length) {
      getImageURLs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaIds?.length]);

  return (
    <div className='bg-white md:bg-card-bg rounded-[14px] p-5 shadow-md md:shadow-none dark:bg-dark-card-bg-old'>
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between items-start'>
          <div className='flex items-center gap-2'>
            <UserAvatar fullName={name} mediaId={profileMediaId} />
            <div>
              <p className='font-semibold'>{name}</p>
              <p className='text-[#545454] text-[12px] dark:text-white'>{relativeTime}</p>
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-1'>
          <span className='text-sm my-4' dangerouslySetInnerHTML={{ __html: cleanHTML }}></span>
          {images?.length ? (
            <div className='flex flex-row gap-3 overflow-x-scroll' id='clearScrollbar'>
              {images.map((img) => (
                <img key={img} src={img} className='max-h-[50px]' />
              ))}
            </div>
          ) : null}

          {comments?.length ? (
            <ReplyOutlinedIcon className='-rotate-[150deg] text-sub-title' />
          ) : null}
          {comments.map((comment, index) => (
            <Comment
              key={comment.created_at as string}
              index={index}
              comment={comment}
              className='px-10'
            />
          ))}
        </div>
      </div>
    </div>
  );
};
