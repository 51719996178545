import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import RowRadioButtonsGroup from 'components/inputs/RadioButtonsGroup';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useAppDispatch } from 'hooks';
import { postDevices } from 'store/thunks';
import { TAddDevicesParams } from 'store/slices/devicesSlice/type';
import { devicesSchema } from 'constants/Schemas';
import { ControlledInput } from 'components';

interface AddDeviceProps {
  onClose: () => void;
  getAllDevices: any;
}

const AddDevice: React.FC<AddDeviceProps> = ({ onClose, getAllDevices }) => {
  const dispatch = useAppDispatch();
  const [eventType, setEventType] = useState<any>();

  const {
    control: invitesControl,
    handleSubmit: devicesSubmit,
    formState: { errors },
  } = useForm<TAddDevicesParams>({
    resolver: yupResolver(devicesSchema),
  });

  const addDevice = (values: TAddDevicesParams) => {
    const sectionCapacity = Number(values.section_capacity.replaceAll(',', ''));

    const data = {
      uid: `${values.device_name}-${eventType}-${values.device_model}`,
      placement: eventType,
      device_model: values.device_model,
      device_name: values.device_name,
      section_name: values.section_name,
      section_capacity: sectionCapacity,
    };

    dispatch(
      postDevices({
        ...data,
        onSuccess() {
          getAllDevices();
          onClose();
        },
      }),
    );
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        <div className='flex justify-between'>
          <p className='font-semibold text-[24px]'>Add New Device</p>
          <div className='flex items-center pr-4 gap-6'>
            <IconButton>
              <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
            </IconButton>
          </div>
        </div>

        <div className='flex flex-col gap-6 mt-14'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            <ControlledInput
              required
              control={invitesControl}
              name='device_name'
              label='Device Name'
              error={!!errors.device_name}
              placeholder='Device Name*'
              helperText={(errors?.device_name?.message as string) ?? ''}
            />
            <ControlledInput
              required
              control={invitesControl}
              name='device_model'
              label='Device Number'
              error={!!errors.device_model}
              placeholder='Device Number*'
              helperText={(errors?.device_model?.message as string) ?? ''}
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            <ControlledInput
              required
              control={invitesControl}
              name='section_name'
              label='Section Name'
              error={!!errors.section_name}
              placeholder='Section Name*'
              helperText={(errors?.section_name?.message as string) ?? ''}
            />
            <ControlledInput
              required
              control={invitesControl}
              name='section_capacity'
              label='Section Capacity'
              error={!!errors.section_capacity}
              placeholder='Section Capacity*'
              helperText={(errors?.section_capacity?.message as string) ?? ''}
            />
          </div>

          <div className='my-10'>
            <RowRadioButtonsGroup
              label='Device Placement'
              options={[
                { value: 'indoor', label: 'Indoor' },
                { value: 'outdoor', label: 'Outdoor' },
              ]}
              value={eventType}
              onChange={(value) => setEventType(value)}
              vertical
            />
          </div>
        </div>
      </div>
      <PrimaryButton
        className='w-full md:w-fit self-end'
        variant='neutral'
        onClick={devicesSubmit(addDevice)}
      >
        Add Device
      </PrimaryButton>
    </div>
  );
};

export default AddDevice;
