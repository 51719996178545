import { Navigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';

import { Routes } from 'types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { EInviteStatus } from 'store/slices/invitationsSlice/types';
import { acceptEmailInvitation, rejectEmailInvitation } from 'store/thunks';

const PublicRoute = ({ children }: any) => {
  const userData = useAppSelector(userDataSelector);
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const token =
    urlParams.get('access_token') || BrowserStorageService.get(BrowserStorageKeys.AccessToken);
  const inviteStatus = urlParams.get('status');
  const isRejectingInvitation = inviteStatus === EInviteStatus.REJECTED;
  const isAcceptingInvitation = inviteStatus === EInviteStatus.ACCEPTED;
  const invitation_token = urlParams.get('token') as string;
  const saved_invitation_token = BrowserStorageService.get(BrowserStorageKeys.InvitationToken);
  const invitation_email = urlParams.get('email') as string;

  const isFirstTimeUser =
    BrowserStorageService.get(BrowserStorageKeys.IsFirstTimeUser, {
      session: true,
    }) === 'true';

  useLayoutEffect(() => {
    // Handle acceptance/rejection of invites from email
    if (window.location.pathname === Routes.Invitation) {
      if (isRejectingInvitation && invitation_token) {
        // Reject invite
        dispatch(rejectEmailInvitation(invitation_token));
        return;
      }

      if (
        isAcceptingInvitation &&
        invitation_token &&
        invitation_email &&
        !token // user has invite, but isn't logged in
      ) {
        // Save invitation token, email, and status for later
        BrowserStorageService.set(BrowserStorageKeys.InvitationToken, invitation_token);
        BrowserStorageService.set(BrowserStorageKeys.InvitationStatus, inviteStatus);
        BrowserStorageService.set(BrowserStorageKeys.InvitationEmail, invitation_email);

        // Navigate user to login page so that they can login and accept invite
        window.location.href = Routes.Login;
      }

      // User has invite and is logged in
      if (
        invitation_token &&
        isAcceptingInvitation &&
        invitation_email === userData?.general_user_info.email &&
        userData?.general_user_info.id
      ) {
        // Accept invite and change organisation
        dispatch(
          acceptEmailInvitation({
            invitation_token,
            user_id: userData?.general_user_info.id,
            onSuccess() {
              // Go to dashboard when invite has been accepted
              window.location.href = Routes.Dashboard;
            },
          }),
        );
      }
    }
  }, [
    dispatch,
    invitation_email,
    invitation_token,
    inviteStatus,
    isAcceptingInvitation,
    isRejectingInvitation,
    token,
    userData?.general_user_info.email,
    userData?.general_user_info.id,
  ]);

  if (token && isFirstTimeUser && !invitation_token && !saved_invitation_token) {
    return <Navigate to={Routes.ProcessForm} />;
  }

  return children;
};

export default PublicRoute;
