import { ongoingEvent } from 'assets/images';

const HIDE = true;

type OngoingEventProps = {
  title: string;
  relativeTime: string;
};

export const OngoingEventCard = ({ title, relativeTime }: OngoingEventProps) => {
  return (
    <div className='bg-white md:bg-[#F0F7FF] dark:bg-dark-card-bg-old  max-w-sm w-[600px] min-w-[400px] p-4 rounded-[14px] flex items-center gap-12 transition-all shadow-md md:shadow-none'>
      <div className='flex items-center gap-3'>
        <img
          src={ongoingEvent}
          alt='upcoming event'
          className='h-[50px] md:h-[60px] rounded-full'
        />
        <div className='flex flex-col gap-2'>
          <p className='font-semibold text-[14px] dark:text-white'>{title}</p>
          <p className='text-[13px] text-[#6B6B6B] dark:text-white'>Started {relativeTime}</p>
        </div>
      </div>
      {HIDE ? null : <p className='text-accent font-semibold cursor-pointer'>View</p>}
    </div>
  );
};
