import React, { FC, useMemo, useRef, useState, useEffect } from 'react';
import { Stage, Layer, Image } from 'react-konva';

import PolygonAnnotation from './PolygonAnnotation';
// import LiveStream from './LiveStream';

const Canvas: FC<{
  videoSource: any;
  points: any;
  setPoints: any;
  setPolyComplete: any;
  isPolyComplete: any;
  setOriginalWH: any;
  setWHOnScreen: any;
  className?: string;
  meetingId?: string;
}> = ({
  videoSource,
  points,
  setPoints,
  setPolyComplete,
  isPolyComplete,
  setOriginalWH,
  setWHOnScreen,
  className,
  meetingId,
}) => {
  const [image, setImage] = useState();
  const imageRef = useRef(null);
  const dataRef = useRef(null);
  const [size, setSize] = useState<any>({});
  const [flattenedPoints, setFlattenedPoints] = useState();
  const [position, setPosition] = useState([0, 0]);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [container, setContainer] = useState<any>();
  const videoElement = useMemo(() => {
    const element: any = new window.Image();
    element.width = container?.offsetWidth;
    element.height = container?.offsetHeight;
    element.style.objectFit = 'cover';
    element.src = videoSource;
    return element;
  }, [videoSource]);
  useEffect(() => {
    const onload = () => {
      setImage(videoElement);
      imageRef.current = videoElement;
    };
    videoElement.addEventListener('load', onload);
    return () => {
      videoElement.removeEventListener('load', onload);
    };
  }, [videoElement]);
  const getMousePos = (stage: any) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };

  const handleMouseDown = (e: any) => {
    if (isPolyComplete) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isMouseOverPoint && points.length >= 3) {
      setPolyComplete(true);
    } else {
      setPoints([...points, mousePos]);
    }
  };
  const handleMouseMove = (e: any) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    setPosition(mousePos);
  };
  const handleMouseOverStartPoint = (e: any) => {
    if (isPolyComplete || points.length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
  };
  const handleMouseOutStartPoint = (e: any) => {
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };
  const handlePointDragMove = (e: any) => {
    const stage = e.target.getStage();
    const index = e.target.index - 1;
    const pos = [e.target._lastPos.x, e.target._lastPos.y];
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;
    if (pos[0] > stage.width()) pos[0] = stage.width();
    if (pos[1] > stage.height()) pos[1] = stage.height();
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
  };

  useEffect(() => {
    setFlattenedPoints(
      points.concat(isPolyComplete ? [] : position).reduce((a: any, b: any) => a.concat(b), []),
    );
  }, [points]);
  const undo = () => {
    setPoints(points.slice(0, -1));
    setPolyComplete(false);
  };
  const handleGroupDragEnd = (e: any) => {
    if (e.target.name() === 'polygon') {
      const result: any = [];
      const copyPoints = [...points];
      copyPoints.map((point) => result?.push([point[0] + e.target.x(), point[1] + e.target.y()]));
      e.target.position({ x: 0, y: 0 });
      setPoints(result);
    }
  };

  const resize = () => {
    setSize({
      width: container?.offsetWidth,
      height: container?.offsetHeight,
    });
  };
  useEffect(() => {
    const container: any = document.querySelector('#stage-parent');
    setContainer(container);
    if (container !== null) {
      setSize({
        width: container?.offsetWidth,
        height: container?.offsetHeight,
      });
    }
    setOriginalWH({
      width: videoElement.naturalWidth,
      height: videoElement.naturalHeight,
    });
    setWHOnScreen({
      width: container?.offsetWidth,
      height: container?.offsetHeight,
    });
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [container]);

  return (
    <div id='stage-parent' className={className}>
      {/* <LiveStream meetingId={meetingId || ''} /> */}
      <Stage
        width={size.width || 719}
        height={size.height || 560}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
      >
        <Layer>
          <Image
            ref={imageRef}
            image={image}
            x={0}
            y={0}
            width={size?.width}
            height={size?.height}
            className='px-1'
          />
          <PolygonAnnotation
            points={points}
            flattenedPoints={flattenedPoints}
            handlePointDragMove={handlePointDragMove}
            handleGroupDragEnd={handleGroupDragEnd}
            handleMouseOverStartPoint={handleMouseOverStartPoint}
            handleMouseOutStartPoint={handleMouseOutStartPoint}
            isFinished={isPolyComplete}
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default Canvas;
