import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { TInitialState } from './types';
import {
  creteNotice,
  getAllNotices,
  getAllActivities,
  getAllHidedPosts,
  postCommentCreate,
  getAllPostLikedUsers,
  createPost,
  getActivePosts,
  getAllNotifications,
  getAllActivityComments,
  getNews,
  askAIAgent,
  updatePost,
  deletePost,
} from './thunks';

const initialState: TInitialState = {
  noticesLimit: 9,
  noticesOffset: 0,
  noticesError: null,
  noticesLoading: false,
  allActivitiesLimit: 9,
  activitiesError: null,
  notices: dataStructure,
  allActivitiesOffset: 0,
  refetchFiltering: false,
  usersActivitiesLimit: 9,
  usersActivitiesOffset: 0,
  activitiesLoading: false,
  posts: dataStructure.data,
  postsCount: dataStructure.total_count,
  postsLoading: false,
  postsError: null,
  postCommentCreateLoad: false,
  postAllComments: dataStructure,
  usersActivities: dataStructure,
  hiddenActivityPosts: dataStructure,
  postLikedAllUsersData: dataStructure,
  notifications: dataStructure,
  postComments: null,
  creatingPost: false,
  news: [],
  newsCount: 0,
  isUpdatingPost: false,
  isDeletingPost: false,
};

const activitiesSlice = createSlice({
  name: 'activitiesSlice',
  reducers: {
    setPage(state, action) {
      state.allActivitiesOffset = (action.payload - 1) * state.allActivitiesLimit;
    },

    refetchFiltering: (state) => {
      state.refetchFiltering = !state.refetchFiltering;
    },

    setUsersActivitiesPage(state, action) {
      state.usersActivitiesOffset = (action.payload - 1) * state.usersActivitiesLimit;
    },

    changeNoticePage(state, action) {
      state.noticesOffset = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getActivePosts.pending, (state) => {
        state.postsLoading = true;
        state.postsError = null;
      })
      .addCase(getActivePosts.fulfilled, (state, action) => {
        const postsResponse = action.payload;
        state.postsLoading = false;
        state.postsError = null;
        state.posts = postsResponse.data ?? [];
        state.postsCount = postsResponse.total_count ?? 0;
      })
      .addCase(getActivePosts.rejected, (state) => {
        state.postsLoading = false;
        state.postsError = null;
      })
      .addCase(createPost.pending, (state) => {
        state.creatingPost = true;
        // state.activitiesLoading = true;
        state.activitiesError = null;
      })
      .addCase(createPost.fulfilled, (state) => {
        state.creatingPost = false;
      })
      .addCase(createPost.rejected, (state, action) => {
        state.creatingPost = false;
        state.activitiesError = action.payload as string;
      })
      .addCase(askAIAgent.pending, (state) => {
        state.creatingPost = true;
        state.activitiesError = null;
      })
      .addCase(askAIAgent.fulfilled, (state) => {
        state.creatingPost = false;
      })
      .addCase(askAIAgent.rejected, (state) => {
        state.creatingPost = false;
      })
      // .addCase(getAllActivities.fulfilled, (state, action: PayloadAction<TActivityReturnType>) => {
      //   state.posts = action.payload;
      //   state.activitiesLoading = false;
      //   state.activitiesError = null;
      // })
      .addCase(getAllActivities.pending, (state) => {
        state.activitiesLoading = true;
        state.activitiesError = null;
      })
      .addCase(getAllActivities.rejected, (state, action) => {
        state.activitiesLoading = false;
        state.activitiesError = action.payload as string;
      })
      // .addCase(
      //   getFilterActivities.fulfilled,
      //   (state, action: PayloadAction<TActivityReturnType>) => {
      //     state.activities = action.payload;
      //     // state.activitiesLoading = false;
      //     state.activitiesError = null;
      //   },
      // )

      .addCase(getAllNotices.fulfilled, (state, action) => {
        state.notices = action.payload;
        state.noticesLoading = false;
        state.noticesError = null;
      })
      .addCase(getAllNotices.pending, (state) => {
        state.noticesLoading = true;
        state.noticesError = null;
      })
      .addCase(getAllNotices.rejected, (state, action) => {
        state.noticesLoading = false;
        state.noticesError = action.payload as string;
      })
      .addCase(getAllHidedPosts.fulfilled, (state, action) => {
        state.hiddenActivityPosts = action.payload;
        // state.activitiesLoading = false;
      })
      .addCase(creteNotice.fulfilled, (state, action) => {
        state.notices = {
          ...state.notices,
          data: [...(state.notices.data || []), action.payload],
        };
        state.noticesLoading = false;
        state.noticesError = null;
      })
      .addCase(postCommentCreate.pending, (state) => {
        state.postCommentCreateLoad = true;
      })
      .addCase(postCommentCreate.fulfilled, (state) => {
        state.postCommentCreateLoad = false;
      })
      .addCase(postCommentCreate.rejected, (state) => {
        state.postCommentCreateLoad = false;
      })
      .addCase(getAllPostLikedUsers.fulfilled, (state, action) => {
        state.postLikedAllUsersData = action?.payload;
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.notifications = action?.payload?.data ? action.payload : dataStructure;
      })
      .addCase(getAllActivityComments.fulfilled, (state, action) => {
        state.postComments = action?.payload;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.news = action?.payload?.data ?? [];
        state.newsCount = action?.payload?.total_count ?? 0;
      })
      .addCase(updatePost.pending, (state) => {
        state.isUpdatingPost = true;
      })
      .addCase(updatePost.fulfilled, (state) => {
        state.isUpdatingPost = false;
      })
      .addCase(updatePost.rejected, (state) => {
        state.isUpdatingPost = false;
      })
      .addCase(deletePost.pending, (state) => {
        state.isDeletingPost = true;
      })
      .addCase(deletePost.fulfilled, (state) => {
        state.isDeletingPost = false;
      })
      .addCase(deletePost.rejected, (state) => {
        state.isDeletingPost = false;
      });
  },
});

export const { setUsersActivitiesPage, changeNoticePage, refetchFiltering } =
  activitiesSlice.actions;

export default activitiesSlice.reducer;
