import { DetailsList } from 'types/components.types';

import DetailsListComponent from './DetailsListComponent';
import IndustryReasonComponent from './IndustryReasonComponent';
import IndustriesHeroSection from './IndustriesHeroSection';

const detailsData: DetailsList[] = [
  {
    title: 'Optimized Staffing',
    subtitle:
      'People counting AI can provide real-time data on the number of people present in different areas of your hospitality establishment. This data can help you allocate staff resources more effectively based on demand.',
    image: '/images/industriesPage/hospitality1.svg',
  },
  {
    title: 'Security and Safety',
    image: '/images/industriesPage/hospitality2.svg',
    subtitle:
      'By monitoring the number of people in various zones, you can identify potential safety hazards or areas that might need immediate attention in case of emergencies.',
  },
  {
    title: 'Enhanced Experience',
    image: '/images/industriesPage/hospitality3.svg',
    subtitle:
      'With accurate people counting, you can manage crowd flow and avoid overcrowding in common areas such as lobbies, restaurants, and event spaces',
  },
  {
    title: 'Space Utilization',
    image: '/images/industriesPage/hospitality4.svg',
    subtitle:
      'By analyzing foot traffic patterns and occupancy data, you can gain insights into how different areas of your establishment are being used.',
  },
];

const HospitalitySection = (): JSX.Element => {
  return (
    <section>
      <IndustriesHeroSection
        imageURL={'/images/industriesPage/hospitality-hero.svg'}
        title='Hospitality CCTV Analytics'
      />

      <IndustryReasonComponent
        data={{
          title: 'Why use HawkEyeVisionTek in the hospitality sector?',
          reasons: [
            'Utilizing this technology for your hospitality business has tremendous benefits.  Be able to monitor guest and employee interactions to boost customer service expeditiously. Track flow and movement of people to prevent overcrowding and congestions.',
            'View open and closed seating areas and communicate to staff to ensure even distribution of guests and avoid. Use this information to better serve your guests, creating repeat visits and generating more revenue for your business today!',
          ],
        }}
      />

      <DetailsListComponent data={detailsData} />
    </section>
  );
};

export default HospitalitySection;
