import { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { sendOrganizationResourcesRequest } from 'api/organizations';
import { PaymentMethodUpdateToastMessages } from 'constants/ToastMessages';
import { setDefaultPaymentMethod } from 'store/slices/subscriptionSlice/thunks';
import { isSubscriptionFree } from 'store/slices/subscriptionSlice/selectors';
import { VoidCallback } from 'types/global/callbacks';

import type { TUseStripePaymentProps } from './types';


const useStripePayment = (options: TUseStripePaymentProps) => {
  const stripe = useStripe();
  const haveFreeSubscribe = useAppSelector(isSubscriptionFree);

  const dispatch = useAppDispatch();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>('');

  const handleSubmit = async (
    client_secret?: string,
    orgId?: number,
    callback?: VoidCallback,
    subscriptionSecret?: string,
  ) => {
    const id = toast.loading(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_UPDATE_START);

    try {
      const setupSecret = options?.clientSecret || client_secret;

      if (!stripe || !elements) {
        return;
      }

      setLoading(true);

      const cardElement = elements.getElement(CardElement);

      const cardDetails = {
        card: cardElement,
      };

      if (cardDetails.card) {
        const newToken = BrowserStorageService.get(BrowserStorageKeys.subscriptionClientSecret);

        const subscriptionIdFromStorage = BrowserStorageService.get(BrowserStorageKeys.SubscriptionId, {
          session: true,
        });
        if (!options?.addCard && !haveFreeSubscribe) {
          const { paymentIntent, error } = await stripe.confirmCardPayment(newToken as string, {
            payment_method: {
              card: cardDetails.card,
              billing_details: {
                name: options.name,
                address: {
                  country: options.country,
                  state: options.state,
                  line1: options.street,
                  postal_code: options.postal_code,
                },
                phone: options.phone,
              },
            },
          });
         
          setError(error?.message ?? null); // If there's an error, set error

          if (!error) {
            await dispatch(
              setDefaultPaymentMethod({
                org_id: orgId as number,
                payment_method_id: paymentIntent?.payment_method as string,
              }),
            );

            await sendOrganizationResourcesRequest(
              Number(orgId),
              String(subscriptionIdFromStorage),
            );
            toast.success(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_UPDATE_SUCCESS, {
              id: id,
            });

            callback?.();
          }
        }

        if (options?.addCard) {
          const { setupIntent, error } = await stripe.confirmCardSetup(setupSecret as string, {
            payment_method: {
              card: cardDetails.card,
              billing_details: {
                name: options.name,
                address: {
                  country: options.country,
                  line1: options.street,
                  state: options.state,
                  postal_code: options.postal_code,
                },
                phone: options.phone,
              },
            },
          });

          if (error) {
            setError(error?.message);
            toast.error(
              error?.message ?? PaymentMethodUpdateToastMessages.PAYMENT_METHOD_UPDATE_FAILURE,
              {
                id: id,
              },
            );
            return;
          }

          await dispatch(
            setDefaultPaymentMethod({
              org_id: (orgId as number) || Number(options?.orgId),
              payment_method_id: setupIntent?.payment_method as string,
            }),
          );

          if (haveFreeSubscribe && !error) {
            await sendOrganizationResourcesRequest(
              Number(orgId),
              String(subscriptionIdFromStorage),
            );
          }

          callback?.();

          toast.success(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_ADD_SUCCESS, {
            id: id,
          });
        }
      }
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_UPDATE_FAILURE, {
        id: id,
      });

      throw Error;
    } finally {
      setLoading(false);
    }
  };

  return { handleSubmit, loading, error, stripe, elements };
};

export default useStripePayment;
