import React, { useCallback, useMemo, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import toast from 'react-hot-toast';

import PrimaryButton from 'components/buttons/PrimaryButton';
import { addPaymentMethodSchema } from 'constants/Schemas';
import { useAppSelector, useDarkMode, useStripePayment, useUserOrganizationsList } from 'hooks';
import NumberControlledInput from 'components/shared/NumberControlledInput';
import ControlledInput from 'components/shared/ControlledInput';
import ControlledSelect from 'components/shared/ControlledSelect';
import { stateOptions } from 'constants/States';
import { Colors } from 'types';
import { subscriptionsApi } from 'api';
import { userActiveSubscriptionSelector } from 'store/slices/subscriptionSlice/selectors';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { getPaymentMethods } from 'store/slices/subscriptionSlice/thunks';
import { useAppDispatch } from 'app/hooks';

import { TEditBillingInfoForm } from './EditBillingInfo';

interface AddPaymentMethodProps {
  onClose: () => void;
}

type TAddPaymentMethodForm = Omit<TEditBillingInfoForm, 'email' | 'city'>;

const AddPaymentMethod: React.FC<AddPaymentMethodProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userDataSelector);
  const { member_info, general_user_info } = userData ?? {};
  const activeSubscription = useAppSelector(userActiveSubscriptionSelector);
  const [isCardValid, setIsCardValid] = useState<boolean>(false);
  const { currentOrganization } = useUserOrganizationsList();

  const { darkMode } = useDarkMode();

  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: darkMode ? Colors.WHITE : '#424770',
        '::placeholder': {
          color: darkMode ? Colors.WHITE : '#aab7c4',
        },
      },

      invalid: {
        color: '#9e2146',
      },
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid: isFormValid, isSubmitting },
  } = useForm<TAddPaymentMethodForm>({
    resolver: yupResolver(addPaymentMethodSchema),
  });

  const addPaymentMethodFormData = watch();

  const { handleSubmit: handleCardSubmit } = useStripePayment({
    name: addPaymentMethodFormData.name,
    phone: addPaymentMethodFormData?.phone,
    orgId: currentOrganization?.id as number,
    postal_code: addPaymentMethodFormData.zip,
    street: addPaymentMethodFormData.address,
    state: addPaymentMethodFormData?.state,
    country: 'US',
    addCard: true,
  });

  const onCardChange = (event: StripeCardElementChangeEvent) => setIsCardValid(!!event.complete);
  const isSubmitButtonDisabled = useMemo(
    () => !isCardValid || !isFormValid || isSubmitting,
    [isCardValid, isFormValid, isSubmitting],
  );

  const onPaymentMethodAdded = useCallback(async () => {
    await dispatch(getPaymentMethods(currentOrganization?.id as number));
    onClose(); // Close Add Payment Method modal
  }, [currentOrganization?.id, dispatch, onClose]);

  const addPaymentMethod = useCallback(async () => {
    try {
      const setupIntentResponse = await subscriptionsApi.setupPaymentIntentRequest(
        currentOrganization?.id as number,
      );
      const cardSetupSecret = setupIntentResponse.data.client_secret;

      await handleCardSubmit(
        cardSetupSecret,
        currentOrganization?.id as number,
        onPaymentMethodAdded,
        activeSubscription?.id,
      );
    } catch (error) {
      toast.error('Could not confirm payment method. Please try again');
    }
  }, [activeSubscription?.id, currentOrganization?.id, handleCardSubmit, onPaymentMethodAdded]);

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        <div className='flex justify-between'>
          <p className='font-semibold text-[24px]'>Add Payment Method</p>
          <div className='flex items-center pr-4 gap-6'>
            <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
          </div>
        </div>
      </div>
      <div>
        <p className='text-semibold mt-7 mb-4'>Card Details</p>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
          <ControlledInput
            defaultValue={general_user_info?.name ?? ''}
            control={control}
            name='name'
            label='Name:*'
            error={!!errors.name}
            placeholder='Full name'
            helperText={errors.name?.message ?? ''}
          />
          <div className='bg-[#F7F7F7] dark:bg-transparent dark:text-white dark:border dark:border-[#6b7280] py-[18px] px-[14px] rounded-sm'>
            <CardElement options={cardElementOptions} onChange={onCardChange} />
          </div>
          <NumberControlledInput
            defaultValue={member_info?.mobile_number ?? ''}
            control={control}
            name='phone'
            label='Phone Number:*'
            error={!!errors.phone}
            placeholder='Phone Number'
            helperText={errors.phone?.message ?? ''}
          />
        </div>
      </div>
      <div>
        <p className='text-semibold my-4'>Billing Information</p>
        <ControlledInput
          defaultValue={member_info?.address ?? ''}
          control={control}
          name='address'
          label='Street:*'
          error={!!errors.address}
          placeholder='Address'
          helperText={errors.address?.message ?? ''}
        />
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-4'>
          <ControlledSelect
            defaultValue={member_info?.state ?? ''}
            name='state'
            control={control}
            options={stateOptions}
            label='State:*'
            border={`1px solid ${Colors.SOFT_SILVER}`}
          />
          <ControlledInput
            defaultValue={member_info?.zip_code ?? ''}
            control={control}
            name='zip'
            label='Zip Code:*'
            error={!!errors.zip}
            placeholder='Zip Code'
            helperText={errors.zip?.message ?? ''}
          />
        </div>
      </div>
      <PrimaryButton
        disabled={isSubmitButtonDisabled}
        className='w-full md:w-fit self-end mt-5'
        onClick={handleSubmit(addPaymentMethod)}
        variant='neutral'
      >
        Add Payment Method
      </PrimaryButton>
    </div>
  );
};

export default AddPaymentMethod;
