import { DetailsList } from 'types/components.types';

import DetailsListComponent from './DetailsListComponent';
import IndustryReasonComponent from './IndustryReasonComponent';
import IndustriesHeroSection from './IndustriesHeroSection';

const detailsData: DetailsList[] = [
  {
    title: 'Counting Feature',
    image: '/images/industriesPage/transport1.svg',
    subtitle:
      'AI algorithms can accurately count the number of passengers entering and exiting vehicles, stations, or transport hubs. This data is invaluable for optimizing services and making informed decisions.',
  },
  {
    title: 'Staff Management',
    image: '/images/industriesPage/transport2.svg',
    subtitle:
      'Knowing passenger flow patterns allows transportation companies to deploy staff more effectively. For instance, if a station is expected to be crowded, additional staff can be assigned for crowd management, ticketing, and customer assistance.',
  },
  {
    title: 'Revenue Generation',
    image: '/images/industriesPage/transport3.svg',
    subtitle:
      'In commercial transportation, such as airports and train stations, the data from people counting AI can be used to identify high-traffic areas. This data can then be leveraged for advertising, retail space allocation, or other revenue-generating opportunities.',
  },
  {
    title: 'Safety and Security',
    image: '/images/industriesPage/transport4.svg',
    subtitle:
      'People counting AI can help identify unusual or unexpected crowd behavior, which could signal security threats or emergency situations. By detecting anomalies, transportation authorities can respond promptly, ensuring passenger safety and maintaining order.',
  },
];

const TransportSection = (): JSX.Element => {
  return (
    <section>
      <IndustriesHeroSection
        imageURL={'/images/industriesPage/transport-hero.svg'}
        title='Transport And Vehicle Counting AI'
      />

      <IndustryReasonComponent
        data={{
          title: 'How can you use HawkEyeVisionTek in the Transportation sector?',
          reasons: [
            'This amazing technology  provides incredible opportunity within the transportation industry. It can be utilized in parking lot management in order to detect vacant spaces, display peak times, and monitor for security and safety purposes, and so much more.',
            'Can be deployed for large and small scale Logistical Operations. This will allow you to store and track the transportation of goods easier and more efficiently, and give you an advantage on making better  strategic decisions.',
          ],
        }}
      />

      <DetailsListComponent data={detailsData} />
    </section>
  );
};

export default TransportSection;
