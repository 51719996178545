import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { subscriptionsApi } from 'api';
import {
  BillingUpdateToastMessages,
  PaymentMethodUpdateToastMessages,
  SubscriptionUpdateToastMessages,
} from 'constants/ToastMessages';
import { RootState, VoidCallback } from 'types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import {
  TCreateSubscription,
  TCreatePaymentIntent,
  TUpdateBillingDetails,
  TUserSubscriptionParams,
  UserSubscriptionStatus,
  TSetDefaultPaymentMethod,
  TUpdateSubscriptionParams,
  TUpdateSubscriptionResponse,
  TGetInvoiceHistoryParams,
} from './types';

export const createSubscription = createAsyncThunk(
  'createSubscription',
  async (options: TCreateSubscription, { dispatch, getState }) => {
    const {
      subscriptions: { freeSubscriptionTrial },
    } = getState() as RootState;

    const sendedValues = freeSubscriptionTrial
      ? {
          ...options,
          free: freeSubscriptionTrial,
        }
      : {
          ...options,
        };

    try {
      const response = await subscriptionsApi.createSubscriptionRequest(sendedValues);

      dispatch(
        getUserSubscriptions({
          org_id: options?.org_id,
          subscription_status: UserSubscriptionStatus.ALL,
        }),
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const createPaymentIntent = createAsyncThunk(
  'createPaymentIntent',
  async (options: TCreatePaymentIntent) => {
    try {
      const response = await subscriptionsApi.createPaymentIntentRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getUserSubscriptions = createAsyncThunk(
  'subscriptionsSlice/getUserSubscriptions',
  async (options: TUserSubscriptionParams) => {
    try {
      const response = await subscriptionsApi.getUserSubscriptionsRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getUserSubscriptionsTrailing = createAsyncThunk(
  'subscriptionsSlice/getUserSubscriptionsTrailing',
  async () => {
    const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

    try {
      const response = await subscriptionsApi.getUserSubscriptionsRequest({
        org_id: Number(currentOrganization),
        subscription_status: UserSubscriptionStatus.TRIALING,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getPaymentMethods = createAsyncThunk(
  'subscriptionsSlice/getPaymentMethods',
  async (org_id: number) => {
    try {
      const response = await subscriptionsApi.getPaymentMethodsRequest(org_id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getDefaultPaymentMethod = createAsyncThunk(
  'subscriptionsSlice/getDefaultPaymentMethod',
  async (org_id: number) => {
    try {
      const response = await subscriptionsApi.getDefaultPaymentMethodsRequest(org_id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const updateBillingDetails = createAsyncThunk(
  'subscriptionsSlice/updateBillingDetails',
  async (
    { onSuccess, ...options }: { onSuccess: () => void } & TUpdateBillingDetails,
    { dispatch },
  ) => {
    const id = toast.loading(BillingUpdateToastMessages.BILLING_UPDATE_START);

    try {
      const response = await subscriptionsApi.updateBillingDetailsRequest(options);
      dispatch(getPaymentMethods(options?.org_id));
      dispatch(getDefaultPaymentMethod(options?.org_id));

      toast.success(BillingUpdateToastMessages.BILLING_UPDATE_SUCCESS, {
        id: id,
      });

      onSuccess?.();

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(BillingUpdateToastMessages.BILLING_UPDATE_FAILURE, {
        id: id,
      });

      throw Error;
    }
  },
);

export const getInvoiceHistory = createAsyncThunk(
  'subscriptionsSlice/getInvoiceHistory',
  async (params: TGetInvoiceHistoryParams) => {
    try {
      const response = await subscriptionsApi.getInvoiceHistoryRequest(params);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const setDefaultPaymentMethod = createAsyncThunk(
  'subscriptionsSlice/setDefaultPaymentMethod',
  async (
    { showToast = false, ...options }: { showToast?: boolean } & TSetDefaultPaymentMethod,
    { dispatch },
  ) => {
    const toastId = showToast
      ? toast.loading(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_SET_DEFAULT_START)
      : null;

    try {
      const response = await subscriptionsApi.setDefaultPaymentMethodRequest(options);
      dispatch(getDefaultPaymentMethod(options.org_id));

      if (toastId) {
        toast.success(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_SET_DEFAULT_SUCCESS, {
          id: toastId,
        });
      }

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      if (toastId) {
        toast.error(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_SET_DEFAULT_FAILURE, {
          id: toastId,
        });
      }

      throw err;
    }
  },
);

export const removePaymentMethod = createAsyncThunk(
  'subscriptionsSlice/removePaymentMethod',
  async (options: TSetDefaultPaymentMethod, { dispatch }) => {
    const toastId = toast.loading(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_DELETE_START);

    try {
      const response = await subscriptionsApi.removePaymentMethodRequest(options);

      await dispatch(getPaymentMethods(options?.org_id)); // Refresh list of payment methods

      toast.success(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_DELETE_SUCCESS, {
        id: toastId,
      });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      toast.error(PaymentMethodUpdateToastMessages.PAYMENT_METHOD_DELETE_FAILURE, {
        id: toastId,
      });
      throw err;
    }
  },
);

export const updateSubscription = createAsyncThunk(
  'subscriptionsSlice/updateSubscription',
  async (
    {
      onSuccess,
      ...options
    }: {
      onSuccess?: (args?: { client_secret?: string; toastId?: string }) => void;
    } & TUpdateSubscriptionParams,
    { dispatch },
  ) => {
    const toastId = toast.loading(SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_START);

    try {
      const response = await subscriptionsApi.updateSubscriptionRequest(options);
      const receivedData = response.data as TUpdateSubscriptionResponse;
      if (receivedData?.type === 'updated_subscription') {
        toast.success(SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_SUCCESS, {
          id: toastId,
        });

        await dispatch(
          getUserSubscriptions({
            org_id: options.org_id,
            subscription_status: UserSubscriptionStatus.ACTIVE,
          }),
        );
        await dispatch(getInvoiceHistory({ org_id: options.org_id, invoice_status: 'paid' }));
        onSuccess?.();
      }

      // Pay for new subscription
      if (receivedData?.type === 'new_subscription' && receivedData.data.client_secret) {
        onSuccess?.({ client_secret: receivedData.data.client_secret, toastId });
      }

      return response.data as TUpdateSubscriptionResponse;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_FAILURE, {
        id: toastId,
      });

      throw Error;
    }
  },
);
