import IndustriesImage1 from 'assets/images/industriesPage/industries1.png';
import { DetailsList } from 'types/components.types';

import DetailsListComponent from './DetailsListComponent';
import IndustriesHeroSection from './IndustriesHeroSection';

const detailsData: DetailsList[] = [
  {
    title: 'Real Time Analysis',
    subtitle:
      'Analyze all information received from sensors in real-time! View trends and be alerted of any abnormalities such as spikes in foot traffic,  or  endangerment',
    image: 'images/realTanalysis.svg',
  },
  {
    title: 'Reduced Theft',
    subtitle:
      'Our people counting AI can be used to identify suspicious behavior, such as people lingering in certain areas of the store or concealing items under their clothing. This information can be used to prevent theft and improve store security.',
    image: 'images/reducedTheft.svg',
  },
  {
    title: 'Crowd Management',
    subtitle:
      'By maintaining a safe number of customers inside the store, retailers can prevent overcrowding and reduce the risk of accidents or discomfort.',
    image: 'images/crowdManagement.svg',
  },
  {
    title: 'Identify Peak Store Time',
    subtitle:
      'This information can be used to schedule staff accordingly and ensure that there are enough employees on hand to handle the influx of customers.',
    image: 'images/peakStore.svg',
  },
];

const RetailSection = () => {
  return (
    <section>
      <IndustriesHeroSection imageURL={IndustriesImage1} title='Retail Store Analytics' />

      <h3 className='text-center text-xl mx-auto md:text-2xl lg:text-3xl font-Avenir-Next-Pro'>
        Why use HawkEyeVisionTek for your retail business?
      </h3>

      <ol className='list-decimal  flex flex-col gap-y-6 list-inside text-sm sm:text-base font-[350] leading-[40px] text-sub-title dark:text-dark-sub-title pt-10'>
        <li>Be able to retrieve all the information necessary to drive your retail business.</li>
        <li>Figure out peak traffic times to determine sales and incentives for customers.</li>
        <li>
          Understand purchasing and browsing habits clearer, helping you gain an advantage in
          determining sales tactics.
        </li>
        <li>
          Receive alerts of any abnormalities detected from sensors, enabling you to take action in
          real-time. The benefits of using this technology for your retail business are
          immeasurable!
        </li>
      </ol>

      <DetailsListComponent data={detailsData} />
    </section>
  );
};

export default RetailSection;
