import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { FC } from 'react';

export const BarGraph: FC<{
  data: any;
  emptyStateData?: any;
  options: any;
}> = ({ data, options, emptyStateData }) => {
  return (
    <div className='flex gap-2 relative px-2 w-full h-full'>
      <div className='w-full h-full'>
        {!data && emptyStateData && (
          <h5 style={{ position: 'absolute', top: '30%', left: '50%' }}>No Data</h5>
        )}
        <Bar data={data ?? emptyStateData} options={options} />
      </div>
    </div>
  );
};
