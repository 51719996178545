import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import {
  TCreateNotice,
  TUpdateNotice,
  TLikePostComment,
  TUpdatePostComment,
  TPostCommentCreate,
  TCreateActivityFlag,
  TPostCommentLikeReturn,
  TActivePostsRequest,
  TUpdatePostRequest,
  TActivityLikesRequest,
  TUpdateNotificationParams,
  TGetAllPostCommentParams,
} from 'store/slices/activitiesSlices/types';
import { activitiesApi } from 'api';
import {
  NoticeToastMessages,
  ActivityFeedToastMessages,
  ReportSendingToastMessages,
} from 'constants/ToastMessages';
import { ThunkAPI } from 'types/global/thunkApi';
import { capitalizeFirstLetter } from 'utils';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import { TParams } from './types';

import { changeNoticePage } from '.';

export const getActivePosts = createAsyncThunk(
  'activitiesSlice/getActivePosts',
  async (params: TActivePostsRequest) => {
    try {
      const response = await activitiesApi.getActivePostsRequest({ ...params, asc: false });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);

export const createPost = createAsyncThunk(
  'activitiesSlice/createPost',
  async (body: FormData, { dispatch }) => {
    const id = toast.loading(ActivityFeedToastMessages.POST_CREATION);

    try {
      const response = await activitiesApi.createPostRequest(body);
      dispatch(getActivePosts({})); // Refresh feed

      toast.success(ActivityFeedToastMessages.POST_CREATION_SUCCESS, {
        id: id,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(ActivityFeedToastMessages.POST_CREATION_FAILURE, {
        id: id,
      });

      throw Error;
    }
  },
);

export const updatePost = createAsyncThunk(
  'activitiesSlice/updatePost',
  async (
    { onSuccess, ...options }: { onSuccess?: () => void } & TUpdatePostRequest,
    { dispatch },
  ) => {
    const id = toast.loading(ActivityFeedToastMessages.POST_EDIT_START);

    try {
      const response = await activitiesApi.updatePostRequest(options);
      await dispatch(getAllActivities({ limit: 100, offset: 0 }));
      dispatch(getActivePosts({ limit: 100, offset: 0 }));

      onSuccess?.();

      toast.success(ActivityFeedToastMessages.POST_EDIT_SUCCESS, {
        id: id,
      });
      return response.data;
    } catch (error) {
      toast.error(ActivityFeedToastMessages.POST_EDIT_FAILURE, {
        id: id,
      });

      const err = error as AxiosError;
      throw err;
    }
  },
);

export const deletePost = createAsyncThunk(
  'activitiesSlice/deletePost',
  async (
    { onSuccess, activity_feed_id }: { onSuccess: () => void; activity_feed_id: number },
    { dispatch },
  ) => {
    const id = toast.loading(ActivityFeedToastMessages.POST_REMOVAL_START);

    try {
      const response = await activitiesApi.deletePostRequest(activity_feed_id);
      dispatch(getAllActivities({ limit: 100, offset: 0 }));
      dispatch(getActivePosts({ limit: 100, offset: 0 }));

      toast.success(ActivityFeedToastMessages.POST_REMOVAL_SUCCESS, {
        id: id,
      });

      onSuccess?.();

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      toast.error(ActivityFeedToastMessages.POST_REMOVAL_FAILURE, {
        id: id,
      });

      throw err;
    }
  },
);

export const getAllActivities = createAsyncThunk(
  'activitiesSlice/getAllActivities',
  async (params: TParams) => {
    const sortedTeamIds = BrowserStorageService.get(BrowserStorageKeys.sortingTeamIds, {
      session: true,
    });

    const sortedTeamSource = BrowserStorageService.get(BrowserStorageKeys.sortingSource, {
      session: true,
    });

    try {
      const sendedParams = sortedTeamSource
        ? { ...params, source: 'team', team_ids: sortedTeamIds }
        : params;

      const response = await activitiesApi.getAllActivitiesRequest(sendedParams as any);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getFilterActivities = createAsyncThunk(
  'activitiesSlice/getFilterActivities',
  async (id: number) => {
    try {
      const response = await activitiesApi.getFilterActivitiesRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const likeActivityPost = createAsyncThunk(
  'activitiesSlice/likeActivityPost',
  async ({ activity_type, activity_id }: TActivityLikesRequest, { dispatch }) => {
    try {
      const response = await activitiesApi.likeActivityPostRequest({ activity_type, activity_id });
      dispatch(getActivePosts({}));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const removeActivityPostLike = createAsyncThunk(
  'activitiesSlice/removeActivityPostLike',
  async ({ activity_type, activity_id }: TActivityLikesRequest, { dispatch }) => {
    try {
      const response = await activitiesApi.removeActivityLikeRequest({
        activity_type,
        activity_id,
      });
      dispatch(getActivePosts({}));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const postCommentCreate = createAsyncThunk(
  'activitiesSlice/postCommentCreate',
  async (data: TPostCommentCreate, { dispatch }) => {
    const id = toast.loading(ActivityFeedToastMessages.COMMENT_CREATION);
    try {
      const response = await activitiesApi.createPostCommentRequest(data);
      // const params = {
      //   offset: 0,
      //   limit: 10,
      //   id: data?.post_id,
      // };
      // dispatch(getAllActivityComments(params));
      dispatch(getActivePosts({})); // Refresh feed
      toast.success(ActivityFeedToastMessages.COMMENT_CREATION_SUCCESS, {
        id: id,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(ActivityFeedToastMessages.COMMENT_CREATION_FAILURE, {
        id: id,
      });
      throw Error;
    }
  },
);

export const likePostComment = createAsyncThunk<
  TPostCommentLikeReturn,
  TLikePostComment | number,
  ThunkAPI
>('activitiesSlice/likePostComment', async (options: TLikePostComment | number) => {
  try {
    const response = await activitiesApi.likeActivityPostCommentRequest(
      options as TLikePostComment,
    );

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;

    throw Error;
  }
});

export const unlikePostComment = createAsyncThunk<TPostCommentLikeReturn, number, ThunkAPI>(
  'activitiesSlice/unlikePostComment',
  async (id: number) => {
    try {
      const response = await activitiesApi.removeActivityCommentLikeRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const removeActivityPostComment = createAsyncThunk(
  'activitiesSlice/removeActivityPostComment',
  async (id: number) => {
    try {
      const response = await activitiesApi.removeActivityCommentRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getAllNotices = createAsyncThunk(
  'activitiesSlice/getAllNotices',
  async (params: TParams) => {
    try {
      const response = await activitiesApi.getAllNotices(params);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const creteNotice = createAsyncThunk(
  'activitiesSlice/creteNotice',
  async (options: TCreateNotice, { dispatch }) => {
    const id = toast.loading(NoticeToastMessages.NOTICE_CREATION);
    try {
      const response = await activitiesApi.createNotice(options);

      toast.success(NoticeToastMessages.NOTICE_CREATION_SUCCESS, {
        id: id,
      });
      dispatch(getAllNotices({ offset: 0, limit: 9 }));
      dispatch(changeNoticePage(0));
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(NoticeToastMessages.NOTICE_CREATION_FAILURE, {
        id: id,
      });

      throw Error;
    }
  },
);

export const removeNotice = createAsyncThunk(
  'activitiesSlice/removeNotice',
  async (notice_id: number, { dispatch }) => {
    const id = toast.loading(NoticeToastMessages.NOTICE_REMOVAL_START);

    try {
      const response = await activitiesApi.removeNoticeById(notice_id);
      dispatch(getAllNotices({ limit: 9, offset: 0 }));
      dispatch(changeNoticePage(0));

      toast.success(NoticeToastMessages.NOTICE_REMOVAL_SUCCESS, {
        id: id,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(NoticeToastMessages.NOTICE_REMOVAL_FAILURE, {
        id: id,
      });

      throw Error;
    }
  },
);

export const updateNotice = createAsyncThunk(
  'activitiesSlice/updateNotice',
  async (options: TUpdateNotice, { dispatch }) => {
    const id = toast.loading(NoticeToastMessages.NOTICE_EDIT_START);

    try {
      const response = await activitiesApi.updateNotice(options);
      dispatch(getAllNotices({ limit: 9, offset: 0 }));
      dispatch(changeNoticePage(0));

      toast.success(NoticeToastMessages.NOTICE_EDIT_SUCCESS, {
        id: id,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(NoticeToastMessages.NOTICE_EDIT_FAILURE, {
        id: id,
      });

      throw Error;
    }
  },
);

export const updatePostComment = createAsyncThunk(
  'activitiesSlice/updatePostComment',
  async (options: TUpdatePostComment) => {
    try {
      const response = await activitiesApi.updatePostCommentRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const createActivityFlag = createAsyncThunk(
  'activitiesSlice/createActivityFlag',
  async (options: TCreateActivityFlag) => {
    const start = toast.loading(ReportSendingToastMessages.REPORT_SENDING_START);
    try {
      const response = await activitiesApi.createActivityFlagRequest(options);

      toast.success(ReportSendingToastMessages.REPORT_SENDING_SUCCESS, {
        id: start,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      const haveErrorMessage =
        !!Error.response &&
        Error.response?.status === 409 &&
        typeof Error?.response?.data === 'string' &&
        !!Error?.response?.data?.length;

      const message = haveErrorMessage
        ? capitalizeFirstLetter(Error?.response?.data as string)
        : ReportSendingToastMessages.REPORT_SENDING_FAILURE;

      toast.error(message, {
        id: start,
      });

      throw Error;
    }
  },
);

export const hideActivityPost = createAsyncThunk(
  'activitiesSlice/hideActivityPost',
  async (id: number, { dispatch }) => {
    try {
      const response = await activitiesApi.hideActivityFeedRequest(id);
      dispatch(getAllHidedPosts());
      dispatch(getAllActivities({ limit: 100, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const unHideActivityPost = createAsyncThunk(
  'activitiesSlice/unHideActivityPost',
  async (id: number, { dispatch }) => {
    try {
      const response = await activitiesApi.unhideActivityFeedRequest(id);
      dispatch(getAllHidedPosts());
      dispatch(getAllActivities({ limit: 100, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getAllHidedPosts = createAsyncThunk('activitiesSlice/getAllHidedPosts', async () => {
  try {
    const response = await activitiesApi.getAllHiddenActivityFeedRequest();

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;

    throw Error;
  }
});

export const getAllPostLikedUsers = createAsyncThunk(
  'activitiesSlice/getAllPostLikedUsers',
  async ({ activity_type, activity_id }: TActivityLikesRequest) => {
    try {
      const response = await activitiesApi.getActivityFeedPostLikesRequest(
        activity_type,
        activity_id,
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getAllNotifications = createAsyncThunk(
  'activitiesSlice/getAllNotifications',
  async (params: TParams) => {
    try {
      const response = await activitiesApi.getAllNotificationsRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);

export const updateNotification = createAsyncThunk(
  'activitiesSlice/updateNotification',
  async (params: TUpdateNotificationParams, { dispatch }) => {
    try {
      const response = await activitiesApi.updateNotificationRequest(params);
      dispatch(getAllNotifications({ limit: 10, offset: 0 })); // Update list of notifications

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);

export const getAllActivityComments = createAsyncThunk(
  'activitiesSlice/getAllActivityComments',
  async (parmas: TGetAllPostCommentParams) => {
    try {
      const response = await activitiesApi.getAllPostCommentsRequest(parmas);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getNews = createAsyncThunk('activitiesSlice/getNews', async (params: TParams) => {
  try {
    const response = await activitiesApi.getNewsRequest(params);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;

    throw Error;
  }
});

export const askAIAgent = createAsyncThunk(
  'activitiesSlice/askAIAgent',
  async (query: string, { dispatch }) => {
    try {
      const response = await activitiesApi.askAIAgentRequest(query);
      await dispatch(getActivePosts({ limit: 100, offset: 0 }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(ActivityFeedToastMessages.ASK_AI_FAILURE);
      throw Error;
    }
  },
);
