import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';

import { useDarkMode } from 'hooks';
import { Routes } from 'types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import ToggleButton from '../buttons/ToggleButton';

import Container from './Container';
import HawkEyeLogo from './HawkEyeLogo';

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigate = useNavigate();
  const { darkMode } = useDarkMode();
  const token = BrowserStorageService.get(BrowserStorageKeys.AccessToken);
  const isFirstTimeUser =
    BrowserStorageService.get(BrowserStorageKeys.IsFirstTimeUser, {
      session: true,
    }) === 'true';
  const hasActiveOrganization =
    BrowserStorageService.get(BrowserStorageKeys.HaveCreatedOrganization) === 'true';

  function goToLogin() {
    // Check if logged in user who has an active organisation
    if (token && !isFirstTimeUser && hasActiveOrganization) navigate(Routes.Dashboard);
    else navigate(Routes.Login);
  }

  return (
    <div className='w-full fixed bg-white dark:bg-[#1f1f1f] dark z-50'>
      <Container className='xl:!px-0 lg:!px-2 md:!px-2'>
        <nav className='flex w-full items-center justify-between py-2 z-50 px-10'>
          <Link
            to={Routes.Landing}
            className='flex items-center justify-center gap-1 cursor-pointer'
          >
            <HawkEyeLogo isDarkMode={darkMode} />
          </Link>
          <div className='flex md:hidden z-50'>
            <button
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-indigo-300'
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className='sr-only'>Open main menu</span>
              <Bars3Icon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>

          {/* Navigation for Large Screens */}
          <div className='hidden md:flex items-center justify-between xl:text-[16px] lg:text-[12px]'>
            <ul className='md:flex xl:space-x-8 lg:space-x-4 items-center '>
              <li className=' px-[10px] py-[5px] rounded-md cursor-pointer'>
                <a href={Routes.Landing} className=' hover:text-accent'>
                  Home
                </a>
              </li>
              <Link to={Routes.Industriespage}>
                <li className=' px-[10px] py-[5px] rounded-md cursor-pointer hover:text-accent'>
                  Industries
                </li>
              </Link>
              <Link to={Routes.ContactUs}>
                <li className=' px-[10px] py-[5px] rounded-md cursor-pointer hover:text-accent'>
                  Contact Us
                </li>
              </Link>
            </ul>
          </div>

          <div className='hidden md:flex md:justify-center md:items-center md:gap-[1rem]'>
            <button
              type='button'
              className='flex gap-x-2 bg-accent px-4 py-2 h-[56px] w-[107px] rounded-[8px] text-white items-center'
              onClick={goToLogin}
            >
              <img src='images/user_nav.svg' alt='' className='w-[24px]' /> login{' '}
            </button>
            <ToggleButton />
          </div>

          <Dialog
            as='div'
            className='lg:hidden bg-[#f8f9fa] z-[999]'
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <Dialog.Panel className='fixed w-full inset-y-0 left-0 z-50 overflow-y-scrol bg-[#f8f9fa] px-4 py-2 text-black'>
              <div className='flex items-center justify-between px-10 py-2 bg-white rounded-[6.25rem]'>
                <Link
                  to='/'
                  className='flex items-center justify-center gap-1 cursor-pointer'
                ></Link>
                <button
                  type='button'
                  className='-m-2.5 rounded-md p-2.5 text-gray-700'
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className='sr-only'>Close menu</span>
                  <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>
              <div className='mt-6 flow-root'>
                <div className='-my-6 divide-y divide-gray-500/10'>
                  <div className='flex flex-col space-y-2 py-6'>
                    <ul>
                      <li
                        className='group py-[5px] rounded-md cursor-pointer relative border-b border-grey-outline my-2'
                        aria-hidden='true'
                      >
                        <Link
                          to={Routes.Landing}
                          className='flex items-center justify-between gap-2 px-4'
                        >
                          <p className='hover:text-accent py-1 group-hover:text-accent'>Home</p>
                        </Link>
                      </li>
                      <li
                        className='group py-[5px] rounded-md cursor-pointer relative border-b border-grey-outline my-2'
                        aria-hidden='true'
                      >
                        <Link
                          to={Routes.Industriespage}
                          className='flex items-center justify-between gap-2 px-4'
                        >
                          <p className='hover:text-accent py-1 group-hover:text-accent'>
                            Industries
                          </p>
                        </Link>
                      </li>
                      <li
                        className='group py-[5px] rounded-md cursor-pointer relative border-b border-grey-outline my-2'
                        aria-hidden='true'
                      >
                        <Link
                          to={Routes.ContactUs}
                          className='flex items-center justify-between gap-2 px-4'
                        >
                          <p className='hover:text-accent py-1 group-hover:text-accent'>
                            Contact Us
                          </p>
                        </Link>
                      </li>

                      <li className=' py-[5px] rounded-md cursor-pointer relative my-2'>
                        <button
                          type='button'
                          className='flex gap-x-2 bg-accent px-4 py-2 h-[56px] w-full rounded-[8px] text-white items-center justify-center'
                          onClick={goToLogin}
                        >
                          <img src='images/user_nav.svg' alt='' className='w-[24px]' /> login{' '}
                        </button>
                      </li>
                      <li className='group py-[5px] rounded-md cursor-pointer relative my-2  text-center'>
                        <ToggleButton />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </nav>
      </Container>
    </div>
  );
};

export default NavBar;
