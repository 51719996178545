import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { IconButton } from '@mui/material';

import { upcomingEvent } from 'assets/images';

const HIDE = true;

type TUpcomingEventCardProps = {
  title: string;
  time: string;
  count?: number;
};

export const UpcomingEventCard = ({ title, time, count }: TUpcomingEventCardProps) => {
  return (
    <div className='flex  items-start justify-between bg-white md:bg-[#F7F7F7] p-5 rounded-[14px] transition-all shadow-md md:shadow-none dark:bg-dark-card-bg'>
      <div className='flex gap-2 items-center'>
        <img
          src={upcomingEvent}
          alt='upcoming event'
          className='h-[60px] md:h-[90px] rounded-full'
        />
        <div className='flex md:items-center justify-between flex-col md:flex-row gap-2'>
          <div className='flex flex-col md:gap-1'>
            <p className='max-w-[220px] md:max-w-sm text-black dark:text-white font-semibold text-[13px] md:text-[16px]'>
              {title}
            </p>
            <p className='text-[11px]'>{time}</p>
            {typeof count === 'number' ? (
              <p className='max-w-[220px] md:max-w-sm text-black dark:text-white font-semibold text-[11px]'>
                {count} {'people'}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      {HIDE ? null : (
        <IconButton>
          <MoreHorizOutlinedIcon className='text-black' />
        </IconButton>
      )}
    </div>
  );
};
