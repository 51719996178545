import { useNavigate } from 'react-router-dom';

import PrimaryButton from 'components/buttons/PrimaryButton';
import Container from 'components/shared/Container';
import HawkEyeLogo from 'components/shared/HawkEyeLogo';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { Routes } from 'types';
import { useClearStorage, useDarkMode } from 'hooks';

const Success = () => {
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();
  const { clearStorage } = useClearStorage(false);

  const landingPage = () => {
    BrowserStorageService.remove(BrowserStorageKeys.subscriptionClientSecret);
    clearStorage();
    navigate(Routes.Landing);
  };

  return (
    <Container className='  gap-x-10 py-2 items-center '>
      <div className='flex flex-col items-center justify-center h-[60vh]  gap-6'>
        <HawkEyeLogo isDarkMode={darkMode} />
        <h1 className=' lg:text-[30px] text-[36px] font-normal  xl:leading-[80px] lg:leading-[60px] leading-[45px] tracking-wide transition-opacity ease-in duration-700 opacity-100'>
          Congratulations
        </h1>
        <p className='text-main-sub-text font-semibold text-center dark:text-white'>
          Your organization is being created. Please check your email to login to your organization
          account.
        </p>
        <div className='flex flex-col gap-8 mt-8'>
          <PrimaryButton onClick={() => landingPage()}>Go to Home Page</PrimaryButton>
        </div>
      </div>
    </Container>
  );
};

export default Success;
