import { FC, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import classNames from 'classnames';
import { Controller, useFieldArray } from 'react-hook-form';

import { useAppDispatch } from 'hooks';
import { PlusFilledIcon, PlusIcon } from 'assets/icons';
import { ProcessFormTitle } from 'components';
import FormLayout from 'components/FormLayout';
import { getAllRoles } from 'store/thunks';
import { TGetRolesParams } from 'store/slices/authSlice/types';

import styles from './InviteUsers.module.scss';
import { TInviteUsersProps } from './types';
import InviteUserItem from './InviteUserItem';

const InviteUsers: FC<TInviteUsersProps> = ({
  errors,
  control,
  withOutTitle = false,
  setIsInvitingYourself,
}) => {
  const dispatch = useAppDispatch();
  const [roles, setRoles] = useState<{ id: number; value: number; label: string }[]>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invitedUsers',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ role: '', email: '', fullName: '', id: Math.random() });
    }
  }, [fields, append]);

  const removeUserInfo = (index: number) => {
    remove(index);
  };

  async function getRoles() {
    const options = {
      type: 'organization',
      limit: 10,
      offset: 0,
    };

    const response = await dispatch(getAllRoles(options as TGetRolesParams)).unwrap();
    setRoles(response?.map((role) => ({ id: role?.id, value: role?.id, label: role?.role })) ?? []);
  }

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerClasses = classNames(styles.container, {
    [styles.container__modal]: withOutTitle,
  });

  const invitedUserItems = fields.map((user, idx) => (
    <div key={user.id} style={{ maxWidth: withOutTitle && idx !== 0 ? 834 : '100%' }}>
      <Controller
        key={user.id}
        control={control}
        name={`invitedUsers[${idx}]`}
        render={() => (
          <InviteUserItem
            idx={idx}
            isOnlyItem={fields.length === 1}
            control={control}
            roles={roles ?? []}
            errors={errors.invitedUsers?.[idx]}
            removeUserInfo={() => removeUserInfo(idx)}
            setIsInvitingYourself={setIsInvitingYourself}
            plusIcon={
              <PlusFilledIcon className={'container__plus'} role='button' onClick={append} />
            }
          />
        )}
      />
    </div>
  ));

  return (
    <div className={containerClasses}>
      {!withOutTitle && <ProcessFormTitle lightSubtitle title='Invite Users' />}

      {!withOutTitle ? (
        <FormLayout withIcons className={styles.container__form}>
          {invitedUserItems}
          <button className='flex gap-3 items-center cursor-pointer' onClick={append}>
            <IconButton>
              <AddCircleOutlineIcon className='text-accent' />
            </IconButton>
            <p className='font-semibold'>Add User</p>
          </button>
        </FormLayout>
      ) : (
        <>
          {invitedUserItems}
          <div>
            <PlusIcon
              width={16}
              height={16}
              role='button'
              onClick={append}
              className={'container__plus__new'}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default InviteUsers;
