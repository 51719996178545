import { useDarkMode } from 'hooks/useDarkMode';

import PrimaryButton from '../../components/buttons/PrimaryButton';
import Card from '../../components/shared/Card';
import Container from '../../components/shared/Container';

const Decision = ({ onClickScheduleCall }: { onClickScheduleCall?: () => void }) => {
  const { darkMode } = useDarkMode();

  const decisionInfo = [
    {
      icons: darkMode ? 'images/darkRealTime.svg' : 'images/real_time.svg',
      title: 'Real time insights',
      subTitle:
        'The real-time capabilities of HawkEyeVisionTek empowers organizations and individuals to react promptly to changing scenarios',
    },
    {
      icons: darkMode ? 'images/darkLock.svg' : 'images/padlock.svg',
      title: 'Privacy-Centric Platform',
      subTitle:
        'HawkEyeVisionTek incorporates de-identification techniques, ensuring that while visual details are clear, individual identities are protected',
    },
    {
      icons: darkMode ? 'images/darkHolistic.svg' : 'images/data.svg',
      title: 'Holistic Data Overview',
      subTitle:
        'HawkEyeVisionTek offers a comprehensive perspective through aggregated data overviews. This panoramic insight is invaluable for strategic planning and understanding broader patterns and trends.',
    },
    {
      icons: darkMode ? 'images/darkAnomaly.svg' : 'images/detect.svg',
      title: 'Anomaly and Outlier Detection',
      subTitle:
        "HawkEyeVisionTek's acute anomaly detection ability is critical in various applications, such as spotting unauthorized activities, identifying potential failures, or flagging unusual patterns that may indicate fraud or other irregularities. ",
    },
  ];

  return (
    <div className=' relative overflow-hidden'>
      {/* <img src='/images/bg_dec_vector.svg' /> */}
      <div className='absolute inset-0 flex justify-center -top-[28px]'>
        <div className='bg-shape bg-primary opacity-50 bg-blur ' />
        <div className='bg-shape bg-red opacity-50 bg-blur ' />
        <div className='bg-shape bg-teal opacity-50 bg-blur ' />
      </div>

      <Container className='xl:py-[70px] lg:py-16 py-14 '>
        <div className='md:w-3/5  w-full '>
          <h3 className='md:text-[30px] text-[18px] font-semibold font-Avenir-Next-Pro mb-2 md:text-left text-center z-10 relative'>
            We help Organizations make informed decisions, backed by data & analytics.
          </h3>
        </div>
        <div className='flex flex-col md:flex-row gap-x-10 gap-y-10 '>
          <div className='md:w-3/5  w-full '>
            <div className='grid lg:grid-cols-2 grid-cols-1  gap-5 mt-7 place-items-center '>
              {decisionInfo.map((analytic, index) => (
                <Card
                  key={String(index + 1)}
                  icon={analytic.icons}
                  title={analytic.title}
                  subTitle={analytic.subTitle}
                  className='!z-10'
                />
              ))}
            </div>
          </div>
          <div className='md:w-2/5  w-full flex justify-end '>
            <div className='bg-white dark:bg-dark-decisions-bg dark:backdrop-blur-[9px] rounded-[40px] center-child flex-col p-10  md:h-[300px] z-10'>
              <h3 className='md:text-[30px] text-[18px] font-semibold capitalize mb-10 font-Avenir-Next-Pro'>
                Do you want to try out HawkEyeVisionTek?
              </h3>
              <PrimaryButton className='w-full z-10' isArrow onClick={onClickScheduleCall}>
                Schedule a Call
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Decision;
