import UserAvatar from 'components/shared/UserAvatar';
import { getRelativeTime } from 'helpers/utils';
import { TPostComment } from 'store/slices/activitiesSlices/types';

export function Comment({
  comment,
  index,
  className = '',
}: {
  index: number;
  comment: TPostComment;
  className?: string;
}) {
  return (
    <div className={`flex flex-row items-start ${className}`} key={index}>
      <UserAvatar fullName={comment?.name} mediaId={comment?.profile_image_url_id} />

      <article className='p-3 pt-0 w-full'>
        <div className='flex flex-row justify-between mb-4'>
          <p className=' text-main-text dark:text-white font-semibold'>{comment?.name ?? ''}</p>
          <p className='text-[#C3C5CD] text-sm'>{getRelativeTime(comment?.created_at)}</p>
        </div>
        <p className='text-sm'>{comment?.content}</p>
      </article>
    </div>
  );
}
