import React from 'react';

interface HawKEyeLogo {
  className?: string;
  isDarkMode?: boolean;
}

const HawkEyeLogo: React.FC<HawKEyeLogo> = ({ className, isDarkMode = false }) => {
  return (
    <img
      src={isDarkMode ? '/images/hawk_white_logo.png' : '/images/hawk_logo.png'}
      width={200}
      alt='HawkEyeVisionTek logo'
      className={className}
    />
  );
};

HawkEyeLogo.defaultProps = {
  className: '',
};

export default HawkEyeLogo;
