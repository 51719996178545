export const DeviceCardSkeleton = () => (
  <div className='animate-pulse relative'>
    <HeadingSkeleton />
    <ImageSkeleton />
    <div className='flex w-full justify-between items-center absolute bottom-3 p-4'>
      <IconSkeleton />
      <IconSkeleton />
    </div>
  </div>
);

export const ImageSkeleton = () => <div className='h-40 bg-gray-200 rounded-md mb-4' />;

export const HeadingSkeleton = () => <div className='h-6 w-[45%] bg-gray-300 rounded mb-4' />;

export const BadgeSkeleton = () => (
  <div className='p-2 rounded bg-gray-300'>
    <div className='h-4 w-[50px] bg-gray-200 rounded mb-4' />
  </div>
);

export const IconSkeleton = () => <div className='size-8 bg-gray-300 rounded-md' />;
