import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SvgIconProps } from '@mui/material';

import { NavItem } from 'components/shared/NavItem';
import { Routes } from 'types';
import { useAppSelector, usePermissions } from 'hooks';

import { TSettingsProps } from './types/profileType';
import './styles/dashboard.scss';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { TUserRole } from 'store/slices/authSlice/types';
import { userRoleEnums } from 'components/shared/EmployeeStatus/types';
import { subscriptionTypeSelector } from 'store/slices/organizationsSlice/selectors';

export type TRoutes = {
  title: string;
  icon?: React.FC<SvgIconProps>;
  isLogout?: boolean;
  route?: string;
  action?: () => void;
};

const Settings: FC<TSettingsProps> = ({ children }) => {
  const userData = useAppSelector(userDataSelector);
  const subscriptionType = useAppSelector(subscriptionTypeSelector);
  const { user_roles } = userData ?? {};
  const location = useLocation();
  const { isAllowedToManageBillingSettings, isAllowedToManageInviteSettings } = usePermissions();

  const isRouteActive = (route: string) => {
    return location.pathname.includes(route);
  };

  const userWritePermission = user_roles?.some((user: TUserRole) => {
    return user?.role?.toLowerCase() === userRoleEnums.OWNER?.toLowerCase();
  });

  const settingsRoutes: TRoutes[] = useMemo(
    () => [
      {
        title: 'My Profile',
        route: Routes.ProfileSettings,
      },
      ...(isAllowedToManageInviteSettings
        ? [
            {
              title: 'Invites',
              route: Routes.InvitesSettings,
            },
          ]
        : []),
      {
        title: 'Notifications',
        route: Routes.NotificationSettings,
      },
      {
        title: 'My Organization',
        route: Routes.OrganizationSettings,
      },
      ...(isAllowedToManageBillingSettings
        ? [
            {
              title: 'Billing',
              route: Routes.BillingSettings,
            },
          ]
        : []),
      ...(userWritePermission && subscriptionType === 'api'
        ? [
            {
              title: 'API',
              route: Routes.APISettings,
            },
          ]
        : []),
      // ...(userWritePermission
      //   ? [
      //       {
      //         title: 'Deployment',
      //         route: Routes.OnPremSettings,
      //       },
      //     ]
      //   : []),
    ],
    [
      isAllowedToManageBillingSettings,
      isAllowedToManageInviteSettings,
      userWritePermission,
      subscriptionType,
    ],
  );

  return (
    <div>
      <p className='text-[24px] font-semibold'>Settings</p>
      <div className='flex gap-3  mt-5 md:mt-10 '>
        <div className=' w-[400px] bg-white p-3 lg:flex flex-col gap-3 hidden dark:bg-dark-card-bg'>
          {settingsRoutes.map(({ title, route }) => (
            <NavItem
              key={title}
              title={title}
              active={isRouteActive(route as string)}
              route={route}
            />
          ))}
        </div>
        <div
          className='w-full bg-white rounded-t-[4px] overflow-scroll dark:bg-dark-card-bg'
          id='clearScrollbar'
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Settings;
