import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import membersStructure from 'constants/membersStructure';
import dataStructure from 'constants/options';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import { TInitialState, TOrganization, TOrganizationMemberReturnType } from './types';
import {
  getAllUsers,
  getInvitedUsers,
  updateOrganization,
  getOrganizationById,
  getAllOrganisations,
  getInvitationByOrgId,
  createNewOrganization,
  getOrganizationMembers,
} from './thunks';

const initialState: TInitialState = {
  error: null,
  loading: false,
  allUsersLimit: 5,
  allUsersOffset: 0,
  allUsersError: null,
  pendingUsersLimit: 5,
  pendingUsersOffset: 0,
  allUsersLoading: false,
  needToFetchList: false,
  allUsers: dataStructure,
  pendingUsersError: null,
  updatedOrganization: null,
  createdOrganization: null,
  pendingUsersLoading: false,
  organizationMembersLimit: 5,
  pendingUsers: dataStructure,
  organizations: dataStructure,
  organizationMembersOffset: 0,
  currentOrganizationById: null,
  createOrganizationError: null,
  updateOrganizationError: null,
  organizationMembersError: null,
  currentOrganizationError: null,
  updateOrganizationLoading: false,
  organizationInvitedUsersLimit: 5,
  createOrganizationLoading: false,
  currentOrganizationLoading: false,
  organizationMembersLoading: false,
  organizationInvitedUsersOffset: 0,
  organizationInvitedUsersError: null,
  organizationInvitedUsersLoad: false,
  organizationMembers: membersStructure,
  organizationInvitedUsers: dataStructure,
  subscriptionType: undefined,
};

const organizationsSlice = createSlice({
  name: 'organizationsSlice',
  initialState,
  reducers: {
    setPage(state, action) {
      state.allUsersOffset = (action.payload - 1) * state.allUsersLimit;
    },
    setLimit(state, action) {
      state.allUsersLimit = action.payload;
    },
    setInvitedUsersPage(state, action) {
      state.organizationInvitedUsersOffset =
        (action.payload - 1) * state.organizationInvitedUsersLimit;
    },

    setInvitedUsersLimit(state, action) {
      state.organizationInvitedUsersLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewOrganization.pending, (state) => {
        state.createOrganizationLoading = true;
      })
      .addCase(createNewOrganization.fulfilled, (state, action: PayloadAction<TOrganization>) => {
        state.createOrganizationLoading = false;
        state.createdOrganization = action.payload;
        state.createOrganizationError = null;

        const orgId = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

        if (orgId) {
          BrowserStorageService.update(
            BrowserStorageKeys.CurrentOrganizationId,
            String(action.payload.id),
          );
        } else {
          BrowserStorageService.set(
            BrowserStorageKeys.CurrentOrganizationId,
            String(action.payload.id),
          );
        }
      })
      .addCase(createNewOrganization.rejected, (state, action) => {
        state.createOrganizationLoading = false;
        state.createOrganizationError = action.error.message as string;
      })
      .addCase(getOrganizationById.pending, (state) => {
        state.currentOrganizationLoading = true;
        state.currentOrganizationError = null;
      })
      .addCase(getOrganizationById.fulfilled, (state, action) => {
        state.currentOrganizationById = action.payload;
        state.subscriptionType = action.payload?.sub_type;
        state.currentOrganizationLoading = false;
      })

      .addCase(updateOrganization.rejected, (state, action) => {
        state.updateOrganizationLoading = false;
        state.updateOrganizationError = action.error.message as string;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.updatedOrganization = action.payload;
        state.updateOrganizationLoading = false;
        state.needToFetchList = !state.needToFetchList;
      })
      .addCase(updateOrganization.pending, (state) => {
        state.updateOrganizationLoading = true;
        state.updateOrganizationError = null;
      })

      .addCase(getOrganizationMembers.rejected, (state, action) => {
        state.organizationMembersLoading = false;
        state.organizationMembersError = action.error.message as string;
      })
      .addCase(
        getOrganizationMembers.fulfilled,
        (state, action: PayloadAction<TOrganizationMemberReturnType>) => {
          state.organizationMembers = action.payload;
          state.organizationMembersLoading = false;
        },
      )
      .addCase(getOrganizationMembers.pending, (state) => {
        state.organizationMembersLoading = true;
        state.organizationMembersError = null;
      })

      .addCase(getOrganizationById.rejected, (state, action) => {
        state.currentOrganizationError = action.payload as string;
        state.currentOrganizationLoading = false;
      })
      .addCase(getAllOrganisations.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        BrowserStorageService.set(
          BrowserStorageKeys.HaveCreatedOrganization,
          JSON.stringify(
            !!action.payload?.total_count &&
              action.payload.data.some((org) => org.resource_status === 'created'),
          ),
        );

        state.organizations = action.payload;
      })

      .addCase(getInvitationByOrgId.pending, (state) => {
        state.organizationInvitedUsersLoad = true;
        state.organizationInvitedUsersError = null;
      })

      .addCase(getInvitationByOrgId.fulfilled, (state, action) => {
        state.organizationInvitedUsersLoad = false;
        state.organizationInvitedUsersError = null;
        state.organizationInvitedUsers = action.payload;
      })

      .addCase(getInvitationByOrgId.rejected, (state, action) => {
        state.organizationInvitedUsersError = action.payload as string;
        state.organizationInvitedUsersLoad = false;
      })

      .addCase(getAllOrganisations.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message as string;
      })

      .addCase(getAllUsers.rejected, (state, action) => {
        state.allUsersLoading = false;
        state.allUsersError = action.error.message as string;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.allUsers = action.payload;
        state.allUsersLoading = false;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.allUsersLoading = true;
        state.allUsersError = null;
      })

      .addCase(getInvitedUsers.rejected, (state, action) => {
        state.pendingUsersLoading = false;
        state.pendingUsersError = action.error.message as string;
      })
      .addCase(getInvitedUsers.fulfilled, (state, action) => {
        state.pendingUsers = action.payload;
        state.pendingUsersLoading = false;
      })
      .addCase(getInvitedUsers.pending, (state) => {
        state.pendingUsersLoading = true;
        state.pendingUsersError = null;
      });
  },
});

export const { setPage, setLimit, setInvitedUsersPage, setInvitedUsersLimit } =
  organizationsSlice.actions;

export default organizationsSlice.reducer;
