import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useStripe } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';

import { useAppSelector, usePermissions, useUserOrganizationsList, useWindowSize } from 'hooks';
import { Routes } from 'types';
import { useAppDispatch } from 'app/hooks';
import {
  getDefaultPaymentMethod,
  getInvoiceHistory,
  getPaymentMethods,
  getUserSubscriptions,
  removePaymentMethod,
  setDefaultPaymentMethod,
  updateSubscription,
} from 'store/slices/subscriptionSlice/thunks';
import {
  currentPaymentMethodSelector,
  invoicesHistorySelector,
  userActiveSubscriptionSelector,
  userPaymentMethodsSelector,
} from 'store/slices/subscriptionSlice/selectors';
import { getCardLogo, secondsToDateTime } from 'helpers/utils';
import { UserSubscriptionStatus } from 'store/slices/subscriptionSlice/types';
import { capitalizeFirstLetter } from 'utils';
import SidebarModal from 'components/shared/SideBarModal';
import EditBillingInfo from 'components/billing/EditBillingInfo';
import subscriptionPlans from 'constants/SubscriptionPlans';
import { TPaymentMethod } from 'types/global/paymentMethods';
import AddPaymentMethod from 'components/billing/AddPaymentMethod';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { TSendDuration } from 'containers/ProcessForm/types';
import { SubscriptionPlanBoxes } from 'components';
import CustomModal from 'components/Modal';
import { RootObject, generatePlan } from 'utils/generatePlan';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';
import { PopOverOption } from 'components/shared/PopOverOption';
import { TPopOverOptionProps } from 'components/shared/PopOverOption/types';
import { SubscriptionUpdateToastMessages } from 'constants/ToastMessages';

const MINIMUM_BREAK_POINT = 768;
const MAX_PAYMENT_METHODS = 3;

type TPlan = 'Basic' | 'Pro' | 'Premier' | 'Custom';
type TPlanType = 'API' | 'Device';

function planBenefits(plan: TPlan, planType: TPlanType = 'API') {
  switch (planType) {
    case 'API':
      switch (plan) {
        case 'Basic':
          return subscriptionPlans.API.BasicPlan;
        case 'Premier':
          return subscriptionPlans.API.PremierPlan;
        case 'Custom':
          return subscriptionPlans.API.CustomPlan;
        default:
          return subscriptionPlans.API.ProPlan;
      }
    case 'Device':
      switch (plan) {
        case 'Basic':
          return subscriptionPlans.device.BasicPlan;
        case 'Premier':
          return subscriptionPlans.device.PremierPlan;
        case 'Custom':
          return subscriptionPlans.device.CustomPlan;
        default:
          return subscriptionPlans.device.ProPlan;
      }
    default:
      return [];
  }
}

const DownloadButton = ({
  type,
  downloadUrl,
  fileName,
}: {
  type: 'primary' | 'secondary';
  downloadUrl: string;
  fileName: string;
}) => {
  const textClassNames = classNames('text-black dark:text-white hidden md:flex', {
    'text-white': type === 'primary',
    'text-black': type === 'secondary',
  });

  return (
    <a
      href={downloadUrl}
      download={fileName}
      id='downloadReceiptBillingTour'
      className={classNames(
        'flex flex-row gap-2 justify-center items-center border p-2 rounded h-[48px] cursor-pointer',
        {
          'bg-accent': type === 'primary',
          'bg-transparent': type === 'secondary',
        },
      )}
    >
      <FileDownloadOutlinedIcon className={textClassNames} />
      <p className={textClassNames}>Download</p>
    </a>
  );
};

type TBillingHistoryItemProps = {
  planName: string;
  invoiceFileName: string;
  amountPaid: number;
  datePaid: Date;
  currencySymbol: string;
  cardType: string;
  cardLast4Digits: string;
  linkToInvoicePdf: string;
};

const RenderBillingHistoryItem = ({
  planName,
  invoiceFileName,
  amountPaid,
  datePaid,
  currencySymbol,
  cardType,
  cardLast4Digits,
  linkToInvoicePdf,
}: TBillingHistoryItemProps) => (
  <div className='flex flex-row items-center p-3 justify-between border rounded-md'>
    <div className='flex flex-col gap-2'>
      <p className='font-bold capitalize'>
        {planName} - {currencySymbol}
        {amountPaid.toFixed(2)}
      </p>
      <p className='text-gray-500 text-sm'>{dayjs(datePaid).format('MMM D[,] YYYY')}</p>
    </div>
    <div className='flex flex-row items-center gap-4'>
      {cardType ? <img src={getCardLogo(cardType.toLowerCase())} className='h-[15px]' /> : null}
      {cardLast4Digits ? (
        <p className='text-gray-500 dark:text-white'>Card ending {cardLast4Digits}</p>
      ) : null}
      <DownloadButton type='secondary' downloadUrl={linkToInvoicePdf} fileName={invoiceFileName} />
    </div>
  </div>
);

const RenderPaymentMethod = ({
  isDefault = false,
  paymentMethod,
  onClickOptions,
  className = '',
}: {
  isDefault?: boolean;
  paymentMethod: TPaymentMethod;
  onClickOptions: (
    event: React.MouseEvent<HTMLButtonElement>,
    paymentMethod: TPaymentMethod,
  ) => void;
  className: string;
}) => {
  const { width: windowWidth } = useWindowSize();
  const cardDescription = paymentMethod?.card?.brand
    ? `${capitalizeFirstLetter(paymentMethod?.card?.brand)} ending in ${paymentMethod?.card?.last4}`
    : '';

  return (
    <div
      className={`p-4 bg-white dark:bg-darkBg flex flex-row justify-between items-start border dark:border-none rounded-md ${className}`}
    >
      <div className='flex flex-row items-start gap-4'>
        {paymentMethod?.card?.brand ? (
          <img src={getCardLogo(paymentMethod?.card?.brand.toLowerCase())} className='h-[25px]' />
        ) : null}
        <div className='flex flex-col gap-2'>
          <p className='font-bold'>
            {isDefault && (windowWidth as number) < MINIMUM_BREAK_POINT
              ? cardDescription + ' (default)'
              : cardDescription}
          </p>
          {paymentMethod?.card?.exp_month && paymentMethod?.card?.exp_year ? (
            <p className='text-gray-500 dark:text-white'>
              Expiry {paymentMethod?.card?.exp_month.toString().padStart(2, '0')}/
              {paymentMethod?.card?.exp_year}
            </p>
          ) : null}
        </div>
      </div>
      <div className='flex flex-row items-center md:gap-4' id='billingsElipsesTour'>
        {isDefault && (windowWidth as number) > MINIMUM_BREAK_POINT ? (
          <p className='bg-accent text-white text-sm p-1 rounded'>Default</p>
        ) : null}
        <button
          className='bg-transparent underline text-accent dark:text-white'
          onClick={(event) => onClickOptions(event, paymentMethod)}
        >
          <MoreVertOutlinedIcon />
        </button>
      </div>
    </div>
  );
};

const BillingTab = () => {
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const { width: windowWidth } = useWindowSize();
  const { currentOrganization } = useUserOrganizationsList();
  const invoicesHistory = useAppSelector(invoicesHistorySelector);
  const paymentMethods = useAppSelector(userPaymentMethodsSelector);
  const activeSubscription = useAppSelector(userActiveSubscriptionSelector);
  const defaultPaymentMethod = useAppSelector(currentPaymentMethodSelector);
  const { items, metadata, current_period_end } = activeSubscription ?? {};
  const { isAllowedToManageBillingSettings } = usePermissions();
  const [isShowingEditBillingDetails, setIsShowingEditBillingDetails] = useState<boolean>(false);
  const [isShowingAddPaymentMethodModal, setIsShowingAddPaymentMethodModal] =
    useState<boolean>(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState<TPaymentMethod | null>(null);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<HTMLButtonElement | null>();
  const [isShowingOptions, setIsShowingOptions] = useState<boolean>(false);
  const [isShowingPlans, setIsShowingPlans] = useState<boolean>(false);
  const [activeCardForSend, setActiveCardForSend] = useState<TSendDuration>({
    title: 'basic',
    duration: 'Monthly',
    amount: 0,
    subscription_type: 'api',
  });
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);

  const onClickOptions = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, paymentMethod: TPaymentMethod) => {
      setPopoverAnchorElement(event.currentTarget);
      setIsShowingOptions(true);
      setActivePaymentMethod(paymentMethod);
    },
    [],
  );

  const onCloseOptions = useCallback(() => {
    setPopoverAnchorElement(null);
    setIsShowingOptions(false);
  }, []);

  const hideEditBillingDetails = useCallback(() => {
    setIsShowingEditBillingDetails(false);
    setActivePaymentMethod(null);
  }, []);

  const showEditBillingDetails = useCallback(() => {
    setIsShowingEditBillingDetails(true);
    setActivePaymentMethod(activePaymentMethod);
    setIsShowingOptions(false);
  }, [activePaymentMethod]);

  const handleRemovingPaymentMethod = useCallback(() => {
    dispatch(
      removePaymentMethod({
        org_id: currentOrganization?.id as number,
        payment_method_id: activePaymentMethod?.id as string,
      }),
    );
    setIsShowingOptions(false);
    setIsDeleteConfirmationOpen(false);
  }, [activePaymentMethod?.id, currentOrganization?.id, dispatch]);

  const onRemovePaymentMethod = useCallback(() => {
    setIsDeleteConfirmationOpen(true);
  }, []);

  const onSetDefaultPaymentMethod = useCallback(() => {
    dispatch(
      setDefaultPaymentMethod({
        showToast: true,
        org_id: currentOrganization?.id as number,
        payment_method_id: activePaymentMethod?.id as string,
      }),
    );
    setIsShowingOptions(false);
  }, [activePaymentMethod?.id, currentOrganization?.id, dispatch]);

  const hideAddPaymentMethodModal = useCallback(() => {
    setIsShowingAddPaymentMethodModal(false);
  }, []);
  const showAddPaymentMethodModal = useCallback(() => {
    setIsShowingAddPaymentMethodModal(true);
  }, []);

  const onClosePlans = useCallback(
    (args?: { client_secret?: string; toastId?: string }) => {
      const { client_secret, toastId } = args ?? {};

      if (client_secret && defaultPaymentMethod?.id && currentOrganization?.id) {
        stripe
          ?.confirmPayment({
            clientSecret: client_secret,
            confirmParams: { payment_method: defaultPaymentMethod.id },
            redirect: 'if_required',
          })
          .then(async (res) => {
            // Payment successful
            if (!res.error) {
              await dispatch(
                getUserSubscriptions({
                  org_id: currentOrganization?.id,
                  subscription_status: UserSubscriptionStatus.ACTIVE,
                }),
              );
              await dispatch(
                getInvoiceHistory({
                  org_id: currentOrganization?.id,
                  invoice_status: 'paid',
                }),
              );

              if (toastId)
                toast.success(SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_SUCCESS, {
                  id: toastId,
                });
            } else {
              if (toastId)
                toast.error(SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_FAILURE, {
                  id: toastId,
                });
            }
          })
          .catch((e) => {
            if (toastId)
              toast.error(SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_FAILURE, {
                id: toastId,
              });
          });
      }
      setIsShowingPlans(false);
    },
    [currentOrganization, defaultPaymentMethod, dispatch, stripe],
  );

  const onShowPlans = useCallback(() => {
    setIsShowingPlans(true);
  }, []);

  const options: TPopOverOptionProps[] = useMemo(
    () => [
      { onClick: showEditBillingDetails, type: 'edit', text: 'Edit', color: 'primary' },

      // User is not allowed to make their default payment default - this is redundant
      ...(defaultPaymentMethod?.id === activePaymentMethod?.id
        ? []
        : ([
            {
              onClick: onSetDefaultPaymentMethod,
              type: 'default',
              text: 'Make default',
              color: 'success',
            },
          ] as TPopOverOptionProps[])),

      // User is not allowed to delete their default payment method
      ...(defaultPaymentMethod?.id === activePaymentMethod?.id
        ? []
        : ([
            { onClick: onRemovePaymentMethod, type: 'delete', text: 'Remove', color: 'error' },
          ] as TPopOverOptionProps[])),
    ],
    [
      activePaymentMethod?.id,
      defaultPaymentMethod?.id,
      onRemovePaymentMethod,
      onSetDefaultPaymentMethod,
      showEditBillingDetails,
    ],
  );

  const daysTillCurrentPlanExpires = useMemo(
    () => dayjs(secondsToDateTime(current_period_end as number)).diff(new Date(), 'day'),
    [current_period_end],
  );

  const daysRemainingText = useMemo(
    () => `${daysTillCurrentPlanExpires} day${daysTillCurrentPlanExpires > 1 ? 's' : ''} 
                remaining`,
    [daysTillCurrentPlanExpires],
  );

  // Make default payment method first
  const sortedPaymentMethods = useMemo(
    () => [
      ...(defaultPaymentMethod ? [defaultPaymentMethod] : []),
      ...(paymentMethods?.data && defaultPaymentMethod
        ? paymentMethods.data.filter(
            (paymentMethod) => paymentMethod.id !== defaultPaymentMethod.id,
          )
        : []),
    ],
    [defaultPaymentMethod, paymentMethods?.data],
  );

  const updateOrgSubscription = useCallback(() => {
    // Only allow user to change their subscription if they have a default payment method
    if (defaultPaymentMethod?.id) {
      dispatch(
        updateSubscription({
          onSuccess: onClosePlans,
          org_id: currentOrganization?.id as number,
          subscription: generatePlan(activeCardForSend as RootObject),
          subscription_id: activeSubscription?.id as string,
        }),
      );
    } else {
      toast.error('You do not have a default payment method.');
    }
  }, [
    activeCardForSend,
    activeSubscription?.id,
    currentOrganization?.id,
    defaultPaymentMethod?.id,
    dispatch,
    onClosePlans,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAllowedToManageBillingSettings) {
      navigate(Routes.ProfileSettings);
    }
  }, [isAllowedToManageBillingSettings, navigate]);

  useEffect(() => {
    if (isAllowedToManageBillingSettings && currentOrganization?.id) {
      dispatch(getInvoiceHistory({ org_id: currentOrganization?.id, invoice_status: 'paid' }));
      dispatch(
        getUserSubscriptions({
          org_id: currentOrganization?.id,
          subscription_status: UserSubscriptionStatus.ACTIVE,
        }),
      );
    }
  }, [currentOrganization?.id, dispatch, isAllowedToManageBillingSettings]);

  useEffect(() => {
    // Get payment method that was used to subscribe to the current plan
    if (isAllowedToManageBillingSettings && currentOrganization?.id) {
      dispatch(getPaymentMethods(currentOrganization?.id));
      dispatch(getDefaultPaymentMethod(currentOrganization?.id));
    }
  }, [currentOrganization?.id, dispatch, isAllowedToManageBillingSettings]);

  return (
    <>
      <div className='p-5 md:p-10 shadow-sm flex flex-col'>
        <p className='p-3 text-[18px] md:text-[24px] font-semibold'>Billing</p>
      </div>
      {activeSubscription ? (
        <div className='m-5 md:m-12 p-4 bg-slate-100 flex flex-col rounded-md  dark:text-white dark:bg-dark-card-bg-old'>
          <div className='flex flex-row p-4 shadow-sm justify-between'>
            <div>
              <div className='flex flex-row gap-4'>
                <p className='font-bold capitalize dark:text-white '>
                  {metadata?.plan as string}&nbsp;
                  <span>
                    {`${
                      items?.data?.[0].plan?.interval ? items?.data?.[0].plan?.interval + 'ly' : ''
                    }${
                      (windowWidth as number) < MINIMUM_BREAK_POINT && current_period_end
                        ? ' (' + daysRemainingText + ')'
                        : ''
                    }`}
                  </span>
                </p>
                <button
                  className='bg-transparent underline text-accent dark:text-dark-text'
                  onClick={onShowPlans}
                  id='changePlanTour'
                >
                  Change Plan
                </button>
              </div>
              <p className='dark:text-white'>Includes:</p>
              {planBenefits(metadata?.plan as TPlan).map((benefit) => (
                <li key={benefit} className='text-sm text-gray-500 dark:text-white'>
                  {benefit}
                </li>
              ))}
            </div>
            {current_period_end &&
            daysTillCurrentPlanExpires > 0 &&
            (windowWidth as number) > MINIMUM_BREAK_POINT ? (
              <p className='font-bold dark:text-white'>{daysRemainingText}</p>
            ) : null}
            {daysTillCurrentPlanExpires < 0 ? (
              <p className='text-red-500 font-bold'>Plan expired</p>
            ) : null}
          </div>
          {!sortedPaymentMethods?.length ? null : (
            <>
              <div className='flex flex-row justify-between items-center my-4'>
                <p className='font-bold capitalize dark:text-white'>Payment Methods</p>
                {sortedPaymentMethods.length < MAX_PAYMENT_METHODS ? (
                  <PrimaryButton onClick={showAddPaymentMethodModal} variant='secondary'>
                    {(windowWidth as number) < MINIMUM_BREAK_POINT ? 'Add' : 'Add Payment Method'}
                  </PrimaryButton>
                ) : null}
              </div>
              {sortedPaymentMethods?.map((paymentMethod, index) => (
                <RenderPaymentMethod
                  key={paymentMethod?.id}
                  isDefault={paymentMethod?.id === defaultPaymentMethod?.id}
                  paymentMethod={paymentMethod}
                  onClickOptions={onClickOptions}
                  className={classNames('dark:text-white', {
                    'mb-0': index === sortedPaymentMethods?.length - 1,
                    'mb-2': index < sortedPaymentMethods?.length - 1,
                  })}
                />
              ))}
              <Popover
                open={isShowingOptions}
                onClose={onCloseOptions}
                anchorEl={popoverAnchorElement}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className='flex flex-col'>
                  {options.map((option) => (
                    <PopOverOption key={option.text} {...option} />
                  ))}
                </div>
              </Popover>
            </>
          )}
        </div>
      ) : null}
      {invoicesHistory?.length ? (
        <div className='m-5 p-4 flex flex-col border rounded-md'>
          <div className='flex flex-row justify-between'>
            <p className='p-3 text-[18px] md:text-[24px] font-semibold'>Billing History</p>
            {/* <DownloadButton type='primary' downloadUrl='' fileName='' /> */}
          </div>
          {invoicesHistory?.map((invoiceHistory) => {
            const billingItem = invoiceHistory.lines.data?.[0] ?? {};

            return (
              <RenderBillingHistoryItem
                key={invoiceHistory.id}
                invoiceFileName={billingItem.description + invoiceHistory.id}
                planName={billingItem.metadata?.plan}
                amountPaid={(billingItem.amount ?? 0) / 100}
                datePaid={secondsToDateTime(billingItem.period?.start)}
                currencySymbol={getSymbolFromCurrency(billingItem.currency?.toUpperCase()) ?? ''}
                cardType={invoiceHistory?.payment_method?.card_type}
                cardLast4Digits={invoiceHistory?.payment_method?.last4}
                linkToInvoicePdf={invoiceHistory?.invoice_pdf}
              />
            );
          })}
        </div>
      ) : null}
      <SidebarModal
        component={
          <EditBillingInfo paymentMethod={activePaymentMethod} onClose={hideEditBillingDetails} />
        }
        show={isShowingEditBillingDetails}
        setShow={setIsShowingEditBillingDetails}
      />
      <SidebarModal
        component={<AddPaymentMethod onClose={hideAddPaymentMethodModal} />}
        show={isShowingAddPaymentMethodModal}
        setShow={setIsShowingAddPaymentMethodModal}
      />
      <CustomModal
        isOpen={isShowingPlans}
        onClose={onClosePlans}
        className='text-center dark:bg-darkBg min-w-fit'
      >
        <p className='font-bold text-3xl mb-6'>Change Plan</p>
        <SubscriptionPlanBoxes
          withTitle={false}
          activeCardForSend={activeCardForSend}
          setActiveCardForSend={setActiveCardForSend}
        />
        <div className='flex flex-row justify-between mt-8'>
          <button
            className='bg-transparent text-accent dark:text-white'
            onClick={() => onClosePlans()}
          >
            Cancel
          </button>
          <button
            className='bg-accent dark:bg-btn-dark text-white p-2 rounded'
            onClick={updateOrgSubscription}
          >
            Change Plan
          </button>
        </div>
      </CustomModal>
      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        title='Remove payment method'
        description='Do you want to remove this payment method?'
        onDelete={handleRemovingPaymentMethod}
      />
    </>
  );
};

export default BillingTab;
