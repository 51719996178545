import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingBar from 'react-top-loading-bar';

import PrimaryButton from 'components/buttons/PrimaryButton';
import { useAppSelector } from 'hooks';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { ControlledInput, ControlledSelect, NumberControlledInput } from 'components';
import { profileSettingsSchema } from 'constants/Schemas';
import { TMemberInfo, TUpdateUser } from 'store/slices/authSlice/types';
import { stateOptions } from 'constants/States';
import { Colors, TChangeInputEvent } from 'types';
import { useAppDispatch } from 'app/hooks';
import { updateDesignation, updateProfileImage, updateUser } from 'store/thunks';
import useTopLoader from 'hooks/useTopLoader';
import { loaderOptions } from 'constants/sizeOptions';
import { handleImageUpload } from 'helpers/utils';
import UserAvatar from 'components/shared/UserAvatar';

type TProfileForm = TUpdateUser & { designation?: string };

const UserInfo = ({
  title,
  text,
  capitalize = true,
}: {
  title: string;
  text?: string;
  capitalize?: boolean;
}) => {
  return (
    <div className='flex flex-col gap-2'>
      <p className=' capitalize text-gray-700 dark:text-gray-300 font-semibold'>{title}</p>
      {text && (
        <p className={`text-gray-400 ${capitalize ? 'capitalize ' : ''} break-words`}>{text}</p>
      )}
    </div>
  );
};

const ProfileTab = () => {
  const userData = useAppSelector(userDataSelector);
  const { member_info, general_user_info } = userData ?? {};
  const [editingEnabled, setEditingEnabled] = useState<boolean>(false);
  const profileImageUploadInput = useRef<HTMLInputElement>(null);
  const { progress, loaderHandler, resetLoader } = useTopLoader();

  const dispatch = useAppDispatch();

  const {
    control: profileSettingsControl,
    handleSubmit: profileSettingsSubmit,
    formState: { errors },
  } = useForm<TProfileForm>({
    resolver: yupResolver(profileSettingsSchema),
  });

  const onClickEdit = useCallback(() => {
    if (!editingEnabled) setEditingEnabled((prevState) => !prevState);
  }, [editingEnabled]);

  async function onSubmitUserProfile({ designation, ...otherProfileData }: TProfileForm) {
    // User filled designation, and user's designation changed
    if (designation && general_user_info?.id && designation !== member_info?.designation) {
      loaderHandler(dispatch(updateDesignation({ user_id: general_user_info.id, designation })));
    }

    // Items from user's form that that not in or same as in user's profile
    const profileToBeUpdated: Record<string, string | boolean> = {};

    // Get new items to be updated in user's profile
    for (const [key, value] of Object.entries(otherProfileData)) {
      if (value && value !== member_info?.[key as keyof TMemberInfo])
        profileToBeUpdated[key] = value;
    }

    // If user updated the edit profile form
    if (Object.keys(profileToBeUpdated).length)
      loaderHandler(dispatch(updateUser(profileToBeUpdated as TUpdateUser)));
    setEditingEnabled((prevState) => !prevState);
  }

  const onProfileImageUpload = (event: TChangeInputEvent) => {
    handleImageUpload(event, (file) => {
      const formData = new FormData();
      formData.append('profile_image', file);
      loaderHandler(dispatch(updateProfileImage(formData)));
    });
  };

  function onClickProfileImage() {
    profileImageUploadInput?.current?.click();
  }

  return (
    <>
      {progress > 0 && (
        <LoadingBar
          color={loaderOptions.color}
          progress={progress}
          height={loaderOptions.height}
          loaderSpeed={1600}
          onLoaderFinished={resetLoader}
        />
      )}
      <div className='p-5 md:p-10 shadow-sm flex flex-row justify-between'>
        <p className='p-3 text-[18px] md:text-[24px] font-semibold'>My Profile</p>
        {editingEnabled ? (
          <button
            form='update-profile-form'
            type='submit'
            className='h-[55px] w-[150px] py-3 px-7 bg-accent rounded-[4px] text-white text-sm md:text-base cursor-pointer'
          >
            Save Details
          </button>
        ) : (
          <PrimaryButton type='button' variant='neutral' onClick={onClickEdit} id='EditProfile'>
            Edit
          </PrimaryButton>
        )}
      </div>
      <div className='py-10 px-4 sm:px-10' id='profileArea'>
        <form
          id='update-profile-form'
          autoComplete='off'
          onSubmit={profileSettingsSubmit(onSubmitUserProfile)}
        >
          <div className='grid grid-cols-6'>
            <div className='col-span-1 flex justify-center items-start'>
              <span id='editProfileImageUpload' className='bg-white rounded-full'>
                <UserAvatar
                  fullName={member_info?.name}
                  mediaId={member_info?.profile_image_url_id}
                  mediaFolder='profile-info'
                  onClick={onClickProfileImage}
                  className='h-[40px] max-w-48 w-[40px] rounded-full cursor-pointer'
                />
              </span>
              <input
                ref={profileImageUploadInput}
                hidden
                accept='image/*'
                type='file'
                onChange={onProfileImageUpload}
              />
            </div>

            <div className='col-span-4'>
              <p className='text-[18px] font-semibold'>Personal Info</p>
              <div className='grid grid-cols-2  gap-7 my-10'>
                {editingEnabled ? (
                  <ControlledInput
                    required
                    defaultValue={member_info?.name}
                    control={profileSettingsControl}
                    name='name'
                    label='Full Name'
                    error={!!errors.name}
                    placeholder='Full Name'
                    helperText={(errors?.name?.message as string) ?? ''}
                  />
                ) : (
                  <UserInfo title='Full name' text={member_info?.name} />
                )}
                {editingEnabled ? (
                  <ControlledInput
                    defaultValue={member_info?.designation ?? ''}
                    control={profileSettingsControl}
                    name='designation'
                    label='Position'
                    error={!!errors.designation}
                    placeholder='Position'
                    helperText={errors.designation ? errors.designation.message : ''}
                  />
                ) : (
                  <UserInfo title='Position' text={member_info?.designation} />
                )}
              </div>
              <div className='grid grid-cols-2 gap-7 my-10'>
                <UserInfo title='Email' text={member_info?.email} capitalize={false} />
                {editingEnabled ? (
                  <NumberControlledInput
                    defaultValue={member_info?.mobile_number ?? ''}
                    control={profileSettingsControl}
                    name='mobile_number'
                    label='Phone Number'
                    error={!!errors.mobile_number}
                    placeholder='Phone Number'
                    helperText={errors.mobile_number ? errors.mobile_number.message : ''}
                  />
                ) : (
                  <UserInfo title='Phone' text={member_info?.mobile_number} />
                )}
              </div>
            </div>
          </div>
          <div className='bg-[#F2F2F2] h-[2px] my-10' />
          <div className='grid grid-cols-6'>
            <div className='col-span-1'></div>
            <div className='col-span-4'>
              <p className='text-[18px] font-semibold'>Address</p>
              <div className='grid grid-cols-1 my-10'>
                {editingEnabled ? (
                  <ControlledInput
                    defaultValue={member_info?.address ?? ''}
                    control={profileSettingsControl}
                    name='address'
                    label='Street'
                    error={!!errors.address}
                    placeholder='Street'
                    helperText={(errors?.address?.message as string) ?? ''}
                  />
                ) : (
                  <UserInfo title='Street' text={member_info?.address} />
                )}
              </div>
              <div className='grid grid-cols-2 gap-7 my-10'>
                {editingEnabled ? (
                  <ControlledInput
                    defaultValue={member_info?.city ?? ''}
                    control={profileSettingsControl}
                    name='city'
                    label='City'
                    error={!!errors.city}
                    placeholder='City'
                    helperText={(errors?.city?.message as string) ?? ''}
                  />
                ) : (
                  <UserInfo title='City' text={member_info?.city} />
                )}
                {editingEnabled ? (
                  <ControlledSelect
                    defaultValue={member_info?.state ?? ''}
                    name='state'
                    control={profileSettingsControl}
                    options={stateOptions}
                    label='State'
                    border={`1px solid ${Colors.SOFT_SILVER}`}
                  />
                ) : (
                  <UserInfo title='State' text={member_info?.state} />
                )}
              </div>
              <div className='grid grid-cols-2 gap-7'>
                {editingEnabled ? (
                  <ControlledInput
                    defaultValue={member_info?.zip_code ?? ''}
                    control={profileSettingsControl}
                    name='zip_code'
                    label='Zip Code'
                    error={!!errors.zip_code}
                    placeholder='Zip Code'
                    helperText={(errors?.zip_code?.message as string) ?? ''}
                  />
                ) : (
                  <UserInfo title='Zip code' text={member_info?.zip_code} />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProfileTab;
