import React, { useCallback, useMemo } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import PrimaryButton from 'components/buttons/PrimaryButton';
import { useAppDispatch } from 'app/hooks';
import { updateBillingDetails } from 'store/slices/subscriptionSlice/thunks';
import { updateBillingDetailsSchema } from 'constants/Schemas';
import { useUserOrganizationsList } from 'hooks';
import NumberControlledInput from 'components/shared/NumberControlledInput';
import { TPaymentMethod } from 'types/global/paymentMethods';
import ControlledInput from 'components/shared/ControlledInput';
import ControlledSelect from 'components/shared/ControlledSelect';
import { stateOptions } from 'constants/States';
import { Colors } from 'types';

interface EditBillingInfoProps {
  onClose: () => void;
  paymentMethod: TPaymentMethod | null;
}

export type TEditBillingInfoForm = {
  name: string;
  phone: string;
  address: string;
  state: string;
  city: string;
  zip: string;
};

const EditBillingInfo: React.FC<EditBillingInfoProps> = ({ paymentMethod, onClose }) => {
  const dispatch = useAppDispatch();
  const { currentOrganization } = useUserOrganizationsList();
  const {
    name: currentName,
    phone: currentPhone,
    address: currentAddress,
  } = paymentMethod?.billing_details ?? {};

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<TEditBillingInfoForm>({
    resolver: yupResolver(updateBillingDetailsSchema),
  });

  const isSubmitButtonDisabled = useMemo(() => !isDirty || isSubmitting, [isDirty, isSubmitting]);

  const updateBillingInfo = useCallback(
    async (formData: TEditBillingInfoForm) => {
      const { name, phone, address, state, city, zip } = formData;

      await dispatch(
        updateBillingDetails({
          onSuccess: onClose,
          org_id: currentOrganization?.id as number,
          payment_method_id: paymentMethod?.id as string,
          name,
          phone,
          address: { line1: address, city, state, country: 'US', postal_code: zip },
        }),
      );
    },
    [currentOrganization?.id, dispatch, onClose, paymentMethod],
  );

  return (
    <div className='flex flex-col gap-4 h-full'>
      <div>
        <div className='flex justify-between'>
          <p className='font-semibold text-[24px]'>Update Billing Information</p>
          <div className='flex items-center pr-4 gap-6'>
            <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
          </div>
        </div>
      </div>
      <div>
        <p className='text-semibold mt-7 mb-4'>Personal Details</p>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
          <ControlledInput
            defaultValue={currentName ?? ''}
            control={control}
            name='name'
            label='Name:*'
            error={!!errors.name}
            placeholder='Full name'
            helperText={errors.name?.message ?? ''}
          />
          <NumberControlledInput
            defaultValue={currentPhone ?? ''}
            control={control}
            name='phone'
            label='Phone Number:*'
            error={!!errors.phone}
            placeholder='Phone Number'
            helperText={errors.phone?.message ?? ''}
          />
        </div>
      </div>
      <div>
        <p className='text-semibold my-4'>Address</p>
        <ControlledInput
          defaultValue={currentAddress?.line1 ?? ''}
          control={control}
          name='address'
          label='Street:*'
          error={!!errors.address}
          placeholder='Address'
          helperText={errors.address?.message ?? ''}
        />
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-4'>
          <ControlledInput
            defaultValue={currentAddress?.city ?? ''}
            control={control}
            name='city'
            label='City:*'
            error={!!errors.city}
            placeholder='City'
            helperText={errors.city?.message ?? ''}
          />
          <ControlledSelect
            defaultValue={currentAddress?.state ?? ''}
            name='state'
            control={control}
            options={stateOptions}
            label='State:*'
            border={`1px solid ${Colors.SOFT_SILVER}`}
          />
          <ControlledInput
            defaultValue={currentAddress?.postal_code ?? ''}
            control={control}
            name='zip'
            label='Zip Code:*'
            error={!!errors.zip}
            placeholder='Zip Code'
            helperText={errors.zip?.message ?? ''}
          />
        </div>
      </div>
      <PrimaryButton
        disabled={isSubmitButtonDisabled}
        className='w-full md:w-fit self-end mt-5'
        onClick={handleSubmit(updateBillingInfo)}
        variant='neutral'
      >
        Update Billing Information
      </PrimaryButton>
    </div>
  );
};

export default EditBillingInfo;
