import { AxiosError } from 'axios';

export enum TokenProvider {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  APPLE = 'apple',
}

export type TOAuthParams = {
  oauth_token: string;
  oauth_provider: TokenProvider;
} & Partial<{
  invitation_token: string;
}>;

export type TUpdateUser = { name: string } & Partial<{
  email: string;
  state: string;
  city: string;
  address: string;
  country: string;
  zip_code: string;
  mobile_number: string;
  office_number: string;
  is_active: boolean;
  email_notification: boolean;
  sms_notification: boolean;
  in_app_notification: boolean;
}>;

export type TUpdateUserTour = { name: string } & Partial<{
  tour: TUpdateTour;
}>;

export type TUpdateTour = Partial<{
  enabled: boolean;
  pages: TUpdateTourPages;
}>;

export type TUpdateTourPages = Partial<{
  overview: boolean;
  events: boolean;
  devices: boolean;
  team: boolean;
  activity_feed: boolean;
}>;

export type TUpdateDesignation = {
  user_id: number;
  designation: string;
};

export type TNewUser = {
  email: string;
};

export type TUser = {
  new_user: TNewUser;
};

export type TPermission = {
  org_id: number;
  role_id: number;
  user_id: number;
  team_id: number | null;
};

export type TInitialState = {
  users: TUser[];
  loading: boolean;
  searchName: string;
  roles: TNewRole[];
  foundUsers: TUser[];
  userRolesLoading: boolean;
  userDataLoading: boolean;
  userAllRoles: TUserDefinedRole[];
  allRolesLoading: boolean;
  error: null | Error | string;
  usersByRoles: TUserByRole[];
  currentUserLoading: boolean;
  userData: TUserProfile | null;
  userDataError: null | Error | string;
  currentUserById: TUserProfile | null;
  currentUserError: string | Error | null;
  permissions: TPermission[] | null;
  currentSingleUserById: TUserProfile | null;
  refreshTOkenError: null | Error | string | AxiosError;
  notificationSetting: TUserNotificationSettings | null;
};

export type TPagination = Partial<{
  limit: number;
  offset: number;
}>;

export type TRole = {
  id: number;
  role: string;
  created_at: string;
  updated_at: string;
};

export type TNewRole = {
  role: string;
  is_public: boolean;
};

export type TNewUserRole = {
  role_id: number;
  user_id: number;
  org_id: number;
};

export type TUserDefinedRole = {
  id: number;
  role: string;
  role_id: number;
  user_id: number;
} & Partial<{
  org_id: number;
  team_id: number;
}>;

export type TRemoveMember = {
  user_id: number;
  org_id: number;
  team_id: number;
};

export type TUpdateUserSettings = {
  id: number;
  email_notification: boolean;
  sms_notification: boolean;
  // in_app_notification: boolean;
};

export type TSearch = {
  query: string;
  limit: number;
  offset: number;
} & Partial<{
  distance: number;
}>;

export type TUserInfo = {
  email: string;
  name: string;
  created_at: string;
  updated_at: string;
  oauth_provider: string;
  id: number;
  default_org_id: number;
};

export type TMemberInfo = Partial<{
  zip_code: string;
  address: string;
  city: string;
  designation: string;
  state: string;
  country: string;
  default_org_id: number;
  mobile_number: string;
  name: string;
  profile_image_url_id: string;
  updated_at: string;
}> & {
  id: number;
  email: string;
  created_at: string;
  designation: string;
  in_app_notification: boolean;
  email_notification: boolean;
  sms_notification: boolean;
  is_active: boolean;
  is_first_time_user: boolean;
  oauth_provider: 'google' | 'apple';
  tour: TUpdateTour;
};

export type TUserProfile = {
  status: number;
  is_first_time_user: boolean;
  general_user_info: TUserInfo;
  member_info: TMemberInfo;
  user_roles: TUserRole[];
  token: TToken;
  designation: string;
  email: string;
  is_invited_user: boolean;
};

export type TUserDataByIdResponse = {
  email: string;
  name: string;
  is_active: boolean;
  is_superuser: boolean;
  created_at: string | Date;
  updated_at: string | Date;
  id: number;
  oauth_provider: string;
  mobile_number: number | null;
  office_number: number | null;
  designation: string;
  profile_image_url_id: string;
  address: string | null;
  state: string | null;
  city: string | null;
  country: string | null;
  zip_code: string;
  is_invited_user: boolean;
  is_first_time_user: boolean;
};

export type TToken = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  access_token_expiry: string;
  refresh_token_expiry: string;
};

export type TUserRole = {
  role_id: number;
  role: string;
  id: number;
};

export type TUserNotificationSettings = {
  id: number;
  email_notification: boolean;
  sms_notification: boolean;
  in_app_notification: boolean;
};

export type TUserByRole = {
  id: number;
  name: string;
  email: string;
  org_id: number;
  user_id: number;
  role_id: number;
  team_id: null | number;
  profile_image_url_id: string;
  mobile_number: null | number;
  office_number: null | number;
};

export type TGetRolesParams = {
  limit: number;
  offset: number;
  type: 'organization' | 'Internal' | 'Pre-defined';
};

export type TGetRolesResponse = TRole[];
