import { useState } from 'react';

const useTopLoader = () => {
  const [progress, setProgress] = useState(0)
  const resetLoader = () => setProgress(0)
  const loaderHandler = (func: any) => {
    setProgress(progress + 10)
    setProgress(progress + 20)
  
    try {
        setProgress(100)
        func();
      } catch (err) {
        setProgress(100)
      }
  };
  return { loaderHandler, progress, resetLoader};
};
export default useTopLoader;