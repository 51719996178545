import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import LinkedCameraOutlinedIcon from '@mui/icons-material/LinkedCameraOutlined';
import {
  IconButton,
  Stack,
  SvgIconProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import { FC } from 'react';
import { TDevice } from 'store/slices/devicesSlice/type';
import { EmptyStateDevices, EmptyStateDevicesDark } from 'assets/icons';
import { useDarkMode } from 'hooks';

export const DevicesTable: FC<{
  devices: TDevice[];
  showAll?: boolean;
  setShowEditDevice?:any | null;
  getEditDeviceInfo?: any;
}> = ({ devices, showAll = false, setShowEditDevice, getEditDeviceInfo }) => {
  const tableHeaders = !showAll
    ? ['Device Type', 'Device Name', 'Status', 'Placement', 'Actions']
    : ['Device Type', 'Device Name', 'Status', 'Time Stamp', 'Placement', 'Actions'];
   const onEdit= (device: any) => {
    if(showAll){
      getEditDeviceInfo(device)
      setShowEditDevice(true)
    }
   
  }
  const { darkMode } = useDarkMode();
  return (
    <>
    {devices?.length  > 0  && <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((text, index) => (
              <TableCell
                key={index}
                variant='head'
                sx={{
                  textAlign: index == tableHeaders.length - 1 ? 'center' : '',
                }}
              >
                {text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {devices?.map((device, index) => (
            <TableRow key={index}>
              <TableCell>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <LinkedCameraOutlinedIcon/> {device.device_model}
                </Stack>
              </TableCell>
              <TableCell>{device.device_name}</TableCell>
            
              <TableCell>
                <Typography
                  className={cn('capitalize w-fit py-1 px-3 rounded-[6px] text-[13px]', {
                    'text-green-700 bg-green-100 ': device.status == 'online',
                    'text-red-700 bg-red-100 ': device.status == 'offline',
                  })}
                >
                  {device.status}
                </Typography>
              </TableCell>
              {showAll ? <TableCell>{device.device_name}</TableCell> : null}
              <TableCell>{device.placement}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                {showAll && <IconButton onClick={() =>onEdit(device)}>
                  <BorderColorOutlinedIcon className='text-[#464646]' />
                </IconButton>}
                <IconButton>
                  <MoreHorizOutlinedIcon className='text-[#464646]' />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    {!devices?.length && <div className='mt-5 pt-5 flex justify-center'>
        <div className='mt-5 pt-5 flex flex-col justify-center'>
          {darkMode ? <EmptyStateDevicesDark  style={{height: '12em', width: '17em'}} /> : <EmptyStateDevices  style={{height: '12em', width: '17em'}} />}
          <p className="font-semibold pt-1">No devices available. Please add a device.</p>
        </div>
      </div>
    }
    </>
  );
};
