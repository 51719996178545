import type { TOrganizationCreate } from 'store/slices/organizationsSlice/types';
import type { TOrganizationData } from './types';

export const createOrganizationData = (data: TOrganizationData, companyLogo?: File | null) => {
  const newOrganization: TOrganizationCreate = {
    name: String(data.organization),
    size: data?.size,
    is_active: true,
    state: data?.state,
    city: data?.city || '',
    zip: data?.zip || '',
    email: data?.email || '',
    address: data?.address || '',
    phone: data?.phoneNumber,
    ownerLastName:
      data?.ownerFirstName?.length > 1 ? data?.ownerFirstName[data.ownerFirstName.length - 1] : '',
    ownerFirstName: data?.ownerFirstName,
    business_type: String(data.typeOfBusiness).toLowerCase(),
    industry_vertical: String(data.industryVertical),
  };

  const formData = new FormData();

  formData.append('zip', newOrganization.zip);
  formData.append('name', newOrganization.name);
  formData.append('size', newOrganization.size);
  formData.append('city', newOrganization.city);
  formData.append('email', newOrganization.email);
  formData.append('state', newOrganization.state);
  formData.append('address', newOrganization.address);
  formData.append('phone', newOrganization.phone || '');
  formData.append('last_name', newOrganization.ownerLastName);
  formData.append('first_name', newOrganization.ownerFirstName);
  formData.append('business_type', newOrganization.business_type);
  formData.append('is_active', newOrganization.is_active.toString());
  formData.append('industry_vertical', newOrganization.industry_vertical);

  if (companyLogo) {
    formData.append('media', companyLogo);
  }

  return formData;
};
