import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAnalyticsDateParams, TAnalyticsDateResponse, TInitialState } from './type';
import { getAnalyticsByDateTime } from './thunks';

const initialState: TInitialState = {
  currentHeadCount: null,
  start_datetime: null,
  end_datetime: null
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateStartDate(state, action: PayloadAction<Date | null>) {
      state.start_datetime = action.payload;
    },
    updateEndDate(state, action: PayloadAction<Date | null>) {
      state.end_datetime = action.payload;
    },
  },
  // extraReducers: (builder: any) => {
  //   builder.addCase(
  //     getAnalyticsByDateTime.fulfilled,
  //     (state: any, action: PayloadAction<TAnalyticsDateResponse & TAnalyticsDateParams>) => {}
  //   );
  // }
});

export const { updateStartDate, updateEndDate } = dashboardSlice.actions;
export default dashboardSlice.reducer;
