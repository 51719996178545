import React, { useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';
import cn from 'classnames';
import { moonIcon, sunIcon } from '../../assets/images/index';
import { useDarkMode } from 'hooks/useDarkMode';
import { useAppDispatch } from 'app/hooks';
import { toggleDarkMode } from 'store/slices/themeSlice';

interface ToggleButtonProps {
  className?: string;
  icon?: React.ReactNode;
}
const ToggleButton: React.FC<ToggleButtonProps> = ({ className, icon }) => {
  const { darkMode } = useDarkMode();
  const dispatch = useAppDispatch()
  
  return (
    <Switch
      checked={darkMode}
      onChange={() => dispatch(toggleDarkMode())}
      className={cn(
        darkMode ? 'bg-[#0A85ED]' : 'bg-[#B9DCFE]',
        `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out ${className}`,
      )}
    >
      <span
        aria-hidden='true'
        className={cn(
          darkMode ? 'translate-x-5' : 'translate-x-0 ',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-[#15145F] shadow transition duration-200 ease-in-out',
          {
            'flex items-center justify-center': icon,
          },
        )}
      >
        {darkMode ? (
          <img src='images/darkMoon.svg' alt='' />
        ) : (
          <img src='images/lightSun.svg' alt='' />
        )}
      </span>
    </Switch>
  );
};

ToggleButton.defaultProps = {
  className: '',
  icon: '',
};

export default ToggleButton;
