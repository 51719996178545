import React, { useMemo } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import PrimaryButton from 'components/buttons/PrimaryButton';
import { TCreateTeamForm } from 'store/slices/teamsSlice/type';
import { createTeamMemberSchema } from 'constants/Schemas';
import ControlledInput from 'components/shared/ControlledInput';

interface AddTeamateProps {
  onClose: () => void;
  teamId: number;
  teamName: string;
  editTeamMemberbyID: any;
  editTeamMemberData: any;
}

const EditTeamMember: React.FC<AddTeamateProps> = ({
  onClose,
  editTeamMemberData,
  editTeamMemberbyID,
}) => {
  const {
    control: editTeamMemberControl,
    handleSubmit: editTeamMemberSubmit,
    formState: { errors },
  } = useForm<TCreateTeamForm>({
    resolver: yupResolver(createTeamMemberSchema),
    defaultValues: useMemo(() => editTeamMemberData, [editTeamMemberData]),
  });

  const editNewTeamMember = async (values: TCreateTeamForm) => {
    const params: any = {
      id: editTeamMemberData?.id,
      position: values?.position || editTeamMemberData?.position,
      contact_info: values?.contactInfo || editTeamMemberData?.contact_info,
      avaliability: values?.avaliability || editTeamMemberData?.availability,
      name: values?.name || editTeamMemberData?.name,
      team_id: editTeamMemberData.team_id,
    };
    try {
      editTeamMemberbyID(params);
      onClose();
    } catch (err: any) {
      //
    }
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        <div className='flex justify-between'>
          <p className='font-semibold text-[24px]'>Update Team Member</p>
          <div className='flex items-center pr-4 gap-6'>
            <IconButton>
              <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
            </IconButton>
          </div>
        </div>

        <div className='mt-7'>
          <div className='flex flex-col gap-8'>
            <ControlledInput
              required
              control={editTeamMemberControl}
              name='name'
              label='Full Name'
              error={!!errors.name}
              placeholder=' Name*'
              helperText={(errors?.name?.message as string) ?? ''}
              defaultValue={editTeamMemberData?.name}
              disabled
            />
            <ControlledInput
              required
              control={editTeamMemberControl}
              name='position'
              label='Position'
              error={!!errors.position}
              placeholder='Position*'
              helperText={(errors?.position?.message as string) ?? ''}
              defaultValue={editTeamMemberData?.position}
            />
            <ControlledInput
              required
              control={editTeamMemberControl}
              name='avaliability'
              label='Avaliability'
              error={!!errors.avaliability}
              placeholder='Avaliability*'
              helperText={(errors?.avaliability?.message as string) ?? ''}
              defaultValue={editTeamMemberData?.avaliability}
            />
            <ControlledInput
              required
              control={editTeamMemberControl}
              name='contactInfo'
              label='Contact Info'
              error={!!errors.contactInfo}
              placeholder='Contact Info*'
              helperText={(errors?.contactInfo?.message as string) ?? ''}
              defaultValue={editTeamMemberData?.contact_info}
            />
          </div>
        </div>
      </div>
      <PrimaryButton
        className='w-full md:w-fit self-end'
        onClick={editTeamMemberSubmit(editNewTeamMember)}
      >
        Update
      </PrimaryButton>
    </div>
  );
};

export default EditTeamMember;
