import { VoidCallback } from 'types';

import CustomModal from '.';

export type TConfirmDeleteProps = {
  isOpen: boolean;
  onClose: VoidCallback;
  title: string;
  description: string;
  onDelete?: VoidCallback;
  deleteText?: string;
};

const ConfirmDeleteModal = ({
  isOpen = false,
  onClose,
  title,
  description,
  deleteText = 'Remove',
  onDelete,
}: TConfirmDeleteProps) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className='max-w-max max-h-max dark:bg-darkBg'>
      <div>
        <div className='mb-10 text-center'>
          <p className='text-[20px] pt-2 font-semibold mb-5'>{title}</p>
          <p>{description}</p>
        </div>
        <div className='flex justify-center'>
          <button
            className='py-3 px-7 bg-[#DFEDFF] dark:bg-btn-dark dark:text-white rounded-[4px] text-darkBg text-sm md:text-base cursor-pointer mr-3'
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='py-3 px-7 bg-[#ff0000] rounded-[4px] text-white text-sm md:text-base cursor-pointer'
            onClick={onDelete}
          >
            {deleteText}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmDeleteModal;
