export enum BrowserStorageKeys {
  Mode = 'mode',
  Role = 'role',
  Scroll = 'scroll',
  Language = 'i18nextLng',
  GoogleToken = 'googleToken',
  AccessToken = 'accessToken',
  TokenType = 'tokenType',
  CurrentAmount = 'currentAmount',
  RefreshToken = 'refreshToken',
  IsFirstTimeUser = 'isFirstTimeUser',
  IsInvitedUser = 'isInvitedUser',
  CurrentUserEmail = 'CurrentUserEmail',
  ProcessFormValue = 'processFormValue',
  InvitationToken = 'invitationToken',
  InvitationStatus = 'invitationStatus',
  InvitationEmail = 'invitationEmail',
  ActiveSettingsTitle = 'activeSettingsTitle',
  currentLiveStreamId = 'currentLiveStreamId',
  CurrentOrganizationId = 'currentOrganizationId',
  subscriptionClientSecret = 'subscriptionClientSecret',
  sortingSource = 'sortingSource',
  sortingTeamIds = 'sortingTeamIds',
  SubscriptionId = 'subscriptionId',
  HaveCreatedOrganization = 'haveCreatedOrganization',
  InProcessForm = 'inProcessForm',
  CurrentIndustry = 'CurrentIndustry',
}

export type BrowserStorageOptions = {
  session: boolean;
};
