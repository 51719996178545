export const sizeOptions = [
  { id: 1, label: '1-10', value: '1-10' },
  { id: 2, label: '11-50', value: '11-50' },
  { id: 3, label: '51-100', value: '51-100' },
];

export const loaderOptions = {
  height: 4,
  color: '#1e64aa',
  error: 'red'
};
