import React from 'react';

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <section className='flex h-[100svh] justify-center py-20'>
      Oops... You see to be lost ☹️
    </section>
  );
};

export default NotFound;
