export function LoadingDots({ className = '' }) {
  return (
    <svg height='40' width='40' className={`bg-[#f1f1f1] text-[grey] ${className}`}>
      <circle className='fill-[grey] animate-blink' cx='10' cy='20' r='3' />
      <circle
        className='fill-[grey] animate-blink'
        cx='20'
        cy='20'
        r='3'
        style={{ animationDelay: '250ms' }}
      />
      <circle
        className='fill-[grey] animate-blink'
        cx='30'
        cy='20'
        r='3'
        style={{ animationDelay: '500ms' }}
      />
    </svg>
  );
}
