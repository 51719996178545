import { DetailsList } from 'types/components.types';

import IndustryReasonComponent from './IndustryReasonComponent';
import DetailsListComponent from './DetailsListComponent';
import IndustriesHeroSection from './IndustriesHeroSection';

const detailsData: DetailsList[] = [
  {
    title: 'Attendance Records',
    image: '/images/industriesPage/ministry1.svg',
    subtitle:
      'AI can accurately track the number of attendees during services, events, or gatherings. This will help ministries and churches understand attendance trends, make informed decisions about scheduling, and plan for resources accordingly to the data received.',
  },
  {
    title: 'Social Interaction',
    image: '/images/industriesPage/ministry2.svg',
    subtitle:
      'Improve and promote social interaction within the congregation. Be able to detect seating arrangements and trends. Distinguish new guests and encourage interaction with established congregation members.',
  },
  {
    title: 'Efficiency Improvement',
    image: '/images/industriesPage/ministry3.svg',
    subtitle:
      'Automated people counting eliminates the need for manual head-counts, freeing up Church staffs and volunteers to focus on other important tasks. This leads to smoother event management and a more efficient use of human resources.',
  },
  {
    title: 'Strategic Decisions',
    image: '/images/industriesPage/ministry4.svg',
    subtitle:
      'Our AI can provide insights into which services or events attract the most attendees. This information can guide strategic decisions about scheduling, content, and outreach efforts to maximize engagement.',
  },
];

const MinistrySection = (): JSX.Element => {
  return (
    <section>
      <IndustriesHeroSection
        imageURL={'/images/industriesPage/ministry-hero.svg'}
        title='Congregations Attendance Counting AI'
      />

      <IndustryReasonComponent
        data={{
          title: 'How can HawkEyeVisionTek help you and your Congregation?',
          reasons: [
            'This technology is extremely useful in the field of Ministry. Track attendance in real time and keep those records for future reference. View seating habits and trends to increase efficiency of service. Improve the social interactions and the overall engagement of the entire congregation.',
            'HawkEyeVisionTek will ensure your fellowship is provided with the best experience possible. The bigger your house of worship, the better this technology will serve you.',
          ],
        }}
      />
      <DetailsListComponent data={detailsData} />
    </section>
  );
};

export default MinistrySection;
