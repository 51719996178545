import React from 'react';

export type TSettingsProps = {
  children: React.ReactNode;
};

export enum EEventType {
  UPCOMING = 'Upcoming Events',
  PAST = 'Past Events',
}
