import { useState } from 'react';

import { subscriptionsApi } from 'api';
import { createOrganizationData } from 'containers/ProcessForm/ProcessForm.utils';
import { useAppDispatch } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService, LocalForageService } from 'services';
import { createNewOrganization, createNewSubscription as createSubscription } from 'store/thunks';
import { LocalForageKeys } from 'services/localForage/types';

export const useOrganizeProcess = () => {
  const [stripeSecret, setStripeSecret] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const organizeAction: any = async () => {
    const organizationData = await LocalForageService.get(LocalForageKeys.ProcessFormFirstStepData);

    const subscriptionData = await LocalForageService.get(
      LocalForageKeys.ProcessFormSecondStepData,
    );

    const newCreatedData = createOrganizationData(
      organizationData.data,
      organizationData.companyLogo,
    );

    const response = await dispatch(createNewOrganization(newCreatedData)).unwrap();

    console.log(subscriptionData, response);

    if (response?.id) {
      const subscriptionResponse = await dispatch(
        createSubscription({
          org_id: response?.id,
          subscription: subscriptionData.subscription,
          subscription_type:
            subscriptionData.subscription_type === 'device'
              ? subscriptionData.subscription_type + 's'
              : subscriptionData.subscription_type,
        }),
      ).unwrap();

      if (subscriptionResponse) {
        BrowserStorageService.set(
          BrowserStorageKeys.subscriptionClientSecret,
          subscriptionResponse.client_secret,
        );

        BrowserStorageService.set(
          BrowserStorageKeys.SubscriptionId,
          subscriptionResponse.subscription_id,
          { session: true }
        );
      }

      const final = await subscriptionsApi
        .setupPaymentIntentRequest(response?.id)
        .then((newResponse) => {
          setStripeSecret(newResponse?.data?.client_secret);
          return {
            secret: newResponse?.data?.client_secret,
            orgId: response?.id,
            subscriptionSecret: subscriptionResponse.client_secret,
          };
        });

      return final;
    }
  };

  return { organizeAction, stripeSecret };
};

export default useOrganizeProcess;
