import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { ActivityFeedCard } from 'components/Cards/ActivityFeedCard';
import { useAppSelector } from 'app/hooks';
import { postsSelector } from 'store/slices/activitiesSlices/selector';
import { getRelativeTime } from 'helpers/utils';
import { ActivityFeedEmptyState, ActivityFeedEmptyStateDark } from 'assets/icons';
import { TPostComment, TPostMetadata } from 'store/slices/activitiesSlices/types';
import { useAppDispatch, useDarkMode } from 'hooks';
import { getAllActivityComments } from 'store/thunks';

import { BOT_NAME } from '../../../constants';

type TPostProps = Partial<{
  name: string;
  profileMediaId: string;
  mediaIds: string[];
}> & {
  id: number;
  text: string;
  time: string;
  metadata: TPostMetadata;
};

function Post({ id, name, time, text, profileMediaId, mediaIds, metadata }: TPostProps) {
  const dispatch = useAppDispatch();
  const [comments, setComments] = useState<TPostComment[]>([]);
  const relativeTime = getRelativeTime(time);

  useEffect(() => {
    // Only load comments if the post was made by a bot
    if (text?.toLowerCase().includes?.(BOT_NAME)) {
      dispatch(
        getAllActivityComments({
          offset: 0,
          limit: 2,
          id,
        }),
      )
        .unwrap()
        .then((response) => {
          setComments(response.data.slice(0).reverse());
        })
        .catch((e) => {});
    }
  }, [dispatch, id, text]);

  return (
    <div className='my-4'>
      <ActivityFeedCard
        name={name}
        relativeTime={relativeTime}
        text={text}
        profileMediaId={profileMediaId}
        mediaIds={mediaIds}
        metadata={metadata}
        comments={comments}
      />
    </div>
  );
}

export default function ActivityFeed() {
  const allPosts = useAppSelector(postsSelector);
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();

  const posts = useMemo(
    () =>
      allPosts
        .filter((post) => !post.is_hidden)
        .slice(0, 2)
        .map(({ id, name, content, created_at, profile_image_url_id, metadata, medias }) => ({
          id,
          text: content,
          name,
          time: created_at.toLocaleString(),
          profileMediaId: profile_image_url_id,
          metadata,
          mediaIds: medias
            .filter((media) => media.media_type === 'image')
            .map((media) => media.media_url_id),
        })),
    [allPosts],
  );

  return (
    <div
      className='md:bg-white md:p-10 rounded-[14px] w-full dark:bg-dark-card-bg'
      id='activityFeedDetails'
    >
      <div>
        <p className='font-extrabold text-[24px]'>Activity Feed</p>
      </div>

      {posts.length ? (
        <p
          className='text-accent cursor-pointer font-semibold text-end text-[15px] dark:text-dark-text'
          onClick={() => navigate('/activity-feed')}
        >
          View all
        </p>
      ) : null}
      {posts.map((post) => (
        <Post key={post.time} {...post} />
      ))}
      {!posts.length ? (
        <div className='flex flex-col h-full items-center justify-center'>
          {darkMode ? (
            <ActivityFeedEmptyStateDark style={{ height: '12em', width: '17em' }} />
          ) : (
            <ActivityFeedEmptyState style={{ height: '12em', width: '17em' }} />
          )}
          <p className='font-semibold mt-5'>No activity at this time</p>
        </div>
      ) : null}
    </div>
  );
}
