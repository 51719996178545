import { DetailsList } from 'types/components.types';

import IndustryReasonComponent from './IndustryReasonComponent';
import DetailsListComponent from './DetailsListComponent';
import IndustriesHeroSection from './IndustriesHeroSection';

const detailsData: DetailsList[] = [
  {
    title: 'Accurate Attendance',
    image: '/images/industriesPage/events1.svg',
    subtitle:
      'People counting AI provides precise attendance figures, helping event organizers plan more effectively and make data-driven decisions.',
  },
  {
    title: 'Real-Time Insights',
    image: '/images/realTanalysis.svg',
    subtitle:
      'Get immediate access to attendee numbers and demographics, allowing for on-the-fly adjustments to event logistics and resources.',
  },
  {
    title: 'Enhanced Safety',
    image: '/images/industriesPage/events3.svg',
    subtitle:
      'Monitor crowd density and respond proactively to prevent overcrowding and ensure the safety of event attendees.',
  },
  {
    title: 'Resource Optimization',
    image: '/images/industriesPage/events4.svg',
    subtitle:
      'Allocate staff and resources efficiently by understanding peak attendance times and areas of interest.',
  },
];

const EventsSection = (): JSX.Element => {
  return (
    <section>
      <IndustriesHeroSection
        imageURL={'/images/industriesPage/events-hero.svg'}
        title='Events People Counting AI'
      />

      <IndustryReasonComponent
        data={{
          title: 'How can this technology benefit your venue or event?',
          reasons: [
            'HawkEyeVisionTek is extremely beneficial for events and venues. Be able to count individuals for capacity reasons. Detect areas of congestion and potential bottlenecks to alleviate traffic and overcrowding. Monitor the crowds and be able to detect spikes in human activity, preventing potential danger.',
            'These are just some of the many reasons why this technology is great for events. Talk to one of our representative today to see how we can easily get set-up in your concert space today!',
          ],
        }}
      />

      <DetailsListComponent data={detailsData} />
    </section>
  );
};

export default EventsSection;
