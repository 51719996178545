import { useState, useRef, FC, useEffect } from 'react';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import { Icon } from '@iconify/react/dist/iconify.js';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { TChangeInputEvent } from 'types/global/events';
import { sizeLimits } from 'constants/SizeLimits';
import { UploadMessages } from 'constants/ToastMessages';

import LazyImage from '../LazyImage';
import FileUploadView from '../FileUploadView';

import styles from './CompanyFileInput.module.scss';

import type { TCompanyFileInputProps } from './types';

const CompanyFileInput: FC<TCompanyFileInputProps> = ({
  imgSrc,
  getFile,
  getImgSrc,
  forLogo = false,
  blockMaxWidth,
  setIsDirty,
  getSetIsRemovedState,
  htmlFor = 'upload-image',
  replaceClasses,
  withBottomBlock = true,
  containerBoxClassName,
  icon = '', ///<LogoInputIcon />,
  title = 'Upload Company Logo',
  className = 'w-full  h-full',
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(imgSrc as string);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (imgSrc) {
      setImageUrl(imgSrc);
    } else {
      setImageUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgSrc]);

  const companyFileInputRef = useRef<HTMLInputElement>(null);

  const containerBoxClasses = classNames(styles.container__box, containerBoxClassName);
  const replaceClassName = classNames(styles.container__replace, replaceClasses);

  const handleRemove = () => {
    setImageUrl(null);
    setFile(null);
    getFile?.(null);
    setIsDirty?.(true);
  };

  const handleFileUpload = (event: TChangeInputEvent) => {
    const file = event?.target?.files?.[0];
    if (forLogo && file && file.size > sizeLimits.logo) {
      toast(UploadMessages.LOGO_SIZE_EXCEEDED, {
        icon: '⌛',
      });
    } else {
      const reader = new FileReader();

      reader.onloadend = () => {
        const result = reader.result as string;
        setImageUrl(result);
        getImgSrc?.(result);
      };

      if (file) {
        reader.readAsDataURL(file);

        setFile(file);

        getFile?.(file);
        getSetIsRemovedState?.(false);
        setIsDirty?.(true);
      }
    }
  };

  const handleRemoveImage = () => {
    setImageUrl(null);
    setFile(null);
    getFile?.(null);
    getImgSrc?.(null);
    setIsDirty?.(true);

    if (imgSrc) {
      getSetIsRemovedState?.(true);
    }
  };

  const handleReplaceImage = () => {
    if (companyFileInputRef.current) {
      companyFileInputRef.current.click();
    }
  };

  return (
    <div className={className}>
      {!imageUrl ? (
        <div className='bg-card-bg dark:bg-darkBg overflow-hidden center-child w-full h-[300px]  border-dashed border-[2px] border-grey-outline border-grey-outline relative group'>
          <label htmlFor={htmlFor}>
            <div className={styles.container__icon}>{icon}</div>
            <span className='flex flex-col items-center gap-2'>
              <Icon icon='solar:upload-linear' className='dark:text-darkBg' />
              <span className='text-sm dark:text-white'>{title}</span>
            </span>
            <input id={htmlFor} hidden accept='image/*' type='file' onChange={handleFileUpload} />
          </label>
        </div>
      ) : (
        <>
          <div className='bg-card-bg overflow-hidden center-child w-full h-[300px]  border-dashed border-[2px] border-grey-outline relative group'>
            <LazyImage
              // fallback={ImageBlank}
              src={imageUrl as string}
              alt='Uploaded Image'
              height={300}
              width={416}
            />
          </div>
        </>
      )}

      <input
        ref={companyFileInputRef}
        hidden
        accept='.jpg,.jpeg,.png,.svg'
        type='file'
        onChange={handleFileUpload}
      />
      {imageUrl && (
        <div className='flex justify-between items-center'>
          <button
            onClick={handleReplaceImage}
            type='button'
            className='flex text-accent dark:text-dark-text pt-1 font-bold'
          >
            Replace image
          </button>
          <div onClick={handleRemoveImage}>
            <DeleteIcon className='text-[#ff0000]' />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyFileInput;
