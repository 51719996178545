import { FC } from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { Button, Tooltip } from '@mui/material';

import ButtonLoader  from '../ButtonLoader';

import styles from './Button.module.scss';
import { sxButtonStyles } from './styles';

import type { TButtonProps } from './types';

const ButtonCustomize: FC<TButtonProps> = ({
  id,
  href,
  size,
  color,
  height,
  variant,
  padding,
  onClick,
  fontSize,
  endIcon,
  className,
  children,
  iconHeight,
  iconWidth,
  maxWidth,
  minWidth,
  startIcon,
  border = 0,
  borderRadius,
  iconMarginLeft,
  width = '100%',
  textClassName,
  iconMarginRight,
  isUppercase = true,
  type = 'button',
  backgroundColor,
  tooltipMessage,
  cursor = 'pointer',
  disabled = false,
  inLoading = false,
  startIconLeftPosition,
  fontFamily = 'CircularStdMedium',
}) => {
  const MyButton = styled(Button)<TButtonProps>({
    border: border,
    height: height,
    letterSpacing: 0,
    padding: padding,
    width: width || '100%',
    boxShadow: 'none',
    textUnderlineOffset: '5px',
    color: `${color} !important`,
    backgroundColor: backgroundColor,
    maxWidth: maxWidth || 'fit-content',
    minWidth: minWidth || '80px',
    borderRadius: borderRadius ? borderRadius : '10px',
    textDecoration: href ? 'underline !important' : 'none',
  });

  const buttonTextClasses = classNames(styles.button, textClassName);

  const buttonStyles = sxButtonStyles({
    isUppercase,
    backgroundColor,
    startIconLeftPosition,
    iconHeight,
    iconMarginLeft,
    iconMarginRight,
    iconWidth,
    cursor,
  });

  return (
    <>
      {tooltipMessage ? (
        <Tooltip title={tooltipMessage} placement='top'>
          <div style={{ width: '100%', maxWidth: maxWidth || 'fit-content', minWidth }}>
            <MyButton
              id={id}
              size={size}
              width={width}
              href={href}
              onClick={onClick}
              variant={variant}
              endIcon={endIcon}
              disabled={disabled}
              type={type}
              className={className}
              startIcon={startIcon}
              sx={buttonStyles as any}
            >
              {inLoading ? (
                <ButtonLoader />
              ) : (
                <span className={buttonTextClasses} style={{ fontSize, fontFamily }}>
                  {children}
                </span>
              )}
            </MyButton>
          </div>
        </Tooltip>
      ) : (
        <MyButton
          id={id}
          size={size}
          width={width}
          href={href}
          onClick={onClick}
          variant={variant}
          endIcon={endIcon}
          disabled={disabled}
          type={type}
          className={className}
          startIcon={startIcon}
          sx={buttonStyles as any}
        >
          {inLoading ? (
            <ButtonLoader />
          ) : (
            <span className={buttonTextClasses} style={{ fontSize, fontFamily }}>
              {children}
            </span>
          )}
        </MyButton>
      )}
    </>
  );
};

export default ButtonCustomize;
