import React, { useCallback, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectChangeEvent, Stack } from '@mui/material';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/CancelRounded';

import PrimaryButton from 'components/buttons/PrimaryButton';
import { TCreateEventForm } from 'api/events/types';
import { createEventSchema } from 'constants/Schemas';
import ControlledInput from 'components/shared/ControlledInput';
import ControlledSelect from 'components/shared/ControlledSelect';
import { Colors, FontNames } from 'types';
import { useAppDispatch } from 'app/hooks';
import { editEvent, getDevices } from 'store/thunks';
import { useAppSelector, useDarkMode } from 'hooks';
import { devicesSelector } from 'store/slices/devicesSlice/selectors';
import { Select } from 'components';
import { eventByIDSelector } from 'store/slices/eventsSlice/selectors';
import CheckBoxBoolean from 'components/inputs/CheckBox';
import {
  eventDurationOptions,
  eventRepeatTypeOptions,
  inferenceTypeOptions,
} from 'constants/eventOptions';
import CustomModal from 'components/Modal';
import { EventCustomCalender, ICustomCalendarData } from './EventCustomCalender';
import { PencilIcon } from '@heroicons/react/24/solid';
import { capitalizeFirstLetter } from 'utils';

interface AddEventProps {
  onClose: () => void;
}

const EditEvent: React.FC<AddEventProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const event = useAppSelector(eventByIDSelector);
  const [startDate, setStartDate] = useState<Date | string>();
  const [endDate, setEndDate] = useState(event?.end_date || '');
  const [deviceId, setdeviceId] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const devices = useAppSelector(devicesSelector);
  const [eventInterval, setEventInterval] = useState('');
  const [eventDays, setEventDays] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customCalenderData, setCustomCalenderData] = useState({} as ICustomCalendarData);
  const [repeatEvery, setRepeatEvery] = useState(event?.repeat_every);

  const {
    control: eventssControl,
    handleSubmit: eventsSubmit,
    formState: { errors },
    reset,
  } = useForm<TCreateEventForm>({
    resolver: yupResolver(createEventSchema),
  });

  const inferenceTypeDefaultValue = inferenceTypeOptions?.find(
    (val) => val.value === event?.inference_type,
  );

  const eventDurationDefaultValue = eventDurationOptions?.find(
    (val) => val.value === event?.duration,
  );

  const defaultValues: any = {
    event_title: event?.event_title,
    repeat_type: event?.repeat_type,
    trigger_interval: event?.trigger_interval ? String(event?.trigger_interval) : '5 min',
    event_interval: event?.event_interval,
    start_date: moment(event?.start_date).format('YYYY-MM-DD'),
    start_time: event?.start_time?.slice(0, 5),
    duration: event?.duration,
    inference_type: event?.inference_type,
    device_ids: selectedItems,
    repeatEvery: event?.repeat_every,
  };

  const onEditEvent = async (values: TCreateEventForm) => {
    const obj = {
      id: event?.id,
      event_title: values?.event_title,
      repeat_type: eventInterval === 'None' ? 'Oneoff' : 'Recurring',
      event_interval: eventInterval,
      duration: values?.duration,
      trigger_interval: +values?.trigger_interval,
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      start_time: dayjs(startDate).format('HH:mm'),
      inference_type: values?.inference_type,
      event_days: eventDays.join(','), // This is a temporary fix (compromise) because backend has not implemented accepting an array of event days, so we are sending a string with commma instead
      device_ids: selectedItems,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      end_date: endDate
        ? dayjs(endDate).format('YYYY-MM-DD')
        : dayjs(startDate).format('YYYY-MM-DD'),
      repeatEvery: repeatEvery,
    };

    try {
      await dispatch(editEvent(obj));
      onClose();
    } catch (err: any) {
      // console.log(err);
    }
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setEventInterval('None');
  };

  const applyCustomRepeat = (data: any) => {
    const { repeatEvery, eventDays, endDate, customCalenderData } = data;

    setEndDate(endDate);

    setEventDays(eventDays);

    setCustomCalenderData(customCalenderData);

    setEventInterval(customCalenderData.interval);

    setIsModalOpen(false);

    setRepeatEvery(repeatEvery);
  };

  const getAllDevices = async () => {
    await dispatch(getDevices({ sort_by: 'created_at', limit: 100 }));
  };

  useEffect(() => {
    getAllDevices();
  }, []);

  const devicesOptions = devices?.map((device: any, index: number) => {
    return { id: index + 1, label: device.device_name, value: device.id };
  });

  const devicesSelected = useCallback(
    (id: number) => {
      if (!selectedItems?.includes(id)) {
        setSelectedItems([...selectedItems, id]);
      }
    },
    [selectedItems],
  );

  const handleDelete = (chipToDelete: number) => () => {
    setSelectedItems(() => selectedItems?.filter((it) => it !== chipToDelete));
  };

  useEffect(() => {
    const devicesList: any = event?.devices?.map((device) => device?.id);

    setSelectedItems(devicesList);

    if (event) {
      reset(defaultValues);

      defaultEventCalender();

      defaultEventInterval();

      defaultEventDays();

      defaultCustomCalendar();
    }
  }, [event]);

  const defaultEventCalender = () => {
    const initialStartDate = dayjs(`${event?.start_date}T${event?.start_time}Z`).format(
      'YYYY-MM-DD HH:mm',
    );

    setStartDate(initialStartDate);
  };

  const defaultEventInterval = () => {
    const initialEventInterval =
      event?.event_interval === 'None' ? 'Does not repeat' : event?.event_interval;

    setEventInterval(initialEventInterval as string);
  };

  const defaultEventDays = () => {
    const eventDays = event?.event_days ? event?.event_days.split(',') : [];

    setEventDays(eventDays);
  };

  const defaultCustomCalendar = () => {
    if (event?.event_interval === 'Custom') {
      const eventDays = event?.event_days ? event?.event_days.split(',') : [];

      const repeatDays = eventDays.map((val) => ({
        name: capitalizeFirstLetter(val),
        value: val.toLowerCase(),
      }));

      const customCalendarData = {
        repeatDays,
        date: endDate,
      };

      setCustomCalenderData(customCalendarData);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEventInterval(e.target.value);

    if (e.target.value === 'None') setEndDate('');

    if (e.target.value === 'Custom') handleOpenCustomCalender();
  };

  const handleOpenCustomCalender = () => setIsModalOpen(true);

  useEffect(() => {
    if (!selectedItems?.length) {
      setdeviceId('');
    }
  }, [deviceId, selectedItems]);

  const { darkMode } = useDarkMode();

  return (
    <>
      <div className='flex flex-col justify-between h-full'>
        <div>
          <div className='flex justify-between'>
            <p className='font-semibold text-[24px]'>Update Event</p>
            <div className='flex items-center pr-4 gap-6'>
              <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
            </div>
          </div>

          <div className='mt-7'>
            <ControlledInput
              required
              control={eventssControl}
              name='event_title'
              label='Event Title'
              error={!!errors.event_title}
              placeholder='Event Title*'
              helperText={(errors?.event_title?.message as string) ?? ''}
              defaultValue={defaultValues.event_title}
            />

            <div className='mt-7'>
              <div className='grid grid-cols-2 md:grid-cols-6 gap-6 -mt-4'>
                <div className='md:col-span-4'>
                  <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                    Starts
                  </label>
                  <input
                    type='datetime-local'
                    value={startDate as string}
                    onChange={handleInputChange}
                    required
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '14px',
                      color: '#6B7280 ',
                      fontWeight: '500',
                    }}
                    className='mt-1 px-2 py-4 block w-full border bg-transparent rounded-md focus:outline-none focus:ring focus:ring-blue-300'
                  />
                </div>

                <div className='grid-cols-1 md:col-span-2'>
                  <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                    Repeats
                  </label>
                  <div className='relative'>
                    <select
                      value={eventInterval}
                      onChange={handleSelectChange}
                      className='mt-1 block w-full border bg-transparent rounded-md px-2 py-4 focus:outline-none focus:ring focus:ring-blue-300 min-w-fit'
                    >
                      {eventRepeatTypeOptions.map((val) => (
                        <option
                          key={val.id}
                          value={val.value}
                          className='text-gray-800 w-full bg-gray-300'
                        >
                          {val.label}
                        </option>
                      ))}
                    </select>

                    {eventInterval === 'Custom' && (
                      <PencilIcon
                        onClick={handleOpenCustomCalender}
                        className='absolute right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-500 cursor-pointer'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='grid gap-8 mt-7'>
              <ControlledInput
                required
                control={eventssControl}
                name='trigger_interval'
                label='Image Capture Interval'
                error={!!errors.event_days}
                placeholder='5'
                rightIcon='Mins'
                helperText={(errors?.trigger_interval?.message as string) ?? ''}
                defaultValue={defaultValues.trigger_interval || '5'}
              />

              <ControlledSelect
                name='duration'
                control={eventssControl}
                defaultValue={eventDurationDefaultValue?.label}
                options={eventDurationOptions}
                label='Event Duration*'
                fontFamily={FontNames.AVENIR_NEXT_PRO}
              />
            </div>

            <div className='mt-7 mb-2'>
              <Controller
                control={eventssControl}
                name='device_ids'
                render={({ field: { onChange } }) => {
                  return (
                    <Select
                      onChange={(event: SelectChangeEvent) => {
                        onChange(event.target.value);
                        if (event.target.value) {
                          devicesSelected(Number(event?.target?.value));
                          setdeviceId(event?.target?.value);
                        }
                      }}
                      defaultValue=''
                      borderRadius='5px'
                      label='Select Device*'
                      options={devicesOptions}
                      fontFamily={FontNames.AVENIR_NEXT_PRO}
                      error={!!errors.device_ids}
                      value={deviceId}
                    />
                  );
                }}
              />
              <Stack direction='row' spacing={1} className='mt-2 mb-6'>
                {selectedItems?.length > 0 &&
                  selectedItems?.map((item: any) => {
                    const device = devicesOptions?.find((device: any) => device.id === item);
                    return (
                      <Chip
                        label={device?.label || `Device ${item - 1}`}
                        key={item}
                        onDelete={handleDelete(item)}
                        deleteIcon={<DeleteIcon />}
                        variant='outlined'
                        sx={{
                          color: darkMode ? Colors.WHITE : '',
                          '.MuiSvgIcon-root': {
                            color: darkMode ? 'white' : '',
                          },
                        }}
                      />
                    );
                  })}
              </Stack>
            </div>

            <ControlledSelect
              name='inference_type'
              control={eventssControl}
              defaultValue={inferenceTypeDefaultValue?.label}
              options={inferenceTypeOptions}
              label='Inference Type*'
              fontFamily={FontNames.AVENIR_NEXT_PRO}
            />
          </div>
        </div>
        <PrimaryButton
          className='w-full md:w-fit self-end mt-5'
          variant='neutral'
          onClick={eventsSubmit(onEditEvent)}
        >
          Update Event
        </PrimaryButton>
      </div>

      {isModalOpen && (
        <CustomModal
          isOpen={isModalOpen}
          onClose={onCloseModal}
          className='w-96 absolute top-20 right-12 rounded-xl p-6 object-cover border-0 bg-white'
          useClassNameOnly={true}
        >
          <EventCustomCalender
            close={onCloseModal}
            startDate={startDate}
            customCalendarData={customCalenderData}
            applyCustomRepeat={applyCustomRepeat}
            setNum={setRepeatEvery}
          />
        </CustomModal>
      )}
    </>
  );
};

export default EditEvent;
