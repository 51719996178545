import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

import IndustriesImage1 from 'assets/images/industriesPage/industries1.png';
import paths from 'constants/paths';

import Badge from '../../components/shared/Badge';
import CallToAction from '../../components/shared/CallToAction';
import Container from '../../components/shared/Container';

const DiverseIndustries = () => {
  const [showAllIndustries, setShowAllIndustries] = useState(false);

  const analyticsInfo = [
    {
      image: IndustriesImage1,
      title: 'Retail Store Analytics',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
      path: paths.INDUSTRIES_PAGE,
    },
    {
      image: '/images/industriesPage/ministry-hero.svg',
      title: 'Congregations Attendance Counting AI',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
      path: paths.MINISTRY_PAGE,
    },
    {
      image: '/images/industriesPage/hospitality-hero.svg',
      title: 'Hospitality CCTV Analytics',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
      path: paths.HOSPITALITY_PAGE,
    },
    {
      image: '/images/industriesPage/transport-hero.svg',
      title: 'Transport',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
      path: paths.TRANSPORT_PAGE,
    },
    {
      image: '/images/industriesPage/construction-hero.svg',
      title: 'Construction People Counter',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
      path: paths.TRANSPORT_PAGE,
    },
    {
      image: '/images/industriesPage/production-hero.svg',
      title: 'Production Company Monitoring AI',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
      path: paths.PRODUCTION_PAGE,
    },
    {
      image: '/images/industriesPage/events-hero.svg',
      title: 'Events People Counting AI',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
      path: paths.EVENTS_PAGE,
    },
  ];

  const visibleIndustries = showAllIndustries ? analyticsInfo : analyticsInfo.slice(0, 3);

  return (
    <Container className='flex flex-col gap-x-10 gap-y-10 xl:py-[70px] lg:py-16 py-14'>
      <div>
        <div className='center-child flex-col'>
          <Badge title='industries' />
          <h3 className='text-accent md:text-[40px] text-[18px] font-semibold capitalize mb-2 font-Avenir-Next-Pro'>
            <span className='text-main-text dark:text-white'>We Serve,</span> Diverse Industries
          </h3>
          <p className='text-[16px] font-light text-sub-title dark:text-[#D1D1D1] md:w-[800px] text-center mb-4'>
            Innovative end-to-end solutions, encompassing data ingestion from sensors and cameras,
            processing with advanced AI technologies, and downstream API integration.
          </p>
        </div>

        <div className='grid lg:grid-cols-3 grid-cols-1 gap-x-5 gap-y-20 mt-7 place-items-center'>
          {visibleIndustries.map((industry, index) => (
            <CallToAction
              key={String(index + 1)}
              image={industry.image}
              title={industry.title}
              description={industry.description}
              path={industry.path}
            />
          ))}
        </div>

        <div
          className='flex items-center justify-center mt-20 gap-3 cursor-pointer'
          onClick={() => setShowAllIndustries(!showAllIndustries)}
        >
          <h4 className='font-semibold'>
            {showAllIndustries ? 'Hide More Industries' : 'View All Industries'}
          </h4>
          {showAllIndustries ? (
            <ChevronUpIcon color='#303030' className='text-main-sub-text w-[20px]' />
          ) : (
            <ChevronDownIcon color='#303030' className='text-main-sub-text w-[20px]' />
          )}
        </div>
      </div>
    </Container>
  );
};

export default DiverseIndustries;
