const MinistryIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='none' viewBox='0 0 24 25'>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M2 22.5h20M15.01 22.49l-12 .02L3 7.57c0-.67.34-1.29.89-1.66l4-2.67c.67-.45 1.55-.45 2.22 0l4 2.67c.56.37.89.99.89 1.66l.01 14.92zM19.98 22.51V18.5M20 14c-1.1 0-2 .75-2 1.667v1.666c0 .917.9 1.667 2 1.667s2-.75 2-1.667v-1.666C22 14.75 21.1 14 20 14zM3 14.5h12'
      ></path>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M9 12.5V6M10.875 8h-3.75'
      ></path>
    </svg>
  );
};

export default MinistryIcon;
