import { AxiosResponse } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';

import { TContactRequestBody, TContactResponse } from './types';

export const postContactRequest = (
  requestBody: TContactRequestBody,
): Promise<AxiosResponse<TContactResponse>> => {
  return client.post(endpoints.ContactUsService.postContact(), requestBody);
};
