import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectChangeEvent, Stack } from '@mui/material';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/CancelRounded';

import PrimaryButton from 'components/buttons/PrimaryButton';
import { TCreateEventForm } from 'api/events/types';
import { createEventSchema } from 'constants/Schemas';
import ControlledInput from 'components/shared/ControlledInput';
import ControlledSelect from 'components/shared/ControlledSelect';
import { Colors, FontNames } from 'types';
import { useAppDispatch } from 'app/hooks';
import { createEvent, getDevices } from 'store/thunks';
import { useAppSelector, useDarkMode } from 'hooks';
import { devicesSelector } from 'store/slices/devicesSlice/selectors';
import { Select } from 'components';
import {
  eventDurationOptions,
  eventRepeatTypeOptions,
  inferenceTypeOptions,
} from 'constants/eventOptions';
import { EventCustomCalender, ICustomCalendarData } from './EventCustomCalender';
import CustomModal from 'components/Modal';

interface AddEventProps {
  onClose: () => void;
}

type DeviceOption = {
  id: number;
  label: string;
  value: string;
};

const AddEvents: React.FC<AddEventProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Date | string>();
  const [endDate, setEndDate] = useState('');
  const [endTimeError, setEndTimeError] = useState<boolean>(false);
  const [startTimeError, setStartTimeError] = useState<boolean>(false);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [deviceId, setdeviceId] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const devices = useAppSelector(devicesSelector);
  const [eventInterval, setEventInterval] = useState('None');
  const [eventDuration, setEventDuration] = useState(2);
  const [eventDays, setEventDays] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customCalenderData, setCustomCalenderData] = useState({} as ICustomCalendarData);
  const [repeatEvery, setRepeatEvery] = useState<number>(1);

  const {
    control: eventssControl,
    handleSubmit: eventsSubmit,
    formState: { errors },
    watch,
  } = useForm<TCreateEventForm>({
    resolver: yupResolver(createEventSchema),
  });

  const { darkMode } = useDarkMode();

  const addEventFields = watch();

  const onCloseModal = () => {
    setIsModalOpen(false);
    setEventInterval('None');
    setCustomCalenderData({} as ICustomCalendarData);
  };

  const applyCustomRepeat = (data: any) => {
    const { repeatEvery, eventDays, endDate, customCalenderData } = data;

    setEndDate(endDate);

    setEventDays(eventDays);

    setCustomCalenderData(customCalenderData);

    setEventInterval(customCalenderData.interval);

    setIsModalOpen(false);

    setRepeatEvery(repeatEvery);
  };

  const isSubmitButtonDisabled = useMemo(
    () =>
      !!errors.event_title ||
      !!errors.event_days ||
      !!errors.device_ids ||
      startDateError ||
      endDateError ||
      startTimeError ||
      endTimeError ||
      !selectedItems.length,
    [
      endDateError,
      endTimeError,
      errors.device_ids,
      errors.event_days,
      errors.event_title,
      selectedItems.length,
      startDateError,
      startTimeError,
    ],
  );

  const onCreateEvent = async (values: TCreateEventForm) => {
    const obj = {
      event_title: values?.event_title,
      repeat_type: eventInterval === 'None' ? 'Oneoff' : 'Recurring',
      event_interval: eventInterval,
      trigger_interval: +values?.trigger_interval,
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      start_time: dayjs(startDate).format('HH:mm'),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      inference_type: values?.inference_type,
      duration: eventDuration,
      event_days: eventDays.join(','), // This is a temporary fix (compromise) because backend has not implemented accepting an array of event days, so we are sending a string with commma instead
      device_ids: selectedItems,
      end_date: endDate
        ? dayjs(endDate).format('YYYY-MM-DD')
        : dayjs(startDate).format('YYYY-MM-DD'),
      repeatEvery: repeatEvery,
    };

    if (!isSubmitButtonDisabled) {
      try {
        await dispatch(createEvent(obj));
        onClose();
      } catch (err: any) {
        // console.log(err);
      }
    }
  };

  const getAllDevices = async () => {
    await dispatch(getDevices({ sort_by: 'created_at', limit: 100 }));
  };

  useEffect(() => {
    getAllDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const devicesOptions = devices?.map((device: any, index: number) => {
    return { id: index + 1, label: device.device_name, value: device.id };
  });

  const devicesSelected = useCallback(
    (id: number) => {
      if (!selectedItems.includes(id)) {
        setSelectedItems([...selectedItems, id]);
      }
    },
    [selectedItems],
  );

  const handleDelete = (chipToDelete: number) => () => {
    setSelectedItems(() => selectedItems.filter((it) => it !== chipToDelete));
  };

  const handleStartDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setEventInterval(value);

    if (value === 'None') {
      setEndDate('');
      setEventDays([]);
    } else if (value === 'Custom') {
      handleOpenCustomCalender();
    }
  };

  const handleOpenCustomCalender = () => setIsModalOpen(true);

  const handleEventDurationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEventDuration(+e.target.value);
  };

  useEffect(() => {
    if (!selectedItems?.length) {
      setdeviceId('');
    }
  }, [deviceId, selectedItems]);

  return (
    <>
      <div className='flex flex-col justify-between h-full'>
        <div className='mb-4'>
          <div className='flex justify-between'>
            <p className='font-semibold text-[24px]'>Create New Event</p>
            <div className='flex items-center pr-4 gap-6'>
              <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
            </div>
          </div>

          <div className='mt-7 px-2'>
            <ControlledInput
              required
              control={eventssControl}
              name='event_title'
              label='Event Title'
              error={!!errors.event_title}
              placeholder='Event Title*'
              helperText={(errors?.event_title?.message as string) ?? ''}
            />

            <div className='mt-7'>
              {/* <EventCalender control={eventCalenderControl} /> */}

              <div className='grid grid-cols-2 md:grid-cols-6 gap-6 -mt-4'>
                <div className={`md:col-span-${eventInterval !== 'None' ? 2 : 4}`}>
                  <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                    Starts
                  </label>
                  <input
                    type='datetime-local'
                    value={startDate as string}
                    onChange={handleStartDateTimeChange}
                    required
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '14px',
                      color: '#6B7280 ',
                      fontWeight: '500',
                    }}
                    className='mt-1 px-2 py-5 block w-full border bg-transparent rounded-md focus:outline-none focus:ring focus:ring-blue-300 font-medium'
                  />
                </div>

                {eventInterval !== 'None' && (
                  <div className='md:col-span-2'>
                    <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                      Ends
                    </label>
                    <input
                      type='date'
                      value={endDate}
                      onChange={handleEndDateChange}
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '14px',
                        color: '#6B7280 ',
                        fontWeight: '500',
                      }}
                      className='mt-1 px-2 py-4 text-base block w-full border bg-transparent rounded-md focus:outline-none focus:ring focus:ring-blue-300 font-medium'
                    />
                  </div>
                )}

                <div className='grid-cols-1 md:col-span-2'>
                  <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                    Repeats
                  </label>

                  <div className='relative'>
                    <select
                      value={eventInterval}
                      onChange={handleSelectChange}
                      className='mt-1 block w-full border bg-transparent rounded-md px-2 py-4 focus:outline-none focus:ring focus:ring-blue-300 min-w-fit'
                    >
                      {eventRepeatTypeOptions.map((val) => (
                        <option
                          key={val.id}
                          value={val.value}
                          className='text-gray-800 w-full bg-gray-300'
                        >
                          {val.label}
                        </option>
                      ))}
                    </select>

                    {eventInterval === 'Custom' && (
                      <PencilIcon
                        onClick={handleOpenCustomCalender}
                        className='absolute right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-500 cursor-pointer'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='grid gap-8 mt-7'>
              <ControlledInput
                required
                defaultValue='5'
                control={eventssControl}
                name='trigger_interval'
                label='Image Capture Interval'
                error={!!errors.event_days}
                placeholder='5'
                rightIcon='Mins'
                helperText={(errors?.trigger_interval?.message as string) ?? ''}
              />

              <div>
                <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                  Event Duration
                </label>
                <select
                  value={eventDuration}
                  onChange={handleEventDurationChange}
                  className='mt-1 block w-full border bg-gray-100 rounded-md px-2 py-4 focus:outline-none focus:ring focus:ring-blue-300 min-w-fit'
                >
                  {eventDurationOptions.map((val) => (
                    <option
                      key={val.id}
                      value={val.value}
                      selected={eventDuration === val.value}
                      className='text-gray-800 w-full'
                    >
                      {val.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='mt-7 mb-2'>
              <Controller
                control={eventssControl}
                name='device_ids'
                render={({ field: { onChange } }) => {
                  return (
                    <Select
                      onChange={(event: SelectChangeEvent) => {
                        onChange(event.target.value);
                        if (event.target.value) {
                          devicesSelected(Number(event?.target?.value));
                          setdeviceId(event?.target?.value);
                        }
                      }}
                      defaultValue=''
                      borderRadius='5px'
                      label='Select Device*'
                      options={devicesOptions}
                      fontFamily={FontNames.AVENIR_NEXT_PRO}
                      error={!!errors.device_ids}
                      value={deviceId}
                      className='w-full'
                    />
                  );
                }}
              />
              <Stack direction='row' spacing={1} className='mt-2 mb-6'>
                {selectedItems.map((item: any) => {
                  const device = devicesOptions.find((device: any) => device.id === item);
                  return (
                    <Chip
                      label={device?.label || `Device ${item - 1}`}
                      key={item}
                      onDelete={handleDelete(item)}
                      deleteIcon={<DeleteIcon className='dark:text-white' />}
                      sx={{
                        color: darkMode ? Colors.WHITE : '',
                        '.MuiSvgIcon-root': {
                          color: darkMode ? 'white' : '',
                        },
                      }}
                      variant='outlined'
                    />
                  );
                })}
              </Stack>
            </div>

            <ControlledSelect
              name='inference_type'
              control={eventssControl}
              options={inferenceTypeOptions}
              label='Inference Type*'
              fontFamily={FontNames.AVENIR_NEXT_PRO}
            />
          </div>
        </div>

        <PrimaryButton
          className='w-full md:w-fit self-end mt-2 '
          variant='neutral'
          onClick={() => onCreateEvent(addEventFields)}
          disabled={isSubmitButtonDisabled}
        >
          Create Event
        </PrimaryButton>
      </div>

      {isModalOpen && (
        <CustomModal
          isOpen={isModalOpen}
          onClose={onCloseModal}
          className='w-96 absolute top-20 right-12 rounded-xl p-6 object-cover border-0 bg-white'
          useClassNameOnly={true}
        >
          <EventCustomCalender
            close={onCloseModal}
            startDate={startDate}
            applyCustomRepeat={applyCustomRepeat}
            customCalendarData={customCalenderData}
            setNum={setRepeatEvery}
          />
        </CustomModal>
      )}
    </>
  );
};

export default AddEvents;
