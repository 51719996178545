import PrimaryButton from '../../components/buttons/PrimaryButton';

const WorkSmarter = ({ onClickScheduleCall }: { onClickScheduleCall?: () => void }) => {
  return (
    <div className='flex  items-center justify-center w-full   py-[60px]  md:px-[140px] px-5 -mb-[200px] z-10 relative '>
      <div className='w-[1200px] flex md:flex-row flex-col bg-[#F0F7FF] dark:bg-accent rounded-[20px] md:px-[74px] px-4 py-[35px] '>
        <div className='flex flex-col gap-4 md:w-1/2 w-full'>
          <h3 className='text-main md:text-[40px] text-[18px] font-semibold font-Avenir-Next-Pro '>
            Work smarter with us, <br /> today
          </h3>
          <p className='text-[12px] font-semibold text-sub-title dark:text-[#D1D1D1]  '>
            Our team is always ready to introduce you to the world of HawkEyeVisionTek!
          </p>
          <PrimaryButton
            className='bg-accent dark:bg-white dark:text-accent my-4 w-[204px]'
            isArrow
            onClick={onClickScheduleCall}
          >
            Schedule a Call
          </PrimaryButton>
        </div>
        <div className='md:w-1/2 w-full'>
          <img src='/images/work_smarter.svg' alt='' />
        </div>
      </div>
    </div>
  );
};

export default WorkSmarter;
