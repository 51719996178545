import { RootState } from 'types';

export const createOrganizationSelector = (state: RootState) =>
  state.organizations.createdOrganization;

export const createOrganizationLoadingSelector = (state: RootState) =>
  state.organizations.createOrganizationLoading;

export const needToFetchOrganizationSelector = (state: RootState) =>
  state.organizations.needToFetchList;

export const createOrganizationErrorSelector = (state: RootState) =>
  state.organizations.createOrganizationError;

export const organizationsSelector = (state: RootState) => state.organizations.organizations;

export const organizationByIdSelector = (state: RootState) =>
  state.organizations.currentOrganizationById;

export const organizationByIdLoadingSelector = (state: RootState) =>
  state.organizations.currentOrganizationLoading;

export const organizationMembersSelector = (state: RootState) =>
  state.organizations.organizationMembers?.members;

export const organizationMembersCountSelector = (state: RootState) =>
  state.organizations.organizationMembers?.members_count;

export const organizationMembersLoadingSelector = (state: RootState) =>
  state.organizations.organizationMembersLoading;

export const organizationMembersErrorSelector = (state: RootState) =>
  state.organizations.organizationMembersError;

export const currentOrganizationDataByIdSelector = (state: RootState) =>
  state.organizations.currentOrganizationById;

export const updatedOrganizationLoadingSelector = (state: RootState) =>
  state.organizations.updateOrganizationLoading;

export const organizationMembersOffsetSelector = (state: RootState) =>
  state.organizations.organizationMembersOffset;

export const organizationMembersLimitSelector = (state: RootState) =>
  state.organizations.organizationMembersLimit;

export const organizationInvitedUsersLoadSelector = (state: RootState) =>
  state.organizations.organizationInvitedUsersLoad;

export const organizationInvitedUsersLimitSelector = (state: RootState) =>
  state.organizations.organizationInvitedUsersLimit;

export const organizationInvitedUsersOffsetSelector = (state: RootState) =>
  state.organizations.organizationInvitedUsersOffset;

export const organizationInvitedUsersDataSelector = (state: RootState) =>
  state.organizations.organizationInvitedUsers;

export const organizationInvitedUsersErrorSelector = (state: RootState) =>
  state.organizations.organizationInvitedUsersError;

export const allUsersLoadSelector = (state: RootState) => state.organizations.allUsersLoading;

export const allUsersLimitSelector = (state: RootState) => state.organizations.allUsersLimit;

export const allUsersOffsetSelector = (state: RootState) => state.organizations.allUsersOffset;

export const allUsersDataSelector = (state: RootState) => state.organizations.allUsers;

export const allUsersErrorSelector = (state: RootState) => state.organizations.allUsersError;

export const pendingUsersLoadSelector = (state: RootState) =>
  state.organizations.pendingUsersLoading;

export const pendingUsersLimitSelector = (state: RootState) =>
  state.organizations.pendingUsersLimit;

export const pendingUsersOffsetSelector = (state: RootState) =>
  state.organizations.pendingUsersOffset;

export const pendingUsersDataSelector = (state: RootState) => state.organizations.pendingUsers;

export const pendingUsersErrorSelector = (state: RootState) =>
  state.organizations.pendingUsersError;

export const subscriptionTypeSelector = (state: RootState) => state.organizations.subscriptionType;
