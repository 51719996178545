import { createSlice } from '@reduxjs/toolkit';

import {
  endStream,
  getBatchUrls,
  getDevices,
  getDevicesCounts,
  getDevicesHistoryByID,
  startStream,
} from './thunks';
import { TInitialState } from './type';

const initialState: TInitialState = {
  devices: null,
  devicesHistory: null,
  loadingDevices: false,
  loadingDevicesError: false,
  total_count: 0,
  online_count: 0,
  offline_count: 0,
  media_Url: [],
  startStreamResponse: [],
  endStreamResponse: [],
  batchUrls: '',
  streamKey: '',
  controls: false,
};

const devicesSlice = createSlice({
  name: 'device',
  initialState: initialState,
  reducers: {
    clearHistory(state) {
      state.devicesHistory = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDevicesHistoryByID?.fulfilled, (state, action) => {
        state.devicesHistory = action?.payload?.data;
        state.total_count = action.payload.total_count;
        state.media_Url = action.payload.data.map((device) => device.image_url_id);
      })
      .addCase(getDevices?.fulfilled, (state, action) => {
        state.devices = action?.payload?.data;
        state.loadingDevices = false;
      })
      .addCase(getDevices?.pending, (state) => {
        state.loadingDevices = true;
        state.loadingDevicesError = null;
      })
      .addCase(getDevices?.rejected, (state) => {
        state.loadingDevices = false;
        state.loadingDevicesError = null;
      })
      .addCase(getDevicesCounts.fulfilled, (state, action) => {
        state.total_count = action.payload?.total_count as number;
        state.offline_count = action.payload?.offline_count as number;
        state.online_count = action.payload?.online_count as number;
      })
      .addCase(startStream.fulfilled, (state, action) => {
        state.startStreamResponse = action.payload;
        state.streamKey = action.payload[0]?.stream_key || '';
        state.controls = action.payload[0]?.controls || false;
      })
      .addCase(endStream.fulfilled, (state, action) => {
        state.endStreamResponse = action.payload;
      })
      .addCase(getBatchUrls.fulfilled, (state, action) => {
        state.batchUrls = action.payload;
      });
  },
});

export const { clearHistory } = devicesSlice.actions;

export default devicesSlice.reducer;
