import { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import GoogleButton from 'react-google-button';

import HawkEyeLogo from 'components/shared/HawkEyeLogo';
import { Routes } from 'types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { useAppDispatch, useClearStorage, useDarkMode, useUserOrganizationsList } from 'hooks';
import { loginOAuth } from 'store/slices/authSlice/authThunks';
import { TUserProfile, TokenProvider } from 'store/slices/authSlice/types';
import { OrganizationInformationUpdateToastMessages } from 'constants/ToastMessages';
import { endpoints } from 'api';
import { isOrganisationCreated, isOrganisationPending } from 'helpers/utils';
import { EInviteStatus } from 'store/slices/invitationsSlice/types';
import { acceptEmailInvitation } from 'store/thunks';
import SignInIcon from 'assets/icons/SignInIcon';

const Login = () => {
  const dispatch = useAppDispatch();
  const { darkMode } = useDarkMode();

  useClearStorage();

  const navigate = useNavigate();

  const { getAllOrganizations } = useUserOrganizationsList();

  const goToRegistrationPage = () => navigate(Routes.SignUp);

  const token = BrowserStorageService.get(BrowserStorageKeys.AccessToken);
  const isFirstTimeUser =
    BrowserStorageService.get(BrowserStorageKeys.IsFirstTimeUser, {
      session: true,
    }) === 'true';
  const hasActiveOrganization =
    BrowserStorageService.get(BrowserStorageKeys.HaveCreatedOrganization) === 'true';

  const handleLogin = async (socialType: TokenProvider, tokenResponse: any) => {
    const invitation_token = BrowserStorageService.get(BrowserStorageKeys.InvitationToken);
    const invitationStatus = BrowserStorageService.get(BrowserStorageKeys.InvitationStatus);
    const invitationEmail = BrowserStorageService.get(BrowserStorageKeys.InvitationEmail);

    const sendedData = new FormData();

    sendedData.append('oauth_token', String(tokenResponse.access_token));
    sendedData.append('oauth_provider', socialType);

    try {
      const response: TUserProfile = await dispatch(loginOAuth(sendedData)).unwrap();

      // if (response.status === 'deactivated') {
      //   toast.error('Your account has been deactivated. Please contact support for assistance.');

      //   BrowserStorageService.remove(BrowserStorageKeys.AccessToken);
      //   BrowserStorageService.remove(BrowserStorageKeys.RefreshToken);

      //   navigate(Routes.Login);

      //   return;
      // }

      if (response.status === 200) {
        if (
          invitation_token &&
          invitationStatus === EInviteStatus.ACCEPTED &&
          invitationEmail === response.general_user_info.email &&
          response?.general_user_info.id
        ) {
          // Accept invite and change organisation
          dispatch(
            acceptEmailInvitation({
              invitation_token,
              user_id: response?.general_user_info.id,
              onSuccess: () => {
                // User now has an active organisation, so go to Dashboard
                navigate(Routes.Dashboard);
              },
            }),
          );
        } else if (response.token && response.is_first_time_user) {
          toast(OrganizationInformationUpdateToastMessages.NO_ORGANIZATION, {
            icon: '⌛',
          });
          navigate(Routes.ProcessForm);
        } else {
          const organisationsResponse = await getAllOrganizations();

          // User hasn't created an organisation
          if (!organisationsResponse?.total_count) {
            toast(OrganizationInformationUpdateToastMessages.NO_ORGANIZATION, {
              icon: '⌛',
            });
            navigate(Routes.ProcessForm);
            return;
          }

          // User has to choose an organization to view
          if (
            organisationsResponse?.total_count > 1 &&
            !organisationsResponse.data.every(isOrganisationPending)
          ) {
            navigate(Routes.OrganizationsList, { state: organisationsResponse });
            return;
          }

          // If organisation resources are still being created
          if (organisationsResponse.data.every(isOrganisationPending)) {
            // Log user out
            BrowserStorageService.remove(BrowserStorageKeys.AccessToken);
            BrowserStorageService.remove(BrowserStorageKeys.RefreshToken);

            // Notify the user of why they aren't logged in
            toast(
              organisationsResponse.data.length === 1
                ? OrganizationInformationUpdateToastMessages.SINGLE_ORGANIZATION_PENDING
                : OrganizationInformationUpdateToastMessages.ORGANIZATION_PENDING,
              {
                icon: '⌛',
              },
            );
            return;
          }

          // If user has an active organisation
          if (
            organisationsResponse?.total_count === 1 &&
            organisationsResponse.data.every(isOrganisationCreated)
          ) {
            BrowserStorageService.set(
              BrowserStorageKeys.CurrentOrganizationId,
              JSON.stringify(organisationsResponse?.data?.[0]?.id),
            );

            navigate(Routes.Dashboard);
            return;
          }
        }
      }
    } catch (error: any) {
      const appleAuthCode = window.location.href.split('code=')?.[1];

      if (appleAuthCode) {
        navigate(Routes.Login, { replace: true });
      }

      if (error?.message === '404') {
        navigate(Routes.SignUp);
      }
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse: any) => {
      BrowserStorageService.set(BrowserStorageKeys.GoogleToken, tokenResponse.access_token, {
        session: true,
      });
      handleLogin(TokenProvider.GOOGLE, tokenResponse);
    },
  });

  useEffect(() => {
    const code = window.location.href.split('code=')?.[1];

    if (code) handleLogin(TokenProvider.APPLE, { access_token: code });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const invitationToken = BrowserStorageService.get(BrowserStorageKeys.InvitationToken);
    const invitationStatus = BrowserStorageService.get(BrowserStorageKeys.InvitationStatus);

    if (invitationToken && invitationStatus === EInviteStatus.ACCEPTED) {
      toast('Please login to accept invite');
    }
  }, []);

  useEffect(() => {
    if (token && !isFirstTimeUser && hasActiveOrganization) navigate(Routes.Dashboard);
  }, [hasActiveOrganization, isFirstTimeUser, navigate, token]);

  return (
    <div className='w-[100vw] h-[100vh] flex items-center justify-center'>
      <section className='max-w-[1400px] m-auto grid md:grid-cols-2 py-7 md:py-0 md:my-auto md:shadow-md '>
        <div className='flex flex-col items-center md:justify-center h-[30rem] md:p-12 md:border border-gray-200 gap-7 rounded-[8px] rounded-tr-none rounded-br-none'>
          <HawkEyeLogo isDarkMode={darkMode} className='flex md:invisible' />
          <h1 className=' lg:text-[30px] text-[36px] font-normal  xl:leading-[80px] lg:leading-[60px] leading-[45px] tracking-wide transition-opacity ease-in duration-700 opacity-100'>
            Login
          </h1>
          <p className='font-semibold text-center flex md:hidden'>
            Welcome To HawkEyeVisionTek! <br /> Let&apos;s Get Started By <br /> Logging in to your
            Account
          </p>
          <div className='flex flex-col gap-8 mt-8'>
            <GoogleButton type='dark' onClick={() => googleLogin()} />
            <a
              className='flex items-center justify-center bg-black py-3 px-5 text-white rounded-md gap-4 cursor-pointer'
              href={endpoints.AppleAuthService.getSignInWithApple(
                process.env.REACT_APP_APPLE_CLIENT_ID ?? '',
                `${window.location.origin}${Routes.Login}`,
              )}
            >
              <img src='/images/apple.png' alt='' className='w-[20px] ' />
              <span> Sign in with Apple</span>
            </a>
          </div>

          <small className='mt-6 inline-block'>
            Don&apos;t have an account?
            <span onClick={() => goToRegistrationPage()}>
              <span className='ml-[0.4rem] font-bold text-accent cursor-pointer dark:text-dark-text'>
                Sign Up
              </span>
            </span>
          </small>
        </div>
        <div className='hidden md:flex flex-col items-center md:justify-center h-[30rem] md:p-12 md:border border-gray-200 gap-7 rounded-[8px] rounded-tl-none rounded-bl-none'>
          <HawkEyeLogo isDarkMode={darkMode} />
          <p className='font-semibold text-center'>
            Welcome To HawkEyeVisionTek! Let&apos;s Get Started By <br /> Logging in to your Account
          </p>
          <SignInIcon width={600} height={900} />
        </div>
      </section>
    </div>
  );
};

export default Login;
