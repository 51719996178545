import { createSlice } from '@reduxjs/toolkit';

import { TInitialState } from './types';
import { createInvitation, getAllInvitedUsers, getUserInvites } from './thunks';

const initialState: TInitialState = {
  currentInvitation: [],
  currentInvitationLoading: false,
  currentInvitationError: null,
  userInvites: [],
  pendingUserInvites: [],
  invitedUsers: [],
};

const invitationSlice = createSlice({
  name: 'invitations',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createInvitation.pending, (state) => {
        state.currentInvitationLoading = true;
        state.currentInvitationError = null;
      })
      .addCase(createInvitation.fulfilled, (state, action) => {
        state.currentInvitationLoading = false;
        state.currentInvitationError = null;
        state.currentInvitation = action.payload;
      })
      .addCase(createInvitation.rejected, (state, action) => {
        state.currentInvitationLoading = false;
        state.currentInvitationError = action.payload as string;
      })
      .addCase(getUserInvites.fulfilled, (state, action) => {
        state.userInvites = action.payload.data;
        state.pendingUserInvites = action?.payload?.data
          ? action.payload.data.filter((invite) => invite.status === 'pending')
          : [];
      })
      .addCase(getAllInvitedUsers.fulfilled, (state, action) => {
        state.invitedUsers = action?.payload?.data?.filter((data) => data?.status !== 'revoked');
      });
  },
});

export default invitationSlice.reducer;
