import { TTabNavigatorItem } from 'components/views/TabNavigator/types';

export type RoutesProps = {
  path: string;
  isProtected: boolean;
  component: JSX.Element;
} & Partial<{
  withNavbar: boolean;
  isLanding: boolean;
  withLogoHeader: boolean;
  isProtectedWithSession: boolean;
  tabNavigatorTitle: string;
  withTabNavigator: boolean;
  tabNavigatorOptions: TTabNavigatorItem[];
}>;

export enum Routes {
  Error = '*',
  Landing = '/',
  Home = '/home',
  Team = '/team',
  Login = '/login',
  SignUp = '/sign-up',
  SignIn = '/sign-in',
  Success = '/success',
  Profile = '/profile',
  ActivityFeed = '/activity-feed',
  ProcessForm = '/process-form',
  OrganizationsList = '/organizations-list',
  Industriespage = '/industries',
  Hospitality = '/industries/hospitality',
  Ministries = '/industries/congregation',
  Transport = '/industries/transport',
  Construction = '/industries/construction',
  Production = '/industries/production',
  Events = '/industries/events',
  NotFound = '/404',
  ContactUs = '/contact-us',
  Dashboard = '/dashboard',
  DashboardEvents = '/dashboard/events',
  DashboardAnalytics = '/analytics',
  DashboardDevices = '/devices',
  DashboardDevice = '/devices/:deviceId',
  DashboardTeam = '/team',
  DashboardActivities = '/dashboard/activities',
  DashboardSettings = '/settings',
  Help = '/help',
  NotificationSettings = '/settings/notification',
  OrganizationSettings = '/settings/organization',
  BillingSettings = '/settings/billing',
  InvitesSettings = '/settings/invites',
  ProfileSettings = '/settings/profile',
  Invitation = '/invitations',
  OnPremSettings = '/settings/deployment',
  APISettings = '/settings/api',
  SeatMapping = '/seat-mapping',
}
