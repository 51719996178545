import Badge from 'components/shared/Badge';
import { DetailsList } from 'types/components.types';

interface Props {
  data: DetailsList[];
}

const DetailsListComponent = ({ data }: Props): JSX.Element => {
  return (
    <ul className='pt-20 flex flex-col gap-y-16'>
      {data.map((item, index) => (
        <li key={index}>
          <figure className='grid gap-y-7 gap-x-5 grid-cols-1 lg:grid-cols-2'>
            <img
              className={`${index % 2 === 0 ? 'lg:order-3 ' : 'order-1'} mx-auto rounded-[20px]`}
              src={item.image}
              alt={item.title}
            />

            <figcaption className='flex flex-col order-2  gap-y-6 justify-center'>
              <Badge title={item.title} />
              <p className='text-sub-title dark:text-dark-sub-title text-sm sm:text-base'>
                {item.subtitle}
              </p>
            </figcaption>
          </figure>
        </li>
      ))}
    </ul>
  );
};

export default DetailsListComponent;
