export enum ContactUsToastMessages {
  POST_CONTACT_START = 'Sending inquiry',
  POST_CONTACT_SUCCESS = 'Inquiry sent! We will contact you as soon as possible',
  POST_CONTACT_FAILURE = 'Could not send inquiry',
}

export enum SubscriptionUpdateToastMessages {
  SUBSCRIPTION_UPDATE_SUCCESS = 'Subscription plan updated successfully.',
  SUBSCRIPTION_UPDATE_FAILURE = 'Failed to update subscription plan.',
  SUBSCRIPTION_UPDATE_START = 'Updating subscription plan...',
}

export enum PaymentMethodUpdateToastMessages {
  PAYMENT_METHOD_UPDATE_SUCCESS = 'Payment method confirmed successfully.',
  PAYMENT_METHOD_UPDATE_FAILURE = 'Failed to confirm payment method.',
  PAYMENT_METHOD_UPDATE_START = 'Confirming payment method...',
  PAYMENT_METHOD_ADD_SUCCESS = 'Payment method added successfully.',
  PAYMENT_METHOD_DELETE_SUCCESS = 'Payment method removed successfully.',
  PAYMENT_METHOD_DELETE_FAILURE = 'Could not remove payment method. Please try again.',
  PAYMENT_METHOD_DELETE_START = 'Removing payment method ...',
  PAYMENT_METHOD_SET_DEFAULT_START = 'Setting payment method as default ...',
  PAYMENT_METHOD_SET_DEFAULT_SUCCESS = 'Default payment method successfully updated',
  PAYMENT_METHOD_SET_DEFAULT_FAILURE = 'Could not update default payment method',
}

export enum TeamToastMessages {
  TEAM_CREATION = 'Team creation',
  TEAM_CREATION_SUCCESS = 'Team created successfully.',
  TEAM_CREATION_FAILURE = 'Failed to create Team.',
  TEAM_EDIT_SUCCESS = 'Team edited successfully.',
  TEAM_EDIT_FAILURE = 'Failed to edit Team.',
  TEAM_EDIT_START = 'Edit the TEAM',
  TEAM_REMOVAL_SUCCESS = 'Team removed successfully.',
  TEAM_REMOVAL_FAILURE = 'Failed to remove Team.',
  TEAM_REMOVAL_START = 'Removing the Team',
  TEAM_UPDATE_FAILURE = 'Failed to update team',

}

export enum OrganizationMemberToastMessages {
  ORGANIZATION_MEMBER_REMOVAL_START = 'The organization member is being removed',
  ORGANIZATION_MEMBER_REMOVAL_SUCCESS = 'The Organization member was removed successfully',
  ORGANIZATION_MEMBER_REMOVAL_FAILURE = 'Failed to remove the organization member',
  INVITATION_SEND_START = 'The invitation request is being sent',
  INVITATION_SEND_SUCCESS = 'The Invitation request was sent successfully',
  INVITATION_SEND_FAIL = 'The Invitation request sending failed',
  MEMBER_UPDATE_START = 'The Member is being updated',
  MEMBER_UPDATE_SUCCESS = 'The Member  was updated successfully',
  MEMBER_UPDATE_FAIL = 'Failed to update the member',
}

export enum ProfileInformationUpdateToastMessages {
  PROFILE_UPDATE_START = 'Your profile information is being updated',
  PROFILE_UPDATE_SUCCESS = 'You profile information was updated successfully',
  PROFILE_UPDATE_FAILURE = 'Failed to update your profile information',
  PROFILE_IMAGE_UPDATE_START = 'Your profile picture is being updated',
  PROFILE_IMAGE_UPDATE_SUCCESS = 'Your profile picture was updated successfully',
  PROFILE_IMAGE_UPDATE_FAILURE = 'Failed to update your profile picture',
}

export enum OrganizationInformationUpdateToastMessages {
  ORGANIZATION_UPDATE_START = 'The organization information is being updated',
  ORGANIZATION_UPDATE_SUCCESS = 'The Organization information was updated successfully',
  ORGANIZATION_UPDATE_FAILURE = 'Failed to update the Organization information',
  NO_ORGANIZATION = 'Please create an organization.',
  ORGANIZATION_PENDING = 'You have organization in pending state. Please wait.',
  SINGLE_ORGANIZATION_PENDING = 'Your organization is still being created. Please wait.',
}

export enum SignInToastMessages {
  SIGNIN_SUCCESS = 'Welcome back! You are now signed in.',
  SIGNIN_FAILURE = 'Oops! Unable to sign in. Please check your credentials and try again.',
  SIGNIN_START = 'Signing you in. Please wait...',
}

export enum SignUpToastMessages {
  SIGNUP_START = 'Signing you up. Please wait...',
  SIGNUP_SUCCESS = 'Sign up successful',
  SIGNUP_FAILURE = 'Oops! Unable to sign up. Please check your credentials and try again.',
}

export enum NoticeToastMessages {
  NOTICE_CREATION = ' A Notice creation',
  NOTICE_CREATION_SUCCESS = 'The Notice was created successfully',
  NOTICE_CREATION_FAILURE = 'Failed to create a notice',
  NOTICE_EDIT_SUCCESS = 'The Notice was edited successfully',
  NOTICE_EDIT_FAILURE = 'Failed to edit the notice',
  NOTICE_EDIT_START = 'The notice is being edited',
  NOTICE_REMOVAL_SUCCESS = 'The Notice  was removed successfully',
  NOTICE_REMOVAL_FAILURE = 'Failed to remove the notice',
  NOTICE_REMOVAL_START = 'The notice is being removed',
}

export enum ActivityFeedToastMessages {
  POST_CREATION = 'A Post creation',
  POST_CREATION_SUCCESS = 'The Post was created successfully',
  POST_CREATION_FAILURE = 'Failed to create a post',
  ASK_AI_FAILURE = 'Could not communicate with AI agent. Please try again.',
  POST_EDIT_SUCCESS = 'The Post was edited successfully',
  POST_EDIT_FAILURE = 'Failed to edit the post',
  POST_REMOVAL_SUCCESS = 'The Post was removed successfully',
  POST_REMOVAL_FAILURE = 'Failed to remove the post',
  POST_EDIT_START = 'The post is being edited',
  POST_REMOVAL_START = 'The post is being removed',
  COMMENT_CREATION = 'Posting comment...',
  COMMENT_CREATION_SUCCESS = 'The Comment was sent successfully',
  COMMENT_CREATION_FAILURE = 'Failed to send a comment',
}

export enum ReportSendingToastMessages {
  REPORT_SENDING_SUCCESS = 'The Report was sent successfully',
  REPORT_SENDING_FAILURE = 'Failed to send the report',
  REPORT_SENDING_START = 'The report is being sent',
}

export enum BillingUpdateToastMessages {
  BILLING_UPDATE_SUCCESS = 'The Billing details were updated successfully',
  BILLING_UPDATE_FAILURE = 'Failed to update the billing details',
  BILLING_UPDATE_START = 'The Billing details are being updated',
}

export enum UploadMessages {
  IMAGE_SIZE_EXCEEDED = 'Image size exceeds the 5MB limit. Please reduce the file size and try again.',
  VIDEO_SIZE_EXCEEDED = 'Video size exceeds the 10GB limit. Please compress or reduce the file size and try again.',
  LOGO_SIZE_EXCEEDED = 'Logo size exceeds the 200KB limit. Please reduce the file size and try again.',
}

export enum InviteToastMessages {
  INVITATION_ACCEPTANCE_START = 'Accepting invite to join',
  INVITATION_ACCEPTANCE_SUCCESS = 'You have successfully joined',
  INVITATION_ACCEPTANCE_FAILURE = 'Could not accept invite',
  INVITATION_REJECTION_START = 'Rejecting invite',
  INVITATION_REJECTION_SUCCESS = 'Invite successfully rejected',
  INVITATION_REJECTION_FAILURE = 'Could not reject invite',
  EMAIL_INVITATION_REJECTION_FAILURE = 'Could not reject invite. Please click the rejection button in your email to try again.',
}

export enum EventToastMessages {
  EVENT_CREATION = 'Please wait while we create your event',
  EVENT_CREATION_SUCCESS = 'Event created successfully.',
  EVENT_CREATION_FAILURE = 'Failed to create event.',
  EVENT_EDIT_SUCCESS = 'Event edited successfully.',
  EVENT_EDIT_FAILURE = 'Failed to edit event.',
  EVENT_EDIT_START = 'Editing the event',
  EVENT_REMOVAL_SUCCESS = 'Event removed successfully.',
  EVENT_REMOVAL_FAILURE = 'Failed to remove event.',
  EVENT_REMOVAL_START = 'Removing the event',
}

export enum DeviceToastMessages {
  DEVICE_CREATION = 'Please wait while we create your Device',
  DEVICE_CREATION_SUCCESS = 'Device created successfully.',
  DEVICE_CREATION_FAILURE = 'Failed to create Device.',
  DEVICE_EDIT_SUCCESS = 'Device edited successfully.',
  DEVICE_EDIT_FAILURE = 'Failed to edit Device.',
  DEVICE_EDIT_START = 'Editing the Device',
  DEVICE_REMOVAL_SUCCESS = 'Device removed successfully.',
  DEVICE_REMOVAL_FAILURE = 'Failed to remove Device.',
  DEVICE_REMOVAL_START = 'Removing the Device',
}

export enum AnalyticsToastMessages {
  HEADCOUNT_REPORT_DOWNLOAD_START = 'Downloading analytics report',
  HEADCOUNT_REPORT_DOWNLOAD_SUCCESS = 'Analytics report downloaded',
  HEADCOUNT_REPORT_DOWNLOAD_FAILURE = 'Could not download report',
}
