import { FC } from 'react';
import cn from 'classnames';
import { SvgIconProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
export const NavItem: FC<{
  route?: string;
  active?: boolean;
  title: string;
  icon?: React.FC<SvgIconProps>;
  isLogout?: boolean;
  action?: any;
  id?: string;
}> = ({ route, active, title, icon: Icon, isLogout, action, id }) => {
  const navigate = useNavigate();

  function goToPage() {
    if (route === window.location.pathname) navigate(0); // re-render page
    else navigate(route ?? '#');
  }

  return (
    <button onClick={goToPage} id={id}>
      <li
        className={cn(
          'flex items-center gap-3 p-4 w-full cursor-pointer hover:bg-[#F7F7F7] hover:text-darkBg  transition-all rounded-s-lg  dark:hover:bg-dark-card-bg-old dark:hover:text-white',
          {
            'dark:bg-dark-card-bg-old bg-[#F7F7F7] border-r-2 border-r-accent dark:border-r-dark-text dark:text-white':
              active,
          },
        )}
        onClick={action}
      >
        {Icon && (
          <Icon
            className={cn('text-[30px]', {
              'text-accent dark:text-dark-text': active,
              'text-red-500': isLogout,
            })}
          />
        )}

        <p
          className={cn('font-semibold text-[18px]', {
            'text-red-500': isLogout,
          })}
        >
          {title}
        </p>
      </li>
    </button>
  );
};
