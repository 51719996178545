import { TButtonStylesParams } from './types';

export const sxButtonStyles = ({
  cursor,
  iconWidth,
  iconHeight,
  isUppercase,
  iconMarginLeft,
  iconMarginRight,
  backgroundColor,
  startIconLeftPosition,
}: TButtonStylesParams) => ({
  cursor,
  textTransform: isUppercase ? 'uppercase' : 'unset !important',

  '& .MuiButton-startIcon': {
    marginLeft: startIconLeftPosition,
  },

  '& .MuiButton-iconSizeMedium': {
    width: iconWidth,
    height: iconHeight,
    marginRight: iconMarginRight,
    marginLeft: iconMarginLeft,
  },

  '&:hover': { backgroundColor: backgroundColor },
  '&:disabled': { opacity: 0.6, backgroundColor: backgroundColor },
});
