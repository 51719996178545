import { UserRole } from 'types/global/userRoles';
import { TUserDefinedRole } from 'store/slices/authSlice/types';

const getUserPermissions = (roles: TUserDefinedRole[], userRole: string) => {
  if (userRole === 'owner') {
    return {
      isSendInvite: true,
      isAccessToTeams: true,
      isAccessToNotices: true,
      isAccessToReports: true,
      isAccessToCatalogs: true,
      isAccessToInvoices: true,
      isAccessToInventory: true,
      isAccessToLivestream: true,
      isAccessToCustomerDatabase: true,
      isAccessToWorkOrdersAndScheduling: true,
      isAccessToDocumentationAndReports: true,
      isCreationOfEstimatesAndAgreements: true,
    };
  } else if (userRole === 'employee') {
    const rolePermissionsEmployee = {
      isSendInvite: true,
      isAccessToTeams: false,
      isAccessToNotices: false,
      isAccessToReports: false,
      isAccessToCatalogs: false,
      isAccessToInvoices: true,
      isAccessToInventory: false,
      isAccessToLivestream: true,
      isAccessToCustomerDatabase: false,
      isAccessToDocumentationAndReports: false,
      isAccessToWorkOrdersAndScheduling: false,
      isCreationOfEstimatesAndAgreements: false,
    };

    roles?.forEach((userRole) => {
      switch (userRole.role) {
        case UserRole.AllowInviteUsers:
          rolePermissionsEmployee.isSendInvite = true;
          break;
        // case UserRole.TeamsEditor:
        //   rolePermissionsEmployee.isAccessToTeams = true;
        //   break;
        // case UserRole.TeamsViewer:
        //   rolePermissionsEmployee.isAccessToTeams = true;
        //   break;
      }
    });

    return rolePermissionsEmployee;
  } else {
    const rolePermissionsManager = {
      isSendInvite: false,
      isAccessToTeams: true,
      isAccessToNotices: true,
    };

    roles?.forEach((userRole) => {
      switch (userRole.role) {
        case UserRole.AllowInviteUsers:
          rolePermissionsManager.isSendInvite = true;
          break;
      }
    });

    return rolePermissionsManager;
  }
};

export default getUserPermissions;
