import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

import { eventsApi } from 'api';
import {
  TAllEventsParams,
  TCurrentEventsParams,
  TEventsDataParams,
  TUpdateEventsBody,
} from 'api/events/types';
import { EventToastMessages } from 'constants/ToastMessages';

export const getEventCounts = createAsyncThunk('events/getEventCounts', async () => {
  try {
    const response = await eventsApi.getEventCountsRequest();
    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
  }
});

export const getCurrentEvents = createAsyncThunk(
  'events/getCurrentEvents',
  async (params: TCurrentEventsParams) => {
    try {
      const response = await eventsApi.getCurrentEventsRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
    }
  },
);

export const getAllEvents = createAsyncThunk(
  'events/getAllEvents',
  async (params: TAllEventsParams) => {
    try {
      const response = await eventsApi.getAllEventsRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
    }
  },
);

export const getEventsData = createAsyncThunk(
  'events/getEventsData',
  async (params?: TEventsDataParams) => {
    try {
      const response = await eventsApi.getEventsDataRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
    }
  },
);

export const createEvent = createAsyncThunk(
  'events/createEvent',
  async (body: any, { dispatch }) => {
    const toastId = toast.loading(EventToastMessages.EVENT_CREATION);
    try {
      const response = await eventsApi.createEventRequest(body);
      await dispatch(getAllEvents({ sort_by: 'start_date', timeframe: 'Future' })); // Update list of upcoming events
      await dispatch(
        getCurrentEvents({
          datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          sort_by: 'start_time',
        }),
      ); // Update list of ongoing events
      await dispatch(getEventCounts()); // Update number of events
      toast.success(EventToastMessages.EVENT_CREATION_SUCCESS, {
        id: toastId,
      });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      toast.error(EventToastMessages.EVENT_CREATION_FAILURE, { id: toastId });
    }
  },
);

export const editEvent = createAsyncThunk('events/editEvent', async (body: any, { dispatch }) => {
  const toastId = toast.loading(EventToastMessages.EVENT_EDIT_START);
  try {
    const response = await eventsApi.editEventRequest(body);
    await dispatch(getAllEvents({ sort_by: 'start_date', timeframe: 'Future' }));
    await dispatch(
      getCurrentEvents({
        datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sort_by: 'start_time',
      }),
    ); // Update list of ongoing events
    await dispatch(getEventCounts()); // Update number of events

    toast.dismiss(toastId);
    toast.success(EventToastMessages.EVENT_EDIT_SUCCESS, {
      id: toastId,
      autoClose: 3000,
    } as any);

    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    toast.dismiss(toastId);
    toast.error(EventToastMessages.EVENT_EDIT_FAILURE);
  }
});
export const getEventByID = createAsyncThunk('events/getEventByID', async (id: number) => {
  try {
    const response = await eventsApi.getEventByIdRequest(id);
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
  }
});

export const updateEvents = createAsyncThunk(
  'events/updateEvents',
  async (body: TUpdateEventsBody) => {
    try {
      const response = await eventsApi.updateEventRequest(body);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
    }
  },
);
