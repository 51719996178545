export const defaultSteps = [
  {
    selector: '#switch-org',
    content: 'Do you have more than one organization? switch here!',
  },
];
export const dashboardSteps = [
  {
    selector: '#switch-org',
    content: 'Do you have more than one organization? switch here!',
  },
  {
    selector: '#weather',
    content: "Check your organization's weather",
  },
  {
    selector: '#overviewDetails',
    content: 'Quick overview of realtime count, devices and events',
  },
  {
    selector: '#eventsGraph',
    content: 'This graph captures real time headcount of events every 5 mins',
  },
  {
    selector: '#eventsDetails',
    content: 'This section shows all records of ongoing, upcoming and past events',
  },
  {
    selector: '#analyticsDetails',
    content: 'This graph captures real time headcount of events every 5 mins',
  },
  {
    selector: '#devicesDetails',
    content: 'This section captures devices linked to an event',
  },
  {
    selector: '#activityFeedDetails',
    content: 'This records activities within the organization',
  },
  {
    selector: '#settingsLink',
    content: 'Update your profile, notification, organization and billing details',
  },
  {
    selector: '#activityLink',
    content: 'Create posts, like and comment on posts within the organization',
  },
  {
    selector: '#teamLink',
    content: 'Create team and add team members within your organization',
  },
  {
    selector: '#devicesLink',
    content: 'This records devices within the organization',
  },
  {
    selector: '#eventLink',
    content: 'Create, update and attach devices to your event',
  },
];

export const eventTourStep = [
  {
    selector: '#addEvent',
    content: 'Click here to add your event(s)',
  },
  {
    selector: '#eventListSection',
    content: 'List of all your upcoming, ongoing and past events shows up here!',
  },
];

export const deviceTourStep = [
  {
    selector: '#addDevice',
    content: 'Click here to add a device',
  },
  {
    selector: '#devicesList',
    content: 'A snapshot of all your devices (both online and offline) shows up here',
  },
];

export const activityFeedTourSteps = [
  {
    selector: '#addActivityPost',
    content: 'Post about something',
  },
  {
    selector: '#imageUploadLink',
    content: 'Upload images',
  },
  {
    selector: '#askHawkeyeVisionTech',
    content: 'Want to create an event, device etc? Ask HawkeyeVision AI Agent',
  }
];

export const teamsTourSteps = [
  {
    selector: '#addTeam',
    content: 'Click here to add a team!',
  },
  {
    selector: '#teamMemberSection',
    content: 'A list of your team members shows here!',
  },
];

export const settingsProfileSteps = [
  {
    selector: '#profileArea',
    content: 'You can update your profile information such as address, logo, name etc.',
  },
  {
    selector: '#EditProfile',
    content: 'Click here to make changes',
  },
  {
    selector: '#editProfileImageUpload',
    content: 'Click here to update profile Image',
  },
];

export const inviteProfileSteps = [
  {
    selector: '#inviteATeamMember',
    content: 'Invite a team member',
  },
  // {
  //   selector: '#settingsTeamMemberSection',
  //   content: 'A list of team members would show here!',
  // },
];

export const settingsOrgSteps = [
  {
    selector: '#orgArea',
    content: 'You can update your organization information such as address, logo, name etc.',
  },
  {
    selector: '#editOrgSettings',
    content: 'Click here to make changes',
  },
  {
    selector: '#editOrgImageUpload',
    content: 'Click here to update profile Image',
  },
];

export const billingsSettingsSteps = [
  {
    selector: '#changePlanTour',
    content: 'You can Change your subscription plan here.',
  },
  {
    selector: '#billingsElipsesTour',
    content: 'Click here to change default payment, edit billing address or delete billing information',
  },
  {
    selector: '#downloadReceiptBillingTour',
    content: 'Click here download your receipt at anytime!',
  },
];

export const notificationTabSteps = [
  {
    selector: '#notificationTabSteps',
    content: 'Update notifications for comments on your posts and replies to our comments, tags, post or story, updates you might have missed and more.',
  },
];

