import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import store from 'app/store';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { getNewToken } from 'store/thunks';

interface AuthHeader {
  Authorization: string;
}

const baseURL = process.env.REACT_APP_BASE_URL;
export const client: AxiosInstance = axios.create({
  baseURL,
});

client.interceptors.request.use((config: any) => {
  const token =
    BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
    BrowserStorageService.get(BrowserStorageKeys.AccessToken, {
      session: true,
    });

  if (token) {
    config.headers = {
      ...config.headers,
      ...({
        Authorization: `Bearer ${JSON.parse(token)}`,
      } as AuthHeader),
    };
  }
  return config;
});

client.interceptors.response.use((response: AxiosResponse) => {
  return response;
});

client.interceptors.response.use(null, async (error) => {
  if (error.response && error.response.status === 403) {
    try {
      await store.dispatch(getNewToken());
      const token =
        BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
        BrowserStorageService.get(BrowserStorageKeys.AccessToken, {
          session: true,
        });
      if (token) {
        const config = error.config;
        config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
        return axios.request(config);
      }
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  }
  return Promise.reject(error);
});
