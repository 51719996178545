import localforage from 'localforage';
import { useCallback, useEffect, useRef } from 'react';

import { BrowserStorageKeys, BrowserStorageService } from 'services';

export default function useClearStorage(clearOnMount = true) {
  const darkMode = useRef(localStorage.getItem('darkMode') ?? 'false');
  const invitationToken = useRef(BrowserStorageService.get(BrowserStorageKeys.InvitationToken));
  const invitationStatus = useRef(BrowserStorageService.get(BrowserStorageKeys.InvitationStatus));
  const invitationEmail = useRef(BrowserStorageService.get(BrowserStorageKeys.InvitationEmail));

  const clearStorage = useCallback(() => {
    localStorage.clear();
    localStorage.setItem('darkMode', darkMode.current); // keep dark mode

    // keep invitation token if we have it
    if (invitationToken.current) {
      BrowserStorageService.set(BrowserStorageKeys.InvitationToken, invitationToken.current);
    }

    // keep invitation status if we have it
    if (invitationStatus.current) {
      BrowserStorageService.set(BrowserStorageKeys.InvitationStatus, invitationStatus.current);
    }

    // keep invitation email if we have it
    if (invitationEmail.current) {
      BrowserStorageService.set(BrowserStorageKeys.InvitationEmail, invitationEmail.current);
    }

    document.body.classList.toggle('dark', darkMode.current === 'true');

    sessionStorage.clear();
    localforage.clear();
  }, []);

  useEffect(() => {
    if (clearOnMount) clearStorage();
  }, [clearOnMount, clearStorage]);

  return { clearStorage };
}
