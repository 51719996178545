import { PencilSquareIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import React from 'react';

type EventCardProps = {
  img?: string;
  dateTime: string;
  title: string;
  className?: string;
  isPast?: boolean;
  totalHeadCount?: number;
  getEventByIdEdit?: any;
  event_id?: number;
  userWritePermission?: boolean;
};

const HIDE = true;

const EventCard: React.FC<EventCardProps> = ({
  img,
  dateTime,
  title,
  className,
  isPast,
  totalHeadCount,
  getEventByIdEdit,
  event_id,
  userWritePermission,
}) => {
  // const formattedTime = dayjs(date).format('YYYY-MM-DD') + 'T' + time;
  const date = dayjs(dateTime).format('MMMM D, YYYY');
  const localTime = dayjs(dateTime).format('hh:mm A');

  if (isPast) {
    return (
      <div className={`w-full md:w-[380px] p-5 rounded-[12px] gap-y-6  bg-white , ${className}`}>
        <img src={img} alt='' className='w-full m-h-[158px] rounded-[10px]' />
        <div className='flex items-center gap-5 text-[#838383] mt-4'>
          <p className='text-[15px] text-center font-light'>{date}</p>
          <div className='bg-[#838383] h-[6px] w-[6px] rounded-full' />
          <p className='text-[15px] text-center font-light'>{localTime}</p>
        </div>
        <p className='text-[20px] mt-4'>{title}</p>
        {typeof totalHeadCount === 'number' ? (
          <div className='flex justify-end my-4'>
            <p>
              Total Count: <span className='text-accent font-bold'>{totalHeadCount}</span>
            </p>
          </div>
        ) : null}
        {HIDE ? null : (
          <div className='grid grid-cols-4 gap-3'>
            <img src='/images/card-sub-img.svg' alt='' className='w-full h-[43px] ' />
            <img src='/images/card-sub-img.svg' alt='' className='w-full h-[43px] ' />
            <img src='/images/card-sub-img.svg' alt='' className='w-full h-[43px] ' />
            <div className='bg-light-blue-bg flex items-center justify-center'>
              <p>+ 28</p>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`w-[380px] p-5  gap-y-6 rounded-[12px]  bg-white , ${className}`}>
      <img src={img} alt='' className='w-full m-h-[158px] rounded-[10px]' />
      <div className='flex items-center gap-5 text-[#838383] mt-4'>
        <p className='text-[15px] text-center font-light'>{date}</p>
        <div className='bg-[#838383] h-[6px] w-[6px] rounded-full' />
        <p className='text-[15px] text-center font-light'>{localTime}</p>
      </div>
      <p className='text-[20px]  mt-4'>{title}</p>
      {userWritePermission && (
        <button
          onClick={() => getEventByIdEdit(event_id)}
          type='button'
          className='flex items-center py-3 gap-4 px-6 rounded-md bg-light-blue-bg dark:border-white  text-accent dark:bg-btn-dark  dark:text-white group ml-auto'
        >
          <PencilSquareIcon className='w-[16px]' />
          Edit Event
        </button>
      )}
    </div>
  );
};

EventCard.defaultProps = {
  className: '',
};

export default EventCard;
