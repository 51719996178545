import { WeatherState } from 'weather-icons-animated';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

import { TOrganization } from 'store/slices/organizationsSlice/types';
import { mastercard, visa, amex, discover, dinersClub, unionPay, jcb } from 'assets/images';
import { TPostLinkType, TPostMetadata } from 'store/slices/activitiesSlices/types';
import { Routes, TChangeInputEvent } from 'types';
import { sizeLimits } from 'constants/SizeLimits';
import { UploadMessages } from 'constants/ToastMessages';

export function getLocalTime(time: string) {
  const splitTime = time.split(':');
  const hours = parseInt(splitTime[0]);
  const mins = parseInt(splitTime[1]);
  const today = new Date();
  today.setHours(hours, mins);
  return dayjs(today).format('h[:]mm a');
}

export function getDateInTimeZone(timestamp: string | Date) {
  const currentDate = new Date(timestamp);

  return new Date(
    currentDate.setHours(currentDate.getHours() - currentDate.getTimezoneOffset() / 60),
  );
}

export function getRelativeTime(timestamp: string | Date) {
  const now = new Date();
  const then = getDateInTimeZone(timestamp);
  const differenceInSeconds = (now.getTime() - then.getTime()) / 1000; // difference in seconds

  const aMinute = 60;
  const anHour = aMinute * 60;
  const aDay = anHour * 24;
  const aWeek = aDay * 7;
  const aMonth = aDay * 28;
  const aYear = aDay * 365;
  const minutesAgo = Math.floor(differenceInSeconds / 60);
  const hoursAgo = Math.floor(differenceInSeconds / anHour);
  const daysAgo = Math.floor(differenceInSeconds / aDay);
  const weeksAgo = Math.floor(differenceInSeconds / aWeek);
  const monthsAgo = Math.floor(differenceInSeconds / aMonth);
  const yearsAgo = Math.floor(differenceInSeconds / aYear);

  switch (true) {
    case differenceInSeconds < aMinute:
      return 'a moment ago';
    case differenceInSeconds < anHour:
      return minutesAgo + ` minute${minutesAgo > 1 ? 's' : ''} ago`;
    case differenceInSeconds < aDay:
      return hoursAgo + ` hour${hoursAgo > 1 ? 's' : ''} ago`;
    case differenceInSeconds < aWeek:
      return daysAgo + ` day${daysAgo > 1 ? 's' : ''} ago`;
    case differenceInSeconds < aMonth:
      return weeksAgo + ` week${weeksAgo > 1 ? 's' : ''} ago`;
    case differenceInSeconds < aYear:
      return monthsAgo + ` month${monthsAgo > 1 ? 's' : ''} ago`;
    default:
      return yearsAgo + ` year${yearsAgo > 1 ? 's' : ''} ago`;
  }
}

export const getWeatherIcon = (weatherId = 800): WeatherState => {
  switch (weatherId) {
    case 300:
    case 301:
    case 302:
    case 310:
    case 311:
    case 312:
    case 313:
    case 314:
    case 321:
    case 500:
    case 501:
    case 520:
    case 521:
      return 'rainy';
    case 502:
    case 503:
    case 504:
    case 522:
    case 531:
      return 'pouring';
    case 210:
    case 211:
    case 212:
    case 221:
      return 'lightning';
    case 200:
    case 201:
    case 202:
    case 230:
    case 231:
    case 232:
      return 'lightning-rainy';
    case 511:
    case 615:
    case 616:
      return 'snowy-rainy';
    case 600:
    case 601:
    case 602:
    case 611:
    case 612:
    case 613:
    case 620:
    case 621:
    case 622:
      return 'snowy';
    case 771:
    case 781:
      return 'windy';
    case 701:
    case 711:
    case 721:
    case 731:
    case 741:
    case 751:
    case 761:
    case 762:
      return 'fog';
    case 801:
    case 802:
      return 'partlycloudy';
    case 803:
    case 804:
      return 'cloudy';
    default:
      return 'sunny';
  }
};

export const getAvaragePoint = (points: any) => {
  let totalX = 0;
  let totalY = 0;
  for (let i = 0; i < points.length; i += 2) {
    totalX += points[i];
    totalY += points[i + 1];
  }
  return {
    x: totalX / (points.length / 2),
    y: totalY / (points.length / 2),
  };
};
export const getDistance = (node1: any, node2: any) => {
  const diffX = Math.abs(node1[0] - node2[0]);
  const diffY = Math.abs(node1[1] - node2[1]);
  const distaneInPixel: any = Math.sqrt(diffX * diffX + diffY * diffY);
  return Number.parseFloat(distaneInPixel).toFixed(2);
};
export const dragBoundFunc = (stageWidth: any, stageHeight: any, vertexRadius: any, pos: any) => {
  let x = pos.x;
  let y = pos.y;
  if (pos.x + vertexRadius > stageWidth) x = stageWidth;
  if (pos.x - vertexRadius < 0) x = 0;
  if (pos.y + vertexRadius > stageHeight) y = stageHeight;
  if (pos.y - vertexRadius < 0) y = 0;
  return { x, y };
};
export const minMax = (points: any) => {
  return points.reduce((acc: any, val: any) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
    return acc;
  }, []);
};

export const isOrganisationCreated = (organisation: TOrganization) =>
  organisation.resource_status === 'created';

export const isOrganisationPending = (organisation: TOrganization) =>
  organisation.resource_status === 'pending' || organisation.resource_status === 'creating';

export function secondsToDateTime(seconds: number) {
  const date = new Date(1970, 0, 1); // Epoch
  date.setSeconds(seconds);
  return date;
}

export function getCardLogo(cardType: string) {
  switch (cardType) {
    case 'visa':
      return visa;
    case 'mastercard':
      return mastercard;
    case 'amex':
      return amex;
    case 'discover':
      return discover;
    case 'diners':
      return dinersClub;
    case 'unionpay':
      return unionPay;
    case 'jcb':
      return jcb;
    default:
      return mastercard;
  }
}

export const getAnchorTag = (href: string, text: string) =>
  `<a href="${href}" class="text-accent dark:text-dark-text cursor-pointer">${text}</a>`;

export const removeNonAlphanumeric = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '');

export function replaceActivityEntitiesWithLinks(post: string, metadata: TPostMetadata) {
  const entitiesRegex = /\{\{([a-zA-Z\s]*)\}\}/g; // entities in posts have a string enclosed in curly brackets e.g. "{{ user }}"
  const entities = post.match(entitiesRegex);
  let updatedPost = post;

  if (entities) {
    entities.forEach((entity) => {
      switch (entity) {
        case '{{ device }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(
            entity,
            getAnchorTag(
              `${Routes.DashboardDevices}/${metadata[entityType].id}`,
              metadata[entityType].name ?? '',
            ),
          );

          break;
        }
        case '{{ event }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(
            entity,
            getAnchorTag(`${Routes.DashboardEvents}`, metadata[entityType].name ?? ''),
          );

          break;
        }
        case '{{ team }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(
            entity,
            getAnchorTag(`${Routes.DashboardTeam}`, metadata[entityType].name ?? ''),
          );

          break;
        }
        case '{{ post }}':
        case '{{ comment }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(
            entity,
            getAnchorTag(`${Routes.ActivityFeed}`, metadata[entityType].name ?? ''),
          );

          break;
        }
        case '{{ user }}':
        case '{{ creator }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(
            entity,
            getAnchorTag('#', '@' + metadata[entityType].name ?? ''),
          );

          break;
        }
        default:
          updatedPost.replace(entity, getAnchorTag('#', entity));
          break;
      }
    });
  }

  return updatedPost;
}

export function replaceActivityEntitiesWithNames(post: string, metadata: TPostMetadata) {
  const entitiesRegex = /\{\{([a-zA-Z\s]*)\}\}/g; // entities in posts have a string enclosed in curly brackets e.g. "{{ user }}"
  const entities = post.match(entitiesRegex);
  let updatedPost = post;

  if (entities) {
    entities.forEach((entity) => {
      switch (entity) {
        case '{{ device }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(entity, metadata[entityType].name ?? '');

          break;
        }
        case '{{ event }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(entity, metadata[entityType].name ?? '');

          break;
        }
        case '{{ team }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(entity, metadata[entityType].name ?? '');

          break;
        }
        case '{{ post }}':
        case '{{ comment }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(entity, metadata[entityType].name ?? '');

          break;
        }
        case '{{ user }}':
        case '{{ creator }}': {
          const entityType = removeNonAlphanumeric(entity) as TPostLinkType;
          updatedPost = updatedPost.replace(entity, '@' + metadata[entityType].name ?? '');

          break;
        }
        default:
          break;
      }
    });
  }

  return updatedPost;
}

export function handleImageUpload(event: TChangeInputEvent, callback: (file: File) => void) {
  const file = event?.target?.files?.[0];
  if (Number(file?.size) > sizeLimits.logo) {
    toast(UploadMessages.LOGO_SIZE_EXCEEDED);
    return;
  }

  if (file) {
    callback?.(file);
  }
}

export function downloadBlob(blob: string, fileType: string, fileName: string) {
  const data = new Blob([blob], { type: fileType });
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
}
