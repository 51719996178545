import { combineReducers } from 'redux';

import authSlice from './authSlice';
import activitiesSlices from './activitiesSlices';
import organizationsSlice from './organizationsSlice';
import subscriptionSlice from './subscriptionSlice';
import dashboardSlice from './dashboardSlice';
import devicesSlice from './devicesSlice';
import eventsSlice from './eventsSlice';
import teamsSlice from './teamsSlice';
import weatherSlice from './weather';
import invitationSlice from './invitationsSlice';
import themeSlice from './themeSlice';
import ApiSlice from './apiSlices';

export const rootReducer = combineReducers({
  auth: authSlice,
  invitations: invitationSlice,
  activities: activitiesSlices,
  organizations: organizationsSlice,
  subscriptions: subscriptionSlice,
  dashboard: dashboardSlice,
  devices: devicesSlice,
  events: eventsSlice,
  teams: teamsSlice,
  weather: weatherSlice,
  theme: themeSlice,
  api: ApiSlice,
});
