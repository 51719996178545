interface Props {
  title: string;
  subtitle?: string;
  imageURL: string;
}

const IndustriesHeroSection = ({
  imageURL,
  subtitle = 'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
  title,
}: Props): JSX.Element => {
  return (
    <>
      <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold font-Avenir-Next-Pro text-center'>
        {title}
      </h1>
      <h2 className=' text-sub-title dark:text-dark-sub-title text-sm sm:text-base font-[350]  max-w-[46.25rem] mt-6 font-Avenir-Next-Pro'>
        {subtitle}
      </h2>
      <img
        className='w-full max-w-[34.625rem] my-10 rounded-[20px] mx-auto'
        src={imageURL}
        alt={title}
      />
    </>
  );
};

export default IndustriesHeroSection;
