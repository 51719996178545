const IndustryRetailIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='none' viewBox='0 0 24 25'>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M2 2.5h1.74c1.08 0 1.93.93 1.84 2l-.83 9.96a2.796 2.796 0 002.79 3.03h10.65c1.44 0 2.7-1.18 2.81-2.61l.54-7.5c.12-1.66-1.14-3.01-2.81-3.01H5.82M16.25 22.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM8.25 22.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM9 8.5h12'
      ></path>
    </svg>
  );
};

export default IndustryRetailIcon;
