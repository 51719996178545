import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDarkMode } from 'hooks/useDarkMode';

interface RowRadioButtonsGroupProps {
  label: string;
  options: Array<{ value: string; label: string; disabled?: boolean }>;
  value: string;
  onChange: (value: string) => void;
  vertical?: boolean; // New prop to control vertical alignment
}

const RowRadioButtonsGroup: React.FC<RowRadioButtonsGroupProps> = ({
  label,
  options,
  value,
  onChange,
  vertical = false, // Default value is horizontal
}) => {
  const { darkMode } = useDarkMode();
  return (
    <FormControl>
      <FormLabel
        sx={{
          color: darkMode ? 'white' : 'inherit',
          borderColor: darkMode ? 'white' : 'black',
          borderStyle: 'none',
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup row={!vertical} value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={
              <Radio
                sx={{
                  color: darkMode ? 'white' : 'defaultColor',
                }}
              />
            }
            label={option.label}
            disabled={option.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RowRadioButtonsGroup;
