import React, { ReactNode, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface SidebarModalProps {
  component: ReactNode;
  show: boolean;
  className?: string;
  setShow: (value: boolean) => void;
}

const SidebarModal: React.FC<SidebarModalProps> = ({ component, show, className, setShow }) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') setShow(false);
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  });

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setShow(false);
  };

  return (
    <AnimatePresence>
      {show ? (
        <motion.div
          initial={{ opacity: 0, right: '-100%' }}
          animate={{ opacity: 1, right: 0 }}
          exit={{ opacity: 0, right: '-100%' }}
          transition={{ duration: 0.2 }}
          className='fixed inset-0 z-[99] flex bg-[rgba(0,0,0,0.5)]'
          onClick={handleClickOutside}
          role='dialog'
          aria-hidden
        >
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className={`absolute h-full max-h-full md:rounded-bl-[20px]  dark:bg-darkBg bg-white px-[30px] py-[48px] ${className} right-0 w-full max-w-[712px] overflow-y-auto`}
            role='dialog'
            aria-hidden
          >
            {component}
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

SidebarModal.defaultProps = {
  className: '',
};

export default SidebarModal;
