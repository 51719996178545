import React from 'react';

const BlurBlue = () => {
  return (
    <div className='absolute inset-0 flex  top-60 items-baseline'>
      <div className='w-[300px] h-[300px] bg-primary opacity-50 bg-blur' />
    </div>
  );
};

export default BlurBlue;
