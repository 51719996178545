import { createSlice } from '@reduxjs/toolkit';
import { TInitialState } from './types';
import { generateApiKey } from './thunks';

const initialState: TInitialState = {
  created_at: null,
  updated_at: null,
  name: null,
  api_key: null,
  expires_at: null,
  loading: false,
  error: null,
};

const ApiSlice = createSlice({
  name: 'ApiSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateApiKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateApiKey.fulfilled, (state, action) => {
        state.loading = false;
        state.created_at = action.payload.created_at;
        state.updated_at = action.payload.updated_at;
        state.name = action.payload.name;
        state.api_key = action.payload.api_key;
        state.expires_at = action.payload.expires_at;
      })
      .addCase(generateApiKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default ApiSlice.reducer;
