import { useState, useEffect, useRef, ReactNode } from 'react';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { TypeAnimation } from 'react-type-animation';

import Container from '../../components/shared/Container';
import { depthPrecision, visionaryPrecision } from '../../assets/images/index';
import PrimaryButton from '../buttons/PrimaryButton';

interface HeaderSection {
  title: ReactNode;
  subtitle: string;
  image: string;
}

const headerInfo: HeaderSection[] = [
  {
    title: (
      <h1 className='xl:text-[50px] font-Avenir-Next-Pro lg:text-[50px] text-[32px] font-semibold xl:leading-[80px] mb-6  lg:leading-[60px] leading-[45px] tracking-wide transition-opacity ease-in duration-700 opacity-100'>
        Precision <br /> beyond{' '}
        <span className='text-accent dark:text-dark-text'>
          <TypeAnimation
            sequence={['sight', 2000, 10000]}
            speed={10}
            // deletionSpeed={90}
            repeat={Infinity}
          />
        </span>
      </h1>
    ),
    subtitle:
      ' Experience the future of vision enhancement, tailored to perfection for clarity and accuracy beyond the visible spectrum.',
    image: '/images/heroImage.svg',
  },
  {
    title: (
      <h1 className='xl:text-[50px] lg:text-[50px] font-Avenir-Next-Pro text-[32px] font-semibold xl:leading-[80px]  lg:leading-[60px] leading-[45px] tracking-wide'>
        Explore the depths of{' '}
        <span className='text-accent dark:text-dark-text'>
          <TypeAnimation
            sequence={['\nprecision', 2000, 10000]}
            speed={10}
            // deletionSpeed={90}
            repeat={Infinity}
            style={{ whiteSpace: 'pre-line' }}
          />
        </span>
      </h1>
    ),
    subtitle:
      'Our visionary technology pushes the boundaries of sight, offering unparalleled clarity and accuracy in every view',
    image: depthPrecision,
  },
  {
    title: (
      <h1 className='xl:text-[50px] lg:text-[50px] text-[32px]  font-Avenir-Next-Pro font-semibold xl:leading-[80px]  lg:leading-[60px] leading-[45px] tracking-wide'>
        Where AI meets <br />{' '}
        <span className='text-accent dark:text-dark-text'>
          <TypeAnimation
            sequence={['visionary', 2000, 10000]}
            speed={10}
            // deletionSpeed={30}

            repeat={Infinity}
          />
        </span>{' '}
        precision
      </h1>
    ),
    subtitle:
      'Delve into deeper insights and enhanced clarity, guided by our sophisticated metrics and AI-driven technology',
    image: visionaryPrecision,
  },
];

const HeroSection = ({ onClickScheduleCall }: { onClickScheduleCall?: () => void }) => {
  const [index, setIndex] = useState(0);
  // const typeAnimationRef = React.createRef();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const onNext = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setIndex((prevIndex) => (prevIndex === headerInfo.length - 1 ? 0 : prevIndex + 1));
  };

  const onPrev = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setIndex((prevIndex) => (prevIndex === 0 ? headerInfo.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      onNext();
    }, 12000);
    return () => clearInterval(interval);
  }, [index]);

  return (
    <div className='relative bg-blend-darken'>
      {/* <div className='bg-gray-300 bg-blur  absolute h-full w-full'></div> */}
      <Container className='flex flex-col md:flex-row gap-x-10 gap-y-10 xl:pt-[150px] lg:py-16 py-14 items-center  z-10'>
        <div className='md:w-1/2  w-full md:mr-20 '>
          <div className='md:h-[250px] h-[150px]'>
            {headerInfo[index].title}
            {headerInfo[index].subtitle}
          </div>

          {/* Buttons */}
          <div className='gap-5 mt-14 md:mt-4 flex md:justify-start justify-center'>
            <PrimaryButton
              className='bg-accent dark:bg-white dark:text-accent md:text-[16px] text-[14px] md:!min-w-[300px]'
              isHover
              isArrow
              onClick={onClickScheduleCall}
            >
              Schedule a Call
            </PrimaryButton>
          </div>

          <div className='w-full '>
            <div className='flex items-center gap-3 mt-10 md:pl-20'>
              {headerInfo.map((slides, i) => (
                <div
                  key={slides.image}
                  className={`rounded-full h-2 w-2 ${
                    index === i ? 'bg-[#0A6ACC]' : 'bg-[#F0F7FF]'
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='center-child md:w-1/2 w-full md:mt-0 mt-6 relative z-10'>
          <div className='absolute inset-0 transform -rotate-[5deg] bg-[#E3F2FD] dark:bg-accent rounded-[30px] scale-105 -z-10 ' />
          <div className='text-xs absolute bg-accent h-fit w-fit bottom-[-3.5rem] md:bottom-[-3rem] right-12 rounded-[20px] flex flex-col justify-center items-center py-2 md:py-5 px-3 md:px-7 md:text-lg'>
            <h1 className='text-center text-lg text-white font-Avenir-Next-Pro'>
              Vehicle Detection & Monitoring
            </h1>
            <div>
              <div className='flex items-center justify-center mt-4'>
                <button
                  className='rounded-full p-2 bg-[#0A6ACC] text-white mr-10 focus:outline-none'
                  onClick={onPrev}
                  aria-label='Previous Slide'
                  type='button'
                >
                  <IoMdArrowBack />
                </button>

                <div className='flex items-center gap-3'>
                  {headerInfo.map((slides, i) => (
                    <div
                      key={slides.image}
                      className={`rounded-full h-2 w-2 ${
                        index === i ? 'bg-[#F0F7FF]' : 'bg-[#0A6ACC]'
                      }`}
                    />
                  ))}
                </div>

                <button
                  className='rounded-full p-2 bg-[#E3F2FD] text-black ml-10 focus:outline-none'
                  onClick={onNext}
                  aria-label='Next Slide'
                  type='button'
                >
                  <IoMdArrowForward />
                </button>
              </div>
            </div>
          </div>
          <div className=''>
            <img src={headerInfo[index].image} alt='hero' className='inset-0 ' />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;
