import { AxiosResponse } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';

import { TMediaParams, TSignedMediaResponse } from './types';

export const getSignedMediaUrl = (
  params: TMediaParams,
): Promise<AxiosResponse<TSignedMediaResponse>> => {
  const options = { params };
  return client.get(endpoints.MediaService.getSignedMediaUrl(), options);
};

export const getBatchUrlsRequest = (params: TMediaParams): Promise<AxiosResponse<string>> => {
  const options = { params };
  return client.get(endpoints.MediaService.getSignedMediaUrls(), options);
};
