import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import Container from 'components/shared/Container';
import HawkEyeLogo from 'components/shared/HawkEyeLogo';
import { TAllOrganizationsReturnType, TOrganization } from 'store/slices/organizationsSlice/types';
import Avatar from 'components/shared/Avatar';
import { OrganizationInformationUpdateToastMessages } from 'constants/ToastMessages';
import { Routes } from 'types';
import { useAppDispatch, useDarkMode } from 'hooks';
import { getCurrentUser, getNewToken, updateDefaultOrganization } from 'store/thunks';
import { orgranizationsApi } from 'api';

type TResourceStatus = 'creating' | 'pending' | 'created';

type TOrganizationItemProps = Readonly<{
  id: number;
  mediaUrlId: string | null;
  name: string;
  resource_status: TResourceStatus;
}> & {
  fromNavbar?: boolean;
  handleClose?: any;
};

export function OrganizationItem({
  id,
  mediaUrlId,
  name,
  resource_status,
  fromNavbar = false,
  handleClose,
}: TOrganizationItemProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickOrganisation = async (id: number) => {
    if (resource_status === 'created') {
      try {
        await dispatch(updateDefaultOrganization(id));
        await dispatch(getNewToken());

        if (fromNavbar) {
          await dispatch(getCurrentUser());
          handleClose();
        }
        navigate(Routes.Dashboard); // Go to dashboard and view default organisation
      } catch (error) {
        const err = error as AxiosError;
        throw err;
      }

      return;
    }

    // Organisation pending
    toast(OrganizationInformationUpdateToastMessages.SINGLE_ORGANIZATION_PENDING, {
      icon: '⌛',
    });
  };

  return (
    <button
      className='flex flex-col gap-2 items-center bg-transparent py-2 px-4 shadow-lg max-w-[200px]'
      onClick={() => onClickOrganisation(id)}
    >
      <Avatar
        alt={name}
        fullName={name}
        mediaId={mediaUrlId as string}
        mediaFolder='organization'
        className={classNames('', {
          'w-[40px] h-[40px]': !mediaUrlId,
          'w-auto h-8': mediaUrlId,
        })}
      />
      <h3 className='capitalize line-clamp-3 text-center'>{name?.toLowerCase()}</h3>
      {resource_status !== 'created' ? (
        <div className='px-2 py-0.5 rounded-xl bg-orange-500'>
          <p className='capitalize text-sm text-white dark:text-white'>Pending</p>
        </div>
      ) : null}
    </button>
  );
}

export default function OrganizationsList() {
  const location = useLocation();
  const organisationsFromPreviousPage = location.state as TAllOrganizationsReturnType;
  const [organisations, setOrganisations] = useState<TOrganization[]>(
    organisationsFromPreviousPage?.data ?? [],
  );

  useEffect(() => {
    orgranizationsApi
      .getUserAllOrganizationsRequest()
      .then((response) => {
        setOrganisations(response?.data?.data);
      })
      .catch((e) => {});
  }, []);

  const { darkMode } = useDarkMode();

  return (
    <Container className='gap-x-10 py-2 items-center flex'>
      <div className='md:m-auto w-fit flex py-5 md:py-0 md:items-center h-[100vh]'>
        <div className='flex flex-col items-center md:justify-center md:p-12 md:border border-gray-200 gap-7 rounded-[8px] md:shadow-md'>
          <HawkEyeLogo isDarkMode={darkMode} />
          <h1 className='text-center lg:text-[30px] text-[36px] font-normal xl:leading-[80px] lg:leading-[60px] leading-[45px] tracking-wide transition-opacity ease-in duration-700 opacity-100'>
            Select Organization
          </h1>
          <p className='text-main-sub-text font-semibold text-center dark:text-white'>
            Please choose an organization that you would like to view
          </p>
          <div className='mt-5 flex flex-row justify-center flex-wrap gap-5'>
            {organisations.map(({ id, media_url_id, name, resource_status }) => (
              <OrganizationItem
                key={id}
                id={id}
                mediaUrlId={media_url_id}
                name={name}
                resource_status={resource_status as TResourceStatus}
              />
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
}
