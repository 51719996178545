import { PlusIcon } from '@heroicons/react/24/outline';
import { Pagination, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  deleteTeamMember,
  getAllTeamMember,
  getAllTeams,
  editTeamMember,
  deleteTeam,
  getAllRoles,
  updateTeam,
} from 'store/thunks';
import { useAppDispatch, useAppSelector, useDarkMode, useTopLoader } from 'hooks';
import SidebarModal from 'components/shared/SideBarModal';
import AddTeamate from 'components/team/AddNewTeam';
import { TeamMemberTable } from 'components/tables/TeamMembersTable';
import PrimaryButton from 'components/buttons/PrimaryButton';
import AddTeamMember from 'components/team/AddNewTeamMember';
import { capitalizeFirstLetter } from 'utils';
import { TCreateTeamParams, TTeamMember } from 'store/slices/teamsSlice/type';
import EditTeamMember from 'components/team/EditTeamMember';
import { teamsSelector } from 'store/slices/teamsSlice/selectors';
import { EmptyStateTeam, EmptyStateTeamDark } from 'assets/icons';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { userRoleEnums } from 'components/shared/EmployeeStatus/types';
import { TGetRolesParams, TUserRole } from 'store/slices/authSlice/types';
import './styles/dashboard.scss';
import Avatar from 'components/shared/Avatar';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';

import Createteam from './createteam';
import PersonIcon from '@mui/icons-material/Person';

export interface TeamMemberType {
  name: string;
  profile_pic_url: string;
  position: string;
  avaliability: string;
  contact_info: string;
  id: number;
  image_url_id: string;
  updated_at: string;
  created_at: string;
  member_id: number;
}

const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;

const Team = () => {
  const dispatch = useAppDispatch();
  const teams = useAppSelector(teamsSelector);
  const userData = useAppSelector(userDataSelector);
  const { user_roles, member_info } = userData ?? {};
  const [showAddTeamate, setShowAddteamate] = useState(false);
  const [teamMembers, setTeamMembers] = useState<TTeamMember[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [viewTeamMember, setViewTeamMeber] = useState(true);
  const [teamToDelete, setTeamToDelete] = useState<number>();
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState<boolean>(false);
  const [showAddTeamMember, setShowAddteamMember] = useState(false);
  const [teamid, setTeamid] = useState(0);
  const [memberId, setMemberId] = useState(0);
  const [showEditTeamMember, setShowEditTeamMember] = useState(false);
  const [editTeamMemberData, setEditTeamMemberData] = useState({});
  const [teamPageNumber, setDevicesPageNumber] = useState(1);
  const [teamTotalCount, setTeamTotalCount] = useState(0);
  const teamMascotUpdateInput = useRef<HTMLInputElement>(null);
  const activeTeam = useMemo(() => teams?.[activeIndex] ?? {}, [activeIndex, teams]);
  const [localImageId, setLocalImageId] = useState(activeTeam?.image_url_id);

  const getTeamMembers = useCallback(async (id: number, offsetBy?: number) => {
    try {
      const options = {
        limit: DEFAULT_LIMIT,
        offset: offsetBy ?? DEFAULT_OFFSET,
        team_id: id,
      };
      const response: any = await dispatch(getAllTeamMember(options)).unwrap();
      setTeamid(id);
      setTeamTotalCount(response?.total_count);
      setTeamMembers(response.data);
      if (response?.data?.length) {
        setMemberId(response?.data[0]?.member_id);
      }
    } catch (err: any) {
      if (err.message === '404') {
        setTeamMembers([]);
        await dispatch(deleteTeam(id));
      } else if (err.message === '500') {
        setTeamMembers([]);
      }
    }
  }, []);

  const getAllTeamMembers = useCallback(async () => {
    const options = {
      limit: DEFAULT_LIMIT,
      offset: DEFAULT_OFFSET,
    };

    dispatch(getAllTeams(options));
  }, []);

  const editTeamMemberbyID = useCallback(async (options: TCreateTeamParams) => {
    try {
      dispatch(editTeamMember(options));
      getAllTeamMembers();
    } catch (err: any) {
      // console.log(err);
    }
  }, []);

  const deleteTeamMemberbyId = async (id: number) => {
    try {
      await dispatch(deleteTeamMember(id)).unwrap();
      getTeamMembers(id);
    } catch (err: any) {
      // console.log(err);
    }
  };

  function hideDeletionConfirmation() {
    setShowDeletionConfirmationModal(false);
  }

  function showDeletionConfirmation(id: number) {
    setTeamToDelete(id);
    setShowDeletionConfirmationModal(true);
  }

  const handleDeleteTeam = async () => {
    if (teamToDelete) {
      try {
        await dispatch(deleteTeam(teamToDelete)).unwrap();
        setActiveIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
        getAllTeamMembers();
      } catch (error) {
        // console.error('Failed to delete team:', error);
      }
    }
  };

  useEffect(() => {
    // If we have user data, fetch data for the dashboard
    if (member_info) getAllTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member_info]);

  const onClickEdit = (value: any) => {
    setShowEditTeamMember(true);
    setEditTeamMemberData(value);
  };

  useEffect(() => {
    if (activeTeam?.id && member_info) {
      getTeamMembers(activeTeam.id);
    }
  }, [activeIndex, teams, member_info, activeTeam?.id, getTeamMembers]);

  const onChangeTeamsPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setDevicesPageNumber(value);
    const offsetBy = value * DEFAULT_LIMIT - DEFAULT_LIMIT;
    if (teams?.[activeIndex] && member_info) {
      getTeamMembers(teams[activeIndex].id, offsetBy);
    }
  };

  const maxTeamPages = useMemo(() => Math.ceil(teamTotalCount / DEFAULT_LIMIT), [teamTotalCount]);

  useEffect(() => {
    const options = {
      type: 'organization',
      limit: 10,
      offset: 0,
    };
    dispatch(getAllRoles(options as TGetRolesParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userWritePermission = user_roles?.some((user: TUserRole) => {
    return (
      user?.role?.toLowerCase() === userRoleEnums.OWNER?.toLowerCase() ||
      user?.role?.toLowerCase() === userRoleEnums.TEAMLEAD?.toLowerCase()
    );
  });

  const onAvatarClick = () => {
    teamMascotUpdateInput.current?.click();
  };

  const onAvatarImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append('media', file);
      formData.append('name', activeTeam.name);
      formData.append('id', String(activeTeam.id));

      const result = await dispatch(updateTeam(formData));

      if (result && result.payload && result.payload.image_url_id) {
        setLocalImageId(result.payload.image_url_id);
      }
    }
  };

  useEffect(() => {
    setLocalImageId(activeTeam?.image_url_id);
  }, [activeTeam?.image_url_id]);

  const { darkMode } = useDarkMode();

  return (
    <>
      <SidebarModal
        component={
          <AddTeamate
            onClose={() => setShowAddteamate(false)}
            getAllTeamMembers={getAllTeamMembers}
          />
        }
        show={showAddTeamate}
        setShow={() => setShowAddteamate(false)}
      />

      <SidebarModal
        component={
          <AddTeamMember
            onClose={() => setShowAddteamMember(false)}
            teamId={teamid}
            memberId={memberId}
            getTeamMembers={getTeamMembers}
            teamName={activeTeam?.name}
          />
        }
        show={showAddTeamMember}
        setShow={() => setShowAddteamMember(false)}
      />

      <SidebarModal
        component={
          <EditTeamMember
            onClose={() => setShowEditTeamMember(false)}
            teamId={teamid}
            editTeamMemberData={editTeamMemberData}
            teamName={activeTeam?.name}
            editTeamMemberbyID={editTeamMemberbyID}
          />
        }
        show={showEditTeamMember}
        setShow={() => setShowEditTeamMember(false)}
      />
      <div>
        <div className='mb-10 flex items-center justify-between'>
          <p className='text-[24px] font-semibold'>Team</p>
        </div>

        <div
          className='bg-white rounded-[14px] my-10 p-5 lg:p-12 lg:h-[70vh] dark:bg-dark-card-bg'
          id='clearScrollbar'
        >
          <div className='my-5  h-[90%] flex flex-col justify-between'>
            <Createteam
              createTeam={setShowAddteamate}
              allTeams={teams}
              teamName={activeTeam?.name}
              setViewTeamMeber={setViewTeamMeber}
              getTeamMembers={getTeamMembers}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              userWritePermission={userWritePermission}
              onDeleteTeam={showDeletionConfirmation}
            />
            <hr className='border-gray-100 mb-1' />

            {activeTeam?.name && teamMembers?.length > 0 && (
              <div className='flex justify-between'>
                <div className='flex items-center gap-2 pt-2 '>
                  <div onClick={onAvatarClick} className='cursor-pointer'>
                    {activeTeam?.image_url_id ? (
                      <Avatar
                        mediaId={localImageId}
                        mediaFolder='teams'
                        alt='team mascot'
                        className='w-8 h-8 rounded-full'
                      />
                    ) : (
                      <PersonIcon color='info' />
                    )}
                  </div>
                  <p className='text-[19px] font-semibold cursor-pointer flex items-center'>
                    {activeTeam?.name && `${capitalizeFirstLetter(activeTeam?.name)}'s`} Team
                    Members
                  </p>
                  <input
                    ref={teamMascotUpdateInput}
                    type='file'
                    accept='image/*'
                    onChange={onAvatarImageUpload}
                    style={{ display: 'none' }}
                  />
                </div>
                {userWritePermission && (
                  <PrimaryButton
                    icon={<PlusIcon className='w-[16px]' />}
                    onClick={() => setShowAddteamMember(true)}
                    variant='neutral'
                  >
                    Add Team Member
                  </PrimaryButton>
                )}
              </div>
            )}

            <div className='my-5  h-[90%] flex flex-col justify-between' id='teamMemberSection'>
              {activeTeam?.name && (
                <TeamMemberTable
                  teammembers={teamMembers || []}
                  deleteTeamMemberbyId={deleteTeamMemberbyId}
                  editTeamMember={editTeamMember}
                  onClickEdit={onClickEdit}
                  userWritePermission={userWritePermission}
                />
              )}
              {(!activeTeam?.name || !teamMembers) && (
                <div className='mt-5 pt-5 flex justify-center' id='teamMemberSection'>
                  <div className='mt-5 pt-5 flex flex-col justify-center'>
                    {darkMode ? (
                      <EmptyStateTeamDark style={{ height: '12em', width: '17em' }} />
                    ) : (
                      <EmptyStateTeam style={{ height: '12em', width: '17em' }} />
                    )}
                    <p className='font-semibold'>No team members, please add team.</p>
                  </div>
                </div>
              )}
              <div className='my-4'>
                {maxTeamPages > 1 && (
                  <Stack spacing={2} direction={'row'} justifyContent={'end'}>
                    <Pagination
                      count={maxTeamPages}
                      onChange={onChangeTeamsPage}
                      page={teamPageNumber}
                      variant='outlined'
                      shape='rounded'
                    />
                  </Stack>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        isOpen={showDeletionConfirmationModal}
        onClose={hideDeletionConfirmation}
        title='Delete Team'
        description='Are you sure you want to delete this team?'
        deleteText='Delete'
        onDelete={() => {
          handleDeleteTeam();
          setShowDeletionConfirmationModal(false);
        }}
      />
    </>
  );
};

export default Team;
