import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { RootState } from 'types';
import { invitationApi } from 'api';
import { InviteToastMessages, OrganizationMemberToastMessages } from 'constants/ToastMessages';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import {
  getAllOrganisations,
  getInvitedUsers,
  updateDefaultOrganization,
} from '../organizationsSlice/thunks';
import { getNewToken } from '../authSlice/authThunks';

import {
  TCreateInvitation,
  TGetInviteParams,
  TInviteUser,
  TUpdateInvitationParams,
  TUpdateUserBody,
} from './types';

export const createInvitation = createAsyncThunk(
  'invitations/createInvitation',
  async (options: TCreateInvitation[], { dispatch, getState }) => {
    const start = toast.loading(OrganizationMemberToastMessages.INVITATION_SEND_START);

    const {
      organizations: { pendingUsersLimit, pendingUsersOffset },
    } = getState() as RootState;

    try {
      const response = await invitationApi.createInvitationRequest(options);

      toast.success(OrganizationMemberToastMessages.INVITATION_SEND_SUCCESS, {
        id: start,
      });
      dispatch(getInvitedUsers({ limit: pendingUsersLimit, offset: pendingUsersOffset }));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(OrganizationMemberToastMessages.INVITATION_SEND_FAIL, {
        id: start,
      });

      throw Error;
    }
  },
);

export const inviteUser = createAsyncThunk(
  'auth/inviteUser',
  async (body: TInviteUser, { dispatch }) => {
    try {
      const response = await invitationApi.inviteUserRequest(body, body?.[0]?.org_id);
      // toast.success('User(s) successfully invited');
      const options = {
        limit: 10,
        offset: 0,
      };
      dispatch(getAllInvitedUsers(options));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(Error.message);
      throw Error;
    }
  },
);

export const getAllInvitedUsers = createAsyncThunk(
  'auth/getAllInvites',
  async (body: TGetInviteParams) => {
    try {
      const response = await invitationApi.getAllInvitedUsersRequest(body);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeInvitesUser = createAsyncThunk(
  'auth/removeInvite',
  async (id: number, { dispatch }) => {
    try {
      const response = await invitationApi.updateInvitationStatusRequest({ id, status: 'revoked' });

      const options = {
        limit: 10,
        offset: 0,
      };
      dispatch(getAllInvitedUsers(options));
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const deactivateUser = createAsyncThunk('user/deactivateUser', async (email: string) => {
  try {
    const response = await invitationApi.deactivateUserRequest(email);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
});

export const reactivateUser = createAsyncThunk('user/reactivateUser', async (email: string) => {
  try {
    const response = await invitationApi.reactivateUserRequest(email);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
});

export const getUserInvites = createAsyncThunk(
  'invitations/getUserInvites',
  async (params: TGetInviteParams) => {
    try {
      const response = await invitationApi.getUserInvitesRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);

export const acceptUserInvitation = createAsyncThunk(
  'auth/acceptInvitation',
  async (params: { id: number; organizationName?: string }, { dispatch }) => {
    const start = toast.loading(InviteToastMessages.INVITATION_ACCEPTANCE_START);

    try {
      const response = await invitationApi.updateInvitationStatusRequest({
        id: params.id,
        status: 'accepted',
      });

      await dispatch(getNewToken()); // Get new token to make other requests
      await dispatch(getUserInvites({ limit: 10, offset: 0 })); // Update user invites
      await dispatch(getAllOrganisations()); // Update list of organisations

      toast.success(
        `${InviteToastMessages.INVITATION_ACCEPTANCE_SUCCESS} ${params.organizationName ?? ''}`,
        {
          id: start,
        },
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      toast.error(InviteToastMessages.INVITATION_ACCEPTANCE_FAILURE, {
        id: start,
      });

      throw err;
    }
  },
);

export const rejectUserInvitation = createAsyncThunk(
  'auth/rejectInvitation',
  async (id: number, { dispatch }) => {
    try {
      const response = await invitationApi.updateInvitationStatusRequest({
        id,
        status: 'rejected',
      });

      await dispatch(getUserInvites({ limit: 10, offset: 0 })); // Update user invites

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      toast.error(InviteToastMessages.INVITATION_REJECTION_FAILURE);
      throw err;
    }
  },
);

export const acceptEmailInvitation = createAsyncThunk(
  'auth/acceptEmailInvitation',
  async (
    {
      onSuccess,
      ...params
    }: {
      onSuccess?: () => void;
      invitation_token: string;
      user_id?: number;
    },
    { dispatch },
  ) => {
    const start = toast.loading(InviteToastMessages.INVITATION_ACCEPTANCE_START);
    try {
      if (!params.user_id) {
        throw Error('Please login to accept invite');
      }

      // Accept invite
      const response = await invitationApi.updateEmailInvitationStatusRequest({
        ...params,
        status: 'accepted',
      });

      // Remove invitation token and status as they're no longer needed
      BrowserStorageService.remove(BrowserStorageKeys.InvitationToken);
      BrowserStorageService.remove(BrowserStorageKeys.InvitationStatus);
      BrowserStorageService.remove(BrowserStorageKeys.InvitationEmail);

      const organisationId = response?.data.org_id;

      // Update default organization
      if (organisationId) {
        await dispatch(updateDefaultOrganization(organisationId));
        await dispatch(getNewToken());
        await dispatch(getAllOrganisations()); // This updates HaveCreatedOrganization in browser storage if user has a created org
      }

      // Notify user of success
      toast.success(InviteToastMessages.INVITATION_ACCEPTANCE_SUCCESS, {
        id: start,
      });

      onSuccess?.();

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      toast.error(InviteToastMessages.INVITATION_ACCEPTANCE_FAILURE, {
        id: start,
      });

      throw err;
    }
  },
);

export const rejectEmailInvitation = createAsyncThunk(
  'auth/rejectEmailInvitation',
  async (invitation_token: string) => {
    const start = toast.loading(InviteToastMessages.INVITATION_REJECTION_START);
    try {
      const response = await invitationApi.updateEmailInvitationStatusRequest({
        invitation_token,
        status: 'rejected',
      });

      // Remove invitation token and status as they're no longer needed
      BrowserStorageService.remove(BrowserStorageKeys.InvitationToken);
      BrowserStorageService.remove(BrowserStorageKeys.InvitationStatus);

      // Notify user of success
      toast.success(InviteToastMessages.INVITATION_REJECTION_SUCCESS, {
        id: start,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      toast.error(InviteToastMessages.EMAIL_INVITATION_REJECTION_FAILURE, {
        id: start,
      });

      throw err;
    }
  },
);
