import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton, SelectChangeEvent } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import PrimaryButton from 'components/buttons/PrimaryButton';
import { TCreateTeamForm, TCreateTeamParams } from 'store/slices/teamsSlice/type';
import { getOrganizationMembers, postNewTeamMember } from 'store/thunks';
import { useAppDispatch } from 'hooks';
import { createTeamMemberSchema } from 'constants/Schemas';
import ControlledInput from 'components/shared/ControlledInput';
import { useAppSelector } from 'app/hooks';
import { organizationMembersSelector } from 'store/slices/organizationsSlice/selectors';
import { Select } from 'components';
import { FontNames } from 'types';

interface AddTeamateProps {
  onClose: () => void;
  teamId: number;
  memberId: number;
  getTeamMembers: any;
  teamName: string;
}

const AddTeamMember: React.FC<AddTeamateProps> = ({ onClose, teamId, getTeamMembers }) => {
  const dispatch = useAppDispatch();
  const members = useAppSelector(organizationMembersSelector);
  const [userMemberID, setuseMemberID] = useState(0);

  const {
    control: createTeamMemberControl,
    handleSubmit: createTeamMemberSubmit,
    formState: { errors },
  } = useForm<TCreateTeamForm>({
    resolver: yupResolver(createTeamMemberSchema),
  });

  async function getInvitesReceived() {
    await dispatch(getOrganizationMembers({ limit: 10, offset: 0 }));
  }

  const addNewTeamMember = async (values: TCreateTeamForm) => {
    const params: TCreateTeamParams = {
      avaliability: values?.avaliability || '',
      position: values?.position || '',
      contact_info: values?.contactInfo || '',
      name: values?.name,
      team_id: teamId,
      member_id: userMemberID,
    };
    try {
      await dispatch(postNewTeamMember(params));
      getTeamMembers(teamId);
      onClose();
    } catch (err: any) {
      //
    }
  };

  useEffect(() => {
    getInvitesReceived();
  }, []);

  const filterMembersOptions = members?.filter((x) => x.user_id);

  const formatMembers: any = filterMembersOptions?.map((member: any) => {
    return { value: member?.user_id, label: member?.name };
  });

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        <div className='flex justify-between'>
          <p className='font-semibold text-[24px]'>Add Team Member</p>
          <div className='flex items-center pr-4 gap-6'>
            <IconButton>
              <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
            </IconButton>
          </div>
        </div>

        <div className='mt-7'>
          <div className='flex flex-col gap-8 mt-8'>
            <Controller
              control={createTeamMemberControl}
              name='name'
              render={({ field: { onChange } }) => {
                return (
                  <Select
                    onChange={(event: SelectChangeEvent) => {
                      onChange(event.target.value);
                      if (event.target.value) {
                        setuseMemberID(Number(event.target.value));
                      }
                    }}
                    // defaultValue=''
                    borderRadius='5px'
                    label='Full Name'
                    options={formatMembers}
                    fontFamily={FontNames.AVENIR_NEXT_PRO}
                    error={!!errors.name}
                    // value={userMemberID}
                  />
                );
              }}
            />
            {/* <ControlledInput  required control={createTeamMemberControl} name='name' label='Full Name'  error={!!errors.name}   placeholder=' Name*' helperText={(errors?.name?.message as string) ?? ''}  />    */}
            <ControlledInput
              required
              control={createTeamMemberControl}
              name='position'
              label='Position'
              error={!!errors.position}
              placeholder='Position*'
              helperText={(errors?.position?.message as string) ?? ''}
            />
            <ControlledInput
              required
              control={createTeamMemberControl}
              name='avaliability'
              label='Avaliability'
              error={!!errors.avaliability}
              placeholder='Avaliability*'
              helperText={(errors?.avaliability?.message as string) ?? ''}
            />
            <ControlledInput
              required
              control={createTeamMemberControl}
              name='contactInfo'
              label='Contact Info'
              error={!!errors.contactInfo}
              placeholder='Contact Info*'
              helperText={(errors?.contactInfo?.message as string) ?? ''}
            />
          </div>
        </div>
      </div>
      <PrimaryButton
        className='w-full md:w-fit self-end'
        variant='neutral'
        onClick={createTeamMemberSubmit(addNewTeamMember)}
      >
        Add
      </PrimaryButton>
    </div>
  );
};

export default AddTeamMember;
