import { AxiosResponse } from 'axios';

import { TParams } from 'store/slices/activitiesSlices/types';
import {
  TAllOrganizationsReturnType,
  TGetOrganizationMembersParams,
  TOrganization,
  TUpdateDefaultOrganizationResponse,
  TUpdateUser,
} from 'store/slices/organizationsSlice/types';
import { StatusEnums } from 'components/shared/EmployeeStatus/types';
import { TGetWithParams } from 'types/global/getWithParams';
import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import { TApiKeyResponse } from 'store/slices/apiSlices/types';

export const createOrganizationRequest = (options: FormData) => {
  const headersOptions = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.post(endpoints.OrganizationsService.createOrganization(), options, headersOptions);
};

export const createSubscriptionRequest = (options: any) => {
  return client.post(
    endpoints.SubscriptionsService.createSubscription(
      options.org_id,
      options.subscription,
      options.subscription_type,
    ),
    options,
  );
};

export const updateOrganizationRequest = (data: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return client.put(endpoints.OrganizationsService.updateOrganization(), data, options);
};

export const getAllOrganizationsRequest = () =>
  client.get(endpoints.OrganizationsService.getAllOrganizations());

export const getUserAllOrganizationsRequest = (): Promise<
  AxiosResponse<TAllOrganizationsReturnType>
> => client.get(endpoints.OrganizationsService.getUserOrganizations());

export const sendOrganizationResourcesRequest = (org_id: number, subscription_id: string) =>
  client.post(endpoints.OrganizationsService.sendOrganizationResources(org_id), null, {
    params: {
      org_id,
      subscription_id,
    },
  });

export const getOrganizationByIdRequest = (org_id: number): Promise<AxiosResponse<TOrganization>> =>
  client.get(endpoints.OrganizationsService.getOrganizationById(), { params: { org_id } });

export const removeOrganizationMemberRequest = (status: string, member_id: number) =>
  client.delete(endpoints.OrganizationsService.removeOrganizationMember(status, member_id));

export const updateOrganizationMemberStatusRequest = (status: StatusEnums, member_id: number) =>
  client.put(endpoints.OrganizationsService.updateOrganizationMemberStatus(member_id, status));

export const updateOrganizationMemberRequest = (body: TUpdateUser) =>
  client.put(endpoints.OrganizationsService.updateOrganizationMember(), body);

export const getOrganizationMembersRequest = (params: TGetOrganizationMembersParams) =>
  client.get(endpoints.OrganizationsService.getOrganizationMembers(), {
    params: {
      limit: params?.limit,
      offset: params?.offset,
    },
  });

export const getInvitationByOrgIdRequest = ({
  limit,
  offset,
}: TGetWithParams<{ org_id: number }, 'org_id'>) =>
  client.get(endpoints.InvitationService.getInvitationByOrgId(), { params: { limit, offset } });

export const getAllUsersRequest = ({ limit, offset }: TParams) =>
  client.get(endpoints.AuthService.getAllUsers(), { params: { limit, offset } });

export const getInvitedPendingUsers = ({ limit, offset }: TParams) =>
  client.get(endpoints.InvitationService.invitedPendingUsers(), { params: { limit, offset } });

export const updateDefaultOrganizationRequest = (
  default_org_id: number,
): Promise<AxiosResponse<TUpdateDefaultOrganizationResponse>> => {
  return client.put(endpoints.OrganizationsService.updateDefaultOrganization(default_org_id));
};

export const generateApiKeyRequest = (name: string): Promise<AxiosResponse<TApiKeyResponse>> => {
  return client.post(endpoints.OrganizationsService.generateApiKey(name));
};
