import cn from 'classnames';
import React from 'react';
import classNames from 'classnames';

import { useDarkMode } from 'hooks';
import { Colors } from 'types';

interface FormInputProps {
  value?: string | number;
  name: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  className?: string;
  containerClassName?: string;
  error?: boolean;
  helperText?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  value,
  disabled = false,
  name,
  placeholder,
  type,
  onChange,
  onKeyDown,
  className,
  containerClassName,
  label,
  error,
  helperText,
}) => {
  const { darkMode } = useDarkMode();
  return (
    <div
      className={classNames('', {
        containerClassName: containerClassName,
        'mb-4 flex flex-col gap-1': !containerClassName,
      })}
    >
      {label && <label>{label}</label>}
      <input
        type={type}
        disabled={disabled}
        value={value}
        name={name}
        autoComplete='off'
        style={{
          color: darkMode ? Colors.WHITE : Colors.ENIGMATIC_MIDNIGHT,
          background: darkMode ? Colors.DARK_MODE_BTN : Colors.LIGHT_SILVER,
        }}
        className={cn(`input-primary-2 p-8 ${className}`, {
          'border border-red-600': error,
        })}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {error ? (
        <div className='pl-2'>
          <p className='text-red-600 text-sm transition-all border-red-600'>{helperText}</p>
        </div>
      ) : null}
    </div>
  );
};
FormInput.defaultProps = {
  label: '',
  type: 'text',
  className: '',
  placeholder: '',
  value: '',
};

export default FormInput;
