import { AxiosResponse } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TParams,
  TUpdateNotice,
  TCreateNotice,
  TLikePostComment,
  TUpdatePostComment,
  TPostCommentCreate,
  TCreateActivityFlag,
  TActivePostsRequest,
  TUpdatePostRequest,
  TActivityType,
  TActivityLikesRequest,
  TNotificationsResponse,
  TUpdateNotificationParams,
  TActivePostsResponse,
  TGetAllPostCommentParams,
  TNewsResponse,
  TPost,
  TGetAllPostCommentResponse,
} from 'store/slices/activitiesSlices/types';
const { getActivePosts } = endpoints.ActivityService;

export const getActivePostsRequest = (
  params: TActivePostsRequest,
): Promise<AxiosResponse<TActivePostsResponse>> => client.get(getActivePosts(), { params });

export const updatePostRequest = ({ id, ...otherOptions }: TUpdatePostRequest) =>
  client.put(endpoints.ActivityService.updatePostById(id), otherOptions, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const createPostRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.post(endpoints.ActivityService.createPost(), body, options);
};

export const getAllActivitiesRequest = (params: TParams) =>
  client.get(endpoints.ActivityService.getActivities(), {
    params,
  });

export const getFilterActivitiesRequest = (org_id: number) =>
  client.get(endpoints.ActivityService.getFilterActivities(org_id));

export const likeActivityPostRequest = (data: TActivityLikesRequest) =>
  client.post(endpoints.ActivityService.postActivityLike(), data, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

export const removeActivityLikeRequest = (data: TActivityLikesRequest) =>
  client.delete(endpoints.ActivityService.removeActivityLike(), {
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

export const likeActivityPostCommentRequest = (options: TLikePostComment) =>
  client.post(endpoints.ActivityService.likePostComment(options?.comment_id), null, {
    params: { ...options },
  });

export const removeActivityCommentLikeRequest = (id: number) =>
  client.delete(endpoints.ActivityService.removePostCommentLike(id));

export const removeActivityCommentRequest = (comment_id: number) =>
  client.delete(endpoints.ActivityService.removePostComment(comment_id));

export const getAllPostCommentsRequest = (
  params: TGetAllPostCommentParams,
): Promise<AxiosResponse<TGetAllPostCommentResponse>> =>
  client.get(endpoints.ActivityService.getActivityComment(params));

export const createPostCommentRequest = (body: TPostCommentCreate) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.post(endpoints.ActivityService.createActivityComment(), body, options);
};

export const deletePostRequest = (activity_feed_id: number) =>
  client.delete(endpoints.ActivityService.deletePostById(activity_feed_id));

export const getAllNotices = (params: TParams) =>
  client.get(endpoints.ActivityService.getNotices(), {
    params,
  });

export const updateNotice = (options: TUpdateNotice) =>
  client.put(endpoints.ActivityService.updateNotice(), options);

export const createNotice = (options: TCreateNotice) =>
  client.post(endpoints.ActivityService.createNotice(), options);

export const getNoticeById = (notice_id: number) =>
  client.get(endpoints.ActivityService.getNoticeById(notice_id));

export const removeNoticeById = (notice_id: number) =>
  client.delete(endpoints.ActivityService.deleteNoticeById(notice_id));

export const getPostCommentRepliesRequest = (comment_id: number) =>
  client.get(endpoints.ActivityService.getActivityReplies(comment_id));

export const getPostCommentByIdRequest = (comment_id: number) =>
  client.get(endpoints.ActivityService.getPostCommentById(comment_id));

export const updatePostCommentRequest = (options: TUpdatePostComment) =>
  client.put(endpoints.ActivityService.updatePostComment(options.id), null, {
    params: { ...options },
  });

export const createActivityFlagRequest = (options: TCreateActivityFlag) =>
  client.post(endpoints.ActivityService.createActivityFlag(), { ...options });

export const hideActivityFeedRequest = (id: number) =>
  client.post(endpoints.ActivityService.hidePost(id));

export const getAllHiddenActivityFeedRequest = () =>
  client.get(endpoints.ActivityService.getAllHiddenActivityFeeds(), {
    params: { limit: 100, offset: 0 },
  });

export const unhideActivityFeedRequest = (id: number) =>
  client.delete(endpoints.ActivityService.removeHiddenActivity(id));

export const getHiddenActivityFeedByIdRequest = (id: number) =>
  client.get(endpoints.ActivityService.getHiddenActivityById(id));

export const getActivityByActivityFeedByIdRequest = (activity_feed_id: number) =>
  client.get(endpoints.ActivityService.getActivityById(activity_feed_id));

export const getActivityFeedPostLikesRequest = (type: TActivityType, id: number) =>
  client.get(endpoints.ActivityService.getActivityLikes(type, id));

export const getAllNotificationsRequest = (
  params: TParams,
): Promise<AxiosResponse<TNotificationsResponse>> =>
  client.get(endpoints.ActivityService.getAllNotifications(), {
    params,
  });

export const updateNotificationRequest = ({
  is_read,
  notificationIds,
}: TUpdateNotificationParams) =>
  client.put(endpoints.ActivityService.getAllNotifications(), [...notificationIds], {
    params: { is_read },
  });

export const getNewsRequest = (params: TParams): Promise<AxiosResponse<TNewsResponse>> =>
  client.get(endpoints.ActivityService.getNews(), {
    params,
  });

export const askAIAgentRequest = (query: string): Promise<AxiosResponse<TPost>> =>
  client.post(endpoints.ActivityService.askAIAgent(), null, { params: { query } });
