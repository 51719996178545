import { useLayoutEffect, useState } from 'react';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { authApi } from 'api';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import useClearStorage from 'hooks/useClearStorage';

const useUserCredentials = () => {
  const { clearStorage } = useClearStorage(false);

  const token =
    BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
    BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

  const [error, setError] = useState<AxiosError | null>(null);

  const checkRefreshTokenRequest = async () => {
    try {
      const response = await authApi.refreshTokenRequest();

      setError(null);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      setError(Error);

      if (
        Error?.message?.includes('401') ||
        Error?.message?.includes('500') ||
        Error?.message === 'Network Error'
      ) {
        toast(
          'To ensure account security, you have been logged out. Please log in again to continue.',
          {
            icon: '⌛',
          },
        );

        clearStorage();
      }
    }
  };

  useLayoutEffect(() => {
    if (token) {
      checkRefreshTokenRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { error };
};

export default useUserCredentials;
