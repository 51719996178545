import { Fragment } from 'react';

interface IndustryReasons {
  title: string;
  reasons: string[];
}

interface Props {
  data: IndustryReasons;
}

const IndustryReasonComponent = ({ data: { reasons, title } }: Props): JSX.Element => {
  return (
    <section className='max-w-[65.563rem] mx-auto text-sm md:text-base lg:text-lg mb-[3rem] font-light'>
      <h3 className='text-center text-xl mx-auto md:text-2xl lg:text-3xl font-Avenir-Next-Pro'>
        {title}
      </h3>

      {reasons.map((reason, key) => (
        <Fragment key={key}>
          <p className='pt-10 '>{reason}</p>
        </Fragment>
      ))}
    </section>
  );
};

export default IndustryReasonComponent;
