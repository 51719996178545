import { landPhone } from 'assets/images';
import Container from 'components/shared/Container';

const HeroSection = () => {
  return (
    <>
      <div className=' mt-10 md:mt-4'>
        <Container className='my-20'>
          <div className='flex flex-col md:flex-row items-center justify-around py-10 gap-8'>
            <div className='flex flex-col gap-5 max-w-xl'>
              <h1 className='text-[50px] font-[900] font-Avenir-Next-Pro'> Contact Us</h1>
              <p className='text-base md:text-[18px] font-[900]'>
                {' '}
                Experience the future of vision enhancement, tailored to perfection for clarity and
                accuracy beyond the visible spectrum.
              </p>
            </div>
            <div className='flex justify-end'>
              <div className=' items-end justify-items-end'>
                <img src={landPhone} alt='Pink land phone' className='inset-0  w-full  ' />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeroSection;
