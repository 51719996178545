import { DetailsList } from 'types/components.types';

import IndustryReasonComponent from './IndustryReasonComponent';
import DetailsListComponent from './DetailsListComponent';
import IndustriesHeroSection from './IndustriesHeroSection';

const detailsData: DetailsList[] = [
  {
    title: 'Time Savings',
    image: '/images/industriesPage/construction1.svg',
    subtitle:
      'Automated people counting reduces the need for manual headcounts, saving time and freeing up personnel for more critical tasks.',
  },
  {
    title: 'Real-time Monitoring',
    image: '/images/realTanalysis.svg',
    subtitle:
      'AI sensors and cameras provide real-time data on construction progress, enabling quicker decision-making and problem-solving.',
  },
  {
    title: 'Client Transparency',
    image: '/images/industriesPage/construction3.svg',
    subtitle:
      'Providing clients with real-time data on workforce presence can improve transparency and trust, enhancing client-contractor relationships.',
  },
  {
    title: 'Accurate Billing',
    image: '/images/industriesPage/construction4.svg',
    subtitle:
      'Precise data on labor hours can lead to more accurate billing and reduce disputes with clients or contractors.',
  },
];

const ConstructionSection = (): JSX.Element => {
  return (
    <section>
      <IndustriesHeroSection
        imageURL={'/images/industriesPage/construction-hero.svg'}
        title='Construction People Counter'
      />

      <IndustryReasonComponent
        data={{
          title: 'How can this technology help your construction business?',
          reasons: [
            'HawkEyeVisionTek is vital for any construction project or business. Use it to gather detailed information on daily work flow and habits to improve efficiency. Be able to track progress and predict completion date of project with ease. Increase the safety of any site by monitoring potential danger zones and threats.',
            'Security of site will also improve significantly as you can be alerted of any movement within the site during off-work hours . Speak to a representative today to learn more on how you can recieve all of these benefits and much, much more.',
          ],
        }}
      />

      <DetailsListComponent data={detailsData} />
    </section>
  );
};

export default ConstructionSection;
