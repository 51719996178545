import { useState, forwardRef, ForwardedRef, useMemo } from 'react';
import classNames from 'classnames';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

import { useAppDispatch, useDarkMode } from 'hooks';
// import { Chip, EmptyTitle, ErrorMessage } from 'components';
// import { deleteJobType } from 'store/slices/agreementSlice/thunks';
// import { ArrowDown, CloseIcon, SquareDeleteIcon } from 'assets/icons';
// import { Colors, FontNames } from 'types';
import { FontNames } from 'types/global/fonts';
import { Colors } from 'types/global/colors';

import ErrorMessage from '../ErrorMessage';

import styles from './Select.module.scss';
import { MenuProps, getStyles, sx } from './styles';

import type { TSelectProps } from './types';

const SelectComponent = forwardRef(
  (
    {
      icon,
      value,
      label,
      onClear,
      options,
      onChange,
      disabled,
      autoComplete = 'off',
      maxWidth,
      labelColor,
      padding,
      fontFamily,
      helperText,
      lineHeight,
      defaultValue,
      labelClasses,
      error = false,
      required = false,
      borderRadius = '5px',
      setJobTypesState,
      withTags = false,
      showLabel = false,
      withLabel = true,
      withClear = false,
      backgroundColor = '',
      isWithoutIcon = false,
      isShowingPickerIcon = false,
    }: TSelectProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const dispatch = useAppDispatch();

    const theme = useTheme();

    const [selectValue, setSelectValue] = useState<string[]>([]);

    const showClear = withClear && (!!value || selectValue?.length > 0);

    const { darkMode } = useDarkMode();

    const handleChange = (event: SelectChangeEvent<typeof selectValue>) => {
      const { value } = event.target;
      const stringValues = typeof value === 'string' ? value.split(',') : value;
      const numberValues =
        typeof value === 'string'
          ? value.split(',').map((str) => Number(str))
          : value.map((str) => Number(str));

      setSelectValue(stringValues);
      setJobTypesState?.(numberValues);
    };

    const handleClear = () => {
      setSelectValue([]);
      if (typeof onChange === 'function') {
        onClear?.();
      }
    };

    const handleChipDelete = (value: string) => {
      // dispatch(deleteJobType(Number(value)));
      const filteredOptions = options?.filter((option) => option.value !== value);
      setSelectValue(filteredOptions as string[]);
    };

    const renderMenuItemValue = (label: string, color: string, value: string) => {
      if (withTags) {
        return (
          // <Chip
          //   color={color}
          //   // deleteIcon={<SquareDeleteIcon />}
          //   label={label}
          //   onDelete={() => handleChipDelete(value)}
          // />
          <p>Chips</p>
        );
      }
      return label;
    };

    const haveLabel = withLabel ? defaultValue || label : false;

    const labelClassName = classNames(styles.container__label, labelClasses);

    const IconComponent = useMemo(() => icon as React.FC<React.SVGProps<SVGSVGElement>>, [icon]);

    return (
      <FormControl
        error={error}
        sx={{
          m: 0,
          width: '100%',
          maxWidth: maxWidth,
          fontFamily: `${fontFamily} !important`,

          '& .MuiFormLabel-root': {
            fontFamily: `${fontFamily || FontNames.CIRCULAR_REG} !important`,
          },
        }}
        ref={ref}
      >
        {haveLabel && (
          <InputLabel
            style={{
              color: error ? Colors.CRIMSON_RED : darkMode ? 'white' : Colors.ENIGMATIC_MIDNIGHT,
            }}
            className={labelClassName}
            id='demo-simple-select-label'
          >
            {haveLabel}
          </InputLabel>
        )}
        {helperText && error && <ErrorMessage message={helperText} />}

        <Select
          required={required}
          disabled={disabled}
          autoComplete={autoComplete}
          className={styles.container__select}
          sx={{
            ...sx,
            fontFamily,
            color: darkMode ? 'white' : '',
            lineHeight: `${lineHeight} !important`,
            '& .MuiSelect-select': {
              padding,
              display: 'flex',
              alignItems: 'center',
            },

            '&:hover fieldset': {
              borderColor: darkMode ? '#ffffff !important' : '#2067dd !important',
            },

            fieldset: {
              border: error
                ? '1px solid red !important'
                : darkMode
                ? '1px solid #6b7280 !important'
                : '1px solid #EDEFF1',
              borderWidth: '1px !important',
            },
          }}
          value={value}
          MenuProps={MenuProps}
          id='demo-simple-select'
          onChange={onChange}
          IconComponent={(props) => (
            <>
              {!isWithoutIcon && (
                <>
                  {showClear && 'Close'}
                  {isShowingPickerIcon ? <ArrowDropDownIcon {...props} /> : null}
                </>
              )}
            </>
          )}
          label={label}
          labelId='demo-simple-select-label'
          style={{
            background: darkMode ? Colors.DARK_MODE_BG : Colors.LIGHT_SILVER,
            borderRadius: borderRadius,
          }}
        >
          {options?.length ? (
            options.map(({ value, label, color }) => (
              <MenuItem
                key={value}
                value={value}
                className={styles.container__select__item}
                sx={getStyles(value as string, selectValue as string[], theme)}
              >
                {icon && <IconComponent style={{ marginRight: '8px' }} />}
                {showLabel
                  ? label
                  : renderMenuItemValue(label as string, String(color), String(value))}
              </MenuItem>
            ))
          ) : (
            <p>Empty title</p>
            // <EmptyTitle title='No Options' />
          )}
        </Select>
      </FormControl>
    );
  },
);

export default SelectComponent;
