import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { TInitialState } from './type';
import {
  getAllEvents,
  getCurrentEvents,
  getEventByID,
  getEventsData,
  updateEvents,
} from './thunks';

const initialState: TInitialState = {
  devices: null,
  futureEvents: dataStructure,
  pastEvents: dataStructure,
  currentEvents: dataStructure,
  eventByIDData: undefined,
  archivedEvents: dataStructure,
  updateEvents: undefined,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllEvents.fulfilled, (state, action) => {
      if (action.meta.arg.timeframe === 'Future') {
        state.futureEvents = {
          data: action.payload?.data ?? [],
          total_count: action.payload?.total_count ?? 0,
        };
      } else if (action.meta.arg.timeframe === 'Past') {
        state.pastEvents = {
          data: action.payload?.data ?? [],
          total_count: action.payload?.total_count ?? 0,
        };
      } else if (action.meta.arg.timeframe === 'Archived') {
        state.archivedEvents = {
          data: action.payload?.data ?? [],
          total_count: action.payload?.total_count ?? 0,
        };
      }
    });
    builder.addCase(getCurrentEvents.fulfilled, (state, action) => {
      state.currentEvents = {
        data: action.payload?.data ?? [],
        total_count: action.payload?.total_count ?? 0,
      };
    });
    builder.addCase(getEventsData.fulfilled, (state, action) => {
      state.pastEvents.data = state.pastEvents.data.map((event) => {
        const eventExtraData = action.payload?.data.find((eventData) => eventData.id === event.id);
        return eventExtraData
          ? { ...event, total_head_count: eventExtraData.total_head_count ?? 0 }
          : event;
      });
    });
    builder
      .addCase(getEventByID.fulfilled, (state, action) => {
        state.eventByIDData = action?.payload ?? undefined;
      })
      .addCase(updateEvents.fulfilled, (state, action) => {
        state.updateEvents = action?.payload ?? undefined;
      });
  },
});

export default eventsSlice.reducer;
