import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { capitalizeFirstLetter } from 'utils';
import { authApi } from 'api';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { ProfileInformationUpdateToastMessages } from 'constants/ToastMessages';

import { TUpdateUser, TUpdateDesignation, TGetRolesParams } from './types';

export const signUp = createAsyncThunk('auth/signUp', async (email: string) => {
  try {
    const response = await authApi.signUpRequest(email);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const signInAuth = createAsyncThunk('auth/signIn', async (email: string) => {
  try {
    const response = await authApi.signInRequest(email);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const loginOAuth = createAsyncThunk('auth/loginOAuth', async (params: FormData) => {
  try {
    const response = await authApi.signInOauthRequest(params);
    const accessToken = response?.data?.token?.access_token;
    if (accessToken) {
      BrowserStorageService.set(BrowserStorageKeys.AccessToken, JSON.stringify(accessToken));

      BrowserStorageService.set(
        BrowserStorageKeys.RefreshToken,
        JSON.stringify(response?.data?.token?.refresh_token),
      );
    }
    return { ...response.data, status: response.status };
  } catch (error) {
    const err = error as AxiosError;
    const haveErrorMessage =
      !!err.response && typeof err?.response?.data === 'string' && !!err?.response?.data?.length;
    toast.error(
      haveErrorMessage
        ? capitalizeFirstLetter(err?.response?.data as string)
        : 'Login unsuccessful. Please check your information and try again later.',
    );

    throw new Error(String(err.response?.status));
  }
});

export const registerOauth = createAsyncThunk('auth/loginOAuth', async (params: FormData) => {
  try {
    const response = await authApi.registerOauthRequest(params);
    const accessToken = response?.data?.token?.access_token;
    if (accessToken) {
      BrowserStorageService.set(BrowserStorageKeys.AccessToken, JSON.stringify(accessToken));

      BrowserStorageService.set(
        BrowserStorageKeys.RefreshToken,
        JSON.stringify(response?.data?.token?.refresh_token),
      );
    }
    return { ...response.data, status: response.status };
  } catch (error) {
    const err = error as AxiosError;

    const haveErrorMessage =
      !!err.response && typeof err?.response?.data === 'string' && !!err?.response?.data?.length;

    toast.error(
      haveErrorMessage
        ? capitalizeFirstLetter(err?.response?.data as string)
        : 'Sign up unsuccessful. Please check your information and try again later.',
    );

    throw new Error(String(err.response?.status));
  }
});

export const refreshToken = createAsyncThunk('authSlice/refreshToken', async () => {
  try {
    const response = await authApi.refreshTokenRequest();

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getNewToken = createAsyncThunk('auth/getNewToken', async () => {
  try {
    const response = await authApi.refreshTokenRequest();
    BrowserStorageService.update(
      BrowserStorageKeys.AccessToken,
      JSON.stringify(response.data.access_token),
    );

    BrowserStorageService.update(
      BrowserStorageKeys.AccessToken,
      JSON.stringify(response.data.access_token),
      {
        session: true,
      },
    );

    return response.data.access_token || response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getCurrentUser = createAsyncThunk('auth/getUser', async () => {
  try {
    const response = await authApi.getUserByTokenRequest();
    return response.data;
  } catch (error) {
    const Error = error as AxiosError;

    throw Error;
  }
});

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async ({ showError = false, ...body }: { showError?: boolean } & TUpdateUser, { dispatch }) => {
    try {
      const response = await authApi.updateUserRequest(body);
      await dispatch(getCurrentUser());

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      if (showError) toast.error(ProfileInformationUpdateToastMessages.PROFILE_UPDATE_FAILURE);
      throw err;
    }
  },
);

export const updateProfileImage = createAsyncThunk(
  'auth/updateProfileImage',
  async (profile_image: FormData, { dispatch }) => {
    try {
      const response = await authApi.updateProfileImageRequest(profile_image);
      await dispatch(getCurrentUser());

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      toast.error(ProfileInformationUpdateToastMessages.PROFILE_IMAGE_UPDATE_FAILURE);

      throw err;
    }
  },
);

export const updateDesignation = createAsyncThunk(
  'auth/updateDesignation',
  async (params: TUpdateDesignation, { dispatch }) => {
    try {
      const response = await authApi.updateDesignationRequest(params);
      await dispatch(getCurrentUser());

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);

export const getUserById = createAsyncThunk('auth/getUserById', async (id: number) => {
  try {
    const response = await authApi.getUserByIdRequest(id);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getCurrentUserById = createAsyncThunk(
  'auth/getCurrentUserById',
  async (id: number) => {
    try {
      const response = await authApi.getUserByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getAllRoles = createAsyncThunk('auth/getAllRoles', async (body: TGetRolesParams) => {
  try {
    const response = await authApi.getAllRolesRequest(body);
    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    // console.log(Error);
    throw Error;
  }
});
