import { FC } from 'react';
import classNames from 'classnames';

import styles from './ProcessFormTitle.module.scss';

import type { TProcessFormTitleProps } from './types';

const ProcessFormTitle: FC<TProcessFormTitleProps> = ({
  title,
  subtitle,
  small = false,
  subtitleClassName,
  lightSubtitle = false,
}) => {
  const subtitleClasses = classNames(styles.container__subtitle, subtitleClassName, {
    [styles.container__subtitle_small]: small,
    [styles.container__subtitle_light]: lightSubtitle,
  });

  return (
    <div className='flex flex-col items-center justify-center mt-10 w-full '>
      {title && (
        <h1
          className='lg:text-[30px] xl:leading-[80px] lg:leading-[60px] leading-[45px] tracking-wide transition-opacity ease-in duration-700 opacity-100'
          style={{ fontFamily: 'Avenir-Next-Bold' }}
        >
          {title}
        </h1>
      )}
      {subtitle && <p className={subtitleClasses}>{subtitle}</p>}
    </div>
  );
};

export default ProcessFormTitle;
