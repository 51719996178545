import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';

import FormInput from 'components/inputs/FormInput';
import RowRadioButtonsGroup from 'components/inputs/RadioButtonsGroup';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useAppDispatch } from 'hooks';
import { editDevices } from 'store/thunks';

interface AddDeviceProps {
  onClose: () => void;
  getAllDevices: any;
  getEditDeviceInfoData?: any;
}

const EditDevice: React.FC<AddDeviceProps> = ({
  onClose,
  getAllDevices,
  getEditDeviceInfoData,
}) => {
  const dispatch = useAppDispatch();
  const [eventType, setEventType] = useState<any>();
  const [deviceNumber, setDeviceNumber] = useState<any>();
  const [deviceName, setDeviceName] = useState<any>();

  const { device_model, device_name, placement, id } = getEditDeviceInfoData ?? {};

  const createEvent = async () => {
    const data = {
      id: id,
      placement: eventType,
      device_model: deviceNumber,
      device_name: deviceName,
    };

    try {
      await dispatch(editDevices(data));
      getAllDevices();
      onClose();
    } catch (err: any) {
      // console.log(err);
    }
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        <div className='flex justify-between'>
          <p className='font-semibold text-[24px]'>Edit New Device</p>
          <div className='flex items-center pr-4 gap-6'>
            <IconButton>
              <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
            </IconButton>
          </div>
        </div>

        <div className='mt-7'>
          <div className='flex flex-col gap-3'>
            <FormInput
              name='deviceName'
              placeholder='Device Name*'
              onChange={(e) => setDeviceName(e.target.value)}
              value={deviceName || device_name}
            />
            <FormInput
              name='deviceNumber'
              placeholder='Device Number*'
              onChange={(e) => setDeviceNumber(e.target.value)}
              value={deviceNumber || device_model}
            />
          </div>

          <div className='my-10'>
            <RowRadioButtonsGroup
              label='Placement'
              options={[
                { value: 'indoor', label: 'Indoor' },
                { value: 'outdoor', label: 'Outdoor' },
              ]}
              value={eventType || placement}
              onChange={(value) => setEventType(value)}
              vertical
            />
          </div>
        </div>
      </div>
      <PrimaryButton className='w-full md:w-fit self-end' onClick={() => createEvent()}>
        Edit Device
      </PrimaryButton>
    </div>
  );
};

export default EditDevice;
