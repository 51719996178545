import { FC } from 'react';
import cn from 'classnames';
export interface TabsProps {
  tabs: {
    text: string;
    value?: number;
  }[];
  activeTabIndex?: number;
  currentTab?: string;
  setCurrentTab: (tab: string, index: number) => void;
  variant?: 'primary' | 'secondary';
}

export const Tabs: FC<TabsProps> = ({
  tabs,
  activeTabIndex,
  currentTab,
  setCurrentTab,
  variant = 'primary',
}) => {
  if (variant == 'secondary') {
    return (
      <div className='flex items-center py-1 px-2 rounded-[5px] border-dotted border'>
        {tabs.map(({ text }, index) => {
          const isActive = activeTabIndex === index || currentTab == text;

          return (
            <div
              onClick={() => setCurrentTab(text, index)}
              key={index}
              className={cn('rounded-[5px] p-2 transition-all cursor-pointer', {
                'bg-accent': isActive,
              })}
            >
              <p
                className={cn('text-[#B3B3B3] font-semibold  text-[18px] tracking-wide ', {
                  '!text-white': isActive,
                })}
              >
                {text}
              </p>{' '}
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className='flex items-center gap-3 '>
      {tabs.map(({ text, value }, index) => {
        const isActive = activeTabIndex === index || currentTab == text;

        return (
          <div
            onClick={() => setCurrentTab(text, index)}
            key={index}
            className={cn(
              'cursor-pointer px-2 py-0 md:p-2 transition-all text-[#6E6E6E] font-semibold md:border-b-4 border-b-transparent border-r-2  border-r-[#C8C8C8] md:border-r-0 last-of-type:border-r-0 border-b-0',
              {
                'md:border-b-4 md:border-b-accent dark:border-b-dark-text text-black': isActive,
              },
            )}
          >
            <p
              className={cn('hover:text-black transition-all text-[13px] md:text-[16px]', {
                ' text-black dark:text-white': isActive,
              })}
            >
              {text}{' '}
              {value && (
                <>
                  <span className='hidden md:inline'>({value})</span>
                  <span
                    className={cn(
                      'inline md:hidden text-white bg-[#6E6E6E] font-semibold py-0.5 px-2 rounded-full',
                      {
                        '!bg-accent': isActive,
                      },
                    )}
                  >
                    {value}
                  </span>
                </>
              )}
            </p>
          </div>
        );
      })}
    </div>
  );
};
