import { createSlice } from '@reduxjs/toolkit';
import { TInitialState } from './type';

const storedDarkMode = localStorage.getItem('darkMode');

const initialState: TInitialState = {
  themeMode: storedDarkMode ? JSON.parse(storedDarkMode) : false
};

const themeSlice = createSlice({
  name: 'theme',
  initialState: initialState,
  reducers: {
    toggleDarkMode(state) {
      state.themeMode = !state.themeMode;
    }
  },
});

export const { toggleDarkMode } = themeSlice.actions;

export default themeSlice.reducer;
