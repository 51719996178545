import SearchIcon from '@mui/icons-material/Search';
import { useMemo } from 'react';

import VideoPreview from 'assets/images/video-preview.webp';
import { CustomTextField } from 'components';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useDarkMode } from 'hooks';
import { Colors } from 'types';

const HIDE = true;

const FAQs = [
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
  { description: 'What is Lorem ipsum?', videoPreview: VideoPreview },
];

type TFAQProps = {
  description: string;
  videoPreview: string;
};

function FAQ({ description, videoPreview }: TFAQProps) {
  return (
    <div className='flex flex-col items-center gap-4 cursor-pointer'>
      <img src={videoPreview} className='w-full' />
      <p>{description}</p>
    </div>
  );
}

export default function Help() {
  const { darkMode: isDarkMode } = useDarkMode();
  function SearchButton() {
    return <PrimaryButton onClick={() => {}}>Search</PrimaryButton>;
  }

  const textColour = useMemo(() => (isDarkMode ? Colors.WHITE : Colors.BLACK), [isDarkMode]);
  const searchIconColour = useMemo(() => (isDarkMode ? Colors.WHITE : Colors.GRAY), [isDarkMode]);

  const searchInputColour = useMemo(
    () => (isDarkMode ? Colors.DARK_MODE_BG : Colors.WHITE),
    [isDarkMode],
  );

  return (
    <div>
      <p className='font-extrabold text-[24px] mb-4'>Help and Support</p>
      {HIDE ? null : (
        <section className='flex flex-col items-center gap-6 md:gap-12'>
          <CustomTextField
            placeholder='Ask a question'
            backGroundColor={searchInputColour}
            padding='24px'
            color={textColour}
            containerClasses='max-w-[600px] w-full mt-6 md:mt-12 mb-6 md:mb-12'
            leftIcon={<SearchIcon style={{ color: searchIconColour }} />}
            rightIcon={<SearchButton />}
          />
        </section>
      )}

      <section className='flex flex-col items-center bg-white dark:bg-darkBg p-4'>
        <p className='font-extrabold text-[24px]'>Help Videos</p>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-12 mt-6 md:mt-12'>
          {FAQs.map(({ description, videoPreview }, index) => (
            <FAQ key={index} description={description} videoPreview={videoPreview} />
          ))}
        </div>
      </section>
    </div>
  );
}
