import { useCallback, useState } from 'react';

import { TDeviceModalProps } from 'components/Modal/DeviceControlModal';

export default function useDeviceControlModal() {
  const [isDeviceModalOpen, setDeviceModalOpen] = useState<boolean>(false);
  const [deviceToShow, setDeviceToShow] = useState<TDeviceModalProps>();

  const openDeviceModal = useCallback((device: TDeviceModalProps) => {
    setDeviceToShow(device);
    setDeviceModalOpen(true);
  }, []);

  const closeDeviceModal = useCallback(() => setDeviceModalOpen(false), []);

  return { deviceToShow, isDeviceModalOpen, setDeviceModalOpen, openDeviceModal, closeDeviceModal };
}
