const TransportIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='none' viewBox='0 0 24 25'>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M15.51 3.33H8.49C6 3.33 5.45 4.57 5.13 6.09L4 11.5h16l-1.13-5.41c-.32-1.52-.87-2.76-3.36-2.76zM21.99 20.32c.11 1.17-.83 2.18-2.03 2.18h-1.88c-1.08 0-1.23-.46-1.42-1.03l-.2-.6c-.28-.82-.46-1.37-1.9-1.37H9.44c-1.44 0-1.65.62-1.9 1.37l-.2.6c-.19.57-.34 1.03-1.42 1.03H4.04c-1.2 0-2.14-1.01-2.03-2.18l.56-6.09c.14-1.5.43-2.73 3.05-2.73h12.76c2.62 0 2.91 1.23 3.05 2.73l.56 6.09zM4 8.5H3M21 8.5h-1M12 3.5v2M10.5 5.5h3M6 15.5h3M15 15.5h3'
      ></path>
    </svg>
  );
};

export default TransportIcon;
