import { FC, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { CardElement, useElements } from '@stripe/react-stripe-js';

import { stateOptions } from 'constants/States';
import { CustomTextField, ProcessFormTitle, Select } from 'components';
import { Colors } from 'types/global/colors';
import { FontNames } from 'types/global/fonts';
import { useDarkMode } from 'hooks';

import FormLayout from '../FormLayout';

import styles from './Payment.module.scss';
import { TPaymentProps } from './types';

const Payment: FC<TPaymentProps> = ({ errors, control, handleCardValid, isDisabled }) => {
  const { darkMode } = useDarkMode();
  const elements = useElements();
  const cardElement = elements?.getElement(CardElement);
  useEffect(() => {
    cardElement?.update({ disabled: isDisabled });
  }, [cardElement, isDisabled]);
  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: darkMode ? Colors.WHITE : '#424770',
        '::placeholder': {
          color: darkMode ? Colors.WHITE : '#aab7c4',
        },
      },

      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <Box className={styles.container}>
      <ProcessFormTitle lightSubtitle title='Payment' />
      <Box className={styles.container__content}>
        <FormLayout title='Card Details' className={styles.container__content__form}>
          <Box className={styles.container__address}>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 '>
              <Controller
                name='name'
                defaultValue=''
                control={control}
                rules={{ required: 'Card holder name is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    autoComplete='cc-name'
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    label='Card Holder Name'
                    placeholder='Card Holder Name:'
                    fontFamily={FontNames.AVENIR_NEXT_PRO}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    disabled={isDisabled}
                  />
                )}
              />

              <div className='bg-[#F7F7F7] dark:bg-transparent dark:text-white dark:border dark:border-[#6b7280] py-[18px] px-[14px] rounded-sm'>
                <CardElement options={cardElementOptions} onChange={handleCardValid} />
              </div>
            </div>
          </Box>
        </FormLayout>
        <FormLayout title='Address'>
          <Box className={styles.container__address}>
            <Stack
              direction='row'
              width='100%'
              sx={{ marginBottom: '16px', gap: '20px', width: '100%' }}
              gap={2}
            >
              <Controller
                name='address'
                control={control}
                defaultValue=''
                rules={{ required: 'Address is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    label='Address'
                    placeholder='Address:'
                    autoComplete='address-line1'
                    error={!!errors.address}
                    fontFamily={FontNames.AVENIR_NEXT_PRO}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    disabled={isDisabled}
                  />
                )}
              />
            </Stack>
            <Stack direction='row' sx={{ gap: '20px', width: '100%' }}>
              <Controller
                name='state'
                control={control}
                defaultValue=''
                rules={{ required: 'State is required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    maxWidth='100%'
                    label='State:*'
                    autoComplete='address-level1'
                    onChange={onChange}
                    options={stateOptions}
                    error={!!errors.state}
                    helperText={errors?.state?.message}
                    fontFamily={FontNames.AVENIR_NEXT_PRO}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    disabled={isDisabled}
                  />
                )}
              />

              <Controller
                name='zip'
                control={control}
                defaultValue=''
                rules={{ required: 'Zip code is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    label='Zip'
                    placeholder='Zip:*'
                    autoComplete='postal-code'
                    error={!!errors.zip}
                    helperText={errors?.zip?.message}
                    fontFamily={FontNames.AVENIR_NEXT_PRO}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    disabled={isDisabled}
                  />
                )}
              />
            </Stack>
          </Box>
        </FormLayout>
      </Box>
    </Box>
  );
};

export default Payment;
