import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';

import { useWindowSize } from 'hooks';
import { industryOptions, subtitleOptions } from 'constants/BusinessTypes';
import { BrowserStorageKeys, BrowserStorageService, LocalForageService } from 'services';
import { LocalForageKeys } from 'services/localForage/types';
import { FontNames } from 'types/global/fonts';
import { Colors } from 'types/global/colors';
import { stateOptions } from 'constants/States';

import { getSubtitleOptionsByIndustry } from '../../utils';
import {
  Select,
  ControlledInput,
  ProcessFormTitle,
  CompanyFileInput,
  ControlledSelect,
} from '../index';
import { sizeOptions } from '../../constants/sizeOptions';

import styles from './CreateAccountForm.module.scss';

import type { TCreateAccountFormProps, TOrganizationData } from './types';

const CreateAccountForm: FC<TCreateAccountFormProps> = ({
  control,
  errors,
  getFile,
  resetField,
  getTypeOfBusinessValue,
}) => {
  const { width } = useWindowSize();

  const [organizationPreviousData, setOrganizationPreviousData] =
    useState<TOrganizationData | null>(null);

  const [logoSrc, setLogoSrc] = useState<string | null>(null);

  useEffect(() => {
    if (organizationPreviousData?.companyLogo) {
      try {
        const imageUrl = URL.createObjectURL(organizationPreviousData?.companyLogo);
        setLogoSrc(imageUrl);
      } catch (error) {
        //
      }
    }
  }, [organizationPreviousData?.companyLogo]);

  const currentIndustry = BrowserStorageService.get(BrowserStorageKeys.CurrentIndustry, {
    session: true,
  });

  const organizationData = async () =>
    await LocalForageService.get(LocalForageKeys.ProcessFormFirstStepData).then((res) => {
      setOrganizationPreviousData(res);
    });

  useEffect(() => {
    organizationData();
  }, []);

  const [activeValue, setActiveValue] = useState<string>(currentIndustry || '');

  const secondOptions = getSubtitleOptionsByIndustry(activeValue, industryOptions, subtitleOptions);

  return (
    <div>
      <ProcessFormTitle
        lightSubtitle
        title={Number(width) > 576 ? 'Create Organization' : 'Profile Form'}
      />
      <div>
        <span className='w-full flex flex-col mx-auto mt-4 gap-7'>
          <p className='font-semibold'>About your organization</p>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
            <CompanyFileInput
              forLogo
              maxWidth='120%'
              imgSrc={logoSrc}
              getFile={getFile}
              blockMaxWidth='120%'
              containerMaxWidth='120%'
              withBottomBlock={false}
              containerClassName={styles.container__content__footer__file}
              containerBoxClassName={styles.container__content__footer__file}
              replaceClasses={styles.container__content__footer__file__replace}
            />

            <div className='flex flex-col gap-6'>
              <ControlledInput
                control={control}
                name='organization'
                required
                autoComplete='organization'
                label='Organization Name:'
                borderColor={Colors.SOFT_SILVER}
                error={!!errors.organization}
                placeholder='Organization Name:'
                fontFamily={FontNames.AVENIR_NEXT_PRO}
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
                helperText={errors.organization ? (errors?.organization?.message as string) : ''}
              />
              <Controller
                control={control}
                name='industryVertical'
                rules={{ required: 'Industry is required' }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={(event: SelectChangeEvent) => {
                        onChange(event.target.value);
                        setActiveValue(event.target.value);
                        getTypeOfBusinessValue?.('');

                        resetField?.();
                      }}
                      value={value}
                      defaultValue=''
                      borderRadius='5px'
                      label='Industry:*'
                      options={industryOptions}
                      fontFamily={FontNames.AVENIR_NEXT_PRO}
                      error={!!errors.industryVertical}
                      border={`1px solid ${Colors.SOFT_SILVER}`}
                      labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    />
                  );
                }}
              />
              <Controller
                name='typeOfBusiness'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={(event: SelectChangeEvent) => {
                      onChange(event.target.value);
                      getTypeOfBusinessValue?.(event?.target.value);
                    }}
                    required
                    showLabel
                    value={value}
                    defaultValue=''
                    borderRadius='5px'
                    options={secondOptions}
                    label='Type of Business:*'
                    fontFamily={FontNames.AVENIR_NEXT_PRO}
                    error={!!errors.typeOfBusiness}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    border={`1px solid ${Colors.SOFT_SILVER}`}
                  />
                )}
              />

              <ControlledSelect
                name='size'
                // icon={SettingsIcon}
                defaultValue=''
                control={control}
                options={sizeOptions}
                label='Organization Size:*'
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
                fontFamily={FontNames.AVENIR_NEXT_PRO}
                border={`1px solid ${Colors.SOFT_SILVER}`}
              />
            </div>
          </div>
          <div className='mt-8 flex flex-col gap-7'>
            <div className='mt-8 flex flex-col gap-7'>
              <p className='font-semibold'>Organization&apos;s Address</p>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
                <ControlledInput
                  control={control}
                  name='address'
                  label='Street:*'
                  placeholder='Street:*'
                  autoComplete='address-line1'
                  borderColor={Colors.SOFT_SILVER}
                  fontFamily={FontNames.AVENIR_NEXT_PRO}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                />
                <ControlledInput
                  name='email'
                  label='Email:*'
                  control={control}
                  readonly
                  disabled
                  placeholder='Email:*'
                  fontFamily={FontNames.AVENIR_NEXT_PRO}
                  borderColor={Colors.SOFT_SILVER}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  helperText={errors.email && (errors?.email?.message as string)}
                />
                <ControlledInput
                  control={control}
                  name='city'
                  label='City:*'
                  error={!!errors.city}
                  autoComplete='address-level2'
                  placeholder='City'
                  helperText={(errors?.city?.message as string) ?? ''}
                />
                <ControlledSelect
                  control={control}
                  name='state'
                  label='State:*'
                  autoComplete='address-level1'
                  options={stateOptions}
                  border={`1px solid ${Colors.SOFT_SILVER}`}
                />
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export default CreateAccountForm;
