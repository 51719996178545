import { createAsyncThunk } from '@reduxjs/toolkit';
import { orgranizationsApi } from 'api';
import { AxiosError } from 'axios';

export const generateApiKey = createAsyncThunk('ApiSlice/generateApiKey', async (name: string) => {
  try {
    const response = await orgranizationsApi.generateApiKeyRequest(name);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
});
