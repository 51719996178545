import { TOption } from 'components/shared/Select/types';

export const countries: TOption[] = [
  { id: 1, value: 'US', label: 'USA' },
  { id: 2, value: 'CA', label: 'Canada' },
];

export const canadaStates: TOption[] = [
  { id: 1, value: 'AB', label: 'Alberta' },
  { id: 2, value: 'BC', label: 'British Columbia' },
  { id: 3, value: 'MB', label: 'Manitoba' },
  { id: 4, value: 'NL', label: 'Newfoundland and Labrador' },
  { id: 5, value: 'NS', label: 'Nova Scotia' },
  { id: 6, value: 'ON', label: 'Ontario' },
  { id: 7, value: 'PE', label: 'Prince Edward Island' },
  { id: 8, value: 'QC', label: 'Quebec' },
];

export const stateOptions: TOption[] = [
  
    { id: 1, value: 'Alabama', label: 'Alabama' },
    { id: 2, value: 'Alaska', label: 'Alaska' },
    { id: 3, value: 'Arizona', label: 'Arizona' },
    { id: 4, value: 'Arkansas', label: 'Arkansas' },
    { id: 5, value: 'California', label: 'California' },
    { id: 6, value: 'Colorado', label: 'Colorado' },
    { id: 7, value: 'Connecticut', label: 'Connecticut' },
    { id: 8, value: 'Delaware', label: 'Delaware' },
    { id: 9, value: 'Florida', label: 'Florida' },
    { id: 10, value: 'Georgia', label: 'Georgia' },
    { id: 11, value: 'Hawaii', label: 'Hawaii' },
    { id: 12, value: 'Idaho', label: 'Idaho' },
    { id: 13, value: 'Illinois', label: 'Illinois' },
    { id: 14, value: 'Indiana', label: 'Indiana' },
    { id: 15, value: 'Iowa', label: 'Iowa' },
    { id: 16, value: 'Kansas', label: 'Kansas' },
    { id: 17, value: 'Kentucky', label: 'Kentucky' },
    { id: 18, value: 'Louisiana', label: 'Louisiana' },
    { id: 19, value: 'Maine', label: 'Maine' },
    { id: 20, value: 'Maryland', label: 'Maryland' },
    { id: 21, value: 'Massachusetts', label: 'Massachusetts' },
    { id: 22, value: 'Michigan', label: 'Michigan' },
    { id: 23, value: 'Minnesota', label: 'Minnesota' },
    { id: 24, value: 'Mississippi', label: 'Mississippi' },
    { id: 25, value: 'Missouri', label: 'Missouri' },
    { id: 26, value: 'Montana', label: 'Montana' },
    { id: 27, value: 'Nebraska', label: 'Nebraska' },
    { id: 28, value: 'Nevada', label: 'Nevada' },
    { id: 29, value: 'New Hampshire', label: 'New Hampshire' },
    { id: 30, value: 'New Jersey', label: 'New Jersey' },
    { id: 31, value: 'New Mexico', label: 'New Mexico' },
    { id: 32, value: 'New York', label: 'New York' },
    { id: 33, value: 'North Carolina', label: 'North Carolina' },
    { id: 34, value: 'North Dakota', label: 'North Dakota' },
    { id: 35, value: 'Ohio', label: 'Ohio' },
    { id: 36, value: 'Oklahoma', label: 'Oklahoma' },
    { id: 37, value: 'Oregon', label: 'Oregon' },
    { id: 38, value: 'Pennsylvania', label: 'Pennsylvania' },
    { id: 39, value: 'Rhode Island', label: 'Rhode Island' },
    { id: 40, value: 'South Carolina', label: 'South Carolina' },
    { id: 41, value: 'South Dakota', label: 'South Dakota' },
    { id: 42, value: 'Tennessee', label: 'Tennessee' },
    { id: 43, value: 'Texas', label: 'Texas' },
    { id: 44, value: 'Utah', label: 'Utah' },
    { id: 45, value: 'Vermont', label: 'Vermont' },
    { id: 46, value: 'Virginia', label: 'Virginia' },
    { id: 47, value: 'Washington', label: 'Washington' },
    { id: 48, value: 'West Virginia', label: 'West Virginia' },
    { id: 49, value: 'Wisconsin', label: 'Wisconsin' },
    { id: 50, value: 'Wyoming', label: 'Wyoming' },
  ];
  