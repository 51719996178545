import { lazy, Suspense, useState } from 'react';

import BlurBlue from 'components/style/BlurBlue';
import Hero from 'components/home/Hero';

import NavBar from '../../components/shared/NavBar';
import Analytics from '../../components/home/Analytics';
import HeroSection from '../../components/home/HeroSection';
import Decision from '../../components/home/Decision';
import Experience from '../../components/home/Experience';
import DiverseIndustries from '../../components/home/DiverseIndustries';
import OtherFeatures from '../../components/home/OtherFeatures';
import WorkSmarter from '../../components/home/WorkSmarter';
import Footer from '../../components/shared/Footer';
const ContactForm = lazy(() => import('components/contact-us/ContactForm'));
const CustomModal = lazy(() => import('components/Modal'));

const Home = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  function onOpenContactForm() {
    setIsContactFormOpen(true);
  }

  function onCloseContactForm() {
    setIsContactFormOpen(false);
  }

  return (
    <div className='w-full mx-auto overflow-hidden font-Roboto bg-white dark:bg-darkBg'>
      <div className=''>
        <BlurBlue />

        <NavBar />

        <HeroSection onClickScheduleCall={onOpenContactForm} />
        {/* <Hero onClickScheduleCall={onOpenContactForm} /> */}
      </div>
      <Analytics />
      <Decision onClickScheduleCall={onOpenContactForm} />
      <Experience />
      <DiverseIndustries />
      <OtherFeatures />
      <WorkSmarter onClickScheduleCall={onOpenContactForm} />
      <Footer />
      <Suspense fallback={null}>
        <CustomModal isOpen={isContactFormOpen} onClose={onCloseContactForm} className='!p-0'>
          <ContactForm
            isModal
            containerClassName='!px-0 !w-[80vw] md:!w-full'
            contentContainerClassName='bg-pill-bg dark:bg-industry-hero-dark dark:text-white rounded-[10px] flex flex-col-reverse lg:flex-row gap-8'
            onClose={onCloseContactForm}
          />
        </CustomModal>
      </Suspense>
    </div>
  );
};

export default Home;
