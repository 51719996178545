import { createSlice } from '@reduxjs/toolkit';

import { getAllTeams, updateTeam } from './thunks';
import { TInitialState } from './type';

const initialState: TInitialState = {
  teams: [],
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTeams.fulfilled, (state, action) => {
      state.teams = action.payload.data;
    });
    builder.addCase(updateTeam.fulfilled, (state: any, action: any) => {
      const updatedTeam = action.payload;
      const teamIndex = state.teams.findIndex((team: any) => team.id === updatedTeam.id);
      if (teamIndex >= 0) {
        state.teams[teamIndex] = updatedTeam;
      }
    });
  },
});

export default teamsSlice.reducer;
