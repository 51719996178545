import { DetailsList } from 'types/components.types';

import IndustryReasonComponent from './IndustryReasonComponent';
import DetailsListComponent from './DetailsListComponent';
import IndustriesHeroSection from './IndustriesHeroSection';

const detailsData: DetailsList[] = [
  {
    title: 'Inventory Management',
    image: '/images/industriesPage/production1.svg',
    subtitle:
      'Accurate people counting can aid in inventory management, helping manufacturers track the movement of personnel in and out of stockrooms and warehouses.',
  },
  {
    title: 'Real-time Alerts',
    image: '/images/industriesPage/production2.svg',
    subtitle:
      'People counting AI helps monitor occupancy and ensures that safety protocols are adhered to, reducing the risk of accidents and injuries.',
  },
  {
    title: 'Enhanced Safety',
    image: '/images/industriesPage/production3.svg',
    subtitle:
      'People counting AI can monitor occupancy levels and ensure that areas are not overcrowded, promoting workplace safety and compliance with social distancing regulations.',
  },
  {
    title: 'Employee Satisfaction:',
    image: '/images/industriesPage/production4.svg',
    subtitle:
      'Improved working conditions through optimal spacing and reduced overcrowding can lead to higher employee morale and satisfaction.',
  },
];

const ProductionSection = (): JSX.Element => {
  return (
    <section>
      <IndustriesHeroSection
        imageURL={'/images/industriesPage/production-hero.svg'}
        title='Production Company Monitoring AI'
      />

      <IndustryReasonComponent
        data={{
          title:
            'How does the Production and Manufacturing Industry benefit from HawkEyeVisionTek?',
          reasons: [
            'It has countless applications and purposes within the Manufacturing and Production industry. Gather metrics on job performance, effectiveness of assembly, tardiness, and other relevant information for your business.',
            'This will improve efficiency, capacity, safety, quality and the production of goods.  Revenue will increase significantly as well, allowing you to grow your Manufacturing and/or Production business expeditiously!',
          ],
        }}
      />

      <DetailsListComponent data={detailsData} />
    </section>
  );
};

export default ProductionSection;
