import { useMemo } from 'react';

import { userRoleEnums } from 'components/shared/EmployeeStatus/types';
import permissions from 'constants/Permissions';
import { TRole } from 'store/slices/authSlice/types';

const usePermissions = () => {
  const user_roles = JSON.parse(localStorage.getItem('userRoles') ?? '') as TRole[];

  const isAllowedToManageBillingSettings = useMemo(
    () =>
      user_roles?.some((role) =>
        permissions.manageBillingSettings.includes(role?.role as userRoleEnums),
      ),
    [user_roles],
  );

  const isAllowedToEditOrganisation = useMemo(
    () =>
      user_roles?.some((role) =>
        permissions.editOrganisationSettings.includes(role?.role as userRoleEnums),
      ),
    [user_roles],
  );

  const isAllowedToManageInviteSettings = useMemo(
    () =>
      user_roles?.some((role) =>
        permissions.manageInviteSettings.includes(role?.role as userRoleEnums),
      ),
    [user_roles],
  );

  return {
    isAllowedToManageBillingSettings,
    isAllowedToEditOrganisation,
    isAllowedToManageInviteSettings,
  };
};

export default usePermissions;
