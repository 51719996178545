import { Box } from '@mui/material';
import { FC } from 'react';
import classNames from 'classnames';

import styles from './FormLayout.module.scss';

import type { TFromLayoutProps } from './types';

const PaymentBox: FC<TFromLayoutProps> = ({ title, children, withIcons = false, className }) => {
  const containerClasses = classNames(styles.container, className, {
    [styles.container__icons]: withIcons,
  });

  return (
    <Box className={containerClasses}>
      {title && <p className='font-semibold'>{title}</p>}
      {children}
    </Box>
  );
};

export default PaymentBox;
