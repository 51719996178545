import React, { useCallback, useMemo, useState } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import dayjs from 'dayjs';

import { useAppDispatch } from 'app/hooks';
import { acceptUserInvitation, rejectUserInvitation, updateNotification } from 'store/thunks';
import { getPendingUserInvitesSelector } from 'store/slices/invitationsSlice/selectors';
import { TNotification, TPostMetadata } from 'store/slices/activitiesSlices/types';
import { useAppSelector, useDarkMode } from 'hooks';
import { notificationsSelector } from 'store/slices/activitiesSlices/selector';
import { TUserInvite } from 'store/slices/invitationsSlice/types';
import { EmptyStateNotifications, EmptyStateNotificationsDark } from 'assets/icons';
import {
  getDateInTimeZone,
  getRelativeTime,
  replaceActivityEntitiesWithNames,
} from 'helpers/utils';
import { Colors } from 'types';
import UserAvatar from 'components/shared/UserAvatar';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';

type TAnyNotification = {
  type: 'general' | 'invite';
  createdAt: string;
  notification?: TNotification;
  invite?: TUserInvite;
};

const InviteNotification = ({ invite }: TAnyNotification) => {
  const dispatch = useAppDispatch();
  const acceptInvite = async (option: { id: number; organizationName: string }) =>
    await dispatch(acceptUserInvitation(option));

  const [isShowingConfirmation, setIsShowingConfirmation] = useState<boolean>(false);

  const { name, email, media_url_id } = invite?.organization ?? {};

  const onDelete = async () => {
    setIsShowingConfirmation(false);
    await dispatch(rejectUserInvitation(invite?.id as number));
  };

  return (
    <>
      <div className='flex flex-row items-center gap-4 pt-5 cursor-pointer'>
        <div className='basis-0.5/5 md:basis-1/12'>
          <UserAvatar
            fullName={name}
            mediaFolder='organization'
            mediaId={media_url_id as string}
            alt='organization icon'
          />
        </div>
        <div className='flex flex-col gap-2 basis-3/5 md:basis-9/12'>
          <p className='font-semibold capitalize truncate w-[150px] md:w-full dark:text-white'>
            {name}
          </p>
          <p className='text-[#222222] opacity-50 truncate w-[150px] md:w-full dark:text-white'>
            {email}
          </p>
        </div>
        <div className='flex flex-row justify-center gap-2 md:gap-6 basis-1/5 md:basis-2/12'>
          <button
            className='cursor-pointer flex flex-row items-center justify-center md:gap-2'
            onClick={() =>
              acceptInvite({ id: invite?.id as number, organizationName: name as string })
            }
          >
            <DoneOutlinedIcon className='flex text-accent md:hidden' />
          </button>
          <button
            className='cursor-pointer flex flex-row items-center justify-center md:gap-2'
            onClick={() => setIsShowingConfirmation(true)}
          >
            <ClearOutlinedIcon className='flex text-red-500 md:hidden' />
          </button>
        </div>
      </div>
      <ConfirmDeleteModal
        isOpen={isShowingConfirmation}
        onClose={() => setIsShowingConfirmation(false)}
        title='Reject Invitation'
        description='Do you want to reject this invitation?'
        deleteText='Reject'
        onDelete={onDelete}
      />
    </>
  );
};

const GeneralNotification = ({ notification }: TAnyNotification) => {
  const dispatch = useAppDispatch();
  const { is_read, activity, id } = notification ?? {};

  const markNotificationAsRead = useCallback(() => {
    dispatch(updateNotification({ is_read: true, notificationIds: [id as number] }));
  }, [dispatch, id]);

  return (
    <button
      className='flex flex-row items-center gap-4 pt-5 cursor-pointer'
      onClick={markNotificationAsRead}
    >
      <div className='basis-0.5/6 md:basis-1/12'>
        <UserAvatar
          fullName={activity?.activity_type}
          mediaFolder='organization'
          // mediaId={media_url_id as string}
          alt='organization icon'
        />
      </div>
      <div className='flex flex-col gap-2 basis-3/4 md:basis-10/12'>
        <p className='font-semibold basis-3/6 line-clamp-2 dark:text-white'>
          {replaceActivityEntitiesWithNames(
            activity?.content as string,
            activity?.metadata as TPostMetadata,
          )}
        </p>
        <p className='text-[#222222] opacity-50 basis-3/6 truncate dark:text-white'>
          {getRelativeTime(activity?.created_at as string)}
        </p>
      </div>
      <div className='flex row justify-center basis-0.5/6 md:basis-1/12'>
        {is_read ? null : <div className='h-3 w-3 rounded-full bg-blue-600' />}
      </div>
    </button>
  );
};

function Notification(notification: TAnyNotification) {
  return notification.type === 'invite' ? (
    <InviteNotification {...notification} />
  ) : (
    <GeneralNotification {...notification} />
  );
}

const NotificationsCard: React.FC = () => {
  const pendingUserInvites = useAppSelector(getPendingUserInvitesSelector);
  const getAllUserNotifications = useAppSelector(notificationsSelector);
  const { data: notifications } = getAllUserNotifications ?? {};
  const { darkMode } = useDarkMode();

  const allNotifications: TAnyNotification[] = useMemo(
    () => [
      ...pendingUserInvites.map((invite) => ({
        type: 'invite' as const,
        invite,
        createdAt: dayjs(invite.created_at).format('YYYY-MM-DDTHH:MM:ss'),
      })),
      ...notifications.map((notification) => ({
        type: 'general' as const,
        notification,
        createdAt: dayjs(getDateInTimeZone(notification.created_at)).format('YYYY-MM-DDTHH:MM:ss'),
      })),
    ],
    [notifications, pendingUserInvites],
  );

  const allNotificationsSortedByTime = useMemo(
    () =>
      allNotifications
        .sort((x, y) => {
          const previous = new Date(x.createdAt).getTime();
          const next = new Date(y.createdAt).getTime();

          if (previous < next) {
            return -1;
          }
          if (previous > next) {
            return 1;
          }
          return 0;
        })
        .reverse(),
    [allNotifications],
  );

  return (
    <div className='flex flex-col p-5' style={{ background: darkMode ? Colors.DARK_MODE_BG : '' }}>
      <p className='text-[20px] font-semibold pb-4 dark:text-white'>Notifications</p>
      <hr className='border-gray-100' />
      {allNotificationsSortedByTime?.map((notification, index) => {
        const key = notification.type + index;
        return <Notification key={key} {...notification} />;
      })}
      {!allNotificationsSortedByTime?.length && (
        <div className='mt-5 py-10  px-10 flex justify-center'>
          <div className='mt-5 pt-5 flex flex-col justify-center'>
            {darkMode ? (
              <EmptyStateNotificationsDark style={{ height: '12em', width: '17em' }} />
            ) : (
              <EmptyStateNotifications style={{ height: '12em', width: '17em' }} />
            )}
            <p className='font-semibold dark:text-white'>No notifications at the moment.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsCard;
