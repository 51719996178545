import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { FC } from 'react';
import { ChartData } from 'chart.js/auto';
import classNames from 'classnames';

export const LineGraph: FC<{
  data?: ChartData<'line', number[], string>;
  emptyStateData: ChartData<'line', number[], string>;
  options: any;
  className?: string;
}> = ({ data, emptyStateData, options, className }) => {
  const mergedOptions = {
    ...options,
    layout: {
      padding: {
        top: 15,
        bottom: 10,
      },
    },
    plugins: {
      ...options.plugins,
      datalabels: {
        anchor: 'start',
        align: 'end',
        offset: 4,
        font: {
          size: 10,
        },
        color: 'grey',
      },
    },
  };
  return (
    <div
      className={classNames('', {
        'flex gap-2 relative px-2 w-full h-full': !className,
        [`${className}`]: !!className,
      })}
    >
      {!data && emptyStateData && (
        <h5 className=' flex justify-center items-center '>No Data</h5>
      )}
      <Line data={data ?? emptyStateData} options={mergedOptions} />
    </div>
  );
};
