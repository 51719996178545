import { Modal } from '@mui/material';
import classNames from 'classnames';

import { VoidCallback } from 'types';

export type TModalProps = {
  isOpen: boolean;
  onClose: VoidCallback;
  className?: string;
  useClassNameOnly?: boolean;
  children: JSX.Element | JSX.Element[];
};

const CustomModal = ({ isOpen, onClose, children, className, useClassNameOnly }: TModalProps) => (
  <Modal open={isOpen} onClose={onClose} className='mt-2 overflow-scroll' id='clearScrollbar'>
    <div
      className={!useClassNameOnly ? classNames(
        'mx-auto w-auto md:w-3/4 rounded-xl p-6  object-cover border-0 top-1/2 left-1/2 bg-white',
        className,
      ) : className}
    >
      {children}
    </div>
  </Modal>
);

export default CustomModal;
