import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import classNames from 'classnames';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

import { useDarkMode } from 'hooks';

import styles from './ListItem.module.scss';

import type { TListItemProps } from './types';

const ListItem: FC<TListItemProps> = ({ title, spacing = 1, isActive = false }) => {
  const listItemClasses = classNames(styles.container, { [styles.container_active]: isActive });
  const { darkMode } = useDarkMode();

  return (
    <Stack direction='row' spacing={spacing} className={listItemClasses} alignItems='flex-start'>
      <Box className={styles.container__icon}>
        <CheckCircleIcon
          className={classNames('w-[24px] text-accent', {
            'text-white': isActive,
          })}
        />
      </Box>
      <p className={darkMode ? styles.container__item_dark : styles.container__item}>{title}</p>
    </Stack>
  );
};

export default ListItem;
