import { TPlanDurationPrices, TSubscriptionPlan } from 'types/global/durationTypes';

export const MONTHLY_DURATION = "Monthly"
export const YEARLY_DURATION = "Yearly"
export const API_FILTER = "API"
export const DEVICE_FILTER = "DEVICE"

export const subscriptionPlansNew: TSubscriptionPlan[] = [
  //API plans
  {
    id: 1,
    title: 'Basic Plan',
    type: 'API',
    options: [
      'API Calls: 500 per month',
      'Additional Calls: $0.05 per month',
      'Team Members: Up to 4',
      `Perfect for: Small to medium-sized churches or those just starting with audience analysis`,
      `Price: $199/month`,
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 199 },
      { id: 2, duration: 'Yearly', price: 2000 },
    ],
    monthlyPrice: 199,
    yearlyPrice: 2000,
  },
  {
    id: 2,
    title: `Pro Plan`,
    type: 'API',
    options: [
      'API Calls: 2,500 per month',
      'Additional Calls: $0.04 per month',
      'Team Members: Up to 7',
      `Perfect for: Larger churches or those with multiple services or locations`,
      `Price: $299/month`,
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 299 },
      { id: 2, duration: 'Yearly', price: 3000 },
    ],
    monthlyPrice: 299,
    yearlyPrice: 3000,
  },
  {
    id: 3,
    title: 'Premier Plan',
    type: 'API',
    options: [
      'API Calls: 10,000 per month',
      'Additional Calls: $0.03 per month',
      'Team Members: Up to 10',
      `Perfect for: Small to medium-sized churches or those just starting with audience analysis`,
      `Price: $399/month`,
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 399 },
      { id: 2, duration: 'Yearly', price: 4000 },
    ],
    monthlyPrice: 399,
    yearlyPrice: 4000,
  },
  {
    id: 4,
    title: 'Custom Plan',
    type: 'API',
    options: [
      'API Calls: Custom',
      'Additional Calls: Custom',
      'Team Members: Custom',
      `Perfect for: All organizations who want a customized plan to fit their specific needs`,
      `Price: Custom Pricing`,
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 0 },
      { id: 2, duration: 'Yearly', price: 0 },
    ],
    monthlyPrice: 0,
    yearlyPrice: 0,
  },

  // device Plans
  {
    id: 5,
    title: 'Basic Plan',
    type: 'Device',
    options: [
      'Devices: 1-7',
      'Team Members: Up to 4',
      `Perfect for: Small to medium-sized churches or those just starting with audience analysis`,
      `Price: $199/month or $2,000/year (save over 16%)`,
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 199 },
      { id: 2, duration: 'Yearly', price: 2000 },
    ],
    monthlyPrice: 199,
    yearlyPrice: 2000,
  },
  {
    id: 6,
    title: 'Pro Plan',
    type: 'Device',
    options: [
      'Devices: 8-14',
      'Team Members: Up to 7',
      `Perfect for: Larger churches or those with multiple services or locations`,
      `Price: $299/month or $3,000/year (save 16%)`,
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 299 },
      { id: 2, duration: 'Yearly', price: 3000 },
    ],
    monthlyPrice: 299,
    yearlyPrice: 3000,
  },
  {
    id: 7,
    title: 'Premier Plan',
    type: 'Device',
    options: [
      'Devices: 15-20',
      'Team Members: Up to 10',
      `Perfect for: Large churches, multi-campus organizations, or large religious events`,
      `Price: $399/month or $4,000/year (save 16%)`,
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 399 },
      { id: 2, duration: 'Yearly', price: 4000 },
    ],
    monthlyPrice: 399,
    yearlyPrice: 4000,
  },
  {
    id: 8,
    title: 'Custom Plan',
    type: 'Device',
    options: [
      'Devices: Custom',
      'Team Members: Custom',
      `Perfect for: All organizations who want a customized plan to fit their specific needs`,
      `Price: Custom Pricing`,
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 0 },
      { id: 2, duration: 'Yearly', price: 0 },
    ],
    monthlyPrice: 0,
    yearlyPrice: 0,
  },
];

const subscriptionPlans = {
  API: {
    BasicPlan: [
      'API Calls: 500 per month',
      'Additional Calls: $0.05 per month',
      'Team Members: Up to 4',
      'Perfect for: Small to medium-sized churches or those just starting with audience analysis',
    ],
    ProPlan: [
      'API Calls: 2,500 per month',
      'Additional Calls: $0.04 per month',
      'Team Members: Up to 7',
      'Perfect for: Larger churches or those with multiple services or locations',
    ],
    PremierPlan: [
      'API Calls: 10,000 per month',
      'Additional Calls: $0.03 per month',
      'Team Members: Up to 10',
      'Perfect for: Small to medium-sized churches or those just starting with audience analysis',
    ],
    CustomPlan: [
      'API Calls: Custom',
      'Additional Calls: Custom',
      'Team Members: Custom',
      'Perfect for: All organizations who want a customized plan to fit their specific needs',
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 25 },
      { id: 2, duration: 'Yearly', price: 270 },
    ] as TPlanDurationPrices[],
  },

  device: {
    BasicPlan: [
      'Devices: 1-7',
      'Team Members: Up to 4',
      'Perfect for: Small to medium-sized churches or those just starting with audience analysis',
    ],
    ProPlan: [
      'Devices: 8-14',
      'Team Members: Up to 7',
      'Perfect for: Larger churches or those with multiple services or locations',
    ],
    PremierPlan: [
      'Devices: 15-20',
      'Team Members: Up to 10',
      'Perfect for: Large churches, multi-campus organizations, or large religious events',
    ],
    CustomPlan: [
      'Devices: Custom',
      'Team Members: Custom',
      'Perfect for: All organizations who want a customized plan to fit their specific needs',
    ],
    durations: [
      { id: 1, duration: 'Monthly', price: 30 },
      { id: 2, duration: 'Yearly', price: 330 },
    ] as TPlanDurationPrices[],
  },
};

export default subscriptionPlans;
