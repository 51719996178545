import { RootState } from 'types';
export const postsSelector = (state: RootState) => state.activities.posts;
export const updatingPostSelector = (state: RootState) => state.activities.isUpdatingPost;
export const deletingPostSelector = (state: RootState) => state.activities.isDeletingPost;

export const notificationsSelector = (state: RootState) => state.activities.notifications;

export const allPostComments = (state: RootState) => state.activities.postComments;
export const creatingPostsSelector = (state: RootState) => state.activities.creatingPost;
export const newsSelector = (state: RootState) => state.activities.news;

// export const refetchFiltering = (state: RootState) => state.activities.refetchFiltering;

// export const allNoticesLoadingSelector = (state: RootState) => state.activities.noticesLoading;

// export const allActivitiesSelector = (state: RootState) => state.activities.activities;
// export const noticesOffsetSelector = (state: RootState) => state.activities.noticesOffset;
// export const noticesLimitSelector = (state: RootState) => state.activities.noticesLimit;

// export const allActivitiesLoadingSelector = (state: RootState) =>
//   state.activities.activitiesLoading;

// export const postAllCommentsSelector = (state: RootState) => state.activities.postAllComments;

// export const hiddenActivityPostsSelector = (state: RootState) =>
//   state.activities.hiddenActivityPosts;

// export const usersActivitiesSelector = (state: RootState) => state.activities.usersActivities;

// export const usersActivitiesLoadingSelector = (state: RootState) =>
//   state.activities.usersActivitiesLoading;

// export const allActivitiesLimitSelector = (state: RootState) => state.activities.allActivitiesLimit;

// export const allActivitiesOffsetSelector = (state: RootState) =>
//   state.activities.allActivitiesOffset;

// export const usersActivitiesLimitSelector = (state: RootState) =>
//   state.activities.usersActivitiesLimit;

// export const usersActivitiesOffsetSelector = (state: RootState) =>
//   state.activities.usersActivitiesOffset;

// export const postCommentCreateLoadSelector = (state: RootState) =>
//   state.activities.postCommentCreateLoad;
