import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { getLocationCoordinatesRequest, getWeatherRequest } from 'api/weather';

import { TLocationParams, TWeatherParams } from './types';

export const getWeather = createAsyncThunk('weather/getWeather', async (params: TWeatherParams) => {
  try {
    const response = await getWeatherRequest(params);
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    throw err;
  }
});

export const getLocationCoordinates = createAsyncThunk(
  'weather/getLocationCoordinates',
  async (params: TLocationParams) => {
    try {
      const response = await getLocationCoordinatesRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);
