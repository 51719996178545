import { ProcessFormNames } from 'containers/ProcessForm/types';
import { TProcessForm } from 'types/global/processForm';

const processFormValues: TProcessForm[] = [
  { id: 0, title: ProcessFormNames.PROCESSFORM },
  { id: 1, title: ProcessFormNames.SUBSCRIPTIONPLAN },
  { id: 2, title: ProcessFormNames.PAYMENT },
  { id: 3, title: ProcessFormNames.INVITEUSERS },
];

export default processFormValues;
