import { AxiosResponse } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TAllTeamsMembersParams,
  TAllTeamsParams,
  TCreateTeamParams,
  TGetTeamsResponse,
  TUpdateTeam,
} from 'store/slices/teamsSlice/type';

export const getTeamsRequest = (id: number) => {
  return client.get(endpoints.TeamsService.getTeam(), { params: { team_id: id } });
};

export const getTeamMemberRequest = (id: number) => {
  return client.get(endpoints.TeamsService.getTeamMember(), { params: { id: id } });
};

export const getAllTeamsRequest = ({
  limit,
  offset,
}: TAllTeamsParams): Promise<AxiosResponse<TGetTeamsResponse>> => {
  return client.get(endpoints.TeamsService.getAllTeams(), { params: { limit, offset } });
};

export const getAllTeamMembersRequest = ({
  team_id,
  sort_by,
  asc,
  limit,
  offset,
}: TAllTeamsMembersParams) => {
  return client.get(endpoints.TeamsService.getAllTeamMembers(), {
    params: { team_id, sort_by, asc, limit, offset },
  });
};

export const createNewTeam = (params: TCreateTeamParams) => {
  const sendedData = new FormData();
  sendedData.append('name', params.name);
  sendedData.append('media', params.media || '');
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return client.post(endpoints.TeamsService.createTeam(params), sendedData);
};

export const createNewTeamMember = (body: TCreateTeamParams) => {
  return client.post(endpoints.TeamsService.createTeamMember(), body);
};

export const getTeamMemberByIDRequest = (id: number) => {
  return client.get(endpoints.TeamsService.getTeamMemberbyID(), { params: { id: id } });
};

export const getTeamByIDRequest = (id: number) => {
  return client.get(endpoints.TeamsService.getTeambyID(), { params: { team_id: id } });
};

export const deleteTeamMemberbyIDRequest = (id: number) => {
  return client.delete(endpoints.TeamsService.deleteTeamMemberbyID(), { params: { id: id } });
};

export const deleteTeamRequest = (id: number) => {
  return client.delete(endpoints.TeamsService.deleteTeam(), { params: { id: id } });
};

export const editTeamMemberRequest = (body: any) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return client.put(endpoints.TeamsService.editTeamMember(), body, options);
};

export const updateTeamRequest = async (
  team_update: FormData
) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  
  return client.put(endpoints.TeamsService.updateTeam(), team_update, options);
};
