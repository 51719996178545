import axios, { AxiosResponse } from 'axios';

import { endpoints } from 'api/endpoints';
import { TLocationParams, TWeatherParams } from 'store/slices/weather/types';

import { TLocationResponse, TWeatherResponse } from './types';

export const getWeatherRequest = (
  params: TWeatherParams,
): Promise<AxiosResponse<TWeatherResponse>> =>
  axios.get(endpoints.WeatherService.getWeather(), { params });

export const getLocationCoordinatesRequest = (
  params: TLocationParams,
): Promise<AxiosResponse<TLocationResponse>> =>
  axios.get(endpoints.WeatherService.getLocationCoordinates(), { params });
