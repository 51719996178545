import React from 'react';

interface ContainerProps {
  id?: string;
  children: React.ReactNode;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({ id, children, className }) => {
  return (
    <div
      id={id}
      className={`w-full max-w-[1500px] px-6 xl:px-10 lg:px-8 md:px-5 mx-auto ${className}`}
    >
      {children}
    </div>
  );
};

Container.defaultProps = {
  className: '',
};

export default Container;
