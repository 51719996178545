import Badge from '../../components/shared/Badge';
import Card from '../../components/shared/Card';
import Container from '../../components/shared/Container';

const Analytics = () => {
  const analyticsInfo = [
    {
      icons: 'images/entity.svg',
      title: 'Entity detection & analysis',
      subTitle:
        'Advanced AI-driven identification and analysis of various entities within visual data.',
      linkTitle: 'More on Entity',
    },
    {
      icons: 'images/flowAnalytics.svg',
      title: 'Crowd Flow Analytics',
      subTitle: 'Monitoring and assessing crowd dynamics and movements in diverse settings.',
      linkTitle: 'More on Crowd flow',
    },
    {
      icons: 'images/counting.svg',
      title: 'Counting & Quantification',
      subTitle:
        'Precise AI-enabled counting for people, products, or vehicles in various environments',
      linkTitle: 'More on counting',
    },
    {
      icons: 'images/predective.svg',
      title: 'Predictive Visual Insights',
      subTitle:
        'Proactive forecasting and recommendations based on visual data trends and patterns',
      linkTitle: 'More on predictive',
    },
  ];
  return (
    <Container className='flex flex-col gap-x-10 gap-y-10 xl:py-[70px] lg:py-16 py-14'>
      <div>
        <div className='center-child flex-col'>
          <Badge title='what we do' />
          <h3 className='md:text-[40px] text-[18px] font-semibold capitalize  font-Avenir-Next-Pro'>
            <span className='text-accent  dark:text-dark-text mb-2 '>Analytics,</span> and more...
          </h3>
          <p className='text-[16px] font-light text-sub-title dark:text-dark-sub-title md:w-[800px] text-center mb-4 '>
            Revolutionizing vision with AI-powered technology, delivering unparalleled precision,
            clarity, and insight across various industries and applications at scale.
          </p>
        </div>

        {/* <div className='grid lg:grid-cols-4 grid-cols-1  gap-5 mt-7 place-items-center'> */}
        <div className='flex md:flex-row flex-col  gap-x-12 gap-y-5 mt-7 justify-center items-center'>
          {analyticsInfo.map((analytic, index) => (
            <Card
              key={String(index + 1)}
              icon={analytic.icons}
              title={analytic.title}
              subTitle={analytic.subTitle}
              linkTitle={analytic.linkTitle}
              isBg
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Analytics;
